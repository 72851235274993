/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const WipesIcon = ({ color }) => (
  <svg width="23px" height="17px" viewBox="0 0 23 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-51" stroke={color || '#393939'}>
        <path
          d="M0.75,0.860523092 L0.75,15.6121956 L3.24941655,14.8893571 L3.45778009,14.8598324 L22.25,14.8598324 L22.25,0.966847299 C21.0908175,1.22869783 19.9300324,1.35983243 18.768553,1.35983243 C14.9670219,1.35983243 14.9670219,1.35983243 4.42226048,1.35983243 L4.31980461,1.35280134 L0.75,0.860523092 Z"
          id="Rectangle"
          strokeWidth="1.5"
        />
        <rect id="Rectangle" x="7.5" y="6.5" width="8" height="3" rx="0.5" />
      </g>
    </g>
  </svg>
)

export default WipesIcon
