import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import propTypes from './props'
import Actions from '../../../actions'
import FlatButton from '../FlatButton'
import LinkButton from '../../atoms/LinkButton'
import { FIRST_COLLECTION } from '../../../utils/urls'

const ChangePassword = ({ t, history }) => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [repassword, setRePassword] = useState('')
  const [errorChangePassword, setErrorChangePassword] = useState(false)
  const errorRecovery = useSelector(state => state.Users.errorRecovery)
  const fetchChangePassword = pass => dispatch(Actions.Users.fetchChangePasswordRecovery(pass))
  const cleanErrors = () => dispatch({ type: 'USER_ERROR_RECOVERY_CLEAN' })

  useEffect(() => {
    cleanErrors()
  }, [])

  useEffect(() => {
    if (errorRecovery === 'OK') {
      cleanErrors()
      history.replace(`/products/${FIRST_COLLECTION}`)
    }
  }, [errorRecovery])

  const handleChangePassword = (pass, repass) => {
    if (pass.length < 6) return setErrorChangePassword(t('default.recoveryPasswordShort'))
    if (pass !== repass) return setErrorChangePassword(t('default.recoveryPasswordDoesntMatch'))
    return fetchChangePassword(pass)
  }

  const useStyles = makeStyles({
    root: {
      '& label.Mui-focused': {
        color: 'grey'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green'
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#54CE8A'
        }
      }
    }
  })
  const classes = useStyles()
  return (
    <Fragment>
      <Grid container alignItems="center" justify="center" className="modal-login-signin">
        <Grid container direction="column" alignItems="center" className="recovery-content">
          <Typography variant="inherit" className="login__title margin">
            {t('default.resetPassword')}
          </Typography>
          <Grid container item xs={12}>
            <Grid item container xs={12} justify="center" className="login__field">
              <TextField
                className={classes.root}
                variant="outlined"
                id="pass"
                label={t('default.newPassword')}
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
              />
            </Grid>
            <Grid item container xs={12} justify="center" className="login__field">
              <TextField
                className={classes.root}
                variant="outlined"
                id="repass"
                label={t('default.repeatPassword')}
                type="password"
                autoComplete=""
                value={repassword}
                onChange={e => setRePassword(e.target.value)}
              />
            </Grid>
          </Grid>
          {errorChangePassword && errorChangePassword !== '' && (
            <Typography variant="body1" className="login__title">
              {errorChangePassword}
            </Typography>
          )}
          {errorRecovery && errorRecovery !== 'OK' && (
            <Typography variant="body1" className="login__title">
              {t('default.recoveryPasswordError')}
            </Typography>
          )}
          <Grid item container xs={12} className="login__links" justify="center">
            <FlatButton
              classes="login-signin-button margin"
              bgColor="green"
              title={t('default.save')}
              onClick={() => handleChangePassword(password, repassword)}
            />
          </Grid>
          <Grid className="modal-login-signin__footer margin" item container xs={12} justify="center">
            <Typography align="right">{t(`default.rememberPass`)}</Typography>

            <LinkButton
              classNames="modal-login-signin__footer"
              xs={3}
              md={2}
              text={t(`default.login`)}
              onClick={() => {
                history.push(`/products/${FIRST_COLLECTION}/login`)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

ChangePassword.propTypes = propTypes

export default translate('common')(ChangePassword)
