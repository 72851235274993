var setFieldValue = function setFieldValue(form, key, value) {
  return {
    type: 'FORMS_UPDATE_FIELD_VALUE',
    key: key,
    value: value,
    form: form
  };
};
var setDefaultValue = function setDefaultValue(form, value) {
  return {
    type: 'FORMS_SET_DEFAULT_VALUES',
    form: form,
    value: value
  };
};
export default {
  setFieldValue: setFieldValue,
  setDefaultValue: setDefaultValue
};