/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import moment from 'moment'
import Actions from '../../actions'
import propTypes from './props'
import IconComponent from '../../components/atoms/IconComponent'
import Header from '../../components/organisms/Header'
// import CheckoutFrameContainer from '../../components/atoms/CheckoutFrameContainer'
import PaymentFrame from '../../components/organisms/PaymentFrame'
import BreadCrumbs from '../../components/atoms/BreadCrumbs'
import { FIRST_COLLECTION } from '../../utils/urls'
import StripePayment from '../../components/atoms/StripePayment'

const Account = ({ history, t, i18n }) => {
  const breadCrumbs = [
    {
      label: t('products.products'),
      link: `/products/${FIRST_COLLECTION}`
    },
    {
      label: t('default.profile'),
      link: '/account'
    }
  ]
  const [addNewCard, setAddNewCard] = useState(false)
  const [cardError, setCardError] = useState(false)
  const [disableSaveCard, setDisableSaveCard] = useState(true)
  const { logged } = useSelector(state => state.Users)
  const { first_name, last_name, phone, email } = useSelector(state => state.Users.userData)
  const status = useSelector(state => state.Checkout.status)

  const { payWithNewCard } = useSelector(state => state.Checkout)
  const currentLanguage = useSelector(state => state.Language.current)
  const paymentCardToken = useSelector(state => state.Checkout.paymentCardToken)

  const { address1, address2, city, country_name, province_code } = useSelector(state =>
    state.Users.userData.addresses && state.Users.userData.addresses.length > 0
      ? state.Users.userData.addresses[0]
      : {
          address1: null,
          address2: null,
          city: null,
          country_name: null,
          province_code: null
        }
  )

  const dispatch = useDispatch()
  const fetchUpdateUser = () => dispatch(Actions.Users.fetchUpdateUser())
  const getUserPaymentMethod = () => dispatch(Actions.Users.getUserPaymentMethod())
  const verifyCard = cb => dispatch({ type: 'CHECKOUT_FETCH_VERIFY_CARD', cb })
  const setPayWithNewCard = value => dispatch(Actions.Checkout.setPayWithNewCard(value))
  const setStatus = stat => dispatch(Actions.Checkout.setStatus(stat))

  const paymentMethod = useSelector(state => state.Users.paymentMethod)

  const havePaymentMethod = paymentMethod && paymentMethod.length > 0

  const paymentMethodActive =
    havePaymentMethod && paymentMethod.find(method => method.paymentType === 'CREDIT' && method.active)

  const isExpired =
    (paymentMethodActive && paymentMethodActive.expiredCard) ||
    (paymentMethodActive &&
      moment([paymentMethodActive.expYear, paymentMethodActive.expMonth]).diff(moment(), 'days') < 0)

  useEffect(() => {
    if (!logged) history.push('/')

    if (paymentCardToken) {
      verifyCard()
      history.push('/account')
    }
    fetchUpdateUser()

    return () => {
      setPayWithNewCard(false)
      setStatus(null)
    }
  }, [])

  useEffect(() => {
    const isError = status && status.status === 'error'
    if (isError) {
      setCardError(isError)
      setAddNewCard(isError)
    } else {
      getUserPaymentMethod()
      setCardError(false)
    }
  }, [status])

  useEffect(() => {
    getUserPaymentMethod()
  }, [payWithNewCard])

  const renderActivePaymentMethod = () => {
    return (
      <Grid item container xs={12} justify="center">
        <Grid
          style={{ position: 'relative' }}
          container
          alignItems="center"
          justify="space-between"
          className="account__payment-section__active-card">
          <Grid item xs={4} lg={4} container justify="center" alignItems="baseline">
            <IconComponent icon={paymentMethodActive.brand || 'credit card'} />
          </Grid>
          <Grid item xs={8} lg={8} container alignItems="center" justify="space-evenly">
            <Grid item xs={12} lg={12} className="payment-card__number">
              <Typography variant="inherit">
                <span>{t('payment.cardFinished')}</span>
                <strong>{!paymentMethodActive.last4 ? '0000' : paymentMethodActive.last4}</strong>
              </Typography>
            </Grid>
          </Grid>
          {isExpired && (
            <p className="credit-card-active__alert expired-in-account">
              {`${t('payment.cardExpired')}
              ${paymentMethodActive.expMonth}/${paymentMethodActive.expYear}`}
            </p>
          )}
          {!isExpired &&
            moment([paymentMethodActive.expYear, paymentMethodActive.expMonth]).diff(moment(), 'days') <=
              moment([paymentMethodActive.expYear, paymentMethodActive.expMonth - 1]).daysInMonth() && (
              <p className="credit-card-active__alert warning-in-account">
                {`${t('payment.cardWillExpire')} ${paymentMethodActive.expMonth}/${paymentMethodActive.expYear}`}
              </p>
            )}
        </Grid>
        <Grid item container justify="center" className="account__payment-section__active-card-text">
          {t('payment.cardPurchase')}
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Header t={t} i18n={i18n} bg history={history} showSideCartIcon showLogin />
      <BreadCrumbs crumbs={breadCrumbs} currentLanguage={currentLanguage} history={history} />
      <Grid container item justify="center" className="account-container">
        <Grid container item xs={12} md={7} lg={5} className="account">
          <Grid item container className="account__title">
            <span>{t('account.profile')}</span>
          </Grid>

          <Grid item container className="account__item">
            <Grid item container xs={12} justify="space-between" className="account__subtitle">
              <Grid item className="account__subtitle__title">
                <span>{t('checkout.contactInfo')}</span>
              </Grid>
              <Grid item className="account__subtitle__edit">
                <button type="button" onClick={() => history.push('/account/editcustomer')}>
                  <span>{t('default.edit')}</span>
                </button>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item container xs={12} lg={5} className="account__subitem">
                <strong>{t('account.firstName')}</strong>
                <span>{first_name}</span>
              </Grid>
              <Grid item container xs={12} lg={7} className="account__subitem">
                <strong>{t('account.lastName')}</strong>
                <span>{last_name}</span>
              </Grid>
              <Grid item container xs={12} lg={5} className="account__subitem">
                <strong>{t('account.phone')}</strong>
                <span>{phone}</span>
              </Grid>
              <Grid item container xs={12} lg={7} className="account__subitem">
                <strong>{t('account.email')}</strong>
                <span>{email}</span>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container className="account__item">
            <Grid item container xs={12} justify="space-between" className="account__subtitle">
              <Grid item className="account__subtitle__title">
                <span>{t('checkout.shippingInfo')}</span>
              </Grid>
              <Grid item className="account__subtitle__edit">
                <button type="button" onClick={() => history.push('/account/editshipping')}>
                  <span>{t('default.edit')}</span>
                </button>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item container xs={12} className="account__subitem">
                <strong>{t('account.city')}</strong>
                <span>{city || '-'}</span>
              </Grid>
              <Grid item container xs={12} className="account__subitem">
                <strong>{t('account.address')}</strong>
                <span>{address1 ? `${address1}, ${city} ${province_code}, ${country_name}.` : '-'}</span>
              </Grid>
              <Grid item container xs={12} className="account__subitem">
                <strong>{t('account.floorApt')}</strong>
                <span>{address2 || '-'}</span>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container className="account__item">
            <Grid item container xs={12} justify="space-between" className="account__subtitle">
              <Grid item className="account__subtitle__title">
                <span>{t('checkout.paymentInfo')}</span>
              </Grid>
              <Grid item className="account__subtitle__edit">
                {paymentMethodActive && (
                  <button type="button" onClick={() => history.push('/account/editpayment')}>
                    <span>{t('default.edit')}</span>
                  </button>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12}>
              {paymentMethodActive ? (
                renderActivePaymentMethod()
              ) : (
                <>
                  {addNewCard ? (
                    <Grid item container justify="center" xs={12}>
                      <PaymentFrame togglePayWithNewCard={value => dispatch(Actions.Checkout.setPayWithNewCard(value))}>
                        <Grid item container className="payment-method__add-new-container">
                          <Grid item container className="payment-method__checkout-frame">
                            <Grid item container className="payment-method__add-new-title">
                              <span>{t('payment.addCard')}</span>
                            </Grid>
                            <StripePayment
                              history={history}
                              disableSaveCard={disableSaveCard}
                              setDisableSaveCard={setDisableSaveCard}
                              setCardError={setCardError}
                            />
                          </Grid>
                          <Grid item container direction="row">
                            <Grid item container justify="center">
                              <button
                                type="button"
                                className="payment-method__cancel-add-card"
                                onClick={() => {
                                  setStatus(null)
                                  setAddNewCard(!addNewCard)
                                }}>
                                {t('default.cancel')}
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </PaymentFrame>
                      <Grid>
                        <Typography className="payment-method__alert-charge">
                          {t('checkout.chargeRefundOnCard')}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container xs={12} justify="center">
                      <Grid
                        item
                        container
                        justify="center"
                        xs={12}
                        lg={10}
                        className="account__payment-section__no-cards">
                        <Grid item xs={12}>
                          <strong>{t('payment.noCardsYet.message')}</strong>
                        </Grid>
                        <Grid item xs={12}>
                          <span>{t('payment.noCardsYet.question')}</span>
                          <button type="button" onClick={() => setAddNewCard(!addNewCard)}>
                            <strong>{t('payment.noCardsYet.clickHere')}</strong>
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {cardError && (
                <Grid item container className="payment-method__error-message">
                  {t('payment.errorCard')}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

Account.propTypes = propTypes
export default translate('common')(Account)
