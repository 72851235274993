/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'react-i18next'
import { Grid, Typography, Collapse, TextField, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'
import 'pure-react-carousel/dist/react-carousel.es.css'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import gift from '../../assets/images/gift.svg'
import IconComponent from '../../components/atoms/IconComponent'
import PureReactCarousel from '../PureReactCarousel'
import { sizes } from '../../utils/constants'
import Actions from '../../actions'

const SamplesAndGifts = ({ t, isAnySubscribed, samplesInfo, setSamplesInfo }) => {
  const [showSamples, setShowSamples] = useState(false)
  const [selectedSize, setSelectedSize] = useState(samplesInfo.diaperSize === null ? 0 : samplesInfo.diaperSize)
  const [visibleSlides, setVisibleSlides] = useState(window.innerWidth < 460 ? 3 : 4)
  const samplings = useSelector(state => state.Products.samplings)
  const totalBeforeTax = useSelector(state => state.Checkout.totalBeforeTax)
  const [allVariants, setAllVariants] = useState([])

  const onChange = e => {
    setSamplesInfo({ ...samplesInfo, [e.target.name]: e.target.value })
  }

  const dispatch = useDispatch()
  const getSamplings = () => dispatch(Actions.Products.getSamplings())

  const getVariants = () => {
    const variants = []

    samplings.forEach(sample =>
      sample.variants.forEach(variant => {
        if (sample.images.length === 0) {
          variants.push({ ...variant, variantImage: null, product: sample, productTitle: sample.title })
        }

        sample.images.forEach(image => {
          if (image.id === variant.image_id) {
            variants.push({ ...variant, variantImage: image.src, product: sample, productTitle: sample.title })
          } else {
            variants.push({ ...variant, variantImage: sample.image.src, product: sample, productTitle: sample.title })
          }
        })
      })
    )
    setAllVariants(variants)
  }

  useEffect(() => {
    if (!samplings) getSamplings()
  }, [])

  useEffect(() => {
    if (samplings.length > 0) getVariants()
  }, [samplings])

  useEffect(() => {
    window.addEventListener('resize', () => setVisibleSlides(window.innerWidth < 460 ? 3 : 4))
    return () => {
      window.removeEventListener('resize', () => setVisibleSlides(window.innerWidth < 460 ? 3 : 4))
    }
  }, [])

  useEffect(() => {
    if (!isAnySubscribed) setShowSamples(false)
  }, [isAnySubscribed])

  return (
    <>
      {!!allVariants.length && (
        <Grid className={`samples-gifts ${!isAnySubscribed && 'disabled'}`}>
          <Grid
            className={`samples-gifts__title-container ${!isAnySubscribed && 'disabled'}`}
            onClick={() => {
              if (isAnySubscribed) setShowSamples(!showSamples)
            }}>
            <img
              className={`samples-gifts__gift-icon ${!isAnySubscribed && 'disabled'}`}
              src={gift}
              alt="Gifts and Samples"
            />
            <Typography className={`samples-gifts__title-container__title ${!isAnySubscribed && 'disabled'}`}>
              {isAnySubscribed ? t('cart.samplings.subscribed') : t('cart.samplings.noSubscribed')}
            </Typography>

            {showSamples ? (
              <KeyboardArrowUpIcon className={`samples-gifts__arrow-icon ${!isAnySubscribed && 'disabled'}`} />
            ) : (
              <KeyboardArrowDownIcon className={`samples-gifts__arrow-icon ${!isAnySubscribed && 'disabled'}`} />
            )}
          </Grid>

          <Collapse in={showSamples}>
            <Grid className="samples-gifts__collapse-container">
              <>
                {totalBeforeTax < 500 && allVariants.length > 1 && (
                  <Grid className="samples-gifts__banner">
                    <Typography className="samples-gifts__banner__text">
                      {t('cart.samplings.banner1')}
                      <strong>{t('cart.samplings.banner2')}</strong>
                      {t('cart.samplings.banner3')}
                      <strong>{t('cart.samplings.banner4')}</strong>
                      {t('cart.samplings.banner5')}
                    </Typography>
                  </Grid>
                )}

                <Grid className="samples-gifts__info-container">
                  <Typography className="samples-gifts__info-title">{t('cart.samplings.tellUsMore')}</Typography>

                  <Typography className="samples-gifts__input-title">{t('cart.samplings.name')}</Typography>
                  <TextField
                    type="text"
                    name="name"
                    onChange={e => onChange(e)}
                    value={samplesInfo.name}
                    autofocus
                    className="samples-gifts__input-name"
                  />
                  <Typography className="samples-gifts__input-title">{t('cart.samplings.gender')}</Typography>
                  <RadioGroup
                    onChange={e => onChange(e)}
                    className="samples-gifts__genders"
                    aria-label="gender"
                    name="gender"
                    value={samplesInfo.gender}>
                    <FormControlLabel
                      value="M"
                      control={
                        <Radio
                          icon={<IconComponent icon="radio" fill="#fff" />}
                          checkedIcon={<IconComponent icon="radio" fill="#CF4A67" />}
                        />
                      }
                      label={t('cart.samplings.boy')}
                    />
                    <FormControlLabel
                      value="F"
                      control={
                        <Radio
                          icon={<IconComponent icon="radio" fill="#fff" />}
                          checkedIcon={<IconComponent icon="radio" fill="#CF4A67" />}
                        />
                      }
                      label={t('cart.samplings.girl')}
                    />
                  </RadioGroup>

                  <Typography className="samples-gifts__input-title">{t('cart.samplings.weight')}</Typography>

                  <Grid className="samples-gifts__sizes-container">
                    {sizes.map((size, i) => (
                      <button
                        index={i}
                        key={size.weight}
                        onClick={() => {
                          setSelectedSize(i)
                          setSamplesInfo({ ...samplesInfo, diaperSize: size.size })
                        }}
                        type="button"
                        className={`filter__item__variants__button${+selectedSize === i ? '__selected' : ''}`}>
                        <Grid container item direction="column">
                          <span>
                            <strong>{size.title}</strong>
                          </span>
                          <span>{size.weight}</span>
                        </Grid>
                      </button>
                    ))}
                  </Grid>

                  <Grid />
                  <Typography align="left" className="samples-gifts__input-title">
                    {totalBeforeTax >= 500 && allVariants.length > 1
                      ? t('cart.samplings.selectSamples')
                      : t('cart.samplings.selectSample')}
                  </Typography>
                </Grid>

                <PureReactCarousel
                  totalBeforeTax={totalBeforeTax}
                  products={allVariants}
                  visibleSlides={visibleSlides}
                />
              </>
            </Grid>
          </Collapse>
        </Grid>
      )}
    </>
  )
}

export default translate('common')(SamplesAndGifts)
