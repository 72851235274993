/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react'
import { Grid, List, ListItem, Typography, Link, Hidden } from '@material-ui/core'
import propTypes from './props'

const FooterRegular = ({
  firstColumn,
  t,
  history,
  Phone,
  Whatsapp,
  Email,
  mainMenu,
  legal,
  siteMap,
  whatsappHref,
  phoneHref
}) => {
  return (
    <>
      {firstColumn && firstColumn}
      <Grid item className="footer__column">
        <Typography className="footer_heading" variant="h6">
          {t('default.mainMenu')}
        </Typography>
        <List>
          {mainMenu.map(item => (
            <ListItem key={`desktop${item.name}`} className="footer__list-item">
              <Link
                component="button"
                onClick={() => {
                  history.push(`/products/${item.path}`)
                  window.scrollTo(0, 0)
                }}>
                {item.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Grid>

      <Grid item className="footer__column">
        <Typography className="footer_heading" variant="h6">
          {t('default.siteMap')}
        </Typography>
        <List>
          {siteMap.map(item => (
            <ListItem key={item.name} className="footer__list-item">
              <Link
                href={`${item.link ? item.path : ''}`}
                target={`${item.link ? '_blank' : '_self'}`}
                onClick={() => {
                  if (!item.link) {
                    history.push(`${item.path}`)
                    window.scrollTo(0, 0)
                  }
                }}>
                {item.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Grid>

      <Grid item className="footer__column">
        <Typography className="footer_heading" variant="h6">
          {t('footer.deliveryAndReturns')}
        </Typography>
        <List>
          {legal.map(item => (
            <ListItem key={item.name} className="footer__list-item">
              <Link
                target="_blank"
                href={item.url || 'https://yallababy.com/legal-yalla-baby-box.html'}
                rel="noreferrer">
                {item.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Grid>

      <Grid item className="footer__column">
        <Typography className="footer_heading" variant="h6">
          {t('footer.needHelp')}
        </Typography>
        <List>
          <ListItem className="footer__list-item">
            <Hidden only="md">
              <img src={Phone} alt="Phone Number" className="list-icon" />
            </Hidden>
            <a href={phoneHref} target="_blank" rel="noopener noreferrer">
              {t('footer.phone')}
            </a>
          </ListItem>
          <ListItem className="footer__list-item">
            <Hidden only="md">
              <img src={Whatsapp} alt="Whatsapp Logo" className="list-icon" />
            </Hidden>
            <a href={whatsappHref} target="_blank" rel="noopener noreferrer">
              {t('footer.whatsapp')}
            </a>
          </ListItem>
          <ListItem className="footer__list-item">
            <Hidden only="md">
              <img src={Email} alt="Email" className="list-icon" />
            </Hidden>
            <a href={`mailto:${t('footer.contactEmail')}`} target="_blank" rel="noopener noreferrer">
              {t('footer.contactEmail')}
            </a>
          </ListItem>
        </List>
      </Grid>
    </>
  )
}

FooterRegular.propTypes = propTypes
export default FooterRegular
