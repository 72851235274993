import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { translate } from 'react-i18next'
import { Grid, Typography, Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { withStyles } from '@material-ui/core/styles'
import BenefitsBanner from '../BenefitsBanner'
import propTypes from './props'

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 22,
    width: '100% !important'
  },

  line: {
    height: 2,
    border: 0,
    backgroundColor: '#979797',
    borderRadius: 1,
    position: 'relative',
    left: -22
  }
})(StepConnector)

const CustomStep = withStyles({
  root: {
    color: '#979797'
  },
  active: {
    color: '#54CE8A'
  },
  completed: {
    color: '#54CE8A'
  }
})(Step)

const Milestones = ({ t }) => {
  const ref = useRef(null)
  const milestonesSubscriptions = useSelector(state => state.Users.subscriptions)
  const [activeFamiliesBoxes, setActiveFamiliesBoxes] = useState([])

  const getfamiliesBoxes = () => {
    const families = []
    let subscriptions = milestonesSubscriptions

    subscriptions = _.orderBy(subscriptions, ['id'], ['asc'])

    subscriptions.forEach(box => {
      if ((box.status === 'PENDING' || box.status === 'PAUSED') && box.purchaseType === 'SUBSCRIPTION') {
        const subscriptionForReward = box.subscriptionOrderForReward === 0 ? 1 : box.subscriptionOrderForReward

        families.push([
          ...Array(subscriptionForReward - 1).fill(''),
          box,
          ...Array(24 - subscriptionForReward).fill('')
        ])
      }
    })

    const orderFamilies = _.map(_.sortBy(families, [el => el.subscriptionOrderForReward], _.values))

    return orderFamilies
  }

  const getLabel = (boxNumber, currentBox) => {
    const numberOfBoxs = [2, 5, 8, 11, 14, 17, 20, 23]

    const getOrdinal = () => {
      switch (boxNumber) {
        case 2:
          return `RD`

        case 20:
          return `ST`

        default:
          return `TH`
      }
    }

    const getPrice = () => {
      switch (boxNumber) {
        case 2:
          return '100'

        case 5:
          return '300'

        default:
          return '500'
      }
    }

    return (
      <div ref={boxNumber === currentBox ? ref : null}>
        {numberOfBoxs.includes(boxNumber) && (
          <div>
            <Typography className="milestones__ordinal-box-text bold">
              {`${boxNumber + 1}${getOrdinal()} BOX`}
            </Typography>
            <Typography className="milestones__ordinal-box-text">{`AED ${getPrice()} Credit (For your next Box)`}</Typography>
          </div>
        )}
      </div>
    )
  }

  const getIcon = (boxNumber, currentBox) => {
    const numberOfBoxs = [2, 5, 8, 11, 14, 17, 20, 23]

    if (numberOfBoxs.includes(boxNumber))
      return (
        <>
          <CardGiftcardIcon className={`milestones__gift-icon ${boxNumber <= currentBox && 'current-box'}`} />
          {currentBox === boxNumber && <ArrowDropDownIcon className="milestones__current-box-icon" />}
        </>
      )

    return (
      <>
        <FiberManualRecordIcon
          className={`${currentBox === boxNumber ? 'milestones__current-circle-icon' : 'milestones__circle-icon'}`}
        />
        {currentBox === boxNumber && <ArrowDropDownIcon className="milestones__current-box-icon marginLeft" />}
      </>
    )
  }

  useEffect(() => {
    if (milestonesSubscriptions) setActiveFamiliesBoxes(getfamiliesBoxes())
  }, [milestonesSubscriptions])

  useEffect(() => {
    if (activeFamiliesBoxes.length > 0 && window.innerWidth < 1000)
      setTimeout(() => {
        if (ref.current) ref.current.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' })
      }, 1400)
  }, [activeFamiliesBoxes])

  const currentBox = family => {
    return family.indexOf(family.find(box => box))
  }

  return (
    <Grid className={`milestones__container ${!activeFamiliesBoxes[0] && 'noFamilies'}`}>
      {activeFamiliesBoxes[0] && (
        <>
          <Stepper
            className="milestones__stepper"
            orientation="horizontal"
            activeStep={currentBox(activeFamiliesBoxes[0]) + 1}
            alternativeLabel
            connector={<CustomConnector />}>
            {activeFamiliesBoxes[0].map((el, i) => (
              <CustomStep>
                <StepLabel
                  className="milestones__text-label"
                  icon={getIcon(i, currentBox(activeFamiliesBoxes[0]))}
                  key={el.id || i}>
                  {getLabel(i, currentBox(activeFamiliesBoxes[0]))}
                </StepLabel>
              </CustomStep>
            ))}
          </Stepper>
        </>
      )}

      <>
        {!activeFamiliesBoxes[0] && <BenefitsBanner t={t} inBoxes />}
        <Grid className={`milestones__banner-container ${!activeFamiliesBoxes[0] && 'noFamilies'}`}>
          <Typography className="milestones__banner-text">
            <span>{t('milestones.earnUp')}</span>
            {t('milestones.to')}
            <span>{t('milestones.AED')}</span>
            {t('milestones.text')}
          </Typography>
        </Grid>
      </>
    </Grid>
  )
}

Milestones.propTypes = propTypes
export default translate('common')(Milestones)
