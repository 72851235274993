/* eslint-disable react/prop-types */
import React from 'react'
import { Grid } from '@material-ui/core'
import CircleItem from '../../atoms/CircleItem'

const CircleList = ({ items, onClick, collectionHandle }) => {
  return (
    <Grid container spacing={2} className="circle-list">
      {items.map(item => (
        <CircleItem key={item.handle} item={item} onClick={onClick} collectionHandle={collectionHandle} />
      ))}
    </Grid>
  )
}

export default CircleList
