/* eslint-disable no-unused-vars */
import React from 'react'
import { Modal, Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import UpvotyWidget from '../UpvotyWidget'
import propTypes from './props'

const UpvotyModal = ({ openUpvoty, setOpenUpvoty, upvotyPath, setOpenSideMenu }) => {
  const tokenUpvoty = useSelector(state => state.Users.tokenUpvoty)
  return (
    <Modal
      aria-label="Upvoty Modal"
      open={openUpvoty}
      setOpenUpvoty={setOpenUpvoty}
      onClose={() => setOpenUpvoty(false)}
      onBackdropClick={() => {
        setOpenUpvoty(false)
        if (setOpenSideMenu) setOpenSideMenu(false)
      }}
      className="upvoty-modal">
      <div className="upvoty-modal__content" tabIndex={-1}>
        <UpvotyWidget ssoToken={tokenUpvoty} baseUrl={upvotyPath} />
        <Grid className="upvoty-modal__close-container">
          <Typography
            onClick={() => {
              setOpenUpvoty(false)
              if (setOpenSideMenu) setOpenSideMenu(false)
            }}
            className="upvoty-modal__close-container__close">
            EXIT
          </Typography>
        </Grid>
      </div>
    </Modal>
  )
}

UpvotyModal.propTypes = propTypes
export default UpvotyModal
