import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import config from '../config';
var useTagManager = function useTagManager() {
  useEffect(function () {
    TagManager.initialize({
      gtmId: config.TAG_MANAGER,
      dataLayerName: 'YallaBaby'
    });
  }, []);
};
export default useTagManager;