/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { translate } from 'react-i18next'
import { Modal, Grid, Typography, RadioGroup, FormControlLabel, Radio, Box } from '@material-ui/core'
import { Widget } from '@typeform/embed-react'
import { getDeliveryDays } from '../../../utils/helpers'
import Actions from '../../../actions'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'
import FlatButton from '../../molecules/FlatButton'

const BoxPausedModal = ({
  t,
  openModal,
  setOpenModal,
  shippingDate,
  boxId,
  status,
  emirate,
  openTypePauseForm,
  setOpenTypePauseForm,
  purchaseType
}) => {
  const PAUSE = 'PAUSE'
  const readableDateFormat = 'MMMM Do, YYYY'
  const SUBSCRIPTION = 'SUBSCRIPTION'

  const [value, setValue] = useState(0)
  const [boxReschedule, setBoxReschedule] = useState(false)
  const [boxPaused, setBoxPaused] = useState(false)

  const dispatch = useDispatch()
  const setNewShippingDate = (newDate, subId) => dispatch(Actions.Users.updateSubscriptionsShippingDate(newDate, subId))
  const pauseSubscriptionDispatch = subscriptionId => dispatch(Actions.Checkout.pauseSubscription(subscriptionId))
  const updateSubscriptionShipping = subscriptionData => dispatch(Actions.Checkout.updateSubscription(subscriptionData))
  const isSubscription = () => purchaseType === SUBSCRIPTION

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handlePauseButton = formValue => {
    const data = {
      id: boxId,
      subscriptionShippingDate: getDeliveryDays(emirate, moment(shippingDate).add(+value, 'months'))
    }

    switch (formValue) {
      case 'PAUSE':
        pauseSubscriptionDispatch(boxId)
        setBoxPaused(true)
        break

      default:
        setBoxReschedule(true)
        updateSubscriptionShipping(data)
        setNewShippingDate(data.subscriptionShippingDate, boxId)
        break
    }
  }

  const getMessage = (reschedule, boxIsPaused) => {
    return (
      <>
        <Typography className="title">
          {reschedule && t('boxes.boxIsRescheduled')}
          {boxIsPaused && t('boxes.boxIsPaused')}
        </Typography>
        <Typography className="subtitle">
          {reschedule && `${t('boxes.yourNextBox')} ${moment(shippingDate).format(readableDateFormat)}`}
          {boxIsPaused && (isSubscription() ? t('boxes.pauseNoCost') : t('boxes.pauseNoCostBox'))}
        </Typography>
      </>
    )
  }

  const handleOnClickPause = () => {
    if (!boxReschedule && status !== 'PAUSED') handlePauseButton(value)
    else {
      setValue(null)
      setBoxReschedule(false)
      setBoxPaused(false)
      setOpenModal(false)
    }
  }

  const handleOnClickDontPause = () => {
    setOpenModal(false)
    setValue(null)
  }

  const getNewShippingDate = () => {
    const newDate = moment(shippingDate).add(+value, 'months')

    return newDate.format(readableDateFormat)
  }

  return (
    <Modal
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={() => setOpenModal(false)}
      className={openTypePauseForm ? 'cancel-modal type-form' : ''}>
      <div tabIndex={-1}>
        <>
          <Grid container className={openTypePauseForm ? 'modal-cancel type-form' : 'yalla-modal box-paused-modal'}>
            {openTypePauseForm ? (
              <Widget
                style={{ height: 'inherit', margin: '0', width: 'inherit', padding: '0' }}
                onSubmit={() => {
                  setOpenTypePauseForm(false)
                }}
                id="QUWCOS"
              />
            ) : (
              <>
                <Grid
                  item
                  xs={2}
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  className="modal-cancel__close"
                />

                <Grid container className="yalla-modal__container" justify="center" alignContent="center">
                  {boxReschedule || boxPaused || status === 'PAUSED' ? (
                    getMessage(boxReschedule, boxPaused)
                  ) : (
                    <Grid item container xs={12} justify="center" alignItems="center">
                      <Typography className="title">
                        {isSubscription() ? t('boxes.needToPause') : t('boxes.needToPauseBox')}
                      </Typography>
                      <Grid item xs={8}>
                        <RadioGroup className="pause-form__radiogroup" onChange={handleChange} value={value}>
                          <FormControlLabel
                            control={
                              <Radio
                                icon={<IconComponent icon="radio" fill="#fff" />}
                                checkedIcon={<IconComponent icon="radio" fill="#333333" borderFill="#333333" />}
                                className="form__radiogroup__pause-check"
                                value="1"
                                name="1"
                              />
                            }
                            label={t('boxes.skip1Month')}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                icon={<IconComponent icon="radio" fill="#fff" />}
                                checkedIcon={<IconComponent icon="radio" fill="#333333" borderFill="#333333" />}
                                className="form__radiogroup__pause-check"
                                value="2"
                                name="2"
                              />
                            }
                            label={t('boxes.skip2Month')}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                icon={<IconComponent icon="radio" fill="#fff" />}
                                checkedIcon={<IconComponent icon="radio" fill="#333333" borderFill="#333333" />}
                                className="form__radiogroup__pause-check"
                                value="3"
                                name="3"
                              />
                            }
                            label={t('boxes.skip3Month')}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                icon={<IconComponent icon="radio" fill="#fff" />}
                                checkedIcon={<IconComponent icon="radio" fill="#333333" borderFill="#333333" />}
                                className="form__radiogroup__pause-check"
                                value="PAUSE"
                                name="PAUSE"
                              />
                            }
                            label={t('boxes.pause')}
                          />
                        </RadioGroup>
                      </Grid>
                      {value !== PAUSE && (
                        <Box mt={2} px={2} className="next-date">
                          <Typography>
                            {t('boxes.nextBoxOn')}
                            <strong>{getNewShippingDate()}</strong>
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  )}
                  <Box mt={2}>
                    <Grid container alignItems="center" spacing={2} className="btn-actions">
                      <Grid item container xs={12} lg={boxReschedule || boxPaused ? 12 : 4}>
                        <FlatButton
                          title={boxReschedule || boxPaused ? 'Ok' : t('default.confirm')}
                          classes={`flat-button--green-jaka ${!value ? 'flat-button--green-jaka--disabled' : ''} ${
                            boxReschedule || boxPaused ? 'box-rescheduled' : ''
                          }`}
                          onClick={() => handleOnClickPause()}
                          disabled={!value}
                        />
                      </Grid>
                      {!boxReschedule && !boxPaused && status !== 'PAUSED' && (
                        <Grid item container lg={8}>
                          <FlatButton
                            title={isSubscription() ? t('boxes.dontPause') : t('boxes.dontPauseBox')}
                            classes="flat-button--gray"
                            onClick={() => handleOnClickDontPause()}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </div>
    </Modal>
  )
}

BoxPausedModal.propTypes = propTypes
export default translate('common')(BoxPausedModal)
