import React, { Fragment, useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
// import CheckoutFrameContainer from '../../atoms/CheckoutFrameContainer'
import CreditCardActive from '../CreditCardActive'
// import IconComponent from '../../atoms/IconComponent'
import StripePayment from '../../atoms/StripePayment'

const CreditCardMethod = ({ history, t, cards, setCardExpired }) => {
  const [disableSaveCard, setDisableSaveCard] = useState(true)
  const [cardError, setCardError] = useState(false)

  const status = useSelector(state => state.Checkout.status)

  const cardIndex = cards && cards.findIndex(card => card.id && card.active)

  const dispatch = useDispatch()
  const setStatus = stat => dispatch(Actions.Checkout.setStatus(stat))

  useEffect(() => {
    const isError = status && status.status === 'error'
    setCardError(isError)
  }, [status])

  useEffect(() => {
    setStatus(null)
    return () => {
      setStatus(null)
    }
  }, [])

  return (
    <Fragment>
      <Grid container item xs={10} alignItems="center" justify="center">
        {cards.length > 0 ? (
          <CreditCardActive history={history} t={t} card={cards[cardIndex]} setCardExpired={setCardExpired} />
        ) : (
          <Grid item container justify="center" xs={12}>
            <Grid item container className="payment-method__add-new-container">
              <Grid item container className="payment-method__checkout-frame">
                <Grid item container className="payment-method__add-new-title">
                  <span>{t('payment.addCard')}</span>
                </Grid>
                {/* <CheckoutFrameContainer
                  history={history}
                  setDisableSaveCard={setDisableSaveCard}
                  setCardError={setCardError}
                /> */}
                <StripePayment
                  history={history}
                  disableSaveCard={disableSaveCard}
                  setDisableSaveCard={setDisableSaveCard}
                  setCardError={setCardError}
                />
              </Grid>
              {cardError && (
                <Grid item container className="payment-method__error-message">
                  {t('payment.errorCard')}
                </Grid>
              )}
              {/* <Grid item container direction="row">
                <Grid item container justify="center">
                  <button
                    type="submit"
                    className={`payment-method__save-card${disableSaveCard ? '__disabled' : ''}`}
                    id="pay-button"
                    disabled={disableSaveCard}>
                    {t('checkout.saveCard')}
                    <IconComponent icon="floppyDisk" />
                  </button>
                </Grid>
              </Grid> */}
            </Grid>
            <Grid>
              <Typography className="payment-method__alert-charge">{t('checkout.chargeRefundOnCard')}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

CreditCardMethod.propTypes = propTypes
export default translate('common')(CreditCardMethod)
