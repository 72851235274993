/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const AddMoreProducts = () => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="46" height="46" rx="6" fill="#DED2AC" />
    <path
      d="M33 23C33 21.9378 32.1391 21.0769 31.0769 21.0769H24.9231V14.9231C24.9231 13.8609 24.0622 13 23 13C21.9378 13 21.0769 13.8609 21.0769 14.9231V21.0769H14.9231C13.8609 21.0769 13 21.9378 13 23C13 24.0622 13.8609 24.9231 14.9231 24.9231H21.0769V31.0769C21.0769 32.1391 21.9378 33 23 33C24.0622 33 24.9231 32.1391 24.9231 31.0769V24.9231H31.0769C32.1391 24.9231 33 24.0622 33 23Z"
      fill="#FFF4CA"
    />
  </svg>
)

export default AddMoreProducts
