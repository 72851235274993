import React from 'react'
import YouTube from '@u-wave/react-youtube'
import { Button, Grid, Modal, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import IconComponent from '../../atoms/IconComponent'
import propTypes from './props'

const YTVideo = ({ open, setOpen, t }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Grid item xs={12} className="video">
      <Button onClick={handleOpen}>
        <IconComponent icon="play" fill="#8e96ca" />
        {t('home.watchVideo')}
      </Button>
      <Modal open={open} onClose={handleClose} className="main-content__video">
        <YouTube
          video="YRv00fCn3Us"
          autoplay
          allowFullscreen
          width={isDesktop ? 1280 : null}
          height={isDesktop ? 720 : null}
        />
      </Modal>
    </Grid>
  )
}

YTVideo.propTypes = propTypes
export default YTVideo
