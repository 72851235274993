/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, RootRef } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import UpsellingCardItem from '../../organisms/UpsellingCardItem'
import Loader from '../../../assets/images/loader2.gif'
import propTypes from './props'
import Actions from '../../../actions'

const MomsAlsoBought = ({ t, history, inCart }) => {
  const dispatch = useDispatch()
  const cards = useRef([])
  const [cardNumber, setCardNumber] = useState(0)

  const upsellingCart = useSelector(state => state.Checkout.upselling)
  const bestSellerAddons = useSelector(state => state.Checkout.bestSellerAddons) || []
  const productsMomsBought = useSelector(
    state => state.Products.detailProduct && state.Products.detailProduct.productMomsAlsoBought
  )
  const currentLanguage = useSelector(state => state.Language.current)

  const getDetailPageURI = (productId, productName) => {
    const slug = productName.split(' ').join('-').toLowerCase()
    history.push(`/product/${productId}-${slug}`)
  }

  const productsToShow = (
    inCart ? bestSellerAddons : productsMomsBought && productsMomsBought.length > 0 && productsMomsBought
  ).slice(0, 5)
  useEffect(() => {
    if (window.location.pathname.includes('product'))
      dispatch(Actions.Products.getDetailedProduct(+window.location.pathname.split('/')[2].split('-')[0]))
  }, [window.location.pathname])

  const focusProduct = number => {
    if (cards && cards.current)
      cards.current[cardNumber + number].scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    setCardNumber(cardNumber + number)
  }

  return (
    <Grid className={`moms-also-bought ${inCart && 'inCart'}`}>
      <Typography className="moms-also-bought__title">{t('default.momsAlsoBought')}</Typography>
      <KeyboardArrowRightIcon
        className="moms-also-bought__arrow-icon right"
        onClick={() => focusProduct(cardNumber === 4 ? -4 : 1)}
      />
      <KeyboardArrowLeftIcon
        className="moms-also-bought__arrow-icon left"
        onClick={() => focusProduct(cardNumber === 0 ? 4 : -1)}
      />
      <div className="moms-also-bought__container">
        {!productsToShow.length ? (
          <img className="moms-also-bought__loader" width="51px" src={Loader} alt="Loading..." />
        ) : (
          productsToShow.map((item, i) => (
            <RootRef
              rootRef={el => {
                cards.current[i] = el
              }}>
              <UpsellingCardItem
                currentLanguage={currentLanguage}
                t={t}
                product={item}
                key={item.id}
                cart={upsellingCart}
                getDetailPageURI={(id, title) => getDetailPageURI(id, title)}
                inMomsAlsoBought
              />
            </RootRef>
          ))
        )}
      </div>
    </Grid>
  )
}

MomsAlsoBought.propTypes = propTypes
export default translate('common')(MomsAlsoBought)
