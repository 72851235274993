import React from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import propTypes from './props'

const ShippingUpdatedSnackbar = ({ t, setShippingUpdate, shippingDateUpdated }) => (
  <Grid item container direction="row" className="shipping-updated-snackbar">
    <Grid item container xs={1} className="shipping-updated-snackbar__info">
      <span>{'!'}</span>
    </Grid>
    <Grid item container xs={10} className="shipping-updated-snackbar__text">
      <Grid item className="shipping-updated-snackbar__text__title">
        <span>{t('shippingUpdate.update')}</span>
      </Grid>
      <Grid item className="shipping-updated-snackbar__text__description">
        <span>
          {`${t('shippingUpdate.message.item1')}`}
          <span style={{ fontWeight: 'bold' }}>{moment(shippingDateUpdated).format('DD/MM')}</span>
          {`${t('shippingUpdate.message.item2')}`}
        </span>
      </Grid>
    </Grid>
    <Grid item container xs={1} className="shipping-updated-snackbar__close-button">
      <button type="button" onClick={() => setShippingUpdate(false)}>
        {'X'}
      </button>
    </Grid>
  </Grid>
)

ShippingUpdatedSnackbar.propTypes = propTypes
export default ShippingUpdatedSnackbar
