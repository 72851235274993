/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-tabs */
import React from 'react'
import propTypes from './props'

const ArrowLeft = props => (
  <button type="button" {...props}>
    <svg
      width={props.width || '12px'}
      height={props.height || '22px'}
      viewBox="0 0 12 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Path-6-Copy-2" transform="translate(1.000000, 1.000000)" stroke={props.color || 'white'} strokeWidth="2">
          <polyline
            id="Path-6-Copy"
            transform="translate(4.500000, 10.000000) rotate(-180.000000) translate(-4.500000, -10.000000) "
            points="9 0 0 10.349678 8.278527 20"
          />
        </g>
      </g>
    </svg>
  </button>
)

ArrowLeft.propTypes = propTypes
export default ArrowLeft
