/* eslint-disable max-len */
import React from 'react'
import propTypes from './props'

const TruckJaka = ({ fill = '#73BB90' }) => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5001 11.2475H19.0001V7.58087C19.0001 7.03467 18.7921 6.52144 18.4141 6.13499L16 3.66662C15.628 3.28567 15.1124 3.0675 14.586 3.0675H13V1.53375C13 0.686992 12.3282 0 11.5 0H1.5C0.671877 0 0 0.686992 0 1.53375V11.7587C0 12.6055 0.671877 13.2925 1.5 13.2925H2.00001C2.00001 14.986 3.34376 16.36 5.00002 16.36C6.65627 16.36 8.00002 14.986 8.00002 13.2925H12C12 14.986 13.3438 16.36 15 16.36C16.6563 16.36 18.0001 14.986 18.0001 13.2925H19.5001C19.7754 13.2925 20.0001 13.0623 20.0001 12.7812V11.7587C20.0001 11.4776 19.7754 11.2475 19.5001 11.2475ZM5.00002 14.8262C4.17189 14.8262 3.50001 14.1392 3.50001 13.2925C3.50001 12.4457 4.17189 11.7587 5.00002 11.7587C5.82814 11.7587 6.50002 12.4457 6.50002 13.2925C6.50002 14.1392 5.82814 14.8262 5.00002 14.8262ZM13 5.1125H14.586L17.0001 7.58087V8.17999H13V5.1125ZM15 14.8262C14.1719 14.8262 13.5 14.1392 13.5 13.2925C13.5 12.4457 14.1719 11.7587 15 11.7587C15.8282 11.7587 16.5001 12.4457 16.5001 13.2925C16.5001 14.1392 15.8282 14.8262 15 14.8262Z"
      fill={fill}
    />
  </svg>
)

TruckJaka.propTypes = propTypes
export default TruckJaka
