import React, { Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import propTypes from './props'

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useSelector(state => state.Users.token)
  return <Fragment>{isLoggedIn ? children : <Redirect to="/login-redirect" />}</Fragment>
}
PrivateRoute.propTypes = propTypes
export default PrivateRoute
