/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Radio } from '@material-ui/core'
import { translate } from 'react-i18next'
import { formatMoney, gtmSingleProduct, haveOfferTag, getDiscountAmount } from '../../../utils/helpers'
import OutOfStockLabel from '../../atoms/OutOfStockLabel'
import FlatButton from '../../molecules/FlatButton'
import QuantityModifier from '../../molecules/QuantityModifier'
import Actions from '../../../actions'
import propTypes from './props'

const UpsellingProductDetail = ({
  t,
  product,
  selectedVariant,
  setSelectedVariant,
  currentLanguage,
  handleAlertOutStock,
  alertOutStock,
  outOfStock
}) => {
  const { variants } = product
  const cart = useSelector(state => state.Checkout.upselling)
  const { userData } = useSelector(state => state.Users)
  const offer = haveOfferTag(product)

  const productInCart = cart.find(
    item => +item.variantId === selectedVariant.id || +item.shopifyVariantId === selectedVariant.id
  )
  const purchaseType = productInCart && (productInCart.purchase_type || productInCart.purchaseType)
  const [toSubscribe, setToSubscribe] = useState(false)
  const { price, compare_at_price, appliedDiscount } = selectedVariant
  const dispatch = useDispatch()
  const addUpselling = data => dispatch(Actions.Checkout.addUpselling(data))
  const removeUpselling = (index, id, subscription) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({ ...product, variantId: selectedVariant.id, purchaseType: subscription })
    )
    dispatch(Actions.Checkout.removeUpselling(index, id))
  }

  useEffect(() => {
    gtmSingleProduct(product, price, selectedVariant.sku, userData, { list: 'productDetail' }, 'detail')
  }, [])

  useEffect(() => {
    if (productInCart) setToSubscribe(purchaseType === 'SUBSCRIPTION')
  }, [productInCart])

  const handleQuantityChange = type => {
    const bubblesAnimation = document.getElementById('cart-button')
    switch (type) {
      case 'add':
        addUpselling({
          ...product,
          variantId: selectedVariant.id,
          purchaseType: toSubscribe ? 'SUBSCRIPTION' : 'ONE_TIME',
          price,
          isOffer: offer
        })
        bubblesAnimation.classList.remove('animate')
        bubblesAnimation.classList.add('animate')
        setTimeout(() => {
          bubblesAnimation.classList.remove('animate')
        }, 500)
        break

      case 'remove':
        for (let index = cart.length - 1; index >= 0; index -= 1) {
          if (+cart[index].variantId === selectedVariant.id || +cart[index].shopifyVariantId === selectedVariant.id) {
            removeUpselling(index, null, toSubscribe ? 'SUBSCRIPTION' : 'ONE_TIME')
            break
          }
        }
        break

      default:
        break
    }
  }

  const getSelectedVariantQuantity = variant => {
    let q = 0
    cart.forEach(up => {
      if (+up.variantId === variant.id || +up.shopifyVariantId === variant.id) {
        q += 1
      }
    })
    return q
  }
  const selectedVariantQuantity = getSelectedVariantQuantity(selectedVariant)

  const setSubscribedUpselling = (variantId, addonsPrices) => {
    dispatch(Actions.Checkout.setSubscribedUpselling(variantId, addonsPrices))
  }

  const isSubscribable = item => {
    return item.variants[0].appliedDiscount > 0 && !haveOfferTag(item)
  }

  const subscriptionPriceCross = formatMoney(price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity))

  const subscriptionPrice = formatMoney(
    compare_at_price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
  )

  const subscriptionPriceWrapper = () => (
    <Typography variant="inherit" className="subscription-price">
      <Typography className="subscription-price__discount-cross">
        <span className="subscription-price__cross">{`${t('default.was')} ${subscriptionPriceCross}`}</span>
      </Typography>
      <span className="subscription-price__discount-price">{`${t('default.aed')} ${subscriptionPrice}`}</span>
      <span className="subscription-price__text">{`${t('default.vatNotIncluded')}`}</span>
    </Typography>
  )

  const subscriptionPriceUpselling = compare_at_price ? subscriptionPrice : subscriptionPriceCross

  return (
    <>
      <Grid container item className="product-detail-page-diaper__add-space">
        <Grid container item xs={12} className="product-detail-page-diaper__subtitle add-space">
          <Typography variant="inherit">{t('vip.option')}</Typography>
        </Grid>
        <Grid container item className="filter__item__variants pdp">
          {variants
            ? variants.map(variant => (
                <button
                  type="button"
                  key={variant.id}
                  className={`filter__item__variants__variant-chip${
                    selectedVariant && variant.id === selectedVariant.id ? '__selected' : ''
                  } pdp`}
                  onClick={() => setSelectedVariant(variant)}>
                  <strong>{`${variant.option1} ${variant.option2}`}</strong>
                </button>
              ))
            : variants.map(
                variant =>
                  variant.id === selectedVariant.id && (
                    <button
                      type="button"
                      key={variant.id}
                      className={`filter__item__variants__variant-chip__selected pdp`}>
                      <strong>{`${variant.option1} ${variant.option2} `}</strong>
                    </button>
                  )
              )}
        </Grid>

        {isSubscribable(product) ? (
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            justify="space-between"
            direction="column"
            wrap="nowrap"
            className={`product-detail-page-content__prices ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
            <Grid
              onClick={() => {
                setToSubscribe(false)
                if (productInCart && purchaseType === 'SUBSCRIPTION')
                  setSubscribedUpselling(productInCart.variantId || +productInCart.shopifyVariantId, {
                    price,
                    compare_at_price,
                    appliedDiscount
                  })
              }}
              className={`purchase-selector ${!toSubscribe && 'selected'} add-space`}>
              <Radio className={`purchase-selector__radio ${!toSubscribe && 'selected'}`} checked={!toSubscribe} />
              <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
                {`${t('boxes.oneTimeProducts')}`}
                <span>{`${t('default.aed')} ${formatMoney(subscriptionPriceCross)}`}</span>
              </Typography>
            </Grid>

            <Grid
              onClick={() => {
                setToSubscribe(true)
                if (productInCart && purchaseType === 'ONE_TIME')
                  setSubscribedUpselling(productInCart.variantId || +productInCart.shopifyVariantId, {
                    price,
                    compare_at_price,
                    appliedDiscount
                  })
              }}
              className={`purchase-selector subscribe ${toSubscribe && 'selected'}`}>
              <Radio className={`purchase-selector__radio ${toSubscribe && 'selected'}`} checked={toSubscribe} />

              <Typography variant="inherit" className="subscription-text">
                {`${t('default.subscription')} ${t('default.get')} `}
                <span className="discount-amount">
                  {`${getDiscountAmount(subscriptionPrice, subscriptionPriceCross)} ${t('default.discountLabel')}`}
                </span>
                <span className="subscription-text__info">
                  {`${t('cart.subscribeInformation.get')} ${
                    selectedVariant.option1 * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
                  } ${selectedVariant.option2} ${t('cart.subscribeInformation.upsellingPerMonth')}`}
                </span>
              </Typography>

              {subscriptionPriceWrapper()}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            justify="space-between"
            direction="column"
            className={`product-detail-page-content__prices ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
            {compare_at_price && (
              <Typography
                className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''} cross upselling`}
                variant="inherit">
                <span className="subscription-price__discount upselling">
                  {`${t('default.aed')} ${formatMoney(appliedDiscount)} ${t('default.off')}`}
                </span>
                {`${t('default.aed')} ${formatMoney(price)}`}
              </Typography>
            )}
            <Typography variant="inherit" className="subscription-price__upselling">
              {`${t('default.aed')} ${formatMoney(subscriptionPriceUpselling)}`}
              <span>{t('default.vatNotIncluded')}</span>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container justify="flex-start" className="product-detail-page-diaper__add-space">
        {selectedVariantQuantity === 0 ? (
          <FlatButton
            idButton="add-wipe"
            cartIcon={outOfStock ? '' : 'cart2'}
            iconClass="cart-icon-2"
            classes={`filter__item__info__add-button pdp ${outOfStock ? 'disabled' : ''}`}
            onClick={() => {
              if (!outOfStock) {
                addUpselling({
                  ...product,
                  variantId: selectedVariant.id,
                  purchaseType: compare_at_price && !offer ? 'SUBSCRIPTION' : 'ONE_TIME',
                  price,
                  isOffer: offer
                })
                const bubblesAnimation = document.getElementById('cart-button')
                bubblesAnimation.classList.remove('animate')
                bubblesAnimation.classList.add('animate')
                setTimeout(() => {
                  bubblesAnimation.classList.remove('animate')
                }, 500)
              }
            }}
            title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
          />
        ) : (
          <div style={{ position: 'relative', width: '100%', marginBottom: '10px' }}>
            <QuantityModifier
              pdp
              quantity={selectedVariantQuantity}
              setQuantity={handleQuantityChange}
              variantSelected={selectedVariant}
              handleAlertOutStock={handleAlertOutStock}
              alertOutStock={alertOutStock}
              stockControl
              cart={cart}
            />
          </div>
        )}
      </Grid>
      {alertOutStock && (
        <Grid item container>
          <OutOfStockLabel inventoryQuantity={selectedVariant.inventory_quantity} inPDP t={t} />
        </Grid>
      )}
      <Grid item container justify="flex-start" className="product-detail-page-diaper fixed-mobile">
        {selectedVariantQuantity === 0 ? (
          <>
            <Grid
              container
              item
              alignItems="flex-start"
              justify="center"
              direction="column"
              className={`product-detail-page-content__prices fixed-mobile ${
                currentLanguage === 'AR' ? 'inverted' : ''
              }`}>
              {appliedDiscount > 0 ? (
                <>
                  <Typography
                    className={`regular-price fixed-mobile ${currentLanguage === 'AR' ? 'inverted' : ''} ${
                      offer || toSubscribe ? 'cross' : 'show'
                    }`}
                    variant="inherit">
                    {`${t('default.aed')} ${formatMoney(subscriptionPriceCross)}`}
                  </Typography>
                  <Typography
                    variant="inherit"
                    className={`subscription-price fixed-mobile ${offer || toSubscribe ? 'show' : 'cross'}`}>
                    {`${t('default.aed')} ${formatMoney(subscriptionPrice)}`}
                  </Typography>
                </>
              ) : (
                <Typography variant="inherit" className="subscription-price fixed-mobile">
                  {`${t('default.aed')} ${formatMoney(subscriptionPriceCross)}`}
                </Typography>
              )}
            </Grid>
            <FlatButton
              idButton="add-wipe"
              cartIcon={outOfStock ? '' : 'cart2'}
              iconClass="cart-icon-2"
              classes={`filter__item__info__add-button fixed-mobile ${outOfStock ? 'disabled' : ''}`}
              onClick={() => {
                if (!outOfStock) {
                  addUpselling({
                    ...product,
                    variantId: selectedVariant.id,
                    purchaseType: compare_at_price && !offer ? 'SUBSCRIPTION' : 'ONE_TIME',
                    price: +price,
                    isOffer: offer
                  })
                  const bubblesAnimation = document.getElementById('cart-button')
                  bubblesAnimation.classList.remove('animate')
                  bubblesAnimation.classList.add('animate')
                  setTimeout(() => {
                    bubblesAnimation.classList.remove('animate')
                  }, 500)
                }
              }}
              title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
            />
          </>
        ) : (
          <div style={{ position: 'relative' }}>
            <QuantityModifier
              pdp
              fixedMobile
              quantity={selectedVariantQuantity}
              setQuantity={handleQuantityChange}
              variantSelected={selectedVariant}
              handleAlertOutStock={handleAlertOutStock}
              alertOutStock={alertOutStock}
              stockControl
              cart={cart}
            />
          </div>
        )}
      </Grid>
    </>
  )
}
UpsellingProductDetail.propTypes = propTypes
export default translate('common')(UpsellingProductDetail)
