/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import propTypes from './props'

const SubscriptionToggle = ({ subscribed, action, discountAmount, t }) => {
  return (
    <Grid item container className="cart-item__subscribe-buttons-container">
      <Typography
        onClick={() => {
          if (subscribed) action()
        }}
        className={`cart-item__subscribe-button ${!subscribed && 'selected'}`}>
        {t('checkout.oneTime')}
      </Typography>

      <Typography
        onClick={() => {
          if (!subscribed) action()
        }}
        className={`cart-item__subscribe-button cart-item__subscribe-button--last ${subscribed && 'selected'}`}>
        {t('default.subscription')}{' '}
        <span>
          {t('default.youSave')} {Math.trunc(discountAmount)}%
        </span>
      </Typography>
    </Grid>
  )
}

SubscriptionToggle.propTypes = propTypes
export default SubscriptionToggle
