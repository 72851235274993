/* eslint-disable max-len */
import React from 'react'
import propTypes from './props'

const Download = () => (
  <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-WEB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="09-Boxes-EDIT-Modal-Copy" transform="translate(-558.000000, -534.000000)" fill="#C38A9F">
        <g id="Group-4" transform="translate(558.000000, 534.000000)">
          <path
            d="M17.3068602,11 L15.9205807,11 C15.5086775,11 15.2317677,11.2500781 15.2317677,11.6259769 L15.2317677,13.1889653 C15.2317677,13.3749609 15.0924475,13.5 14.8830345,13.5 L3.11696553,13.5 C2.90668718,13.5 2.7682323,13.3749609 2.7682323,13.1889653 L2.7682323,11.6259769 C2.7682323,11.2500781 2.49132253,11 2.07509254,11 L0.693139753,11 C0.276909764,11 0,11.2500781 0,11.6259769 L0,15.3740231 C0,15.7499219 0.276909764,16 0.693139753,16 L17.3068602,16 C17.7222249,16 18,15.7499219 18,15.3740231 L18,11.6259769 C18,11.2500781 17.7222249,11 17.3068602,11 Z"
            id="Fill-1"
          />
          <path
            d="M8.49950888,10.7920475 C8.64237878,10.9295368 8.85489776,11 8.99776766,11 C9.14153049,11 9.35762122,10.9295368 9.50049112,10.7920475 L13.787481,6.66994766 C13.9303509,6.5324584 14,6.39410984 14,6.18615733 C14,5.98164206 13.9303509,5.8441528 13.787481,5.70666354 L12.7864988,4.74337942 C12.642736,4.60503086 12.4998661,4.53800484 12.2837753,4.53800484 C12.0712564,4.53800484 11.9283865,4.60503086 11.7855166,4.74337942 L11.0702741,5.43082572 C10.8568622,5.6362003 10.4282525,5.49785173 10.4282525,5.1558472 L10.4282525,0.688305601 C10.4282525,0.274978517 10.1425127,0 9.71301009,0 L8.28698991,0 C7.85748728,0 7.57174748,0.274978517 7.57174748,0.688305601 L7.57174748,5.22287321 C7.57174748,5.49785173 7.21189392,5.70666354 6.92615412,5.49785173 L6.21448344,4.81384267 C6.07161354,4.6754941 5.92874364,4.60503086 5.71265292,4.60503086 C5.499241,4.60503086 5.3563711,4.6754941 5.21350121,4.81384267 L4.21251897,5.77368956 C4.06964908,5.91117881 4,6.04866807 4,6.25662058 C4,6.46199516 4.06964908,6.59948442 4.21251897,6.73697367 L8.49950888,10.7920475 Z"
            id="Fill-4"
          />
        </g>
      </g>
    </g>
  </svg>
)

Download.propTypes = propTypes
export default Download
