/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-danger */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import FlatButton from '../FlatButton'
import propTypes from './props'
import { formatMoney, haveOfferTag } from '../../../utils/helpers'
import IconComponent from '../../atoms/IconComponent'

const InfoModalDiapers = ({
  t,
  diaper,
  selectedVariant,
  selectVariant,
  getSelectedVariantQuantity,
  sizeRanges,
  selectedAmount,
  setSelectedAmount,
  prices,
  selectedVariantQuantity,
  addDiaper,
  setShowOk,
  QuantityModifier,
  handleQuantityChange,
  onlyInfo,
  isEdition,
  currentLanguage
}) => {
  const { options, presentationsBySize } = diaper
  const { price, compare_at_price, appliedDiscount } = prices

  return (
    <>
      <Grid container className="info-modal-diaper">
        <Grid container item xs={12} className="info-modal-diaper__subtitle">
          <Typography variant="inherit">{t('vip.quantity')}</Typography>
        </Grid>
        <Grid container item xs={12} lg={7} className="info-modal-diaper__add-space" justify="flex-start">
          {isEdition ? (
            <button
              type="button"
              key={selectedVariant.option2}
              className={`filter__item__variants__variant-chip__selected`}>
              <strong>{`${selectedVariant.option2} u.`}</strong>
            </button>
          ) : (
            <>
              {selectedVariant && !onlyInfo
                ? presentationsBySize[selectedVariant.option1].map(item => (
                    <button
                      type="button"
                      key={item.quantity}
                      className={`filter__item__variants__variant-chip${
                        item.quantity === selectedAmount.quantity ? '__selected' : ''
                      }`}
                      onClick={() => setSelectedAmount(item)}>
                      <strong>{`${item.quantity} u.`}</strong>
                    </button>
                  ))
                : presentationsBySize[selectedVariant.option1].map(
                    item =>
                      +item.quantity === +selectedVariant.option2 && (
                        <button
                          type="button"
                          key={item.quantity}
                          className={`filter__item__variants__variant-chip__selected`}>
                          <strong>{`${item.quantity} u.`}</strong>
                        </button>
                      )
                  )}
            </>
          )}
        </Grid>
        <Grid container item xs={12} className="info-modal-diaper__subtitle">
          <Typography variant="inherit">{t('vip.selectSize')}</Typography>
        </Grid>
        <Grid container item xs={12} md={10} lg={9} className="info-modal-diaper__add-space">
          {options && !onlyInfo
            ? options[0].values.map((size, index) => (
                <button
                  type="button"
                  key={`${options.product_id}-${size}`}
                  className={`filter__item__variants__button${
                    (selectedVariant && size === selectedVariant.option1) ||
                    (getSelectedVariantQuantity(selectedVariant) > 0 && size === selectedVariant.option1)
                      ? '__selected'
                      : ''
                  }`}
                  onClick={() => {
                    selectVariant(size)
                  }}>
                  <Grid container item direction="column">
                    <span>
                      {size !== 'NB' ? 'Size ' : ''}
                      <strong>{`${size} `}</strong>
                    </span>
                    <span>{sizeRanges[index]}</span>
                  </Grid>
                </button>
              ))
            : options[0].values.map(
                (size, index) =>
                  selectedVariant.option1 === size && (
                    <button
                      type="button"
                      key={`${options.product_id}-${size}`}
                      className={`filter__item__variants__button__selected`}>
                      <Grid container item direction="column">
                        <span>
                          {size !== 'NB' ? 'Size ' : ''}
                          <strong>{`${size} `}</strong>
                        </span>
                        <span>{sizeRanges[index]}</span>
                      </Grid>
                    </button>
                  )
              )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="space-between"
          direction="column"
          className={`info-modal-content__prices ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
          <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
            {`${t('default.aed')} ${formatMoney(price)}`}
          </Typography>
          {compare_at_price > 0 && (
            <Typography variant="inherit" className="subscription-price">
              {`${t('default.aed')} ${formatMoney(compare_at_price)} `}
            </Typography>
          )}
          {appliedDiscount > 0 && (
            <Typography variant="inherit" className="subscription-text">
              {`${t('default.subscription')} ${t('default.youSave')} ${t('default.aed')} ${formatMoney(
                appliedDiscount
              )}`}
            </Typography>
          )}
        </Grid>
      </Grid>

      {!onlyInfo && (
        <Grid item container justify="center" xs={12} lg={12} className="info-modal-diaper__add-space">
          {selectedVariantQuantity === 0 ? (
            <FlatButton
              idButton="add-diaper"
              classes={`filter__item__info__add-button`}
              onClick={() => {
                addDiaper({
                  ...diaper,
                  variant_id: selectedVariant.id,
                  purchase_type: 'SUBSCRIPTION',
                  price,
                  product_size: selectedVariant.option1,
                  sku: selectedVariant.sku
                })
                setTimeout(() => {
                  setShowOk(false)
                }, 500)
                setShowOk(true)
              }}
              title={t(`default.addToBundle`)}
            />
          ) : (
            <QuantityModifier
              quantity={selectedVariantQuantity}
              setQuantity={handleQuantityChange}
              variantSelected={selectedVariant}
            />
          )}
        </Grid>
      )}
    </>
  )
}
const InfoModalUpsellingWipes = ({
  wipe,
  product,
  selectedVariant,
  setSelectedVariant,
  selectVariant,
  prices,
  selectedVariantQuantity,
  addWipe,
  addUpselling,
  setShowOk,
  QuantityModifier,
  handleQuantityChange,
  t,
  onlyInfo,
  offer,
  currentLanguage
}) => {
  const offerTag = haveOfferTag(product)
  const { price, compare_at_price, appliedDiscount } = prices

  return (
    <>
      {wipe ? (
        <>
          <Grid container item className="info-modal-diaper__add-space" justify="flex-start">
            {wipe.options && !onlyInfo
              ? wipe.options[0].values.map(size => (
                  <button
                    type="button"
                    key={size}
                    className={`filter__item__variants__variant-chip${
                      selectedVariant && size === selectedVariant.option1 ? '__selected' : ''
                    }`}
                    onClick={() => selectVariant(size)}>
                    <Grid container item direction="column">
                      <span>
                        <strong>{`${size} `}</strong>
                        {'u.'}
                      </span>
                    </Grid>
                  </button>
                ))
              : wipe.options[0].values.map(
                  size =>
                    size === selectedVariant.option1 && (
                      <button type="button" key={size} className={`filter__item__variants__variant-chip__selected`}>
                        <Grid container item direction="column">
                          <span>
                            <strong>{`${size} `}</strong>
                            {'u.'}
                          </span>
                        </Grid>
                      </button>
                    )
                )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justify="space-between"
            direction="column"
            className={`info-modal-content__prices ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
            <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
              {`${t('default.aed')} ${formatMoney(price)}`}
            </Typography>
            <Typography variant="inherit" className="subscription-price">
              {`${t('default.aed')} ${formatMoney(compare_at_price)} `}
            </Typography>

            {!offerTag && (
              <Typography variant="inherit" className="subscription-text">
                {`${t('default.subscription')} ${t('default.youSave')} ${t('default.aed')} ${formatMoney(
                  appliedDiscount
                )}`}
              </Typography>
            )}
          </Grid>
        </>
      ) : (
        <Grid container item className="info-modal-diaper__add-space">
          <Grid container item className="filter__item__variants">
            {product.variants && !onlyInfo
              ? product.variants.map(variant => (
                  <button
                    type="button"
                    key={variant.id}
                    className={`filter__item__variants__variant-chip${
                      selectedVariant && variant.id === selectedVariant.id ? '__selected' : ''
                    }`}
                    onClick={() => setSelectedVariant(variant)}>
                    <strong>{`${variant.option1} `}</strong>
                    {variant.option2}
                  </button>
                ))
              : product.variants.map(
                  variant =>
                    variant.id === selectedVariant.id && (
                      <button
                        type="button"
                        key={variant.id}
                        className={`filter__item__variants__variant-chip__selected`}>
                        <strong>{`${variant.option1} `}</strong>
                        {variant.option2}
                      </button>
                    )
                )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justify="space-between"
            direction="column"
            className={`info-modal-content__prices ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
            <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
              {compare_at_price ? `${t('default.aed')} ${formatMoney(price)}` : ''}
            </Typography>
            <Typography variant="inherit" className="subscription-price">
              {compare_at_price
                ? `${t('default.aed')} ${formatMoney(compare_at_price)} `
                : `${t('default.aed')} ${formatMoney(price)}`}
            </Typography>
            <Typography variant="inherit" className="subscription-text">
              {compare_at_price && !offer
                ? `${t('default.subscription')} ${t('default.youSave')} ${t('default.aed')} ${formatMoney(
                    appliedDiscount
                  )}`
                : ''}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!onlyInfo && (
        <Grid item container justify="center" className="info-modal-diaper__add-space">
          {selectedVariantQuantity === 0 ? (
            <FlatButton
              idButton="add-wipe"
              classes="filter__item__info__add-button"
              onClick={() => {
                if (wipe)
                  addWipe({
                    ...wipe,
                    variant_id: selectedVariant.id,
                    purchase_type: 'SUBSCRIPTION',
                    price: +price,
                    sku: selectedVariant.sku
                  })
                else
                  addUpselling({
                    ...product,
                    variantId: selectedVariant.id,
                    purchaseType: compare_at_price && !offer ? 'SUBSCRIPTION' : 'ONE_TIME',
                    price: +price,
                    isOffer: offer,
                    sku: selectedVariant.sku
                  })
                setTimeout(() => {
                  setShowOk(false)
                }, 500)
                setShowOk(true)
              }}
              title={t('default.addToBundle')}
            />
          ) : (
            <QuantityModifier
              quantity={selectedVariantQuantity}
              setQuantity={handleQuantityChange}
              variantSelected={selectedVariant}
            />
          )}
        </Grid>
      )}
    </>
  )
}

const isMonthly = (amountSelected, monthlyRecommendation) => {
  return monthlyRecommendation === amountSelected
}

const InfoModal = ({
  t,
  img,
  diaper,
  wipe,
  product,
  selectedVariant,
  setSelectedVariant,
  selectVariant,
  getSelectedVariantQuantity,
  sizeRanges,
  selectedAmount,
  setSelectedAmount,
  prices,
  selectedVariantQuantity,
  addDiaper,
  addWipe,
  addUpselling,
  setShowOk,
  QuantityModifier,
  handleQuantityChange,
  handleClose,
  onlyInfo,
  isEdition,
  offer,
  currentLanguage
}) => {
  return (
    <Grid container className="info-modal-content">
      <IconComponent width="16px" height="16px" className="close-modal" icon="close" onClick={() => handleClose()} />
      <Grid item container direction="row" justify="center" className="content">
        <Grid item container className={diaper ? 'info-modal-img__diaper' : 'info-modal-img'}>
          <img src={img} alt={t('default.info')} />
          {diaper && (
            <>
              <div className="info-modal-img__diaper__icon">
                <IconComponent icon="diaperQuantity" alt={selectedVariant.option2 || selectedAmount.quantity} />
              </div>
              <div className="info-modal-img__diaper__icon">
                <span>{selectedVariant.option2 || selectedAmount.quantity}</span>
              </div>
            </>
          )}
        </Grid>
        <Grid item container direction="column" wrap="nowrap" xs={12} md={7} lg={7}>
          <Typography variant="inherit" className="info-title">
            {diaper ? diaper.title : product ? product.title : wipe.title}
            {diaper ? ` X ${selectedVariant.option2 || selectedAmount.quantity} units` : ''}
          </Typography>

          <Typography variant="inherit" className="info-unit">
            {diaper
              ? `(${t('default.aed')} ${formatMoney(
                  prices.compare_at_price / (selectedVariant.option2 || selectedAmount.quantity)
                )} ${t('default.per-unit')})`
              : ''}
          </Typography>
          {diaper && diaper.presentationsBySize && (
            <span className="info-subtitle">
              <Typography variant="inherit">
                {' '}
                {selectedVariant &&
                  isMonthly(
                    onlyInfo ? selectedVariant.option2 : selectedAmount.quantity,
                    diaper.presentationsBySize[selectedVariant.option1][0].quantity
                  ) &&
                  t('products.subcategories.monthlydiapers')}
              </Typography>
            </span>
          )}
          <Grid item xs={12} lg={10} className="info-description">
            {diaper ? (
              <InfoModalDiapers
                t={t}
                isEdition={isEdition}
                onlyInfo={onlyInfo}
                selectedVariant={selectedVariant}
                getSelectedVariantQuantity={getSelectedVariantQuantity}
                selectVariant={selectVariant}
                selectedAmount={selectedAmount}
                prices={prices}
                setSelectedAmount={setSelectedAmount}
                selectedVariantQuantity={selectedVariantQuantity}
                addDiaper={addDiaper}
                setShowOk={setShowOk}
                QuantityModifier={QuantityModifier}
                handleQuantityChange={handleQuantityChange}
                sizeRanges={sizeRanges}
                diaper={diaper}
                currentLanguage={currentLanguage}
              />
            ) : (
              <InfoModalUpsellingWipes
                t={t}
                onlyInfo={onlyInfo}
                wipe={wipe}
                product={product}
                selectedVariant={selectedVariant}
                setSelectedVariant={setSelectedVariant}
                selectVariant={selectVariant}
                getSelectedVariantQuantity={getSelectedVariantQuantity}
                prices={prices}
                selectedVariantQuantity={selectedVariantQuantity}
                addWipe={addWipe}
                addUpselling={addUpselling}
                setShowOk={setShowOk}
                QuantityModifier={QuantityModifier}
                handleQuantityChange={handleQuantityChange}
                offer={offer}
                currentLanguage={currentLanguage}
              />
            )}
            <div
              className="info-details"
              dangerouslySetInnerHTML={{
                __html: diaper ? diaper.body_html : product ? product.body_html : wipe.body_html
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
InfoModal.propTypes = propTypes
export default InfoModal
