import _toConsumableArray from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
var initialState = {
  token: null,
  userData: null,
  logged: false,
  loading: false,
  errorLogin: null,
  errorRegister: null,
  registerData: null,
  errorRecovery: null,
  recoveryToken: null,
  subscriptions: [],
  pastSubscriptions: [],
  newsletter: false,
  paymentMethod: [],
  currentEditableBox: null,
  retryPaymentStatus: null,
  invoiceStatus: null,
  shippingDateUpdated: false,
  editedSubscriptionDate: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'UPDATE_SUBSCRIPTION_ITEM':
      return _objectSpread({}, state, {
        subscriptions: state.subscriptions.map(function (s) {
          if (s.id === action.payload.id) {
            return _objectSpread({}, s, {
              boxProducts: s.boxProducts.map(function (p) {
                if (p.product_type === action.payload.product.product_type) {
                  return _objectSpread({}, action.payload.product);
                }
                return p;
              })
            });
          }
          return s;
        })
      });
    case 'USER_ERROR_LOGIN':
      return _objectSpread({}, state, {
        errorLogin: action.payload
      });
    case 'SET_UPVOTY_TOKEN':
      return _objectSpread({}, state, {
        tokenUpvoty: action.payload
      });
    case 'USER_SET_RECOVERY_TOKEN':
      return _objectSpread({}, state, {
        recoveryToken: action.payload
      });
    case 'USER_ERROR_RECOVERY_CLEAN':
      return _objectSpread({}, state, {
        errorRecovery: null
      });
    case 'USER_RECOVERY_OK':
      return _objectSpread({}, state, {
        errorRecovery: 'OK'
      });
    case 'USER_NEWSLETTER':
      return _objectSpread({}, state, {
        newsletter: true
      });
    case 'USER_SET_CURRENT_PAYMENT_METHOD':
      return _objectSpread({}, state, {
        paymentMethod: action.payload.data
      });
    case 'USER_REMOVE_CARD':
      return _objectSpread({}, state, {
        paymentMethod: state.paymentMethod.filter(function (c) {
          return c.id !== action.payload.data;
        })
      });
    case 'USER_UPDATE_ACTIVE_CARD':
      return _objectSpread({}, state, {
        paymentMethod: state.paymentMethod.filter(function (c) {
          return c.id === action.payload.data;
        }).active === true
      });
    case 'USER_ERROR_REGISTER':
      return _objectSpread({}, state, {
        errorRegister: action.payload.response.data,
        loading: false
      });
    case 'USER_ERROR_RECOVERY':
      return _objectSpread({}, state, {
        errorRecovery: action.payload
      });
    case 'USER_RESET_ERROR_LOGIN':
      return _objectSpread({}, state, {
        errorLogin: null
      });
    case 'USER_RESET_ERROR_REGISTER':
      return _objectSpread({}, state, {
        errorRegister: null
      });
    case 'USER_SET_DATA':
      return _objectSpread({}, state, {
        userData: _objectSpread({}, state.userData, action.payload),
        logged: true
      });
    case 'USER_SET_AUTH_TOKEN':
      return _objectSpread({}, state, {
        token: action.payload.token,
        logged: true,
        loading: false
      });
    case 'USER_SET_REGISTER_DATA':
      return _objectSpread({}, state, {
        registerData: _objectSpread({}, action.payload)
      });
    case 'USER_UPDATE_ADDRESS':
      return _objectSpread({}, state, {
        userData: _objectSpread({}, state.userData, {
          default_address: _objectSpread({}, action.payload)
        })
      });
    case 'USER_SET_SUBSCRIPTIONS':
      {
        var newState = _objectSpread({}, state, {
          loading: false
        });
        if (action.payload.isPastSubscriptions) {
          newState = _objectSpread({}, newState, {
            pastSubscriptions: action.payload.boxes
          });
        } else if (action.payload.isPendingSubscriptions) {
          newState = _objectSpread({}, newState, {
            pendingSubscriptions: action.payload.boxes
          });
        } else {
          newState = _objectSpread({}, newState, {
            subscriptions: action.payload.boxes
          });
        }
        return newState;
      }
    case 'USER_CLEAN_SUBSCRIPTIONS':
      return _objectSpread({}, state, {
        subscriptions: [],
        pastSubscriptions: [],
        loading: false
      });
    case 'SET_LOADING_SUBSCRIPTIONS':
      return _objectSpread({}, state, {
        loading: true
      });
    case 'USER_CLEAN_DATA':
      return initialState;
    case 'USER_UPDATE_SUBSCRIPTION_STATE':
      {
        var subs = _toConsumableArray(state.subscriptions);
        if (action.payload.newStatus === 'CANCELED') {
          var index;
          var boxCanceled = subs.find(function (sub, i) {
            index = i;
            return sub.id === action.payload.babyBoxId;
          });
          boxCanceled.status = action.payload.newStatus;
          subs.splice(index, 1);
        } else {
          subs.forEach(function (sub, index) {
            if (sub.id === action.payload.babyBoxId) subs[index].status = action.payload.newStatus;
          });
        }
        return _objectSpread({}, state, {
          subscriptions: subs
        });
      }
    case 'SET_HELPDESK_USER_TOKEN':
      return _objectSpread({}, state, {
        token: action.payload
      });
    case 'UPDATE_SHIPPING_DATE':
      {
        var subscriptions = _toConsumableArray(state.subscriptions);
        subscriptions.forEach(function (subs, index) {
          if (subs.id === action.payload.subId) subscriptions[index].shippingDate = action.payload.newDate;
        });
        return _objectSpread({}, state, {
          subscriptions: subscriptions
        });
      }
    case 'RETRY_PAYMENT_STATUS':
      return _objectSpread({}, state, {
        retryPaymentStatus: action.payload
      });
    case 'CHECKOUT_SET_STATUS_CARD_REQUEST':
      return _objectSpread({}, state, {
        statusCardRequest: action.payload
      });
    case 'INVOICE_STATUS':
      return _objectSpread({}, state, {
        invoiceStatus: action.payload
      });
    case 'SET_SHIPPING_UPDATE':
      return _objectSpread({}, state, {
        shippingDateUpdated: action.payload
      });
    case 'SET_EDITED_SUBSCRIPTION_DATE':
      return _objectSpread({}, state, {
        editedSubscriptionDate: _objectSpread({}, action.payload)
      });
    case 'SET_NULL_EDITED_SUBSCRIPTION_DATE':
      return _objectSpread({}, state, {
        editedSubscriptionDate: action.payload
      });
    case 'SET_STATUS_PHONE_CODE':
      return _objectSpread({}, state, {
        statusPhoneCode: action.payload
      });
    default:
      return state;
  }
};
export default reducer;