import React, { Fragment, useState } from 'react'
import { translate } from 'react-i18next'
import { Modal, Grid } from '@material-ui/core'
import CancelSubscription from '../../molecules/CancelSubscription'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'

const CancelSubscriptionModal = ({
  t,
  openModal,
  handleClose,
  id,
  history,
  disableEditContent,
  boxDiscount,
  status,
  setOpenDatePicker,
  setOpenModal,
  isOneOffBox,
  subscription,
  isSubscribableBox,
  handleOptionSelected
}) => {
  const [showTypeForm, setShowTypeForm] = useState(false)

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={handleClose}
      className={showTypeForm ? 'cancel-modal type-form' : ''}>
      <div tabIndex={-1}>
        <Fragment>
          <Grid container className={showTypeForm ? 'modal-cancel type-form' : 'yalla-modal'}>
            <Grid
              item
              xs={2}
              container
              justify="center"
              alignItems="center"
              direction="column"
              className="modal-cancel__close yalla-modal__close">
              <IconComponent
                width="15px"
                height="15px"
                fill="black"
                icon="close"
                onClick={() => {
                  handleClose()
                }}
              />
            </Grid>

            <Grid container item>
              <CancelSubscription
                disableEditContent={disableEditContent}
                t={t}
                id={id}
                history={history}
                handleClose={handleClose}
                showTypeForm={showTypeForm}
                setShowTypeForm={setShowTypeForm}
                boxDiscount={boxDiscount}
                status={status}
                setOpenDatePicker={setOpenDatePicker}
                setOpenModal={setOpenModal}
                isOneOffBox={isOneOffBox}
                subscription={subscription}
                isSubscribableBox={isSubscribableBox}
                handleOptionSelected={handleOptionSelected}
              />
            </Grid>
          </Grid>
        </Fragment>
      </div>
    </Modal>
  )
}

CancelSubscriptionModal.propTypes = propTypes
export default translate('common')(CancelSubscriptionModal)
