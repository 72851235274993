/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
import React from 'react'
// Tools
import propTypes from './props'

const AddRemove = ({ remove, fill = '#48AEE1', border = '#fff', height = '25px', ...props }) => (
  <button type="button" {...props}>
    <svg
      height={height}
      viewBox="0 0 18 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M15.3636364,3.3642857 C17.0643939,5.06504327 18,7.32261903 18,9.72792206 C18,12.1332251 17.0643939,14.3908008 15.3636364,16.0915584 C13.6628788,17.792316 11.405303,18.7279221 9,18.7279221 C6.59469697,18.7279221 4.33712121,17.792316 2.63636364,16.0915584 C0.935606061,14.3908008 0,12.1332251 0,9.72792206 C0,7.32261903 0.935606061,5.06504327 2.63636364,3.3642857 C4.33712121,1.66352812 6.59469697,0.727922061 9,0.727922061 C11.405303,0.727922061 13.6628788,1.66352812 15.3636364,3.3642857 Z M14.6401515,15.3680736 C17.75,12.2582251 17.75,7.19761903 14.6401515,4.08777055 C13.1325758,2.58019479 11.1287879,1.75064933 9,1.75064933 C6.87121212,1.75064933 4.86742424,2.58019479 3.35984848,4.08777055 C0.25,7.19761903 0.25,12.2582251 3.35984848,15.3680736 C4.86742424,16.8756493 6.87121212,17.7051948 9,17.7051948 C11.1287879,17.7051948 13.1325758,16.8756493 14.6401515,15.3680736 Z M12.9659091,5.76201297 C13.1666667,5.96277055 13.1666667,6.28474024 12.9659091,6.48549782 L9.72348485,9.72792206 L12.9583333,12.9703463 C13.1590909,13.1711039 13.1590909,13.4930736 12.9583333,13.6938312 C12.8598485,13.7961039 12.7310606,13.8453463 12.5984848,13.8453463 C12.469697,13.8453463 12.3371212,13.792316 12.2386364,13.6938312 L8.99621212,10.4514069 L5.75378788,13.6938312 C5.65530303,13.7961039 5.52272727,13.8453463 5.39393939,13.8453463 C5.26515152,13.8453463 5.13257576,13.792316 5.03409091,13.6938312 C4.83333333,13.4930736 4.83333333,13.1711039 5.03409091,12.9703463 L8.27651515,9.72792206 L5.03409091,6.48549782 C4.83333333,6.28474024 4.83333333,5.96277055 5.03409091,5.76201297 C5.23484848,5.56125539 5.55681818,5.56125539 5.75757576,5.76201297 L9,9.00443721 L12.2424242,5.76201297 C12.4431818,5.56125539 12.7651515,5.56125539 12.9659091,5.76201297 Z"
          id="path-1"
        />
      </defs>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle id="Oval" fill={fill} cx="8.5" cy="9.72792206" r="8.5" />
        <mask id="mask-2" fill="white">
          <use href="#path-1" />
        </mask>
        {!remove ? (
          <use
            id="delet"
            fill={border}
            fillRule="nonzero"
            href="#path-1"
            transform="translate(9.000000, 9.727922) rotate(-45) translate(-9.000000, -9.727922) "
          />
        ) : (
          <use id="delet" fill={border} fillRule="nonzero" href="#path-1" />
        )}
      </g>
    </svg>
  </button>
)

AddRemove.propTypes = propTypes
export default AddRemove
