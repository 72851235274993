/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Divider, Container } from '@material-ui/core'
import Actions from '../../../actions'
import propTypes from './props'
import PaymentFrame from '../PaymentFrame'
import PriceDetail from '../PriceDetail'
import CreditCardMethod from '../../molecules/CreditCardMethod'
import IconComponent from '../../atoms/IconComponent'
import StepShipping from '../StepShipping'
import { gtmCheckoutSteps } from '../../../utils/helpers'
import Loader from '../../atoms/Loader'

const StepPayment = ({
  history,
  t,
  formValues,
  uniqueCartProducts,
  uniqueCartProductsQty,
  isAccount,
  setValues,
  logged,
  setTypeLoginSignin
}) => {
  const { selectedDiapers, selectedWipes, upselling, shippingDate, promoCode } = useSelector(state => state.Checkout)

  const provinceName = useSelector(state =>
    state.Users.userData && state.Users.userData.default_address && state.Users.userData.default_address.province
      ? state.Users.userData.default_address.province
      : 'Dubai'
  )

  const userAddress = useSelector(state =>
    state.Users.userData && state.Users.userData.default_address && state.Users.userData.default_address.address1
      ? state.Users.userData.default_address.address1
      : ''
  )

  const { shippingCost } = useSelector(state => state.Checkout.taxAndDiscount || {})

  const done = useSelector(state => (state.Checkout.status ? state.Checkout.status.status === 'ok' : false))

  const error = useSelector(state => (state.Checkout.status ? state.Checkout.status.status === 'error' : false))

  const cardError = useSelector(
    state =>
      state.Checkout.status &&
      state.Checkout.status.err &&
      state.Checkout.status.err.response &&
      state.Checkout.status.err.response.data &&
      state.Checkout.status.err.response.data.appCode === 8000
  )

  const paymentError = useSelector(
    state =>
      state.Checkout.status &&
      state.Checkout.status.err &&
      state.Checkout.status.err.response &&
      state.Checkout.status.err.response.data &&
      state.Checkout.status.err.response.data.appCode === 8001
  )

  const signupFormRef = useRef(null)
  const shippingFormRef = useRef(null)
  const currentLanguage = useSelector(state => state.Language.current)
  const paymentCardToken = useSelector(state => state.Checkout.paymentCardToken)
  const { vatPercent, codPercent, codAmount } = useSelector(state => state.Checkout.taxAndDiscount)

  const { userData } = useSelector(state => state.Users)

  const [disabledPayButton, setDisabledPayButton] = useState(false)
  const [cardExpired, setCardExpired] = useState(false)
  const noUserAddres = userData && !userData.addresses.length && !formValues.address1
  const [showInfo, setShowInfo] = useState(noUserAddres)

  const paymentMethod = useSelector(state => state.Users.paymentMethod)

  const errorStatus = useSelector(state => state.Checkout.status && state.Checkout.status)

  const havePaymentMethod = paymentMethod.length > 0

  const cards = havePaymentMethod && paymentMethod.filter(method => method.paymentType === 'CREDIT')

  const haveCards = cards.length > 0

  const [paymentType, setPaymentType] = useState('CREDIT')
  const [payLoader, setPayLoader] = useState(false)

  const products = selectedDiapers.concat(selectedWipes).concat(upselling)

  const verifySubscription = () => {
    return products.find(prod => prod.purchase_type === 'SUBSCRIPTION' || prod.purchaseType === 'SUBSCRIPTION')
  }

  const dispatch = useDispatch()
  const setCutstomerAddress = value => dispatch(Actions.Checkout.setCutstomerAddress(value))
  const fetchUpdateAddress = (a, cb) => dispatch(Actions.Users.fetchUpdateAddress(a, cb))
  const setShowOneOffs = value => dispatch(Actions.Checkout.setShowOneOffs(value))
  const getUserPaymentMethod = cb => dispatch(Actions.Users.getUserPaymentMethod(cb))
  const verifyCard = cb => dispatch({ type: 'CHECKOUT_FETCH_VERIFY_CARD', cb })
  const setPayWithNewCard = value => dispatch(Actions.Checkout.setPayWithNewCard(value))
  const setPayWithCOD = value => dispatch(Actions.Checkout.setPayWithCOD(value))
  const setIncomingBoxInfo = info => dispatch(Actions.Checkout.setIncomingBoxInfo(info))

  useEffect(() => {
    if (paymentCardToken) {
      verifyCard(() => getUserPaymentMethod())
    } else {
      getUserPaymentMethod()
    }
    setDisabledPayButton(!haveCards)
  }, [paymentCardToken])

  useEffect(() => {
    gtmCheckoutSteps(
      [...selectedDiapers, ...selectedWipes, ...upselling],
      userData,
      'paymentMethod',
      { step: '5', action: paymentType },
      promoCode && promoCode.title
    )
  }, [paymentType])

  useEffect(() => {
    if (paymentMethod.length > 0) {
      setDisabledPayButton(false)
    }
  }, [paymentMethod])

  const processNewBox = info => dispatch(Actions.Checkout.processNewBox(info))

  useEffect(() => {
    if (paymentError) {
      dispatch(Actions.Checkout.setStatusPaymentError(errorStatus))
      history.push('/checkout/fail')
    } else if (error && !cardError && done) {
      history.push('/checkout/done')
    }
  }, [error])

  useEffect(() => {
    if (done) {
      history.push('/checkout/done')
    }
  })

  const handlePayButton = () => {
    setPayLoader(true)
    setIncomingBoxInfo({ shippingDate, provinceName, isSubscription: verifySubscription() })

    if (paymentType === 'CREDIT') {
      setPayWithCOD(false)
      setPayWithNewCard(!haveCards)
    } else {
      setPayWithCOD(true)
      setPayWithNewCard(false)
    }
    if (!verifySubscription()) setShowOneOffs(true)
    processNewBox(formValues)
  }

  return (
    <Container className="cart">
      <Grid container className={currentLanguage === 'AR' ? 'invertAr' : ''} spacing={4}>
        <Grid item className="information-steps__step-payment" md={8} xs={12}>
          <PaymentFrame
            userAddress={userAddress}
            paymentType={paymentType}
            fetchUpdateAddress={fetchUpdateAddress}
            setCutstomerAddress={setCutstomerAddress}
            formValues={formValues}
            togglePayWithNewCard={value => dispatch(Actions.Checkout.setPayWithNewCard(value))}>
            <Grid item>
              <Grid item className={`information-steps__form__shipping${isAccount ? '__account' : ''}`}>
                <StepShipping
                  formValues={formValues}
                  setValues={setValues}
                  t={t}
                  history={history}
                  isAccount={isAccount}
                  setTypeLoginSignin={setTypeLoginSignin}
                  logged={logged}
                  inStepPayment
                  signupFormRef={signupFormRef}
                  shippingFormRef={shippingFormRef}
                  showInfo={showInfo}
                  setShowInfo={setShowInfo}
                />
              </Grid>
            </Grid>
            {logged && (
              <>
                <Grid
                  item
                  container
                  xs={12}
                  justify="flex-start"
                  className="information-steps__form__title payment-title">
                  <span>{t('checkout.billingInfo')}</span>
                </Grid>
                <Divider className="information-steps__form__divider" light variant="fullWidth" />
                <Grid item container className="information-steps__form__subtitle">
                  <Grid
                    item
                    container
                    xs={12}
                    className={`information-steps__form__payment ${paymentType === 'CREDIT' && 'selected'}`}>
                    <Grid
                      item
                      container
                      xs={12}
                      direction="row"
                      className="information-steps__form__payment__selection"
                      onClick={() => {
                        if (paymentType !== 'CREDIT') {
                          setDisabledPayButton(!haveCards)
                          setPaymentType('CREDIT')
                        }
                      }}>
                      <div className="information-steps__form__payment__radio-button">
                        <div
                          className={`${
                            paymentType === 'CREDIT' ? 'information-steps__form__payment__radio-button-selected' : ''
                          }`}>
                          {' '}
                        </div>
                      </div>
                      <Grid container item xs={10} justifyContent="space-between">
                        <Grid item xs={6} className="info">
                          <span>{t('checkout.creditCards')}</span>
                        </Grid>
                        <Grid item container justifyContent="space-evenly" direction="row" xs={6} md={3}>
                          <IconComponent icon="Visa" />
                          <IconComponent icon="Mastercard" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <CreditCardMethod
                      history={history}
                      t={t}
                      haveCards={haveCards}
                      paymentType={paymentType}
                      cards={cards}
                      setDisableSaveCard={setDisabledPayButton}
                      setCardExpired={setCardExpired}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={`information-steps__form__payment  ${paymentType === 'COD' ? 'selected' : ''}`}>
                    <Grid
                      item
                      container
                      xs={12}
                      direction="row"
                      className={`information-steps__form__payment__selection`}
                      onClick={() => {
                        if (paymentType !== 'COD') {
                          setDisabledPayButton(false)
                          setPaymentType('COD')
                        }
                      }}>
                      <div className="information-steps__form__payment__radio-button">
                        <div
                          className={`${
                            paymentType === 'COD' ? 'information-steps__form__payment__radio-button-selected' : ''
                          }`}>
                          {' '}
                        </div>
                      </div>
                      <Grid container item xs={10}>
                        <Grid item xs={8} className="info">
                          <span>{t('checkout.COD')}</span>
                        </Grid>
                      </Grid>
                      {paymentType === 'COD' && (
                        <>
                          <Grid item container xs={12} className="payment-card__cash-selected-text">
                            {t('payment.cashPurchase')}
                          </Grid>
                          <Grid item container xs={12} className="payment-card__cash-charge-text">
                            {`${t('payment.codChargeInfo')} ${codPercent ? '%' : t('cart.priceDetail.positiveAED')} ${
                              codPercent || codAmount
                            } ${t('payment.codCharge')}`}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Loader loading />
          </PaymentFrame>
        </Grid>
        <Grid item xs={12} md={4} className="information-steps__form__price-detail">
          <PriceDetail
            t={t}
            idButton="pay-button"
            handleButtonClick={() => handlePayButton()}
            payLoader={payLoader}
            titleButton={t('payment.pay')}
            showLimitInfo
            selectedDiapers={selectedDiapers}
            selectedWipes={selectedWipes}
            upselling={upselling}
            promoCode={promoCode}
            vatPercent={vatPercent}
            codPercent={codPercent}
            paymentType={paymentType}
            disabledPayButton={disabledPayButton}
            history={history}
            stepPay
            cardExpired={cardExpired}
            formValues={formValues}
            shippingCost={shippingCost}
            uniqueCartProducts={uniqueCartProducts}
            uniqueCartProductsQty={uniqueCartProductsQty}
            logged={logged}
            signupFormRef={signupFormRef}
            shippingFormRef={shippingFormRef}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            codFixedAmount={codAmount}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

StepPayment.propTypes = propTypes
export default translate('common')(StepPayment)
