/* eslint-disable max-len */
import React from 'react'

const AboutUsIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-59" transform="translate(1.000000, 1.000000)" stroke="#393939">
        <circle id="Oval" cx="11" cy="9" r="3" />
        <circle id="Oval-Copy-13" cx="18" cy="10" r="2" />
        <circle id="Oval-Copy-14" cx="4" cy="10" r="2" />
        <path
          d="M22,10.9999651 C22,4.93452852 17.065433,0 10.9999681,0 C4.93450331,0 0,4.93452852 0,10.9999651 C-0.00164740932,12.5820288 0.339905954,14.1455352 1.00099723,15.582137 C1.01199482,15.6167894 1.02745775,15.6498547 1.04699419,15.6804951 C2.8676202,19.5378343 6.74275447,21.9982978 10.9999681,22 C17.065433,22 22,17.0654715 22,10.9999651 Z"
          id="Path"
          fillRule="nonzero"
        />
        <path
          d="M19.9969057,14.3346838 C19.8910013,14.2290816 18.7244662,14.3988003 17.2381818,14.0618846 C16.4740996,13.88868 15.7650808,13.174619 14.7808253,13.0700692 C13.6361398,12.9484782 12.2716206,14.0401061 11,14.0618846 C9.62052423,14.0855103 8.33377993,13.0459018 7.11171772,13.0700692 C6.22418502,13.087621 5.56048803,13.8389261 4.68015056,14.0618846 C3.42176581,14.3805892 2.07346555,14.2077379 2,14.2766089 C2.77469209,16.3120876 6.14028298,20.2567509 11,20.2732799 C15.585116,20.2888764 20.1356571,15.8723888 19.9969057,14.3346838 Z"
          id="Path"
          strokeLinejoin="round"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
)

export default AboutUsIcon
