import Universal from './universal';
import Users from './users';
import Products from './products';
import Checkout from './checkout';
import Forms from './forms';
import Language from './language';
import Version from './version';
var reducers = {
  Universal: Universal,
  Users: Users,
  Products: Products,
  Checkout: Checkout,
  Forms: Forms,
  Language: Language,
  Version: Version
};
window.reducers = reducers;
export default reducers;