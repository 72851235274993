/* eslint-disable max-len */
import React from 'react'

const CartIconJaka = () => (
  <svg width="37" height="28" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 19.5C10.0073 19.5 9 20.5073 9 21.75C9 22.9927 10.0073 24 11.25 24C12.4927 24 13.5 22.9927 13.5 21.75C13.5 20.5073 12.4927 19.5 11.25 19.5ZM24.75 19.5C23.5073 19.5 22.5 20.5073 22.5 21.75C22.5 22.9927 23.5073 24 24.75 24C25.9927 24 27 22.9927 27 21.75C27 20.5073 25.9927 19.5 24.75 19.5ZM29.6967 2.09691C29.4103 1.71755 28.9738 1.5 28.4985 1.5H8.71673L8.60522 0.914625C8.50416 0.383953 8.04019 0 7.5 0H4.125C3.50367 0 3 0.503672 3 1.125C3 1.74633 3.50367 2.25 4.125 2.25H6.56911L9.39487 17.0855C9.49594 17.6161 9.95986 18 10.5 18H25.875C26.4963 18 27 17.4963 27 16.875C27 16.2537 26.4963 15.75 25.875 15.75H11.4309L11.0024 13.5H25.9276C26.5972 13.5 27.1856 13.0562 27.3696 12.4124L29.9413 3.41236C30.0717 2.95533 29.9823 2.47631 29.6967 2.09691ZM1.125 6.75H4.875C5.49638 6.75 6 6.24628 6 5.625C6 5.00362 5.49638 4.5 4.875 4.5H1.125C0.503719 4.5 0 5.00362 0 5.625C0 6.24628 0.503719 6.75 1.125 6.75ZM1.125 10.5H5.625C6.24638 10.5 6.75 9.99628 6.75 9.375C6.75 8.75362 6.24638 8.25 5.625 8.25H1.125C0.503719 8.25 0 8.75362 0 9.375C0 9.99628 0.503719 10.5 1.125 10.5ZM6.375 12H1.125C0.503719 12 0 12.5036 0 13.125C0 13.7463 0.503719 14.25 1.125 14.25H6.375C6.99638 14.25 7.5 13.7463 7.5 13.125C7.5 12.5036 6.99638 12 6.375 12Z"
      fill="#948F8B"
    />
  </svg>
)

export default CartIconJaka
