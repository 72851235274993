import React from 'react'
import { Grid, Typography, Modal } from '@material-ui/core'
import propTypes from './props'
import Logo from '../../../assets/images/logo.svg'
import Background from '../../../assets/images/background-dash.svg'

const MaintenanceModeModal = ({ t, open }) => {
  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        className="header__maintenance-modal"
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{ style: { backdropFilter: 'blur(3px)' } }}>
        <Grid>
          <>
            <Grid container item>
              <Grid container direction="column" justify="center" alignContent="center">
                <img
                  className="header__maintenance-modal__logo"
                  height="283px"
                  width="283px"
                  src={Logo}
                  alt="yalla baby logo"
                />
                <Grid className="header__maintenance-modal__text-container">
                  <img
                    className="header__maintenance-modal__text-container__background"
                    src={Background}
                    alt="yalla baby yellow dash"
                  />
                  <Typography className="header__maintenance-modal__text-container__text">
                    {t('default.underMaintenance')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Modal>
    </>
  )
}

MaintenanceModeModal.propTypes = propTypes
export default MaintenanceModeModal
