/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Hidden, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import propTypes from './props'
import ShoppingCartIcon from '../ShoppingCartIcon'
import { CalcTotalPriceMKT, formatMoney } from '../../../utils/helpers'

const ShoppingCartWrapper = ({ t, toggleDrawer, cart, history }) => {
  const isCartEmpty = cart.length === 0 || false

  const handleClick = () => {
    toggleDrawer(false, 'cart')
    if (!isCartEmpty) history.push('/cart')
  }

  const {
    selectedDiapers,
    selectedWipes,
    upselling,
    promoCode,
    taxAndDiscount: { vatPercent } = {}
  } = useSelector(state => state.Checkout)

  const { totalPriceWithDiscounts } = CalcTotalPriceMKT(
    selectedDiapers,
    selectedWipes,
    upselling,
    vatPercent,
    'Dubai',
    promoCode
  )

  const cartText = (
    <>
      <Box className="cart-text">
        {isCartEmpty ? (
          <Typography>{t('default.cartEmpty')}</Typography>
        ) : (
          <>
            <Typography className="cart-text--full">{t('default.cart')}</Typography>
            <Typography className="cart-text--full">{`AED ${formatMoney(totalPriceWithDiscounts)}`}</Typography>
          </>
        )}
      </Box>
    </>
  )

  return (
    <Box className="shopping-cart-wrapper" onClick={handleClick}>
      <Box className={`btn-cart ${isCartEmpty ? 'btn-cart--empty' : 'btn-cart--full'}`}>
        <ShoppingCartIcon handleClick={handleClick} t={t} />
        <Hidden smDown>
          {cartText}
          <div className={`vertical-line ${!isCartEmpty && `vertical-line--cart-full`}`} />
          <Box className={`btn-cart__chevron ${isCartEmpty ? 'btn-cart__chevron--empty' : 'btn-cart__chevron--full'}`}>
            <ChevronRightIcon />
          </Box>
        </Hidden>
      </Box>
    </Box>
  )
}

ShoppingCartWrapper.propTypes = propTypes
export default ShoppingCartWrapper
