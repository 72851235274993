/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Actions from '../../../actions'
import propTypes from './props'
import phoneRegex from '../../../utils/regexExpressions'
import { getErrorMessage, gtmCheckoutSteps } from '../../../utils/helpers'
import FlatButton from '../../molecules/FlatButton'
import PhoneVerificationModal from '../../../containers/PhoneVerificationModal'

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#54CE8A'
      }
    }
  }
})

const StepContactInfo = ({ history, t, isAccount, handleOpenLoginSignin }) => {
  const classes = useStyles()
  const currentLanguage = useSelector(state => state.Language.current)

  const { userData, logged, loading, errorRegister } = useSelector(state => state.Users)
  const { selectedDiapers, selectedWipes, upselling, promoCode } = useSelector(state => state.Checkout)

  const dispatch = useDispatch()
  const fetchUpdateUser = (data, cb) => dispatch(Actions.Users.fetchUpdateUser(data, cb))
  const fetchRegister = (data, cb) => dispatch(Actions.Users.fetchRegister(data, cb))
  const resetErrorRegister = () => dispatch(Actions.Users.resetErrorRegister())
  const setStatusPhoneCode = status => dispatch(Actions.Users.setStatusPhoneCode(status))

  const [formValues, setValues] = useState({
    first_name: userData ? userData.first_name : '',
    last_name: userData ? userData.last_name : '',
    phone: userData ? userData.phone : '',
    email: userData ? userData.email : '',
    pass: '',
    verifyCode: [0, 0, 0, 0]
  })
  const [showPhoneModal, setShowPhoneModal] = useState(false)
  const [step, setStep] = useState(1)

  const setFormValues = (key, value) => {
    const data = { ...formValues }
    data[key] = value
    setValues(data)
  }

  const submitHandle = values => {
    dispatch(Actions.Checkout.completeContactInfo())
    if (logged) {
      return fetchUpdateUser(values, () => history.push(`${isAccount ? '/account' : '/checkout/shipping'}`))
    }
    return fetchRegister({ ...values, verified_email: true })
  }

  useEffect(() => {
    setStep(1)
    setStatusPhoneCode(null)
    window.scroll(0, 0)
    resetErrorRegister()
    gtmCheckoutSteps(
      [...selectedDiapers, ...selectedWipes, ...upselling],
      userData,
      'initiateCheckout',
      { step: '2', action: 'checkout' },
      promoCode && promoCode.title
    )
  }, [])

  useEffect(() => {
    setValues({
      first_name: userData ? userData.first_name : '',
      last_name: userData ? userData.last_name : '',
      phone: userData ? userData.phone : '',
      email: userData ? userData.email : '',
      pass: '',
      verifyCode: []
    })
  }, [userData])

  const { first_name, last_name, phone, email, pass, verifyCode } = formValues

  return (
    <ValidatorForm onSubmit={() => submitHandle(formValues)}>
      <Grid container className={currentLanguage === 'AR' ? 'invertAr' : ''}>
        <Grid container item justify="center" alignItems="stretch" direction="row">
          <Grid item xs={12} className="information-steps__form__title">
            <span>{t('checkout.contactInfo')}</span>
          </Grid>
          <Grid item container xs={12} justify="center" className="information-steps__form__field">
            <TextValidator
              id="first-name"
              className={classes.root}
              variant="outlined"
              label={t('default.firstName')}
              value={first_name || ''}
              onChange={evt => setFormValues('first_name', evt.target.value)}
              name="first-name"
            />
          </Grid>
          <Grid item container xs={12} justify="center" className="information-steps__form__field">
            <TextValidator
              id="last-name"
              label={t('default.lastName')}
              value={last_name || ''}
              className={classes.root}
              variant="outlined"
              onChange={evt => setFormValues('last_name', evt.target.value)}
              name="last-name"
            />
          </Grid>

          <Grid
            onClick={() => setShowPhoneModal(true)}
            item
            container
            xs={12}
            justify="center"
            className="information-steps__form__field">
            <TextValidator
              id="phone"
              label={t('default.phone')}
              value={userData.phone || ''}
              className={`${classes.root} information-steps__form__field__phone-field`}
              variant="outlined"
              name="phone"
              disabled={logged}
              placeholder="+97143322290"
            />
          </Grid>
          <PhoneVerificationModal
            showPhoneModal={showPhoneModal}
            setShowPhoneModal={setShowPhoneModal}
            phoneRegex={phoneRegex}
            phone={phone}
            classes={classes}
            t={t}
            setFormValues={setFormValues}
            userData={userData}
            formValues={formValues}
            verifyCode={verifyCode}
            step={step}
            setStep={setStep}
            setValues={setValues}
          />

          <Grid item container xs={12} justify="center" className="information-steps__form__field">
            <TextValidator
              id="email"
              type="email"
              label={t('default.email')}
              value={email || ''}
              className={classes.root}
              variant="outlined"
              onChange={evt => setFormValues('email', evt.target.value)}
              name="email"
              disabled={logged}
              placeholder="example@mail.com"
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'email is not valid']}
            />
          </Grid>
          {!logged && (
            <Grid item container xs={12} justify="center" className="information-steps__form__field">
              <TextValidator
                id="password"
                type="password"
                label={t('default.password')}
                value={pass || ''}
                className={classes.root}
                variant="outlined"
                onChange={evt => setFormValues('pass', evt.target.value)}
                name="password"
                validators={['required']}
                errorMessages={['This field is required']}
              />
            </Grid>
          )}
          {errorRegister && (
            <Grid item container xs={12} justify="center">
              {Object.entries(errorRegister.description.errors).map(kv => (
                <Grid item container key={`${kv[0]}-${kv[1]}`}>
                  <Typography variant="inherit" className={'notify--danger notify--block'} key={kv[0]}>
                    {t(getErrorMessage(kv)[0])}
                    {getErrorMessage(kv) && getErrorMessage(kv).length > 1 && (
                      <>
                        <button type="button" onClick={() => handleOpenLoginSignin('login')}>
                          {t(getErrorMessage(kv)[1])}
                        </button>
                        {t(getErrorMessage(kv)[2])}
                      </>
                    )}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid className="price-detail__buttons" container justify="center">
            <Grid item xs={6} container justify="center">
              <FlatButton
                idButton="checkout_contactInfo_nextButton"
                classes={`information-steps__next-button${loading ? '__disabled' : ''}`}
                disabled={loading}
                type="submit"
                bgColor="green"
                title={t(`default.${isAccount ? 'save' : 'next'}`)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}

StepContactInfo.propTypes = propTypes
export default translate('common')(StepContactInfo)
