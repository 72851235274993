import { combineReducers, createStore, applyMiddleware } from 'redux';
import { save, load } from 'redux-localstorage-simple';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import sagas from './sagas';
var reducersCombined = combineReducers(reducers);
var sagaMiddleware = createSagaMiddleware();
var options = {
  namespace: 'yb-store',
  states: ['Checkout', 'Users', 'Language', 'Products', 'Version']
};
var skipLoggerActions = [];
var mylogger = createLogger({
  predicate: function predicate(getState, action) {
    return process.env.NODE_ENV !== 'production' && skipLoggerActions.indexOf(action.type) === -1;
  }
});
var store = createStore(reducersCombined, load(options), applyMiddleware(sagaMiddleware, mylogger, save(options)));
sagaMiddleware.run(sagas);
window.store = store;
export default store;