/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react'
import { translate } from 'react-i18next'
import { Grid, Modal, Typography, LinearProgress, Box } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { FIRST_COLLECTION } from '../../utils/urls'
import YallaBaby from '../../assets/images/logo.svg'

const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: 15,
      borderRadius: 10,
      marginTop: 20,
      marginBottom: 0
    },
    colorPrimary: {
      backgroundColor: 'white'
    },
    bar: {
      borderRadius: 10,
      backgroundColor: '#5DCB8E'
    }
  })
)(LinearProgress)

const UpdatingPage = () => {
  const history = useHistory()

  const redirect = () => {
    localStorage.clear()
    history.push(`/products/${FIRST_COLLECTION}`)
    window.location.reload()
  }

  setTimeout(redirect, 5000)

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        const diff = Math.random() * 80
        return Math.min(oldProgress + diff, 100)
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      <Modal open className="updating-page" disableBackdropClick disableEscapeKeyDown hideBackdrop>
        <Grid className="updating-page__container">
          <Grid container direction="column" justify="center" alignContent="center">
            <img className="updating-page__logo" src={YallaBaby} alt="logo" />
            <Typography align="center" className="updating-page__text1">
              Yalla baby has been updated
            </Typography>
            <Typography align="center" className="updating-page__text2">
              Please wait a few seconds, thank you.
            </Typography>
          </Grid>
          <Box sx={{ width: '100%' }}>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Box>
        </Grid>
      </Modal>
    </>
  )
}

export default translate('common')(UpdatingPage)
