/* eslint-disable max-len */
import React from 'react'

const EditJaka = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6408 1.88303L11.1148 0.357056C10.6388 -0.119019 9.86586 -0.119019 9.39004 0.357056L7.95471 1.7929L11.1819 5.0429L12.6179 3.60706C13.1181 3.13073 13.1181 2.35911 12.6408 1.88303ZM7.3824 2.36723L0.788458 8.96118C0.731735 9.0179 0.693116 9.09011 0.677348 9.16877L0.00855915 12.5127C-0.0499662 12.7969 0.200665 13.0483 0.48504 12.9924L3.82898 12.3237C3.90765 12.3079 3.97983 12.2693 4.03655 12.2126L10.6305 5.61868L7.3824 2.36723Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
)

export default EditJaka
