/* eslint-disable max-len */
import React from 'react'
// Tools
import propTypes from './props'

const SortBy = ({ fill = '#B5889B', height = '15px' }) => (
  <svg width="20" height={height} viewBox="0 0 20 19" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.29341 13.293L6.00041 15.5859V1.84619C6.00041 1.58098 5.89505 1.32662 5.70751 1.13908C5.51998 0.951548 5.26562 0.846191 5.00041 0.846191C4.73519 0.846191 4.48084 0.951548 4.2933 1.13908C4.10576 1.32662 4.00041 1.58098 4.00041 1.84619V15.5859L1.70741 13.293C1.51881 13.1109 1.2662 13.0101 1.00401 13.0124C0.741809 13.0146 0.490997 13.1198 0.305589 13.3052C0.120181 13.4906 0.0150115 13.7414 0.0127331 14.0036C0.0104547 14.2658 0.111249 14.5184 0.293407 14.707L4.29341 18.707C4.48111 18.8942 4.73537 18.9993 5.00046 18.9993C5.26554 18.9993 5.51981 18.8942 5.70751 18.707L9.70751 14.707C9.89033 14.5186 9.99168 14.2657 9.98968 14.0032C9.98767 13.7406 9.88247 13.4893 9.69679 13.3037C9.51111 13.118 9.25985 13.0128 8.99727 13.0108C8.7347 13.0088 8.48188 13.1102 8.29341 13.293Z"
      fill="#B5889B"
    />
    <path
      d="M9 3H19C19.2652 3 19.5196 2.89464 19.7071 2.70711C19.8946 2.51957 20 2.26522 20 2C20 1.73478 19.8946 1.48043 19.7071 1.29289C19.5196 1.10536 19.2652 1 19 1H9C8.73478 1 8.48043 1.10536 8.29289 1.29289C8.10536 1.48043 8 1.73478 8 2C8 2.26522 8.10536 2.51957 8.29289 2.70711C8.48043 2.89464 8.73478 3 9 3Z"
      fill="#B5889B"
    />
    <path
      d="M19 5H9C8.73478 5 8.48043 5.10536 8.29289 5.29289C8.10536 5.48043 8 5.73478 8 6C8 6.26522 8.10536 6.51957 8.29289 6.7071C8.48043 6.89464 8.73478 7 9 7H19C19.2652 7 19.5196 6.89464 19.7071 6.7071C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
      fill="#B5889B"
    />
    <path
      d="M16 9H9C8.73478 9 8.48043 9.10536 8.29289 9.29289C8.10536 9.48043 8 9.73478 8 10C8 10.2652 8.10536 10.5196 8.29289 10.7071C8.48043 10.8946 8.73478 11 9 11H16C16.2652 11 16.5196 10.8946 16.7071 10.7071C16.8946 10.5196 17 10.2652 17 10C17 9.73478 16.8946 9.48043 16.7071 9.29289C16.5196 9.10536 16.2652 9 16 9Z"
      fill="#B5889B"
    />
  </svg>
)

SortBy.propTypes = propTypes
export default SortBy
