/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
import React, { Fragment, useEffect } from 'react'
import { Grid, Typography, Link } from '@material-ui/core'
import { useLastLocation } from 'react-router-last-location'
import { makeStyles } from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import Actions from '../../actions'
import propTypes from './props'
import FlatButton from '../../components/molecules/FlatButton'
import phoneRegex from '../../utils/regexExpressions'
import { getErrorMessage } from '../../utils/helpers'

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#cf4a67'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#54CE8A'
      }
    }
  }
})

const SignIn = ({ t, onClose, setTypeLoginSignin, shouldRedirect, inCart, inStepPayment, signupFormRef }) => {
  const classes = useStyles()

  const lastLocation = useLastLocation()
  const dispatch = useDispatch()
  const errorRegister = useSelector(state => state.Users.errorRegister)
  const formValues = useSelector(state => state.Forms.userRegisterData || {})
  const loading = useSelector(state => state.Users.loading)
  const logged = useSelector(state => state.Users.logged)
  const setFormValues = (key, value) => dispatch(Actions.Forms.setFieldValue('userRegisterData', key, value))
  const fetchRegister = (data, cb) => dispatch(Actions.Users.fetchRegister(data, cb))
  const trimSpaces = form => {
    Object.keys(form).forEach(key => {
      form[key] = typeof form[key] === 'string' ? form[key].trim() : form[key]
    })
    return form
  }

  useEffect(() => {
    if (logged && !inCart && onClose) {
      onClose()
    }
  }, [logged])

  return (
    <Fragment>
      <Grid
        container
        className={`login ${inStepPayment ? 'in-checkout' : ''}`}
        direction="column"
        justify="center"
        alignItems="center">
        {!inCart && !inStepPayment && (
          <Typography variant="inherit" className="login__title">
            {t('default.pleaseSignUp')}
          </Typography>
        )}
        <ValidatorForm
          ref={signupFormRef}
          className={`signup__form ${inStepPayment ? 'in-checkout' : ''}`}
          onSubmit={() =>
            fetchRegister(
              trimSpaces({
                ...formValues,
                pass: formValues.pass || '',
                verified_email: true,
                shouldRedirect,
                lastLocation
              })
            )
          }>
          <Grid spacing={2} container className="signup__name-container">
            <Grid
              item
              container
              xs={12}
              md={inStepPayment ? 6 : 12}
              justify="center"
              className={`signup__field ${inStepPayment ? 'in-checkout' : ''}`}>
              <TextValidator
                id="first-name"
                className={classes.root}
                label={t('default.firstName')}
                value={formValues.first_name || ''}
                variant="outlined"
                onChange={evt => setFormValues('first_name', evt.target.value)}
                name="first-name"
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={inStepPayment ? 6 : 12}
              justify="center"
              className={`signup__field ${inStepPayment ? 'in-checkout' : ''}`}>
              <TextValidator
                id="last-name"
                className={classes.root}
                label={t('default.lastName')}
                value={formValues.last_name || ''}
                variant="outlined"
                onChange={evt => setFormValues('last_name', evt.target.value)}
                name="last-name"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="center"
            className={`signup__field ${inStepPayment ? 'in-checkout' : ''}`}>
            <TextValidator
              id="phone"
              className={classes.root}
              label={t('default.phone')}
              type="text"
              value={formValues.phone || ''}
              variant="outlined"
              placeholder="+97143322290"
              onChange={evt => {
                if (phoneRegex.test(evt.target.value)) {
                  setFormValues('phone', evt.target.value)
                }
                if (!evt.target.value.includes('+') && evt.target.value)
                  setFormValues('phone', '+'.concat(evt.target.value))
              }}
              name="phone"
              validators={['required']}
              errorMessages={['This field is required', 'Phone is not valid']}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="center"
            className={`signup__field ${inStepPayment ? 'in-checkout' : ''}`}>
            <TextValidator
              id="email"
              className={classes.root}
              label={t('default.email')}
              type="email"
              value={formValues.email || ''}
              variant="outlined"
              onChange={evt => setFormValues('email', evt.target.value)}
              name="email"
              placeholder="example@mail.com"
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'email is not valid']}
            />
          </Grid>
          {!inStepPayment && (
            <Grid
              item
              container
              xs={12}
              justify="center"
              className={`signup__field ${inStepPayment ? 'in-checkout' : ''}`}>
              <TextValidator
                id="password"
                className={classes.root}
                label={t('default.password')}
                type="password"
                autoComplete="current-password"
                value={formValues.pass || ''}
                variant="outlined"
                onChange={evt => setFormValues('pass', evt.target.value)}
                name="password"
                validators={inStepPayment ? '' : ['required']}
                errorMessages={['This field is required']}
              />
            </Grid>
          )}

          {!inStepPayment && (
            <Grid container justify="center" className="login__links">
              <Grid item xs={6} container justify="center">
                <FlatButton
                  classes={`login-signin-button margin ${loading ? 'disabled' : ''}`}
                  disabled={loading}
                  type="submit"
                  bgColor="green"
                  title={inCart ? t('loginSignUp.continue') : t('default.signup')}
                />
              </Grid>
            </Grid>
          )}
        </ValidatorForm>

        {!inCart && !inStepPayment && (
          <Grid container justify="center" className="login__terms">
            <Typography variant="inherit">
              {t('default.bySignAccept')}
              <Link target="_blank" href="https://yallababy.com/legal-yalla-baby-box.html" rel="noreferrer">
                {t('default.termsCondition')}
              </Link>
            </Typography>
          </Grid>
        )}
      </Grid>
      {errorRegister && (
        <Grid item container xs={12} justify="center">
          {Object.entries(errorRegister.description.errors).map(kv => {
            const formatedKey = `${kv[0]}-${kv[1][0].toLowerCase().replaceAll(' ', '-')}`
            return (
              <Grid xs={10} md={8} item container justify="center" key={formatedKey}>
                <Typography variant="inherit" className={'notify--danger notify--block'} key={kv[0]}>
                  {t(getErrorMessage(kv)[0])}
                  {getErrorMessage(kv) && getErrorMessage(kv).length > 1 && (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          if (!inCart) setTypeLoginSignin('login')
                          else setTypeLoginSignin(true)
                        }}>
                        {t(getErrorMessage(kv)[1])}
                      </button>
                      {t(getErrorMessage(kv)[2])}
                    </>
                  )}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Fragment>
  )
}

SignIn.propTypes = propTypes
export default translate('common')(SignIn)
