import React, { Fragment, useEffect } from 'react'

import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../actions'
import Header from '../../components/organisms/Header'
import Loader from '../../components/atoms/Loader'
import CheckoutDoneOk from '../../components/organisms/CheckoutDoneOk'
import CheckoutDoneError from '../../components/organisms/CheckoutDoneError'

import propTypes from './props'

const CheckDone = ({ t, history }) => {
  const loading = useSelector(state => state.Checkout.processing)
  const boxInfo = useSelector(state => state.Checkout.boxInfo && state.Checkout.boxInfo)

  const done = useSelector(state =>
    state.Checkout.status ? !state.Checkout.status.status || state.Checkout.status.status === 'ok' : false
  )
  const error = useSelector(state => (state.Checkout.status ? state.Checkout.status.status === 'error' : false))

  const paymentError = useSelector(state =>
    state.Checkout.statusPaymentError.status ? state.Checkout.statusPaymentError.status.status === 'error' : false
  )
  const currentLanguage = useSelector(state => state.Language.current)

  const dispatch = useDispatch()

  const flushCheckout = () => dispatch(Actions.Checkout.flush())

  useEffect(() => {
    if (done && !paymentError) {
      flushCheckout()
    }
  }, [done])

  return (
    <Fragment>
      <Header t={t} bg history={history} showHelpBtn />
      {loading && <Loader loading />}
      {done && !paymentError && (
        <CheckoutDoneOk
          t={t}
          history={history}
          currentLanguage={currentLanguage}
          incomingBoxDate={boxInfo.shippingDate}
          provinceName={boxInfo.provinceName}
          isSubscription={boxInfo.isSubscription}
        />
      )}
      {(error || paymentError) && <CheckoutDoneError t={t} history={history} currentLanguage={currentLanguage} />}
    </Fragment>
  )
}

CheckDone.propTypes = propTypes
export default translate('common')(CheckDone)
