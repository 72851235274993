/* eslint-disable max-len */
import React from 'react'

const BulletCheck = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7812 0C4.8269 0 0 4.8269 0 10.7812C0 16.7356 4.8269 21.5625 10.7812 21.5625C16.7356 21.5625 21.5625 16.7356 21.5625 10.7812C21.5625 4.8269 16.7356 0 10.7812 0ZM15.9837 8.79624L10.2337 14.5462C9.98811 14.7919 9.66521 14.9141 9.34375 14.9141C9.02229 14.9141 8.69939 14.7919 8.45376 14.5462L5.57876 11.6712C5.0888 11.1799 5.0888 10.3826 5.57876 9.89126C6.07007 9.3999 6.86743 9.3999 7.35874 9.89126L9.34375 11.8776L14.2038 7.01626C14.6951 6.5249 15.4924 6.5249 15.9837 7.01626C16.4737 7.50757 16.4737 8.30493 15.9837 8.79624Z"
      fill="#73BB90"
    />
  </svg>
)

export default BulletCheck
