/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const ContactUsIcon = ({ color }) => (
  <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M21.915001,21.9150263 C21.8681423,21.9618851 21.7678173,22.0329877 21.6216573,21.982825 L16.5777664,20.2496529 C16.5031741,20.2240597 16.4256037,20.2113096 16.348266,20.2113096 C16.2342603,20.2113096 16.1207664,20.2389038 16.0179752,20.293301 C14.5602379,21.0644941 12.912972,21.4735202 11.2542125,21.4760329 C5.69120011,21.4850138 1.0894651,16.9665096 1.00133173,11.4043608 C0.956985838,8.60590634 2.02147326,5.97580863 3.9987042,3.9985704 C5.97593514,2.0213787 8.6052321,0.95707349 11.4044673,1.00132647 C16.9665491,1.08950671 21.4846644,5.68897856 21.4760093,11.254245 C21.4734034,12.9130572 21.0644255,14.5603292 20.2932817,16.0179787 C20.201984,16.1905235 20.1862094,16.3931752 20.2496338,16.577772 L21.9827995,21.6216816 C22.0330085,21.767749 21.9619062,21.868214 21.915001,21.9150263 Z"
        id="Path"
        stroke={color || '#393939'}
        strokeWidth="1.5"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default ContactUsIcon
