import React from 'react'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/images/logo.svg'
import LogoAr from '../../../assets/images/logo-arabic.svg'
import propTypes from './props'

const LogoComponent = ({ language, xs, md, justify, className }) => (
  <Grid item xs={xs} md={md} container justify={justify} alignItems="center" className="header-toolbar__img">
    <Link to="/" className={className || ''}>
      <img src={language === 'AR' ? LogoAr : Logo} alt="" />
    </Link>
  </Grid>
)

LogoComponent.propTypes = propTypes
export default LogoComponent
