import React from 'react'
import { translate } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Hidden, Grid } from '@material-ui/core'
import ChangePassword from '../../components/molecules/ChangePassword'
import propTypes from './props'
import GenericModal from '../../components/templates/GenericModal'
import Header from '../../components/organisms/Header'
import Footer from '../../components/molecules/Footer'
import { FIRST_COLLECTION } from '../../utils/urls'

const ChangePasswordModal = ({ t, history }) => {
  const [openModal, setOpenModal] = React.useState(true)

  const currentLanguage = useSelector(state => state.Language.current)

  const handleCloseModal = () => {
    setOpenModal(false)
    history.push(`/products/${FIRST_COLLECTION}`)
  }

  return (
    <>
      <Header t={t} bg history={history} />
      <Grid container className="content-height" />
      <GenericModal openModal={openModal} handleClose={handleCloseModal} currentLanguage={currentLanguage}>
        <ChangePassword t={t} history={history} handleClose={handleCloseModal} />
      </GenericModal>
      <Hidden smDown>
        <Footer t={t} currentLanguage={currentLanguage} history={history} />
      </Hidden>
    </>
  )
}

ChangePasswordModal.propTypes = propTypes
export default translate('common')(ChangePasswordModal)
