/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react'
import { Grid, Hidden, Typography } from '@material-ui/core'
import { translate } from 'react-i18next'
import checkoutLogo from '../../../assets/images/checkout-logo.svg'
import bitEncryption from '../../../assets/images/256bit.svg'
import mastercard from '../../../assets/images/mastercard.svg'
import visa from '../../../assets/images/visa.svg'
import propTypes from './props'

const SecurePay = ({ t, inFooter = false }) => {
  const isCheckout = window.location.pathname === '/checkout'
  const weAcceptText = (
    <Typography
      display="inline"
      className={`secure-pay-text secure-pay-text--bold ${inFooter ? 'secure-pay-text--in-footer' : ''}`}>
      {t('securePay.weAccept')}
    </Typography>
  )

  return (
    <Grid container className={`secure-pay-container ${inFooter ? 'secure-pay-container--in-footer' : ''}`}>
      {!isCheckout && (
        <Grid className="secure-pay-container__module">
          <Grid container spacing={2} className="secure-pay-container__visa-master">
            {inFooter && <Hidden smDown>{weAcceptText}</Hidden>}
            <Grid item>
              <img className="secure-pay-container__module__image" src={visa} alt="Pay with visa" />
            </Grid>
            <Grid item>
              <img className="secure-pay-container__module__image" src={mastercard} alt="Pay with mastercard" />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid className="secure-pay-container__module">
        <Grid container spacing={1} className="confidence">
          <Grid item>
            <img className="secure-pay-container__module__lock" src={bitEncryption} alt="256 bit encryption" />
          </Grid>
          <Grid item>
            <Typography display="inline" className="secure-pay-text secure-pay-text--bold">
              {t('securePay.confidence')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} className="checkout">
          <Grid item>
            <Typography className="secure-pay-text">{t('securePay.encrypted')}</Typography>
          </Grid>
          <Grid item>
            <img className="secure-pay-container__module__checkout" src={checkoutLogo} alt="Pay with checkout" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

SecurePay.propTypes = propTypes
export default translate('common')(SecurePay)
