import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
var initialState = {
  current: 'EN'
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'LANGUAGE_CHANGE':
      return _objectSpread({}, state, {
        current: action.payload
      });
    default:
      return state;
  }
};
export default reducer;