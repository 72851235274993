import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import TagManager from 'react-gtm-module'
import { Widget } from '@typeform/embed-react'
import propTypes from './props'
import Actions from '../../../actions'
import FirstModalCancel from '../FirstModalCancel'
import SecondModalCancel from '../SecondModalCancel'
import FlatButton from '../FlatButton'

const CancelSubscription = ({
  t,
  id,
  handleClose,
  showTypeForm,
  setShowTypeForm,
  boxDiscount,
  status,
  setOpenDatePicker,
  setOpenModal,
  isOneOffBox,
  isSubscribableBox,
  subscription,
  handleOptionSelected
}) => {
  const dispatch = useDispatch()
  const cancelSubscriptionDispatch = subscriptionId => dispatch(Actions.Checkout.cancelSubscription(subscriptionId))

  const savePromoCode = (idSubs, promoCodeToSave) => dispatch(Actions.Checkout.savePromoCode(idSubs, promoCodeToSave))
  const removePromoCode = () => dispatch(Actions.Checkout.removePromoCode())

  const { userData, subscriptions } = useSelector(state => state.Users)
  const [contentModal, setContentModal] = useState(1)
  const [loading, setLoading] = useState(false)

  const promoCodeSubscription = boxDiscount && {
    value: boxDiscount.value,
    value_type: boxDiscount.type,
    title: boxDiscount.code,
    id: boxDiscount.id,
    productsId: boxDiscount.entitledProductIds,
    variantsId: boxDiscount.entitledVariantIds
  }

  const promoCode = useSelector(state => (status === 'PENDING' ? state.Checkout.promoCode : promoCodeSubscription))

  const activeSubscriptions = subscriptions.filter(
    sub => sub.purchaseType === 'SUBSCRIPTION' && sub.status === 'PENDING'
  )

  const cancelSubscription = subId => {
    const subPrice = subscriptions.filter(sub => sub.id === subId)[0].babyBoxPrice
    cancelSubscriptionDispatch(subId)
    TagManager.dataLayer({
      dataLayer: {
        event: 'boxes',
        actionType: 'CancelSubscription',
        subscriptionPrice: subPrice,
        userId: userData.id
      },
      dataLayerName: 'YallaBaby'
    })
  }

  useEffect(() => {
    return () => {
      setShowTypeForm(false)
      setContentModal(1)
      removePromoCode()
    }
  }, [])

  useEffect(() => {
    if (promoCode) {
      savePromoCode(id, promoCode)
      setLoading(false)
    }
  }, [promoCode])

  const getTitleSecondaryBtn = () => {
    if (isOneOffBox && !!activeSubscriptions.length) {
      return t('boxes.cancelBox1')
    }
    return t('boxes.yesUnsubscribe')
  }

  const handleOnClickSecondaryBtn = () => {
    if (contentModal === 1 && (!isOneOffBox || (isOneOffBox && !activeSubscriptions.length))) setContentModal(2)
    else setShowTypeForm(true)
  }

  return (
    <Fragment>
      {showTypeForm ? (
        <Grid container className="modal-cancel type-form">
          <Widget
            style={{ height: 'inherit', margin: '0', width: 'inherit', padding: '0' }}
            onSubmit={() => {
              cancelSubscription(id)
              savePromoCode(id)
              handleClose()
              setShowTypeForm(false)
              setContentModal(1)
            }}
            id="ORKzCmPW"
          />
        </Grid>
      ) : (
        <Grid container justify="center" alignContent="center">
          {contentModal === 1 && (
            <FirstModalCancel
              t={t}
              handleClose={handleClose}
              isOneOffBox={isOneOffBox}
              activeSubscriptions={activeSubscriptions}
              isSubscribableBox={isSubscribableBox}
              subscription={subscription}
              handleOptionSelected={handleOptionSelected}
            />
          )}

          {contentModal === 2 && !showTypeForm && (
            <>
              <SecondModalCancel
                t={t}
                handleClose={handleClose}
                boxDiscount={boxDiscount}
                status={status}
                setOpenDatePicker={setOpenDatePicker}
                setOpenModal={setOpenModal}
                loading={loading}
                setLoading={setLoading}
                setShowTypeForm={setShowTypeForm}
              />
            </>
          )}

          {contentModal === 1 && (
            <Grid item xs={12} md={6}>
              <Box mt={2} width={1}>
                <FlatButton
                  title={getTitleSecondaryBtn()}
                  classes={isOneOffBox && !!activeSubscriptions.length ? 'flat-button--gray' : 'flat-button--link'}
                  onClick={() => handleOnClickSecondaryBtn()}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  )
}

CancelSubscription.propTypes = propTypes
export default CancelSubscription
