import React from 'react'

const Trash = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="100.000000pt"
    height="100.000000pt"
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,100.000000) scale(0.039063,-0.039063)" fill="#c38a9f" stroke="none">
      <path
        d="M712 2140 c-39 -24 -72 -75 -72 -112 l0 -28 -180 0 c-167 0 -181 -1
-200 -20 -27 -27 -27 -93 0 -120 19 -19 33 -20 200 -20 l180 0 0 -27 c0 -38
33 -89 72 -113 29 -17 51 -20 168 -20 117 0 139 3 168 20 39 24 72 75 72 113
l0 27 580 0 c567 0 580 0 600 20 13 13 20 33 20 60 0 27 -7 47 -20 60 -20 20
-33 20 -600 20 l-580 0 0 28 c0 37 -33 88 -72 112 -29 17 -51 20 -168 20 -117
0 -139 -3 -168 -20z"
      />
      <path
        d="M1352 1500 c-39 -24 -72 -75 -72 -112 l0 -28 -500 0 c-487 0 -501 -1
-520 -20 -13 -13 -20 -33 -20 -60 0 -27 7 -47 20 -60 19 -19 33 -20 520 -20
l500 0 0 -27 c0 -38 33 -89 72 -113 29 -17 51 -20 168 -20 117 0 139 3 168 20
39 24 72 75 72 113 l0 27 260 0 c247 0 261 1 280 20 13 13 20 33 20 60 0 27
-7 47 -20 60 -19 19 -33 20 -280 20 l-260 0 0 28 c0 37 -33 88 -72 112 -29 17
-51 20 -168 20 -117 0 -139 -3 -168 -20z"
      />
      <path
        d="M712 860 c-39 -24 -72 -75 -72 -112 l0 -28 -180 0 c-167 0 -181 -1
-200 -20 -27 -27 -27 -93 0 -120 19 -19 33 -20 200 -20 l180 0 0 -27 c0 -38
33 -89 72 -113 29 -17 51 -20 168 -20 117 0 139 3 168 20 39 24 72 75 72 113
l0 27 580 0 c567 0 580 0 600 20 13 13 20 33 20 60 0 27 -7 47 -20 60 -20 20
-33 20 -600 20 l-580 0 0 28 c0 37 -33 88 -72 112 -29 17 -51 20 -168 20 -117
0 -139 -3 -168 -20z"
      />
    </g>
  </svg>
)

export default Trash
