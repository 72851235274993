/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react'
import { Grid, Container, Typography, Collapse } from '@material-ui/core'
import moment from 'moment'
import IconComponent from '../../atoms/IconComponent'
import propTypes from './props'
import { hasDeliveryToday } from '../../../utils/helpers'

const ArrivesTodayNotification = ({ t, columnSizesIcon = {}, columnSizesText = {} }) => {
  const PURCHASE_TIME_LIMIT = 12
  const DAY = 'day'
  const HOUR = 'hour'
  const HOURS = 'hours'

  const minutesLeft = 60 - moment().minutes()
  const hour = moment().hours()

  let hoursLeft

  if (hour < PURCHASE_TIME_LIMIT) {
    const midday = moment().startOf(DAY).add(12, HOUR)
    hoursLeft = midday.diff(moment(), HOURS)
  } else {
    const midnight = moment().endOf(DAY)
    hoursLeft = midnight.diff(moment(), HOURS)
  }

  const [minutes, setMinutes] = useState(minutesLeft)
  const [hours, setHours] = useState(hoursLeft)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (minutes > -1) {
      setTimeout(() => {
        setMinutes(minutes - 1)
      }, 60000)
    }

    if (minutes === -1) {
      setHours(hours - 1)
      setMinutes(59)
    }
  }, [minutes])

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 2000)
  }, [])

  const getHours = () =>
    `${hours > 0 ? `${hours}:` : ''}${minutes < 10 ? `0${minutes}` : `${minutes}`} ${hours > 0 ? 'hs' : 'min'} `

  return (
    <Collapse className="arrives-notification" in={show}>
      <Container>
        <Grid container spacing={2} className="arrives-notification__wrapper">
          <Grid item className="icon" {...columnSizesIcon}>
            <IconComponent icon="truckJaka" fill="#ffffff" />
          </Grid>
          <Grid item {...columnSizesText} className="column-text">
            <Grid className="title">
              <Typography>
                <span>{hasDeliveryToday() ? t('cart.arrivesToday1') : t('cart.arrivesTomorrow')}</span>{' '}
                {`${t('cart.arrivesToday2')} ${getHours()} ${t('cart.arrivesToday3')}`}
              </Typography>
            </Grid>
            <Grid className="subtitle">
              <Typography>{t('cart.nextDayDelivery')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Collapse>
  )
}
ArrivesTodayNotification.propTypes = propTypes
export default ArrivesTodayNotification
