import React, { useEffect } from 'react'
import moment from 'moment'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import propTypes from './props'
import { anySubscribableProduct } from '../../../utils/helpers'

const SubscriptionMessage = ({ t, shippingDate, isAnySubscribed, productsToSubscribe, setSubscribeAll }) => {
  const { isEdition, selectedDiapers, selectedWipes, boxTypePurchase, upselling } = useSelector(state => state.Checkout)
  const { subscriptions } = useSelector(state => state.Users)
  const activeSubscriptions = subscriptions.filter(
    sub => sub.purchaseType === 'SUBSCRIPTION' && sub.status === 'PENDING'
  )

  const showOneTimeMessage = () => anySubscribableProduct(selectedDiapers, selectedWipes, upselling)

  useEffect(() => {
    if (productsToSubscribe === 0) setSubscribeAll(false)
  }, [productsToSubscribe])

  return (
    <Grid container className="subscription-message">
      <Grid item className="subscription-message__text">
        {!(boxTypePurchase !== 'oneOffs' && !!activeSubscriptions.length) &&
          (isAnySubscribed ? (
            <Typography>{t('subscriptionMessage.subscriptionDescription')}</Typography>
          ) : (
            showOneTimeMessage() && <Typography>{t('subscriptionMessage.oneTimeDescription')}</Typography>
          ))}
        {isEdition && (
          <Grid className="subscription-message__comming-box">
            {t('subscriptionMessage.isCommingOn')}
            <span>{moment(shippingDate).format('Do MMMM')}</span>
          </Grid>
        )}
      </Grid>
      {productsToSubscribe > 0 && !isAnySubscribed && boxTypePurchase !== 'oneOffs' && (
        <Grid className="subscription-message__subscribe-all">
          <Grid
            className="subscription-message__subscribe-all__button"
            onClick={() => {
              setSubscribeAll(true)
              window.scrollTo(0, 0)
            }}>
            {t('subscriptionMessage.subscribeMyBox')}
          </Grid>
          <Grid>
            <Typography className="subscription-message__subscribe-all__text">
              {`${productsToSubscribe} ${t('subscriptionMessage.productsEligible')}`}
            </Typography>
            <Typography className="subscription-message__subscribe-all__text">
              {t('subscriptionMessage.subscribeAndSave')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

SubscriptionMessage.propTypes = propTypes
export default SubscriptionMessage
