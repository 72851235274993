import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { translate } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Modal, Grid, Typography } from '@material-ui/core'
import FlatButton from '../../molecules/FlatButton'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'

const RetryPaymentModal = ({ t, openModal, handleClose }) => {
  const { retryPaymentStatus } = useSelector(state => state.Users)
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={handleClose}
      className="retry-payment-modal">
      <div tabIndex={-1}>
        <Fragment>
          <Grid container className="modal-retry-payment">
            <Grid
              item
              xs={2}
              container
              justify="center"
              alignItems="center"
              direction="column"
              className="modal-retry-payment__close">
              <IconComponent
                width="15px"
                height="15px"
                fill="black"
                icon="close"
                onClick={() => handleClose(retryPaymentStatus)}
              />
            </Grid>
            <Grid container direction="column" item xs={12} justify="center" alignItems="center">
              <Grid container direction="column" item xs={10} justify="space-evenly" alignItems="center">
                {retryPaymentStatus === 'ok' ? (
                  <>
                    <Typography variant="inherit" className="retry-payment-text retry-ok">
                      {t('boxes.retryPaymentOkTitle')}
                    </Typography>
                    <Typography variant="inherit" className="retry-payment-text retry-ok">
                      {t('boxes.retryPaymentOkText')}
                    </Typography>
                    <FlatButton
                      classes="retry-payment-modal-button"
                      title={t('boxes.retryPaymentOkButton')}
                      onClick={() => handleClose(retryPaymentStatus)}
                    />
                  </>
                ) : (
                  retryPaymentStatus === 'error' && (
                    <>
                      <Typography variant="inherit" className="retry-payment-text">
                        {t('boxes.retryPaymentFailTitle')}
                      </Typography>
                      <Typography variant="inherit" className="retry-payment-text">
                        {t('boxes.retryPaymentFailText')}
                        <Link to="/account/editpayment">{t('account.title')}</Link>
                      </Typography>
                      <button type="button" className="try-again-link" onClick={() => handleClose(retryPaymentStatus)}>
                        {t('boxes.retryPaymentFailButton')}
                      </button>
                    </>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    </Modal>
  )
}

RetryPaymentModal.propTypes = propTypes
export default translate('common')(RetryPaymentModal)
