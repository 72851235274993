/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Modal } from '@material-ui/core'
import { translate } from 'react-i18next'
import { TextValidator } from 'react-material-ui-form-validator'
import CloseIcon from '@material-ui/icons/Close'
import propTypes from './props'
import phoneImage from '../../assets/images/phone.svg'
import check from '../../assets/images/check.svg'
import send from '../../assets/images/send.svg'
import warning from '../../assets/images/warning.svg'
import greenLoader from '../../assets/images/loader4.gif'
import Actions from '../../actions'
import { getNextId } from '../../utils/random'

const PhoneVerificationModal = ({
  showPhoneModal,
  setShowPhoneModal,
  phoneRegex,
  phone,
  classes,
  t,
  setFormValues,
  userData,
  formValues,
  step,
  setStep
}) => {
  const [disabled, setDisabled] = useState(true)
  const [loader, setLoader] = useState(false)
  const [code, setCode] = useState({})

  const statusPhoneCode = useSelector(state => state.Users.statusPhoneCode && state.Users.statusPhoneCode)

  const dispatch = useDispatch()
  const setPhoneVerificationCode = number => dispatch(Actions.Users.setPhoneVerificationCode(number))
  const verifyPhoneNumberCode = (phoneNumber, codeToVerify) =>
    dispatch(Actions.Users.verifyPhoneNumberCode(phoneNumber, codeToVerify))
  const setStatusPhoneCode = status => dispatch(Actions.Users.setStatusPhoneCode(status))
  const fetchUpdateUser = (data, cb) => dispatch(Actions.Users.fetchUpdateUser(data, cb))

  useEffect(() => {
    setDisabled(userData.phone === phone)
  }, [phone])

  useEffect(() => {
    if (code[0] && code[1] && code[2] && code[3]) {
      verifyPhoneNumberCode(formValues.phone, Object.values(code).join(''))
      setLoader(true)
      setCode({})
    }
  }, [code])

  const onClose = () => {
    setFormValues('phone', userData.phone)
    setCode({})
    setStatusPhoneCode(null)
    setLoader(false)
    setStep(1)
    setShowPhoneModal(false)
  }

  useEffect(() => {
    if (statusPhoneCode && statusPhoneCode.status) {
      setStep(3)
      setFormValues('phone', userData.phone)
      if (statusPhoneCode.status === 204)
        setTimeout(() => {
          fetchUpdateUser({ ...userData, phone: formValues.phone }, null)
          onClose()
        }, 4500)
    }
  }, [statusPhoneCode])

  const codeChange = (e, i) => {
    setCode({ ...code, [i]: e.target.value })
  }
  const fieldRef = useRef([])

  useEffect(() => {
    if (step === 2) {
      fieldRef.current[0].focus()
    }
  }, [step])

  return (
    <>
      <Modal open={showPhoneModal} onClose={() => onClose()}>
        <Grid container className="phone-verification-modal" spacing={2}>
          <CloseIcon onClick={() => onClose()} className="phone-verification-modal__close" />

          {step === 1 && (
            <>
              <Grid item className="text-wrapper">
                <Typography className="phone-verification-modal__title" variant="inherit">
                  {t('phone.newPhone')}
                </Typography>
              </Grid>
              <Grid item>
                <img className="phone-verification-modal__img" src={phoneImage} alt="New cell phone number" />
              </Grid>
              <Grid item container justifyContent="center">
                <Grid className="information-steps__form__field in-phone-verification">
                  <TextValidator
                    id="phone-validate"
                    autoFocus
                    value={phone || ''}
                    className={classes.root}
                    variant="outlined"
                    onChange={evt => {
                      if (evt.target.value === '' || phoneRegex.test(evt.target.value)) {
                        setFormValues('phone', evt.target.value)
                      }
                      if (!evt.target.value.includes('+') && evt.target.value)
                        setFormValues('phone', '+'.concat(evt.target.value))
                    }}
                    name="phone"
                    placeholder="+97143322290"
                    validators={['required']}
                    errorMessages={['This field is required', 'Phone is not valid']}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <button
                  type="button"
                  disabled={disabled}
                  onClick={() => {
                    setStep(2)
                    setPhoneVerificationCode({ newPhoneNumber: formValues.phone })
                  }}
                  className={`phone-verification-modal__button ${disabled && 'disabled'}`}>
                  Accept
                </button>
              </Grid>
            </>
          )}

          {step === 2 && (
            <>
              <Grid item>
                <img className="phone-verification-modal__img" src={send} alt="validate code" />
              </Grid>
              <Grid item>
                <Grid className="text-wrapper">
                  <Typography className="text-wrapper__title">{t('phone.sendCode')}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid className="text-wrapper">
                  <Typography className="text-wrapper__title">{t('phone.enterIt')}</Typography>
                </Grid>
              </Grid>

              <Grid item container className="information-steps__form__field in-phone-verification">
                {loader ? (
                  <img height="60" src={greenLoader} alt="green loader" />
                ) : (
                  <Grid className="phone-verification-modal__fields-container">
                    {Array(4)
                      .fill('')
                      .map((e, i) => (
                        <input
                          key={getNextId()}
                          ref={el => {
                            fieldRef.current[i] = el
                          }}
                          id={`validate-code${i}`}
                          value={code[i]}
                          className="phone-verification-modal__code-field"
                          variant="outlined"
                          onChange={evt => {
                            codeChange(evt, i)
                            if (i < 3 && evt.target.value.length === 1) {
                              fieldRef.current[i + 1].focus()
                            }
                          }}
                          name={`verify-phone-code${i}`}
                          placeholder=""
                          maxLength={1}
                          type="number"
                        />
                      ))}
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {step === 3 && (
            <>
              {statusPhoneCode && statusPhoneCode.status === 204 && (
                <>
                  <Grid item>
                    <img className="phone-verification-modal__img" src={check} alt="confirm validation" />
                  </Grid>
                  <Grid item>
                    <Typography className="phone-verification-modal__ok-text">{t('phone.updated')}</Typography>
                  </Grid>
                </>
              )}
              {statusPhoneCode && (statusPhoneCode.status === 404 || statusPhoneCode.status === 500) && (
                <>
                  <Grid item>
                    <img className="phone-verification-modal__img" src={warning} alt="error warning" />
                  </Grid>
                  <Grid item>
                    <Typography className="phone-verification-modal__status-text">
                      {statusPhoneCode.data.appCode === 5006 && t('phone.invalidCode')}
                      {statusPhoneCode.data.appCode === 5005 && t('phone.expiredCode')}
                      {statusPhoneCode.data.appCode === 6000 && t('phone.phoneInvalid')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <button
                      type="button"
                      onClick={() => {
                        onClose()
                        setShowPhoneModal(true)
                      }}
                      className="phone-verification-modal__button try-again">
                      {t('phone.tryAgain')}
                    </button>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Modal>
    </>
  )
}

PhoneVerificationModal.propTypes = propTypes
export default translate('common')(PhoneVerificationModal)
