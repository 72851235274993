/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import { Grid, Container, withStyles, makeStyles, List, ListItem, Link, Hidden } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import Box from '../../components/organisms/Box'
import Loader from '../../components/atoms/Loader'
import propTypes from './props'
import BoxesEmpty from '../../components/molecules/BoxesEmpty'
import Actions from '../../actions'
import Header from '../../components/organisms/Header'
import ShippingUpdatedSnackbar from '../../components/atoms/ShippingUpdatedSnackbar'
import Milestones from '../../components/organisms/Milestones'
import BenefitsBanner from '../../components/organisms/BenefitsBanner'
import Footer from '../../components/molecules/Footer'

const Accordion = withStyles({
  root: {
    backgroundColor: '#E6E3E0',
    border: 'none',
    boxShadow: 'none',
    '&:last-child': {
      borderRadius: '6px'
    },
    '&:first-child': {
      borderRadius: '6px'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    color: '#483153',
    '&$expanded': {
      minHeight: 'unset'
    }
  },
  content: {
    margin: '1px 0',
    '&$expanded': {
      margin: '1px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
  root: {
    backgroundColor: '#fff'
  },
  content: {
    margin: '1px 0',
    '&$expanded': {
      margin: '1px 0'
    }
  },
  expanded: {}
})(MuiAccordionDetails)

const useStylesAccordion = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

const useStylesList = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '15px',
    boxSizing: 'border-box'
  }
}))

const useStylesListItem = makeStyles(() => ({
  root: {
    paddingTop: 13,
    paddingBottom: 13,
    '&.active': {
      backgroundColor: 'rgba(225, 222, 219, 0.5);',
      borderRadius: '6px'
    }
  }
}))

const MyAccountMenu = ({ t, history, boxType, setBoxType }) => {
  const [menuTitle, setMenuTitle] = useState('My subscription box')

  const menuMyAccount = [
    { name: t('account.mySubscriptionBox'), path: null, key: 'subscriptions' },
    { name: t('loginMenu.oneTimeOrders'), path: null, key: 'oneOffs' },
    { name: t('menu.pastOrders'), path: '/past-orders', key: null },
    { name: t('loginMenu.paymentDetails'), path: '/account/editpayment', key: null },
    { name: t('menu.profile'), path: '/account', key: null }
  ]

  const classesAccordion = useStylesAccordion()
  const classesList = useStylesList()
  const classesListItem = useStylesListItem()

  const handleOnClick = item => {
    if (item.path) {
      history.push(item.path)
    } else {
      setBoxType(item.key)
      setMenuTitle(item.name)
    }
  }

  const isItemActive = item => boxType === item.key

  return (
    <>
      <Hidden mdUp>
        <div className={`${classesAccordion.root} boxes__accordion`}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="boxes__accordion__summary">
              <p>{menuTitle}</p>
            </AccordionSummary>
            <AccordionDetails className="boxes__accordion__detail">
              <List>
                {menuMyAccount.map(item => (
                  <ListItem key={item.name.replace(' ', '_').toLowerCase()}>
                    <Link onClick={() => handleOnClick(item)}>{item.name}</Link>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
      </Hidden>
      <Hidden smDown>
        <List className={`${classesList.root} boxes__list`}>
          {menuMyAccount.map(item => (
            <ListItem
              key={item.name.replace(' ', '_').toLowerCase()}
              className={`${classesListItem.root} ${isItemActive(item) ? 'active' : ''}`}>
              <Link onClick={() => handleOnClick(item)}>{item.name}</Link>
            </ListItem>
          ))}
        </List>
      </Hidden>
    </>
  )
}

const Boxes = ({ history, t }) => {
  const ONE_OFFS = 'oneOffs'
  const SUBSCRIPTIONS = 'subscriptions'
  const PURCHASE_TYPE = {
    SUBSCRIPTION: 'SUBSCRIPTION'
  }
  const BOX_STATUS = {
    PENDING: 'PENDING',
    PAUSED: 'PAUSED'
  }

  const subscriptions = useSelector(
    state => state.Users.subscriptions.filter(sub => sub.purchaseType === 'SUBSCRIPTION') || []
  )
  const oneOffs = useSelector(state => state.Users.subscriptions.filter(sub => sub.purchaseType === 'ONE_TIME') || [])
  const editedSubscriptionDate = useSelector(state => state.Users.editedSubscriptionDate)

  const { shippingDateUpdated, loading } = useSelector(state => state.Users)
  const currentLanguage = useSelector(state => state.Language.current)

  const showOneOffs = useSelector(state => state.Checkout.showOneOffs && state.Checkout.showOneOffs)

  const { errorStockShippingDate } = useSelector(state => state.Checkout)

  const [showMessage, setShowMessage] = useState(false)
  const [boxType, setBoxType] = useState(() => {
    return (history.location.state && history.location.state.showOneOffs) || showOneOffs ? ONE_OFFS : SUBSCRIPTIONS
  })

  const dispatch = useDispatch()

  const setStatus = status => dispatch(Actions.Checkout.setStatus(status))
  const setShowOneOffs = value => dispatch(Actions.Checkout.setShowOneOffs(value))

  const flushCheckout = () => dispatch(Actions.Checkout.flush())
  const setShippingUpdate = isShippingUpdated => dispatch(Actions.Users.setShippingUpdate(isShippingUpdated))
  const getSubscriptions = type => dispatch(Actions.Users.setFetchSubscriptions(type))
  const setBoxInEdition = box => {
    dispatch(Actions.Checkout.setBoxInEdition(box))
  }
  const updateSubscriptionShipping = subscriptionData => dispatch(Actions.Checkout.updateSubscription(subscriptionData))

  const setErrorStockShippingDate = value => dispatch(Actions.Checkout.setErrorStockShippingDate(value))

  const localShippingDateUpdate = (newDate, subId) =>
    dispatch(Actions.Users.updateSubscriptionsShippingDate(newDate, subId))
  const setNullEditedSubscriptionDate = value => dispatch(Actions.Users.setNullEditedSubscriptionDate(value))
  const setBoxTypePurchase = type => dispatch(Actions.Checkout.setBoxTypePurchase(type))

  useEffect(() => {
    if (errorStockShippingDate) setErrorStockShippingDate(null)
    setBoxInEdition(null)
    getSubscriptions('CURRENT')
    if (shippingDateUpdated) {
      setShowMessage(true)
    }
    flushCheckout()
    setStatus()
    window.scrollTo(0, 0)

    if (editedSubscriptionDate) {
      const data = {
        id: editedSubscriptionDate.subId,
        subscriptionShippingDate: editedSubscriptionDate.newDate
      }
      updateSubscriptionShipping(data)
      localShippingDateUpdate(data.subscriptionShippingDate, data.id)

      setNullEditedSubscriptionDate(null)
    }

    return () => {
      if (shippingDateUpdated) {
        setShippingUpdate(false)
      }
      setStatus()
      setBoxTypePurchase(null)
      if (showOneOffs) setShowOneOffs(false)
    }
  }, [])

  useEffect(() => {
    if (!shippingDateUpdated) {
      setShowMessage(false)
    }
  }, [shippingDateUpdated])

  const hasSOR = () => {
    const boxesSubs = subscriptions.filter(m => m.purchaseType === PURCHASE_TYPE.SUBSCRIPTION)

    return boxesSubs.every(box => box.subscriptionOrderForReward > 0 && box.subscriptionOrderForReward < 25)
  }

  const haveBoxesPendingPaused = () =>
    subscriptions.some(box => box.status === BOX_STATUS.PENDING || box.status === BOX_STATUS.PAUSED)

  const haveBoxes = () => !!(boxType === SUBSCRIPTIONS ? subscriptions : oneOffs).length

  const propsMyAccountMenu = { t, history, boxType, setBoxType }

  return (
    <>
      <Header t={t} bg history={history} showSideCartIcon showSearch showLogin />
      <Grid container className="boxes">
        <Container>
          {haveBoxes() && boxType === SUBSCRIPTIONS && haveBoxesPendingPaused() && hasSOR() && <Milestones t={t} />}
          <Grid container spacing={2}>
            <Grid container md={3} className="boxes__first-column">
              <h1>My account</h1>
              <MyAccountMenu {...propsMyAccountMenu} />
            </Grid>
            <Grid container item md={9} className="boxes__second-column">
              {loading ? (
                <Loader loading />
              ) : (
                <>
                  {!haveBoxes() && (
                    <>
                      <BenefitsBanner t={t} inBoxes />
                      <BoxesEmpty type={boxType} t={t} history={history} />
                    </>
                  )}
                  {haveBoxes() && boxType === SUBSCRIPTIONS && !haveBoxesPendingPaused() && <BenefitsBanner t={t} />}
                </>
              )}
              <Grid item container xs={12}>
                {!loading &&
                  subscriptions &&
                  (boxType === 'subscriptions' ? subscriptions : oneOffs)
                    .sort((a, b) => new Date(a.shippingDate) - new Date(b.shippingDate))
                    .map(sub => (
                      <Fragment key={sub.id}>
                        <Box
                          t={t}
                          history={history}
                          subscription={sub}
                          currentLanguage={currentLanguage}
                          boxType={boxType}
                        />
                      </Fragment>
                    ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Footer t={t} history={history} />
      {showMessage && (
        <ShippingUpdatedSnackbar
          t={t}
          setShippingUpdate={setShippingUpdate}
          shippingDateUpdated={shippingDateUpdated}
        />
      )}
    </>
  )
}

Boxes.propTypes = propTypes
export default translate('common')(Boxes)
