/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Grid, Hidden } from '@material-ui/core'
import propTypes from './props'
import LoginButton from '../../atoms/LoginButton'
import ShoppingCartWrapper from '../../atoms/ShoppingCartWrapper'
import HelpButton from '../../atoms/HelpButton'
import SearchButton from '../../atoms/SearchButton'

const HeaderLeftItems = ({
  t,
  showLogin,
  showSideCartIcon,
  toggleDrawer,
  cart,
  history,
  handleOpenLoginSignin,
  setOpenAlertModal,
  setPathToPush,
  showHelpBtn,
  showSearch,
  setInputSearchVisible
}) => {
  return (
    <Grid item className="search-and-cart">
      {showSearch && (
        <Hidden lgUp>
          <SearchButton setInputSearchVisible={setInputSearchVisible} />
        </Hidden>
      )}
      {showLogin && (
        <Hidden mdDown>
          <LoginButton
            t={t}
            handleOpenLoginSignin={handleOpenLoginSignin}
            setOpenAlertModal={setOpenAlertModal}
            setPathToPush={setPathToPush}
            history={history}
          />
        </Hidden>
      )}
      {showSideCartIcon && <ShoppingCartWrapper t={t} toggleDrawer={toggleDrawer} cart={cart} history={history} />}
      {showHelpBtn && (
        <Hidden smDown>
          <HelpButton />
        </Hidden>
      )}
    </Grid>
  )
}

HeaderLeftItems.propTypes = propTypes
export default HeaderLeftItems
