import React from 'react'
import propTypes from './props'

const SvgRadioOnButton = ({ fill, borderFill = '#D8D8D8' }) => (
  <svg viewBox="0 0 510 510" width="1.5rem">
    <ellipse cx={255} cy={255} rx={165.4} ry={176} fill={fill} />
    <path
      d="M254 0C113.8 0-1 114.8-1 255s114.8 255 255 255 255-114.8 255-255S394.2 0 254 0zm0 473c-119.9 0-218-98.1-218-218S134.1 37 254 37s218 98.1 218 218-98.1 218-218 218z"
      fill={borderFill}
    />
  </svg>
)

SvgRadioOnButton.propTypes = propTypes
export default SvgRadioOnButton
