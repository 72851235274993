/* eslint-disable max-len */
import React from 'react'

const FaqsIcon = () => (
  <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-60">
        <g id="Group-61">
          <text
            id="?"
            fontFamily="Lato-Bold, Lato"
            fontSize="14"
            fontWeight="bold"
            letterSpacing="0.875"
            fill="#393939">
            <tspan x="8" y="15">
              ?
            </tspan>
          </text>
          <rect id="Rectangle" stroke="#393939" x="0.5" y="0.5" width="21" height="20" rx="10" />
        </g>
      </g>
    </g>
  </svg>
)

export default FaqsIcon
