/* eslint-disable react/prop-types */
import React from 'react'
import { Grid, Typography, Box, Hidden } from '@material-ui/core'
import FlatButton from '../FlatButton'
import IconComponent from '../../atoms/IconComponent'
import { FIRST_COLLECTION } from '../../../utils/urls'

const AddMoreProducts = ({ history, t }) => {
  const handleOnClick = () => {
    history.push(`/products/${FIRST_COLLECTION}`)
  }
  return (
    <Box className="add-more-products">
      <Grid container className="add-more-products__container" spacing={2}>
        <Grid item className="add-more-products__icon">
          <IconComponent icon="AddMoreProducts" />
        </Grid>
        <Hidden smDown>
          <Grid item className="add-more-products__text">
            <Typography className="title">{t('cart.addNewProduct')}</Typography>
          </Grid>
        </Hidden>
        <Grid item>
          <FlatButton
            title={t('cart.addNewProduct')}
            classes="flat-button--green-jaka"
            onClick={() => handleOnClick()}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddMoreProducts
