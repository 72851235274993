/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { translate } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import {
  ImageCheck,
  formatMoney,
  gtmSingleProduct,
  haveOfferTag,
  getAddedAmount,
  gtmSelectItem,
  productInEditionQty
} from '../../../utils/helpers'
import OkIcon from '../../../assets/images/YB-ok-green-icon.svg'
import OfferLabel from '../../../assets/images/offer-label.svg'
import QuantityModifier from '../../molecules/QuantityModifier'
import FlatButton from '../../molecules/FlatButton'
import OutOfStockLabel from '../../atoms/OutOfStockLabel'
import ArrivesTodayLabel from '../../atoms/ArrivesTodayLabel'

const UpsellingCardItem = ({ product, t, key, cart, getDetailPageURI, inMomsAlsoBought }) => {
  const { title, images } = product
  const currentLanguage = useSelector(state => state.Language.current)
  const { userData } = useSelector(state => state.Users)
  const { upselling } = useSelector(state => state.Checkout)
  const { inStock } = useSelector(state => state.Products.filters)

  const dispatch = useDispatch()
  const addUpselling = data => dispatch(Actions.Checkout.addUpselling(data))
  const [variantSelected, setVariantSelected] = useState(product.variants[0])
  const removeUpselling = (index, id, subscription) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({ ...product, variantId: variantSelected.id, purchaseType: subscription })
    )
    dispatch(Actions.Checkout.removeUpselling(index, id))
  }

  const selectedVariantImage =
    images && images.length > 0 && images.find(img => variantSelected && img.id === variantSelected.image_id)

  const [showOk, setShowOk] = useState(false)
  const offer = haveOfferTag(product)
  const { price, compare_at_price, appliedDiscount } = variantSelected

  const [alertOutStock, setAlertOutStock] = useState(false)
  const [outOfStock, setOutOfStock] = useState(variantSelected.inventory_quantity <= 0)

  const handleAlertOutStock = boolean => {
    setAlertOutStock(boolean)
  }
  useEffect(() => {
    setVariantSelected(product.variants[0])
  }, [inStock])

  const handleQuantityChange = type => {
    const bubblesAnimation = document.getElementById('cart-button')
    switch (type) {
      case 'add':
        addUpselling({
          ...product,
          variantId: variantSelected.id,
          purchaseType: 'ONE_TIME',
          price,
          isOffer: offer,
          sku: variantSelected.sku
        })
        bubblesAnimation.classList.remove('animate')
        bubblesAnimation.classList.add('animate')
        setTimeout(() => {
          setShowOk(false)
          bubblesAnimation.classList.remove('animate')
        }, 500)
        setShowOk(true)
        break

      case 'remove':
        for (let index = cart.length - 1; index >= 0; index -= 1) {
          if (+cart[index].variantId === variantSelected.id || +cart[index].shopifyVariantId === variantSelected.id) {
            removeUpselling(index, null, 'ONE_TIME')
            break
          }
        }
        break

      default:
        break
    }
  }

  const getSelectedVariantQuantity = variant => {
    let q = 0
    cart.forEach(up => {
      if (+up.variantId === variant.id || +up.shopifyVariantId === variant.id) {
        q += 1
      }
    })
    return q
  }

  const selectedVariantQuantity = getSelectedVariantQuantity(variantSelected)

  const pushToPdp = () => {
    gtmSelectItem(product, variantSelected)
    getDetailPageURI(product.id, title)
  }
  useEffect(() => {
    setOutOfStock(variantSelected.inventory_quantity + productInEditionQty(variantSelected, cart) <= 0)
  }, [upselling, variantSelected])

  return (
    <Fragment>
      <Grid
        item
        container
        xs={2}
        sm={inMomsAlsoBought ? 2 : 3}
        justify="center"
        className={`filter__item ${inMomsAlsoBought && 'in-moms-also-bought'}`}
        key={key}
        onClick={() =>
          gtmSingleProduct(
            product,
            compare_at_price,
            variantSelected.sku,
            userData,
            { list: 'upselling' },
            'productClick'
          )
        }>
        {showOk && (
          <div className="filter__item__okey">
            <img src={OkIcon} alt="Ok" />
          </div>
        )}
        {offer && (
          <>
            <div className="filter__item__offer">
              <img src={OfferLabel} alt="offer" />
            </div>
            <Grid container item xs={6} direction="row" className="filter__item__offer-text">
              <Typography variant="inherit">
                <span>{t('default.aed')}</span>
              </Typography>
              <Typography variant="inherit">
                <strong>{` ${formatMoney(appliedDiscount)} ${t('default.off')}`}</strong>
              </Typography>
            </Grid>
          </>
        )}
        <Grid xs={12}>
          {alertOutStock && <OutOfStockLabel inventoryQuantity={variantSelected.inventory_quantity} t={t} />}
          <Grid item container>
            <ArrivesTodayLabel t={t} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="center"
          direction="row"
          alignItems="center"
          onClick={() => pushToPdp()}
          className="filter__item__image-product-detail">
          <img src={selectedVariantImage ? selectedVariantImage.src : ImageCheck(product).src} alt={product.title} />
        </Grid>
        <Grid container item xs={12} className="filter__item__desc" direction="column">
          <Typography variant="inherit">{title}</Typography>
        </Grid>
        <Grid container item className="filter__item__variants">
          {product.variants &&
            product.variants.map(variant => (
              <button
                type="button"
                key={variant.id}
                className={`filter__item__variants__variant-chip${
                  variantSelected && variant.id === variantSelected.id ? '__selected' : ''
                }`}
                onClick={() => setVariantSelected(variant)}>
                <strong>{`${variant.option1} `}</strong>
                {variant.option2}
              </button>
            ))}
        </Grid>
        <Grid container item xs={12} className="filter__item__info" justify="flex-start">
          <Grid
            container
            item
            className={`filter__item__info__amount-card ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
            <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
              {+appliedDiscount > 0
                ? `${t('default.aed')} ${formatMoney(
                    price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
                  )}`
                : ''}
            </Typography>
            <Grid alignItems="center">
              <Typography variant="inherit" className="subscription-price">
                {`${t('default.aed')} ${formatMoney(getAddedAmount(compare_at_price, price, selectedVariantQuantity))}`}
              </Typography>
              {!offer && +appliedDiscount > 0 && (
                <Typography variant="inherit" className="subscription-text">
                  <strong>
                    {`${t('default.aed')} ${formatMoney(
                      appliedDiscount * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
                    )}
                  ${t('default.off')} `}
                  </strong>
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item container justify="center" className="button-height">
            {selectedVariantQuantity === 0 ? (
              <FlatButton
                idButton="add-addOn"
                cartIcon={outOfStock ? '' : 'cart2'}
                iconClass="cart-icon-2"
                classes={`filter__item__info__add-button ${inMomsAlsoBought && 'in-moms-also-bought'} ${
                  outOfStock ? 'out-of-stock' : ''
                }`}
                onClick={() => {
                  if (!outOfStock) {
                    addUpselling({
                      ...product,
                      variantId: variantSelected.id,
                      purchaseType: 'ONE_TIME',
                      price,
                      isOffer: offer,
                      sku: variantSelected.sku
                    })
                    const bubblesAnimation = document.getElementById('cart-button')
                    bubblesAnimation.classList.remove('animate')
                    bubblesAnimation.classList.add('animate')
                    setTimeout(() => {
                      setShowOk(false)
                      bubblesAnimation.classList.remove('animate')
                    }, 500)
                    setShowOk(true)
                  }
                }}
                title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
              />
            ) : (
              <QuantityModifier
                quantity={selectedVariantQuantity}
                setQuantity={handleQuantityChange}
                variantSelected={variantSelected}
                handleAlertOutStock={handleAlertOutStock}
                alertOutStock={alertOutStock}
                cart={cart}
                stockControl
                inMomsAlsoBought
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

UpsellingCardItem.propTypes = propTypes
export default translate('common')(UpsellingCardItem)
