import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import { CalcTotalPriceMKT } from '../../../utils/helpers'
import propTypes from './props'

const PastOrder = ({ t, downloadInvoice, order }) => {
  const selectedDiapers = []
  const selectedWipes = []
  const upselling = []

  const emirate = useSelector(
    state =>
      (state.Users.userData && state.Users.userData.default_address && state.Users.userData.default_address.city) ||
      'Dubai'
  )

  order.boxProducts.forEach(prod => {
    if (prod.type === 'diapers') {
      for (let i = 0; i < prod.quantity; i += 1) {
        selectedDiapers.push({ ...prod, ...prod.shopifyProduct })
      }
    }
    if (prod.type === 'baby wipes') {
      for (let i = 0; i < prod.quantity; i += 1) {
        selectedWipes.push({ ...prod, ...prod.shopifyProduct })
      }
    }
  })

  order.boxAddOns.forEach(up => {
    for (let i = 0; i < up.quantity; i += 1) {
      upselling.push({ ...up, ...up.shopifyProduct })
    }
  })

  const promoCode = order.boxDiscount && {
    value: order.boxDiscount.value,
    value_type: order.boxDiscount.type,
    title: order.boxDiscount.code,
    id: order.boxDiscount.id,
    productsId: order.boxDiscount.entitledProductIds,
    variantsId: order.boxDiscount.entitledVariantIds
  }

  const { finalPrice, totalProducts } = CalcTotalPriceMKT(
    selectedDiapers,
    selectedWipes,
    upselling,
    order.vatPercent,
    emirate,
    promoCode,
    order.codPercent,
    order.paymentType,
    order.shippingCost,
    false,
    order.codAmount
  )

  return (
    <Grid className="orders__past-order">
      <Grid className="info-container">
        <Typography className="order-number">
          {t('pastOrders.order')}
          {order.order.orderNumber}
        </Typography>
        <Typography className="info">
          {t('pastOrders.date')}
          {moment(order.shippingDate).format('DD/MM/YYYY')}
        </Typography>
        <Typography className="info">
          {t('pastOrders.products')}
          {totalProducts}
        </Typography>
        <Typography className="info">
          {t('pastOrders.total')}
          {finalPrice.toFixed(2)}
        </Typography>
      </Grid>

      <Typography onClick={() => downloadInvoice(order.shippingDate, order.id)} className="download-button">
        Download invoice
      </Typography>
    </Grid>
  )
}
PastOrder.propTypes = propTypes
export default PastOrder
