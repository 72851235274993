/* eslint-disable react/no-array-index-key */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useRef } from 'react'
import { Grid, Typography, Dialog, DialogContent, Collapse } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import RetryPaymentModal from '../RetryPaymentModal'
import Actions from '../../../actions'
import propTypes from './props'
import StatusAndShippingInformation from '../../atoms/StatusAndShippingInformation'
import BoxItem from '../../molecules/BoxItem'
import IconComponent from '../../atoms/IconComponent'
import CancelSubscriptionModal from '../CancelSubscriptionModal'
import BoxPausedModal from '../BoxPausedModal'
import gift from '../../../assets/images/gift.svg'

import {
  CalcTotalPriceMKT,
  editSelectedSubscription,
  formatMoney,
  getDeliveryDays,
  isAnyProductSubscribed,
  haveOfferTag
} from '../../../utils/helpers'
import PriceDetailModal from '../PriceDetailModal'
import FlatButton from '../../molecules/FlatButton'

const Box = ({ t, subscription, history, currentLanguage, boxType }) => {
  const PENDING = 'PENDING'
  const divRef = useRef([])
  const [openModal, setOpenModal] = useState(false)
  const [openRetryPaymentModal, setOpenRetryPaymentModal] = useState(false)
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [openTypePauseForm, setOpenTypePauseForm] = useState(false)

  const dispatch = useDispatch()

  const setPromoCode = promoCodeToSet => dispatch(Actions.Checkout.setPromoCode(promoCodeToSet))
  const getSubscriptions = () => dispatch(Actions.Users.setFetchSubscriptions('CURRENT'))
  const setThisBox = box => dispatch(Actions.Checkout.setThisBox(box))

  const activateSubscriptionDispatch = (subscriptionId, activationDate) =>
    dispatch(Actions.Checkout.activateSubscription(subscriptionId, activationDate))

  const emirate = useSelector(
    state =>
      (state.Users.userData && state.Users.userData.default_address && state.Users.userData.default_address.city) ||
      'Dubai'
  )

  const setSubscribeAllProducts = boolean => dispatch(Actions.Checkout.setSubscribeAllProducts(boolean))

  const promoCode = useSelector(state => state.Checkout.promoCode)

  const handleOpenDatePicker = () => {
    setOpenDatePicker(true)
  }

  const handleCloseDatePicker = () => {
    setOpenDatePicker(false)
  }

  const {
    id,
    shippingDate,
    status,
    paymentType,
    paymentStatus,
    boxProducts,
    boxAddOns,
    boxDiscount,
    vatPercent,
    codPercent,
    sourceId,
    deliveryAdditionalInfo,
    subscriptionOrderForReward,
    shippingCost,
    purchaseType,
    codAmount
  } = subscription

  const isRetryPaymentCase = () => {
    return status === 'IN_PROCESS' && paymentStatus === 'NOT_PAID' && paymentType === 'CREDIT'
  }

  const rewardedBoxs = [3, 6, 9, 12, 15, 18, 21, 24]
  const boxRewarded = rewardedBoxs.includes(subscriptionOrderForReward)
  const isInflu = boxDiscount && boxDiscount.code.includes('INFLU')

  const disableEditContent = paymentType === 'CREDIT' && paymentStatus === 'PAID' && sourceId === 0

  const disableCancel = deliveryAdditionalInfo === 'nocancel'
  const boxPaused = status === 'PAUSED'

  const [showBox, setShowBox] = useState(status === PENDING || boxPaused || isRetryPaymentCase())

  const promoCodeSubscription = boxDiscount && {
    value: boxDiscount.value,
    value_type: boxDiscount.type,
    title: boxDiscount.code,
    id: boxDiscount.id,
    productsId: boxDiscount.entitledProductIds,
    variantsId: boxDiscount.entitledVariantIds
  }

  const selectedDiapers = []
  const selectedWipes = []

  boxProducts.forEach(prod => {
    if (prod.product_type === 'diapers' || prod.type === 'diapers') {
      for (let i = 0; i < prod.quantity; i += 1) {
        selectedDiapers.push({ ...prod, ...prod.shopifyProduct })
      }
    }
    if (prod.product_type === 'baby wipes' || prod.type === 'baby wipes') {
      for (let i = 0; i < prod.quantity; i += 1) {
        selectedWipes.push({ ...prod, ...prod.shopifyProduct, price: +prod.listPrice / prod.quantity })
      }
    }
  })

  const upselling = []
  boxAddOns.forEach(ups => {
    for (let i = 0; i < ups.quantity; i += 1) {
      upselling.push({ ...ups, ...ups.shopifyProduct })
    }
  })

  const { totalPriceWithSubscription, discount, finalPrice } = CalcTotalPriceMKT(
    selectedDiapers,
    selectedWipes,
    upselling,
    vatPercent,
    emirate,
    promoCodeSubscription,
    codPercent,
    paymentType,
    shippingCost,
    true,
    codAmount
  )

  const boxInEdition = {
    selectedDiapers,
    selectedWipes,
    upselling,
    paymentType,
    promoCode: promoCode ? promoCode.id : '',
    shippingDate
  }

  const isOneOffBox = !isAnyProductSubscribed(selectedDiapers, selectedWipes, upselling)

  const handleEditAndSubscribeBox = option => {
    if (boxPaused) {
      activateSubscriptionDispatch(id, getDeliveryDays(emirate))
    } else if (!disableEditContent) {
      setThisBox(boxInEdition)
      const subs = editSelectedSubscription(subscription)
      dispatch(Actions.Checkout.editSubscription(subs))
      dispatch(Actions.Checkout.setStatus())
      history.push('/cart')
      if (option === 'Subscribe Box') setSubscribeAllProducts(true)
    }
  }

  const handleOptionSelected = option => {
    switch (option) {
      case 'Edit Content':
      case 'Subscribe Box':
        handleEditAndSubscribeBox(option)
        break

      case 'Cancel':
        if (!disableCancel) setOpenModal('cancel')
        break

      case 'Reschedule':
        if (!boxPaused) handleOpenDatePicker()
        break

      case 'Pause':
        if (!boxPaused) {
          setOpenTypePauseForm(true)
          setOpenModal('pause')
        }
        break

      default: {
        return null
      }
    }
    return null
  }

  const getUniqueProducts = products => {
    return _.uniqWith(products, (item, itemComp) => {
      switch (item.product_type) {
        case 'diapers':
          return (
            item.shopifyVariantId === itemComp.shopifyVariantId &&
            item.product_size === itemComp.product_size &&
            item.quantity === itemComp.quantity
          )

        case 'baby wipes': {
          return item.shopifyVariantId === itemComp.shopifyVariantId
        }

        default: {
          return item.shopifyVariantId === itemComp.shopifyVariantId
        }
      }
    })
  }

  const getMonthlyAndOneTimeProducts = () => {
    const monthlyProducts = []
    const oneTimeProducts = []
    let oneTimeQty = 0
    let monthlyQty = 0

    const products = boxProducts.concat(boxAddOns)
    products.forEach(prod => {
      if (prod.purchase_type === 'SUBSCRIPTION' || prod.purchaseType === 'SUBSCRIPTION') {
        monthlyProducts.push({ ...prod, ...prod.shopifyProduct })
        monthlyQty += prod.quantity
      } else {
        oneTimeProducts.push({ ...prod, ...prod.shopifyProduct })
        oneTimeQty += prod.quantity
      }
    })

    const monthlyProductsUnique = getUniqueProducts(monthlyProducts)
    const oneTimeProductsUnique = getUniqueProducts(oneTimeProducts)

    return {
      monthlyProducts,
      oneTimeProducts,
      monthlyProductsUnique,
      oneTimeProductsUnique,
      oneTimeQty,
      monthlyQty
    }
  }
  const isSubscribable = oneTimeProduct => {
    const variant = oneTimeProduct.variants[0]
    return variant.appliedDiscount > 0 && !haveOfferTag(oneTimeProduct)
  }

  const { monthlyProducts, oneTimeProducts, monthlyProductsUnique, oneTimeProductsUnique, oneTimeQty, monthlyQty } =
    getMonthlyAndOneTimeProducts()
  const isSubscribableBox = () => oneTimeProductsUnique.find(prod => isSubscribable(prod))

  const handleRetryPayment = () => {
    dispatch(Actions.Users.retryBoxPayment(id, setOpenRetryPaymentModal(true)))
  }

  const handleCloseRetryPaymentModal = retryStatus => {
    if (retryStatus === 'ok') getSubscriptions()
    setOpenRetryPaymentModal(false)
  }

  const handleScroll = () => {
    if (divRef.current[id] && divRef.current[id].getBoundingClientRect().y < 400) {
      window.removeEventListener('scroll', handleScroll)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [divRef])

  const handlePauseBox = () => {
    if (!boxPaused) {
      setOpenTypePauseForm(true)
      setOpenModal('pause')
    }
  }

  const getPauseButtonTitle = () => (!isOneOffBox ? t('boxes.pauseSubscription') : t('boxes.pauseOneOff'))

  const getCancelBtnTitle = () => (!isOneOffBox ? t('boxes.cancelSubscription') : t('boxes.cancelOneOff'))

  const EditRescheduleButtons = () => (
    <>
      <Grid item xs={12} lg={3}>
        <FlatButton
          bgColor="pink-darker"
          title={t('boxes.editBoxContent')}
          cartIcon="editJaka"
          disabled={boxPaused && true}
          onClick={() => handleEditAndSubscribeBox()}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <FlatButton bgColor="pink-darker" title={t('boxes.reschedule')} onClick={() => handleOpenDatePicker()} />
      </Grid>
    </>
  )

  return (
    <Grid
      ref={el => {
        divRef.current[id] = el
      }}
      className="box"
      container
      item
      key={id}>
      <Dialog className="status-shipping-information__dialog" open={openDatePicker} onClose={handleCloseDatePicker}>
        <IconComponent
          className="status-shipping-information__close"
          height="12"
          width="12"
          icon="close"
          onClick={handleCloseDatePicker}
        />
        <DialogContent className="status-shipping-information__dialog-content">
          <Grid container direction="column">
            <Grid
              container
              className="status-shipping-information__heading-container"
              alignItems="center"
              justify="center">
              <div className="status-shipping-information__title">
                <Typography>{t('boxes.reschedule')}</Typography>
              </div>
              <div className="status-shipping-information__subtitle">
                <Typography>{t('boxes.selectNewDate')}</Typography>
              </div>
            </Grid>

            <StatusAndShippingInformation
              shippingDate={shippingDate}
              status={status}
              t={t}
              currentLanguage={currentLanguage}
              emirate={emirate}
              id={id}
              openDatePicker={openDatePicker}
              handleCloseDatePicker={handleCloseDatePicker}
              boxAddOns={boxAddOns}
              history={history}
              editSelectedSubscription={editSelectedSubscription}
              subscription={subscription}
              selectedDiapers={selectedDiapers}
              selectedWipes={selectedWipes}
              upselling={upselling}
              paymentType={paymentType}
              promoCode={promoCode ? promoCode.id : ''}
              boxPaused={boxPaused}
              setOpenModal={setOpenModal}
            />
          </Grid>
        </DialogContent>
      </Dialog>

      <Grid
        container
        item
        className={`box__content ${boxRewarded && showBox && 'rewarded'} ${!showBox && 'hideBox'}`}
        key={id}>
        <Grid
          onClick={() => {
            if (status !== PENDING) setShowBox(!showBox)
          }}
          item
          container
          justify="space-between"
          className={`box__content__grey-section__header ${isRetryPaymentCase() && 'box__red-header'} ${
            status === PENDING && 'no-pointer'
          }`}>
          <Typography variant="inherit" className="box__content__grey-section__header__status">
            {`${isRetryPaymentCase() ? `#${t('boxes.retryPaymentTitle')}` : t(`boxes.status.${status}`)}`}
            {(status === 'ON_HOLD' || isRetryPaymentCase()) && (
              <button type="button" onClick={() => history.push('/contact-us')}>
                {t('boxes.contactSupport')}
              </button>
            )}
          </Typography>
          {!isRetryPaymentCase() && (
            <Typography
              variant="subtitle"
              className={`box__content__grey-section__header__status__shipping ${boxPaused && 'text-center'}`}>
              {`${
                !boxPaused
                  ? `${t('boxes.shippingDate')} ${moment(shippingDate).format('DD/MM/YYYY')}`
                  : `${t('boxes.boxPaused')}`
              }`}
            </Typography>
          )}
          {status !== PENDING && (
            <Grid>
              {showBox ? (
                <ExpandMoreIcon className="box__content__grey-section__expand-icon" />
              ) : (
                <ExpandLessIcon className="box__content__grey-section__expand-icon" />
              )}
            </Grid>
          )}
        </Grid>

        {isRetryPaymentCase() && (
          <Grid container xs={12} alignItems="center" justify="center">
            <Typography className="box__retry-payment-text" variant="inherit">
              {t('boxes.retryPaymentText')}
              <Link to="/account/editpayment">{t('account.title')}</Link>
            </Typography>
            <button type="button" className="retry-payment-button" onClick={() => handleRetryPayment()}>
              {t('boxes.retryPayment')}
            </button>
          </Grid>
        )}
        <Collapse in={showBox}>
          <Grid className={`${isRetryPaymentCase() && 'less-opacity'}`}>
            {!!monthlyProductsUnique.length && (
              <Grid item container className={`box__content__info-section ${boxPaused && 'less-opacity'} `}>
                <Grid container className="box__content__info-section__buttons" spacing={2} alignItems="center">
                  {(status === PENDING || boxPaused) && <EditRescheduleButtons />}
                  <Grid item xs={12} className="box__content__info-section__title">
                    <Typography>
                      {`${t('cart.reciveEveryMonthTitle')}`}
                      <span>
                        {` (${monthlyQty}
                    ${monthlyQty > 1 ? t('cart.priceDetail.products') : t('cart.priceDetail.product')})`}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                {monthlyProductsUnique.map((prod, index) => {
                  return <BoxItem key={`${prod.id}${index}`} t={t} item={prod} subscribed products={monthlyProducts} />
                })}
              </Grid>
            )}
            {oneTimeProductsUnique.length > 0 && (
              <Grid item container className={`box__content__info-section ${boxPaused && 'less-opacity'} `}>
                <Grid container className="box__content__info-section__buttons" spacing={2} alignItems="center">
                  {(status === PENDING || boxPaused) && !monthlyProductsUnique.length && <EditRescheduleButtons />}
                </Grid>
                <Grid item xs={12} className="box__content__info-section__title">
                  <Typography>
                    {`${t('cart.reciveThisMonthTitle')}`}
                    <span>
                      {` (${oneTimeQty}
                    ${oneTimeQty > 1 ? t('cart.priceDetail.products') : t('cart.priceDetail.product')})`}
                    </span>
                  </Typography>
                </Grid>
                {oneTimeProductsUnique.map((prod, index) => {
                  return (
                    <BoxItem
                      t={t}
                      key={`${prod.id}${index}`}
                      item={prod}
                      isSubscribable={isSubscribable(prod)}
                      products={oneTimeProducts}
                    />
                  )
                })}
              </Grid>
            )}
            <Grid item container direction="row" className="box__content__grey-section__footer">
              {promoCodeSubscription && (
                <Grid
                  className="box__promoCode-container"
                  item
                  container
                  xs={12}
                  lg={12}
                  alignItems="flex-end"
                  justify="center">
                  <Typography className="box__promoCode" variant="inherit" align="center">
                    {promoCodeSubscription.title}
                    <span className="boxes__price-information__price-total__title__number">
                      {`${t('cart.priceDetail.negativeAED')}${formatMoney(discount)}`}
                    </span>
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                container
                xs={12}
                lg={12}
                className="boxes__price-information__price-total__title"
                justify="center">
                <Grid
                  item
                  container
                  xs={12}
                  lg={12}
                  alignItems="center"
                  justify={status === PENDING ? 'space-between' : 'flex-end'}
                  className="boxes__price-information__price-total__title__container">
                  <Grid className={`boxes__price-information__container-grid ${boxPaused && 'less-opacity'} `}>
                    <Typography className="boxes__price-information__total-text">TOTAL</Typography>
                    <Grid className="boxes__price-information__price-total__container-grid">
                      {t('default.aed')}
                      <span className="boxes__price-information__price-total__title__number">
                        {formatMoney(finalPrice)}
                      </span>
                      <IconComponent
                        className="boxes__price-information__price-total__title__info-icon"
                        icon="infoButton"
                        t={t}
                        color="black"
                        onClick={() => {
                          const subs = editSelectedSubscription(subscription)
                          dispatch(Actions.Checkout.editSubscription(subs))
                          dispatch(Actions.Checkout.setStatus())
                          if (promoCodeSubscription) {
                            setPromoCode(promoCodeSubscription)
                          }
                          setOpenModal('detail')
                        }}
                      />
                    </Grid>
                  </Grid>
                  {(status === PENDING || boxPaused) && (
                    <Grid container className="boxes__edit-options-text__container" spacing={2}>
                      <Grid item xs={6}>
                        <FlatButton
                          bgColor="link"
                          title={getCancelBtnTitle()}
                          onClick={() => !disableCancel && setOpenModal('cancel')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FlatButton
                          bgColor={boxPaused ? 'pink-darker' : 'gray'}
                          title={boxPaused ? t('boxes.activateSubscription') : getPauseButtonTitle()}
                          onClick={() => (boxPaused ? handleEditAndSubscribeBox() : handlePauseBox())}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>

        <PriceDetailModal
          t={t}
          history={history}
          subscription={subscription}
          currentLanguage={currentLanguage}
          setOpenModal={setOpenModal}
          openModal={openModal}
          selectedDiapers={selectedDiapers}
          selectedWipes={selectedWipes}
          upselling={upselling}
          totalPriceWithSubscription={totalPriceWithSubscription}
          boxes
        />
        <CancelSubscriptionModal
          openModal={openModal === 'cancel'}
          setOpenModal={setOpenModal}
          handleClose={() => {
            setOpenModal(false)
          }}
          t={t}
          id={id}
          history={history}
          disableEditContent={disableEditContent}
          sourceId={sourceId}
          subscription={subscription}
          boxType={boxType}
          boxDiscount={boxDiscount}
          status={status}
          setOpenDatePicker={setOpenDatePicker}
          isOneOffBox={isOneOffBox}
          isSubscribableBox={isSubscribableBox()}
          handleOptionSelected={handleOptionSelected}
        />
        <RetryPaymentModal
          t={t}
          openModal={openRetryPaymentModal}
          handleClose={retryStatus => handleCloseRetryPaymentModal(retryStatus)}
        />
        <BoxPausedModal
          shippingDate={shippingDate}
          t={t}
          openModal={openModal === 'pause'}
          setOpenModal={setOpenModal}
          handleOpenDatePicker={handleOpenDatePicker}
          boxId={id}
          status={status}
          emirate={emirate}
          openTypePauseForm={openTypePauseForm}
          setOpenTypePauseForm={setOpenTypePauseForm}
          purchaseType={purchaseType}
        />
        {boxRewarded && showBox && !isInflu && (
          <Grid
            onClick={() => {
              handleOptionSelected('Edit Content')
            }}
            className="box__content__reward-banner">
            <img className="box__content__reward-banner__icon" src={gift} alt="Gifts and Samples" />
            <Typography className="box__content__reward-banner__text">{t('boxes.getSamples')}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

Box.propTypes = propTypes
export default Box
