/* eslint-disable camelcase */
/* eslint-disable react/jsx-wrap-multilines */
import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { SingleDatePicker } from 'react-dates'
import { Grid, Typography, FormControl, Select, MenuItem, TextField, Collapse, Divider, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { getNextId } from '../../../utils/random'
import propTypes from './props'
import Actions from '../../../actions'
import FlatButton from '../../molecules/FlatButton'
import LinkButton from '../../atoms/LinkButton'
import SignIn from '../../../containers/SignIn'
import { getDeliveryDays, getDayBlocked, gtmCheckoutSteps, isHoliday } from '../../../utils/helpers'
import { allEmirates } from '../../../utils/constants'
import ArrivesTodayNotification from '../../molecules/ArrivesTodayNotification'

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#54CE8A'
      }
    }
  }
})

const StepShipping = ({
  history,
  t,
  isAccount,
  formValues,
  setValues,
  setTypeLoginSignin,
  signupFormRef,
  logged,
  showInfo,
  setShowInfo,
  shippingFormRef
}) => {
  const classes = useStyles()
  const isInCheckout = window.document.URL.includes('/checkout')

  const address = useSelector(state =>
    state.Users.userData && state.Users.userData.default_address ? state.Users.userData.default_address : null
  )
  const currentLanguage = useSelector(state => state.Language.current)
  const loading = useSelector(state => state.Universal.request.process)

  const dispatch = useDispatch()
  const fetchUpdateAddress = (a, cb) => dispatch(Actions.Users.fetchUpdateAddress(a, cb))
  const setCutstomerAddress = value => dispatch(Actions.Checkout.setCutstomerAddress(value))

  const { userData } = useSelector(state => state.Users)

  const { shippingDate, selectedDiapers, selectedWipes, upselling, promoCode } = useSelector(state => state.Checkout)

  const [datepickerFocused, setDatepickerFocused] = useState(false)

  const fetchUpdateUser = () => dispatch(Actions.Users.fetchUpdateUser())
  const setShippingDate = value => dispatch(Actions.Checkout.setShippingDate(value))
  const getUserPaymentMethod = () => dispatch(Actions.Users.getUserPaymentMethod())

  const setFormValues = (key, value) => {
    const data = { ...formValues }
    data[key] = value
    if (key === 'province') {
      const today = getDeliveryDays(value)
      setDatepickerFocused(today)
      setShippingDate(today)
    }
    setValues(data)
  }

  useEffect(() => {
    const values = {
      ...formValues,
      address1: address ? address.address1 : '',
      address2: address ? address.address2 : '',
      province: address ? address.city : 'Dubai',
      date: shippingDate,
      id: address ? address.id : ''
    }
    setValues(values)
  }, [address])

  const buttonHandle = () => {
    setCutstomerAddress(formValues)
    fetchUpdateAddress(formValues)
    if (!isInCheckout) history.push('/account')
  }

  const minDate = getDeliveryDays((formValues && formValues.province) || (address && address.province) || 'Dubai')
  const selectedDate = shippingDate !== '' ? moment(shippingDate) : moment(minDate)

  useEffect(() => {
    if (moment(shippingDate).isSameOrBefore(moment())) {
      setShippingDate(getDeliveryDays(formValues.province))
    }

    fetchUpdateUser()
    getUserPaymentMethod()
    const today = getDeliveryDays((formValues && formValues.province) || (address && address.province) || 'Dubai')
    setShippingDate(shippingDate && moment(shippingDate).isAfter(today) ? shippingDate : today)
    gtmCheckoutSteps(
      [...selectedDiapers, ...selectedWipes, ...upselling],
      userData,
      'completeContactInfo',
      { step: '3', action: 'checkout' },
      promoCode && promoCode.title
    )

    gtmCheckoutSteps(
      [...selectedDiapers, ...selectedWipes, ...upselling],
      userData,
      'completeShippingInfo',
      { step: '4', action: 'checkout' },
      promoCode && promoCode.title
    )
  }, [])

  const ArrivesTodayFirstColumn = { xs: 12, sm: 1, md: 2, lg: 2 }
  const ArrivesTodaySecondColumn = { xs: 12, sm: 11, md: 10 }

  return (
    <ValidatorForm ref={shippingFormRef} onSubmit={() => buttonHandle(formValues)}>
      <Grid container>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          xs={12}
          className="information-steps__form__title">
          <span>{t('checkout.shippingInfo')}</span>

          {!logged && !isAccount && (
            <Grid className="information-steps__login">
              <Typography align="left">{t('default.IHaveAccount')}</Typography>
              <LinkButton
                classNames="information-steps__login__button"
                text={t('default.login')}
                onClick={() => {
                  setTypeLoginSignin('login')
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider className="information-steps__form__divider" light variant="fullWidth" />
      {logged && !showInfo && (
        <Typography onClick={() => setShowInfo(!showInfo)} className="information-steps__edit-info" align="right">
          {t('shipping.editShippingInfo')}
        </Typography>
      )}

      {logged && (
        <Collapse in={!showInfo}>
          <>
            <Typography className="information-steps__shipping-info">
              <span>{`${t('checkout.contactInfo')}:`}</span>
              {userData.email}
            </Typography>
            <Typography className="information-steps__shipping-info">
              <span>{`${t('shipping.address')}:`}</span>
              {`${formValues.address1}, ${formValues.province}`}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography className="information-steps__shipping-info">
                <span>{`${t('shipping.date')}:`}</span>
                {`${formValues.date || shippingDate} - ${formValues.deliveryTime}`}
              </Typography>
              {isInCheckout && (
                <Box maxWidth="150px">
                  <FlatButton
                    bgColor="pink-darker"
                    title={t('boxes.reschedule')}
                    classes="reschedule-btn"
                    onClick={() => setShowInfo(!showInfo)}
                  />
                </Box>
              )}
            </Box>
          </>
        </Collapse>
      )}
      <Collapse in={showInfo || !logged}>
        <Grid container md={isAccount ? 12 : 7} className={currentLanguage === 'AR' ? 'invertAr' : ''}>
          {!isAccount && (
            <ArrivesTodayNotification
              t={t}
              columnSizesIcon={ArrivesTodayFirstColumn}
              columnSizesText={ArrivesTodaySecondColumn}
            />
          )}

          <Grid container item justify="center" alignItems="stretch" direction="row">
            <Grid item xs={12} className="shipping__info__form">
              {!isAccount && !logged && (
                <SignIn
                  setTypeLoginSignin={setTypeLoginSignin}
                  signupFormRef={signupFormRef}
                  inStepPayment
                  t={t}
                  history={history}
                />
              )}
              <Grid item xs={12} className="information-steps__form__subtitles-fields">
                <span>{t('shipping.city')}</span>
              </Grid>
              <FormControl>
                <Select
                  value={formValues.province || ''}
                  className={classes.root}
                  variant="outlined"
                  onChange={evt => {
                    setFormValues('province', evt.target.value)
                  }}
                  inputProps={{
                    name: 'province',
                    id: 'province'
                  }}>
                  {allEmirates.map(state => (
                    <MenuItem value={state.value} key={getNextId()}>
                      {t(state.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="information-steps__form__field">
              <TextValidator
                id="address"
                label={t('shipping.address')}
                value={formValues.address1 || ''}
                className={classes.root}
                variant="outlined"
                onChange={evt => setFormValues('address1', evt.target.value)}
                name="address"
                validators={['required']}
                errorMessages={['This field is required']}
              />
            </Grid>
            <Grid container justify="space-between">
              <Grid item xs={12} className="information-steps__form__field">
                <TextValidator
                  id="floor"
                  label={`${t('shipping.floor')} / ${t('shipping.aptnumber')}`}
                  value={formValues.address2 || ''}
                  className={classes.root}
                  variant="outlined"
                  onChange={evt => setFormValues('address2', evt.target.value)}
                  name="floor"
                  validators={[]}
                  errorMessages={['This field is required']}
                />
              </Grid>
            </Grid>
            {!isAccount && (
              <Fragment>
                <Grid
                  item
                  container
                  className="information-steps__form__field margin-datepicker"
                  direction="column"
                  xs={12}>
                  <span className="information-steps__form__subtitles-fields">{t('shipping.date')}</span>

                  <SingleDatePicker
                    hideKeyboardShortcutsPanel
                    id="calendarComponent"
                    date={selectedDate}
                    onDateChange={date => setShippingDate(date.format('YYYY-MM-DD'))}
                    focused={!!datepickerFocused}
                    onFocusChange={({ focused }) => {
                      setDatepickerFocused(focused)
                    }}
                    isOutsideRange={day =>
                      day.isBefore(moment(minDate)) ||
                      day.isAfter(moment(getDeliveryDays(formValues.province)).add(60, 'days'))
                    }
                    displayFormat="DD/MM/YYYY"
                    showDefaultInputIcon
                    isDayBlocked={date => getDayBlocked(formValues.province, date.day()) || isHoliday(date)}
                    readOnly
                    numberOfMonths={1}
                  />
                </Grid>
              </Fragment>
            )}
            {!isAccount && (
              <Grid container className="delivery-time" justify="flex-start" direction="column">
                <Grid container className="additional-info" direction="column">
                  <TextField
                    label={t('shipping.additionalInfo')}
                    id="additionalInfo"
                    placeholder={t('shipping.addInfoPlaceholder')}
                    value={formValues.deliveryAdditionalInfo || ''}
                    className={classes.root}
                    variant="outlined"
                    onChange={evt => setFormValues('deliveryAdditionalInfo', evt.target.value)}
                    name="additionalInfo"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            )}

            {isAccount && (
              <Grid className="price-detail__buttons" container justify="center">
                <Grid item xs={6} container justify="center">
                  <FlatButton
                    disabled={loading}
                    idButton="checkout_shipping_nextButton"
                    classes={`information-steps__next-button${loading ? '__disabled' : ''}`}
                    type="submit"
                    bgColor="green"
                    title={t(`default.save`)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </ValidatorForm>
  )
}

StepShipping.propTypes = propTypes
export default translate('common')(StepShipping)
