/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const MyAccountIcon = ({ color }) => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M10.9997495,1 C5.48012424,1 1,5.48012424 1,10.9997495 C1,16.5198758 5.48012424,21 10.9997495,21 C16.5198758,21 21,16.5198758 21,10.9997495 C21,5.48012424 16.5198758,1 10.9997495,1 L10.9997495,1 Z M10.9997495,4 C12.6599315,4 14,5.34006846 14,7.00025048 C14,8.65993154 12.6599315,10 10.9997495,10 C9.34006846,10 8,8.65993154 8,7.00025048 C8,5.34006846 9.34006846,4 10.9997495,4 L10.9997495,4 Z M11,18 C8.50020873,18 6.28997245,16.7805344 5,14.9332061 C5.03005761,13.0376908 9.00016699,12 11,12 C12.9903148,12 16.9699424,13.0376908 17,14.9332061 C15.7100276,16.7805344 13.5002922,18 11,18 L11,18 Z"
        id="Fill-9-Copy"
        stroke={color || '#393939'}
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default MyAccountIcon
