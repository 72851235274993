/* eslint-disable max-len */
import React from 'react'
import propTypes from './props'

const DiaperQuantity = () => (
  <svg width="43px" height="38px" viewBox="0 0 43 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M34.334677,1.33366646 C34.0136159,1.01370375 33.5769589,0.833132992 33.121036,0.831616195 L2.46160396,0.831616195 C1.99841304,0.825718526 1.55241152,1.0051486 1.22488997,1.32910371 C0.901290828,1.64655692 0.718667856,2.07830696 0.717283475,2.52910648 L0.717283475,14.0637338 C0.73251167,23.3812077 8.36795158,30.9308448 17.79132,30.9459019 C27.194961,30.9182973 34.8118271,23.3890784 34.8422835,14.0911103 L34.8422835,2.53366922 C34.8408991,2.08286971 34.6582761,1.65111967 34.334677,1.33366646 Z"
        id="diaperSVG"
      />
      <filter x="-17.6%" y="-16.6%" width="135.2%" height="139.8%" filterUnits="objectBoundingBox" id="filter-2">
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" type="matrix" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="card/Diapers/-Se-mores/MOB-" transform="translate(-105.000000, -89.000000)">
        <g id="Group-36" transform="translate(109.000000, 91.000000)">
          <g id="Path" fillRule="nonzero">
            <use fill="black" fillOpacity="1" filter="url(#filter-2)" href="#diaperSVG" />
            <use stroke="#FBDE50" strokeWidth="2" fill="#FFFFFF" href="#diaperSVG" />
          </g>
          <rect id="Rectangle" fill="#FBDE50" x="0.717283475" y="3.48875905" width="3.5" height="3.54285714" rx="1" />
          <rect id="Rectangle-Copy-60" fill="#FBDE50" x="31.3422835" y="3.48875905" width="3.5" height="3.54285714" rx="1" />
        </g>
      </g>
    </g>
  </svg>
)
DiaperQuantity.propTypes = propTypes

export default DiaperQuantity
