var config = {
  ENDPOINT_URL: process.env.REACT_APP_ENDPOINT_URL,
  API_KEY: '',
  UPLOAD_URL: process.env.REACT_APP_UPLOAD_URL,
  KLAVIYO_KEY: process.env.REACT_APP_KLAVIYO_KEY,
  KLAVIYO_LIST: process.env.REACT_APP_KLAVIYO_LIST,
  KLAVIYO_API_KEY: process.env.REACT_APP_KLAVIYO_API_KEY,
  CHECKOUT_KEY: process.env.REACT_APP_CHECKOUT_KEY,
  ANALYTICS_TRACKID: process.env.REACT_APP_ANALYTICS_TRACKID,
  ALGOLIA_KEY: process.env.REACT_APP_ALGOLIA_SEARCH_KEY,
  TAG_MANAGER: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
  TAG_MANAGER_ENV: process.env.REACT_APP_TAG_MANAGER_ENV,
  CRISP_ID: process.env.REACT_APP_CRISP_ID,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY
};
export default config;