/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const MumsIcon = ({ color }) => (
  <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Group-49"
        transform="translate(1.000000, 1.000000)"
        fillRule="nonzero"
        stroke={color || '#393939'}
        strokeWidth="1.5">
        <path
          d="M20.8240423,1.15842129 C19.2610615,-0.377729869 16.7212676,-0.382178688 15.1520741,1.14411528 C14.6622475,0.90510028 14.1422006,0.700062046 13.5938859,0.531268606 C11.795089,-0.0225221736 10.3234791,-0.0019790959 10.1958526,0.000768704299 C7.57525508,-0.0225221736 4.55172414,1.12476139 4.55172414,2.94970669 C3.5340671,3.41509902 1.86649305,5.77721172 1.86649305,8.75818239 C1.86649305,10.7436643 0.894827618,11.7802828 0.315671357,12.221501 C0.099247705,12.386369 -0.01795034,12.6452729 0.00224091724,12.9140339 C0.0224321745,13.1829258 0.177083892,13.4222025 0.415961998,13.5541405 C0.634205082,13.6746948 0.879650893,13.7904077 1.11697582,13.9023261 C1.32683177,14.0012905 1.62504111,14.1419081 1.77046254,14.2334142 L1.77046254,15.0100385 C1.77046254,15.1162867 1.74982752,15.2200489 1.70913437,15.3185772 L1.5991253,15.5847212 C1.44846746,15.9490883 1.50393794,16.3662741 1.74303793,16.6802212 L1.74303793,16.7758708 C1.74303793,17.058807 1.8697769,17.3206767 2.08087538,17.4983242 L2.08087538,18.0102873 C2.08087538,19.1074447 2.98899382,20 4.1052819,20 C10.9517597,19.3931432 14.4428503,18.924607 14.5785536,18.5943912 C16.8213326,13.1368896 8.58266011,10.470415 9.55638885,10.9012048 C11.0721976,11.5718426 16.6102822,13.9023261 14.5785536,18.5943912 L15.4363492,18.3650153 C17.6495329,17.1993374 19.205591,15.3910668 19.9473867,13.1137074 C20.5387021,11.298371 20.5669699,9.26142237 20.0673361,7.33220494 C20.3410941,7.1686454 20.5947939,6.97337713 20.8239979,6.74810113 C22.3919933,5.20706499 22.3919933,2.69954466 20.8240423,1.15842129 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
)

export default MumsIcon
