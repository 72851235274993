import React, { Fragment, useState } from 'react'
import { Grid, Typography, Input, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import config from '../../../config'
import propTypes from './props'

const styles = {
  underline: {
    borderBottom: '1px solid #fff',
    '&:before': {
      borderBottom: '1px solid #fff'
    },
    '&:after': {
      borderBottom: '1px solid #fff',
      backgroundColor: '#fff'
    }
  }
}

const NewsLetter = props => {
  const [value, setValue] = useState('')
  const newsletter = useSelector(state => state.Users.newsletter)

  const { t, classes } = props
  return (
    <Fragment>
      {!newsletter && (
        <Grid container justify="center" className="newsletter">
          <Grid item container xs={12} justify="center" alignContent="center">
            <Grid item xs={6} className="newsletter__title">
              <Typography variant="inherit">{t('home.newsletterTitle')}</Typography>
            </Grid>
            <Grid item xs={10} className="newsletter__subtitle">
              <Typography variant="inherit">{t('home.newsletterText1')}</Typography>
            </Grid>
            <Grid item xs={10} container direction="column" justify="center" alignItems="center">
              <Grid item container xs={12} lg={5} justify="center" alignItems="center" className="newsletter__email">
                <form id="subscription-form" action={`https://manage.kmail-lists.com/subscriptions/subscribe?a=${config.KLAVIYO_KEY}&g=${config.KLAVIYO_LIST}`} method="POST" target="_blank" value={config.KLAVIYO_LIST}>
                  <Grid item xs={12} className="subscription-form-controls">
                    <input type="hidden" name="g" value={config.KLAVIYO_LIST} />
                    <Input
                      type="email"
                      value={value}
                      name="email"
                      id="k_id_email"
                      placeholder={t('home.enterEmailAddress')}
                      classes={{
                        underline: classes.underline
                      }}
                      onChange={evt => setValue(evt.target.value)}
                    />
                    <Button variant="contained" className="newsletter__button" type="submit">
                      {t('home.subscribe')}
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {newsletter && (
        <Grid container justify="center" className="newsletter">
          <Grid item container xs={12} justify="center" alignContent="center">
            <Grid item xs={6} className="newsletter__title">
              <Typography variant="inherit">{t('home.newsletterTitleThx')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

NewsLetter.propTypes = propTypes

export default withStyles(styles)(NewsLetter)
