/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react'

const ArrowRight = ({ className }) => (
  <svg className={className} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.707 5.70703L11.707 9.70703C11.3162 10.0979 10.6834 10.0974 10.293 9.70703C9.90234 9.31641 9.90234 8.68359 10.293 8.29297L12.5859 6H1C0.44725 6 0 5.55275 0 5C0 4.44725 0.44725 4 1 4H12.5859L10.293 1.70703C9.90234 1.31641 9.90234 0.683594 10.293 0.292969C10.6836 -0.0976562 11.3164 -0.0976562 11.707 0.292969L15.707 4.29297C16.0977 4.68359 16.0977 5.31641 15.707 5.70703Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
)

export default ArrowRight
