/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const BabyEssentialsIcon = ({ color }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M21.9261237,2.07555078 C21.2324194,1.38194922 20.3102735,1 19.3295132,1 C18.3491827,1 17.4275095,1.38177734 16.7342349,2.07494922 C16.4909678,2.31819531 16.3203672,2.53682031 16.1975089,2.76283594 C15.8966588,3.31635937 15.773156,3.83894531 15.6642208,4.30008594 C15.5160518,4.92708594 15.3990378,5.42230078 14.9078197,5.91343359 C14.6162516,6.20501953 14.2496106,6.3466875 13.7868403,6.3466875 C13.4539327,6.3466875 13.0776229,6.27123437 12.6679235,6.12428125 L11.0454556,4.50195312 C10.7487737,4.20529688 10.3543296,4.04192969 9.93478953,4.04192969 C9.51533542,4.04192969 9.12089127,4.20525391 8.82429539,4.50191016 L7.77198595,5.55408594 C7.15962849,6.16643359 7.15967147,7.16283594 7.7720719,7.77509766 L7.82677583,7.82979687 C6.08935657,8.00528125 4.47874902,8.76642969 3.22764901,10.0174219 C1.79112287,11.4538242 1,13.3636133 1,15.3949609 C1,17.4263086 1.79112287,19.3360977 3.22760604,20.7725 C4.66417515,22.2089453 6.57412882,23 8.60569452,23 C10.6372173,23 12.5471709,22.2089453 13.9836541,20.772543 C15.2225069,19.5338398 15.9904677,17.8990937 16.1697058,16.1720078 L16.2262575,16.2285547 C16.5228964,16.5252109 16.9172975,16.6885781 17.3367517,16.6885781 C17.7562917,16.6885781 18.1507359,16.5252109 18.4474177,16.2285977 L19.4996841,15.1764219 C20.1119986,14.5641172 20.1119986,13.5677578 19.4996841,12.9554531 L17.8762278,11.3321797 C17.5205878,10.3429102 17.5914924,9.58988281 18.088082,9.09342188 C18.5792572,8.60228906 19.0745147,8.48524219 19.7015687,8.33712891 C20.1627061,8.22816016 20.6853801,8.10471094 21.2389512,7.80384375 C21.4650723,7.68091016 21.6836731,7.51041016 21.9268543,7.26716406 C23.3579659,5.83608984 23.357708,3.50718359 21.9261237,2.07555078 Z"
        id="Path"
        stroke={color || '#393939'}
        strokeWidth="1.5"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default BabyEssentialsIcon
