import React, { Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core'
import NotBoxYet from '../../../assets/images/no-boxes-yet.png'
import propTypes from './props'

const BoxesEmpty = ({ t, type }) => (
  <Fragment>
    <Grid item container className="boxes-empty" justify="center" alignContent="center">
      <img src={NotBoxYet} alt={t('boxes.dontBoxYet')} />
      <Typography variant="inherit" className="boxes-empty__title">
        {type === 'oneOffs' ? t('boxes.dontOneOffBoxYet') : t('boxes.dontSubscriptionYet')}
      </Typography>
      <Typography variant="inherit" className="boxes-empty__desc">
        {type === 'oneOffs' ? t('boxes.boxOneOffEmptyText') : t('boxes.subscriptionEmptyText')}
      </Typography>
    </Grid>
  </Fragment>
)

BoxesEmpty.propTypes = propTypes
export default BoxesEmpty
