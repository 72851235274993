/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core'
import { translate } from 'react-i18next'
import delivery from '../../../assets/images/delivery1.svg'
import stars from '../../../assets/images/stars.svg'
import prize from '../../../assets/images/prize1.svg'

import propTypes from './props'

const YallaFeaturesBanner = ({ t }) => {
  const useStyles = makeStyles(() => ({
    root: {
      fontWeight: 600,
      marginLeft: 10,
      color: '#f3f1ef'
    }
  }))

  const classesTypography = useStyles()

  const Feature = ({ image, text }) => (
    <Grid className="yalla-features-banner__module">
      <Box className="feature__wrapper-img">
        <img src={image} className="feature__img" alt="Credit Cards" />
      </Box>
      <Typography className={`${classesTypography.root} yalla-features-banner__text`}>{text}</Typography>
    </Grid>
  )

  return (
    <Box className="yalla-features-banner">
      <Container className="yalla-features-banner__container">
        <Box className="yalla-features-banner__wrapper">
          <Feature image={delivery} text={t('yallaFeatures.shippingBanner')} />
          <Feature image={stars} text={t('yallaFeatures.trustedBanner')} />
          <Feature image={prize} text={t('yallaFeatures.rewardsBanner')} />
        </Box>
      </Container>
    </Box>
  )
}

YallaFeaturesBanner.propTypes = propTypes
export default translate('common')(YallaFeaturesBanner)
