import React from 'react'
import propTypes from './props'

const CancelIcon = () => (
  <svg width="9px" height="9px" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="07--CURRENT-BOXIES" transform="translate(-232.000000, -322.000000)" fill="#8D8C8C">
        <rect id="Rectangle-Copy" x="232" y="322" width="9" height="9" />
      </g>
    </g>
  </svg>
)

CancelIcon.propTypes = propTypes
export default CancelIcon
