import React from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import IconComponent from '../../atoms/IconComponent'
import propTypes from './props'
import Actions from '../../../actions'

const CreditCardActive = ({ t, card, history, setCardExpired }) => {
  const dispatch = useDispatch()
  const setPrevCardsQuantity = quantity => dispatch(Actions.Checkout.setPrevCardsQuantity(quantity))
  const paymentMethod = useSelector(state => state.Users.paymentMethod)

  const isCheckout = window.location.pathname === '/checkout'

  const { last4, brand, expiredCard, expMonth, expYear } = card || {}

  const isExpired = expiredCard || moment([expYear, expMonth]).diff(moment(), 'days') < 0

  if (setCardExpired) setCardExpired(isExpired)

  return (
    <>
      {card && (
        <>
          <Grid item container justify="flex-start" className="credit-card-active__description">
            {t('payment.payWith')}
          </Grid>
          <Grid
            sm={8}
            container
            alignItems="center"
            justify="space-between"
            className="credit-card-active__card-active">
            <Grid item xs={2} lg={2} container justify="center" alignItems="baseline">
              <IconComponent icon={brand || 'credit card'} />
            </Grid>
            <Grid item xs={8} lg={8} container alignItems="center" justify="space-evenly">
              <Grid item xs={12} lg={12} className="credit-card-active__number">
                <Typography variant="inherit">
                  <span>{t('payment.cardFinished')}</span>
                  <strong>{!last4 ? '0000' : last4}</strong>
                </Typography>
              </Grid>
            </Grid>
            {isExpired && (
              <p className="credit-card-active__alert expired">
                {`${t('payment.cardExpired')}
              ${expMonth}/${expYear}`}
              </p>
            )}
            {!isExpired &&
              moment([expYear, expMonth]).diff(moment(), 'days') <= moment([expYear, expMonth - 1]).daysInMonth() && (
                <p className="credit-card-active__alert warning">
                  {`${t('payment.cardWillExpire')} ${expMonth}/${expYear}`}
                </p>
              )}
          </Grid>
        </>
      )}

      <Grid item container justify="center" className="credit-card-active__my-account-description" direction="column">
        <Grid item xs={12}>
          {t(`payment.changeCard`)}
        </Grid>
        <Grid item xs={12}>
          <button
            type="button"
            onClick={() => {
              if (isCheckout) setPrevCardsQuantity(paymentMethod.length)
              history.push('/checkout/editpayment')
            }}>
            {t('account.title')}
          </button>
        </Grid>
      </Grid>
    </>
  )
}

CreditCardActive.propTypes = propTypes
export default CreditCardActive
