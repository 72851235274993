import React from 'react'
import { translate } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import propTypes from './props'
import leafs2 from '../../../assets/images/leafs2.svg'
import happyGirl from '../../../assets/images/happy-girl.svg'
import happyGirlAlone from '../../../assets/images/happy-girl-alone.svg'

const BenefitsBanner = ({ inBoxes, t }) => {
  return (
    <Grid className={`benefits-banner ${inBoxes && 'benefits-banner--in-boxes'}`} container>
      <img src={happyGirl} className="benefits-banner__happy-girl" alt="Happy girl" />
      <img src={happyGirlAlone} className="benefits-banner__happy-girl-alone" alt="Happy girl" />
      <img src={leafs2} className="benefits-banner__leafs2" alt="leafs" />
      <Grid className={`benefits-banner__benefits-container ${inBoxes && 'in-boxes'}`}>
        <Typography className="benefits-banner__title">{t('benefitsBanner.title')}</Typography>
        <Typography className="benefits-banner__benefits">{t('benefitsBanner.benefit1')}</Typography>
        <Typography className="benefits-banner__benefits">{t('benefitsBanner.benefit2')}</Typography>
        <Typography className="benefits-banner__benefits">{t('benefitsBanner.benefit3')}</Typography>
        <Typography className="benefits-banner__benefits">{t('benefitsBanner.benefit4')}</Typography>
      </Grid>
    </Grid>
  )
}

BenefitsBanner.propTypes = propTypes
export default translate('common')(BenefitsBanner)
