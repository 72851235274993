/* eslint-disable no-undef */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import propTypes from './props'
import ErrorImage from '../../../assets/images/error-payment.svg'

const CheckoutDoneError = ({ t, history }) => {
  const dispatch = useDispatch()
  const setStatus = status => dispatch(Actions.Checkout.setStatus(status))
  const setStatusPaymentError = status => dispatch(Actions.Checkout.setStatusPaymentError(status))

  return (
    <>
      <Grid container item justify="center" className="checkout-done-container">
        <Grid
          container
          item
          alignItems="flex-start"
          alignContent="flex-start"
          xs={12}
          md={6}
          lg={4}
          className="checkout-done">
          <Grid item container justify="center" className="done__image">
            <img src={ErrorImage} alt="error" />
          </Grid>
          <Grid item className="done__title">
            <span>{t('checkout.errorTitle')}</span>
          </Grid>

          <Grid item container justify="center" className="done__body">
            <Typography variant="inherit">{t('checkout.tryAgainText')}</Typography>
          </Grid>

          <Grid container item justify="center" alignItems="flex-start" className="done__link-boxes">
            <button
              type="button"
              onClick={() => {
                setStatusPaymentError(null)
                setStatus(null)
                history.push('/checkout')
              }}>
              <strong>{t('checkout.tryAgain')}</strong>
            </button>
          </Grid>
        </Grid>
      </Grid>
      <div id="background" />
    </>
  )
}

CheckoutDoneError.propTypes = propTypes
export default CheckoutDoneError
