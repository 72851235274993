import React, { Fragment } from 'react'
import RoundCloseIcon from './Icons/RoundCloseIcon'
import propTypes from './props'

const EditIcon = React.lazy(() => import('./Icons/EditIcon'))
const CancelIcon = React.lazy(() => import('./Icons/CancelIcon'))
const SvgRubbishBin = React.lazy(() => import('./Icons/RubbishBin'))
const SvgVisa = React.lazy(() => import('./Icons/Visa'))
const SvgMastercard = React.lazy(() => import('./Icons/MasterCard'))
const SvgCash = React.lazy(() => import('./Icons/SvgCash'))
const SvgDinersCard = React.lazy(() => import('./Icons/DinersCard'))
const SvgMaestroCard = React.lazy(() => import('./Icons/MaestroCard'))
const SvgDiscoverCard = React.lazy(() => import('./Icons/DiscoverCard'))
const SvgAmex = React.lazy(() => import('./Icons/Amex'))
const SvgCreditCard = React.lazy(() => import('./Icons/CreditCard'))
const Box = React.lazy(() => import('./Icons/Box'))
const AddRemove = React.lazy(() => import('./Icons/AddRemove'))
const SvgRadioOnButton = React.lazy(() => import('./Icons/Radio'))
const SvgMenu = React.lazy(() => import('./Icons/Menu'))
const CloseICon = React.lazy(() => import('./Icons/CloseIcon'))
const SvgWipes = React.lazy(() => import('./Icons/Wipes'))
const SvgDiapers = React.lazy(() => import('./Icons/Diapers'))
const Play = React.lazy(() => import('./Icons/Play'))
const InfoButton = React.lazy(() => import('./Icons/Info'))
const Shipping = React.lazy(() => import('./Icons/Shipping'))
const YallaTruck = React.lazy(() => import('./Icons/YallaTruck'))
const SearchIcon = React.lazy(() => import('./Icons/SearchIcon'))
const ArrowLeft = React.lazy(() => import('./Icons/ArrowLeft'))
const Arrow = React.lazy(() => import('./Icons/Arrow'))
const PinkArrow = React.lazy(() => import('./Icons/PinkArrow'))
const CartIcon = React.lazy(() => import('./Icons/CartIcon'))
const DiaperQuantity = React.lazy(() => import('./Icons/DiaperQuantity'))
const Trash = React.lazy(() => import('./Icons/Trash'))
const Filter = React.lazy(() => import('./Icons/Filter'))
const Lightning = React.lazy(() => import('./Icons/Lightning'))
const CurlRight = React.lazy(() => import('./Icons/CurlRight'))
const CurlLeft = React.lazy(() => import('./Icons/CurlLeft'))
const CurlBottom = React.lazy(() => import('./Icons/CurlBottom'))
const Download = React.lazy(() => import('./Icons/Download'))
const CartIcon2 = React.lazy(() => import('./Icons/CartIcon2'))
const SortBy = React.lazy(() => import('./Icons/SortBy'))
const CartIconJaka = React.lazy(() => import('./Icons/CartIconJaka'))
const CartFullIconJaka = React.lazy(() => import('./Icons/CartFullIconJaka'))
const UserIcon = React.lazy(() => import('./Icons/UserIcon'))
const PhoneIcon = React.lazy(() => import('./Icons/PhoneIcon'))
const SearchJakaIcon = React.lazy(() => import('./Icons/SearchJakaIcon'))
const ArrowRight = React.lazy(() => import('./Icons/ArrowRight'))
const TrashJaka = React.lazy(() => import('./Icons/TrashJaka'))
const CartMobileFullJaka = React.lazy(() => import('./Icons/CartMobileFullJaka'))
const EditJaka = React.lazy(() => import('./Icons/EditJaka'))
const LockIconJaka = React.lazy(() => import('./Icons/LockIconJaka'))
const AddMoreProducts = React.lazy(() => import('./Icons/AddMoreProducts'))
const FloppyDisk = React.lazy(() => import('./Icons/FloppyDisk'))
const BulletCheck = React.lazy(() => import('./Icons/BulletCheck'))
const TruckJaka = React.lazy(() => import('./Icons/TruckJaka'))
const FiltersIcon = React.lazy(() => import('./Icons/FiltersIcon'))

// Side menu Icons
const HomeIcon = React.lazy(() => import('./Icons/SideMenu/Home'))
const MyBoxesIcon = React.lazy(() => import('./Icons/SideMenu/MyBoxes'))
const MyAccountIcon = React.lazy(() => import('./Icons/SideMenu/MyAccount'))
const DiapersIcon = React.lazy(() => import('./Icons/SideMenu/Diapers'))
const WipesIcon = React.lazy(() => import('./Icons/SideMenu/Wipes'))
const BabyEssentialsIcon = React.lazy(() => import('./Icons/SideMenu/BabyEssentials'))
const MumsIcon = React.lazy(() => import('./Icons/SideMenu/Mums'))
const BundleIcon = React.lazy(() => import('./Icons/SideMenu/Bundle'))
const PromotionsIcon = React.lazy(() => import('./Icons/SideMenu/Promotions'))
const BlogIcon = React.lazy(() => import('./Icons/SideMenu/Blog'))
const AboutUsIcon = React.lazy(() => import('./Icons/SideMenu/AboutUs'))
const FaqsIcon = React.lazy(() => import('./Icons/SideMenu/Faqs'))
const ContactUsIcon = React.lazy(() => import('./Icons/SideMenu/ContactUs'))
const LoginIcon = React.lazy(() => import('./Icons/SideMenu/Login'))
const LogoutIcon = React.lazy(() => import('./Icons/SideMenu/Logout'))

const getSideCartIcon = (type, color) => {
  switch (type) {
    case 'home':
      return <HomeIcon />
    case 'my-boxes':
      return <MyBoxesIcon color={color} />
    case 'my-account':
      return <MyAccountIcon color={color} />
    case 'diapers':
      return <DiapersIcon color={color} />
    case 'wipes':
      return <WipesIcon color={color} />
    case 'baby-essentials':
      return <BabyEssentialsIcon color={color} />
    case 'mums':
      return <MumsIcon color={color} />
    case 'bundle-offers':
      return <BundleIcon color={color} />
    case 'promotions':
      return <PromotionsIcon color={color} />
    case 'blog':
      return <BlogIcon />
    case 'about-us':
      return <AboutUsIcon />
    case 'faqs':
      return <FaqsIcon />
    case 'contact-us':
      return <ContactUsIcon color={color} />
    case 'login':
      return <LoginIcon />
    case 'logout':
      return <LogoutIcon />
    case 'cart2':
      return <CartIcon2 />
    case 'sortby':
      return <SortBy />

    default:
      return <BundleIcon color={color} />
  }
}

const IconComponent = ({
  t,
  icon,
  fill,
  stroke,
  strokeWidth,
  height,
  onClick,
  remove,
  border,
  width,
  className,
  color,
  onKeyDown,
  sideMenuType,
  borderFill
}) => (
  <Fragment>
    {icon === 'arrow' && <Arrow />}
    {icon === 'pinkArrow' && <PinkArrow />}
    {icon === 'cart' && <CartIcon />}
    {icon === 'cart2' && <CartIcon2 className={className} />}
    {icon === 'sortby' && <SortBy />}
    {icon === 'edit' && <EditIcon fill={fill} />}
    {icon === 'cancel' && <CancelIcon />}
    {icon === 'remove' && <SvgRubbishBin fill={fill} />}
    {icon === 'visa' && <SvgVisa />}
    {icon === 'discover' && <SvgDiscoverCard />}
    {icon === 'maestro' && <SvgMaestroCard />}
    {icon === 'diners club' && <SvgDinersCard />}
    {icon === 'mastercard' && <SvgMastercard />}
    {icon === 'cash' && <SvgCash width={width} height={height} />}
    {icon === 'american express' && <SvgAmex />}
    {icon === 'credit card' && <SvgCreditCard />}
    {icon === 'box' && <Box fill={fill} stroke={stroke} strokeWidth={strokeWidth} height={height} />}
    {icon === 'addRemove' && (
      <AddRemove remove={remove} fill={fill} border={border} height={height} onClick={onClick} className={className} />
    )}
    {icon === 'radio' && <SvgRadioOnButton fill={fill} borderFill={borderFill} />}
    {icon === 'menu' && <SvgMenu onClick={onClick} />}
    {icon === 'close' && (
      <CloseICon className={className} onClick={onClick} width={width} height={height} color={fill || '#8D8C8C'} />
    )}
    {icon === 'roundClose' && (
      <RoundCloseIcon
        className={className}
        onKeyDown={onKeyDown}
        onClick={onClick}
        width={width}
        height={height}
        color={fill}
      />
    )}
    {icon === 'diaper' && <SvgDiapers height={height} />}
    {icon === 'wipe' && <SvgWipes height={height} />}
    {icon === 'play' && <Play fill={fill} />}
    {icon === 'infoButton' && <InfoButton t={t} onClick={onClick} className={className} color={color} />}
    {icon === 'shipping' && <Shipping fill={fill} />}
    {icon === 'truck' && <YallaTruck className={className} />}
    {icon === 'search' && <SearchIcon onClick={onClick} width={width} height={height} color={fill} />}
    {icon === 'arrowLeft' && <ArrowLeft onClick={onClick} width={width} height={height} color={fill} />}
    {icon === 'diaperQuantity' && <DiaperQuantity />}
    {icon === 'trash' && <Trash />}
    {icon === 'filter' && <Filter />}
    {icon === 'lightning' && <Lightning fill={fill} />}
    {icon === 'curlRight' && <CurlRight />}
    {icon === 'curlLeft' && <CurlLeft />}
    {icon === 'curlBottom' && <CurlBottom />}
    {icon === 'download' && <Download />}
    {icon === 'sideMenu' && getSideCartIcon(sideMenuType, color)}
    {icon === 'cartJaka' && <CartIconJaka />}
    {icon === 'cartFullJaka' && <CartFullIconJaka />}
    {icon === 'user' && <UserIcon />}
    {icon === 'phone' && <PhoneIcon />}
    {icon === 'searchJaka' && <SearchJakaIcon />}
    {icon === 'arrowRight' && <ArrowRight className={className} />}
    {icon === 'trashJaka' && <TrashJaka />}
    {icon === 'cartMobileFullJaka' && <CartMobileFullJaka />}
    {icon === 'editJaka' && <EditJaka />}
    {icon === 'LockIconJaka' && <LockIconJaka className={className} />}
    {icon === 'AddMoreProducts' && <AddMoreProducts />}
    {icon === 'floppyDisk' && <FloppyDisk />}
    {icon === 'bulletCheck' && <BulletCheck />}
    {icon === 'truckJaka' && <TruckJaka fill={fill} />}
    {icon === 'filtersIcon' && <FiltersIcon />}
  </Fragment>
)

IconComponent.propTypes = propTypes
export default IconComponent
