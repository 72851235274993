import _toConsumableArray from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { haveOfferTag } from '../utils/helpers';
/* eslint-disable no-case-declarations */
var initialState = {
  activeStep: 0,
  babyData: null,
  selectedSamplings: [],
  selectedDiapers: [],
  selectedWipes: [],
  upselling: [],
  shippingInformation: null,
  paymentToken: null,
  paymentCardToken: null,
  paymentVerifyCardToken: null,
  payWithNewCard: false,
  payWithCOD: false,
  shippingDate: '',
  totalPrice: 0,
  totalProducts: 0,
  discount: 0,
  processing: false,
  promoCode: null,
  prevPromoCode: null,
  isEdition: false,
  taxAndDiscount: {},
  statusPaymentError: {},
  thisBox: {},
  boxInEdition: null,
  checkedToSubscribe: false,
  boxTypePurchase: null,
  prevCardsQuantity: 0
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'CHECKOUT_SET_UPSELLING':
      return _objectSpread({}, state, {
        upselling: _toConsumableArray(action.payload.data)
      });
    case 'CHECKOUT_SET_STATUS':
      return _objectSpread({}, state, {
        status: action.payload
      });
    case 'REMOVE_CARD_TOKEN_DATA':
      return _objectSpread({}, state, {
        cardTokenData: null
      });
    case 'CHECKOUT_SET_STATUS_PAYMENT_ERROR':
      return _objectSpread({}, state, {
        statusPaymentError: action.payload
      });
    case 'CHECKOUT_SET_ACTIVE_STEP':
      return _objectSpread({}, state, {
        activeStep: action.payload.step
      });
    case 'RESET_CHECKOUT_PROCESS':
      return _objectSpread({}, state, {
        processing: false,
        status: null
      });
    case 'CHECKOUT_INIT_PROCESS':
      return _objectSpread({}, state, {
        processing: true,
        status: null
      });
    case 'CHECKOUT_END_PROCESS':
      return _objectSpread({}, state, {
        processing: false
      });
    case 'CHECKOUT_SET_SHIPPING_DATE':
      return _objectSpread({}, state, {
        shippingDate: action.payload
      });
    case 'CHECKOUT_SET_PAYMENT_TOKEN':
      return _objectSpread({}, state, {
        paymentToken: action.payload
      });

    // cardTokenData - due to the replacement of checkout.com by stripe we have to set this property in Checkout to sendo to the backend 08-02-2024
    case 'CHECKOUT_SET_PAYMENT_CARD_TOKEN':
      return _objectSpread({}, state, {
        paymentCardToken: action.payload.id,
        cardTokenData: action.payload.card
      });
    case 'CHECKOUT_SET_PAYMENT_CARD_VERIFY_CARD_TOKEN':
      return _objectSpread({}, state, {
        paymentVerifyCardToken: action.payload
      });
    case 'CHECKOUT_UPSELLING_ADD':
      var upsellingInCart = state.upselling.find(function (ups) {
        return +ups.shopifyVariantId === action.payload.variantId;
      });
      if (state.isEdition && upsellingInCart) {
        return _objectSpread({}, state, {
          upselling: [].concat(_toConsumableArray(state.upselling), [upsellingInCart])
        });
      }
      return _objectSpread({}, state, {
        upselling: [].concat(_toConsumableArray(state.upselling), [_objectSpread({}, action.payload)])
      });
    case 'CHECKOUT_UPSELLING_REMOVE':
      return _objectSpread({}, state, {
        upselling: action.variantId ? state.upselling.filter(function (up) {
          return !(up.variantId === action.variantId || +up.shopifyVariantId === action.variantId);
        }) : state.upselling.filter(function (up, index) {
          return index !== action.index;
        })
      });
    case 'CHECKOUT_SELECT_SAMPLINGS':
      return _objectSpread({}, state, {
        selectedSamplings: [].concat(_toConsumableArray(state.selectedSamplings), [action.payload])
      });
    case 'REMOVE_SAMPLE':
      return _objectSpread({}, state, {
        selectedSamplings: _toConsumableArray(state.selectedSamplings.filter(function (sample) {
          return sample.variant_id !== action.payload || +sample.shopifyVariantId === action.payload;
        }))
      });
    case 'CHECKOUT_ADD_DIAPER':
      var diaperInCart = state.selectedDiapers.find(function (diaper) {
        return +diaper.shopifyVariantId === action.payload.variant_id;
      });
      if (state.isEdition && diaperInCart) {
        return _objectSpread({}, state, {
          selectedDiapers: [].concat(_toConsumableArray(state.selectedDiapers), [_objectSpread({}, diaperInCart)])
        });
      }
      return _objectSpread({}, state, {
        selectedDiapers: [].concat(_toConsumableArray(state.selectedDiapers), [_objectSpread({}, action.payload)])
      });
    case 'CHECKOUT_REMOVE_DIAPERS':
      return _objectSpread({}, state, {
        selectedDiapers: action.variant_id ? state.selectedDiapers.filter(function (diap) {
          return !(diap.variant_id === action.variant_id || +diap.shopifyVariantId === action.variant_id);
        }) : state.selectedDiapers.filter(function (diap, index) {
          return index !== action.index;
        })
      });
    case 'CHECKOUT_SUBSCRIBE_DIAPER':
      {
        var diapers = state.selectedDiapers.map(function (item) {
          if (+item.shopifyVariantId === action.variant_id || +item.variant_id === action.variant_id) return _objectSpread({}, item, {
            purchase_type: item.purchase_type === 'SUBSCRIPTION' || item.purchaseType === 'SUBSCRIPTION' ? 'ONE_TIME' : 'SUBSCRIPTION',
            purchaseType: item.purchaseType === 'SUBSCRIPTION' || item.purchase_type === 'SUBSCRIPTION' ? 'ONE_TIME' : 'SUBSCRIPTION'
          });
          return item;
        });
        return _objectSpread({}, state, {
          selectedDiapers: diapers
        });
      }
    case 'CHECKOUT_ADD_WIPE':
      {
        var wipeInCart = state.selectedWipes.find(function (wipe) {
          return +wipe.shopifyVariantId === action.payload.variant_id;
        });
        if (state.isEdition && wipeInCart) {
          return _objectSpread({}, state, {
            selectedWipes: [].concat(_toConsumableArray(state.selectedWipes), [_objectSpread({}, wipeInCart, {
              purchase_type: action.payload.purchase_type === 'SUBSCRIPTION' ? 'SUBSCRIPTION' : 'ONE_TIME'
            })])
          });
        }
        return _objectSpread({}, state, {
          selectedWipes: [].concat(_toConsumableArray(state.selectedWipes), [_objectSpread({}, action.payload, {
            purchase_type: action.payload.purchase_type === 'SUBSCRIPTION' ? 'SUBSCRIPTION' : 'ONE_TIME'
          })])
        });
      }
    case 'CHECKOUT_REMOVE_WIPE':
      return _objectSpread({}, state, {
        selectedWipes: action.variant_id ? state.selectedWipes.filter(function (wipe) {
          return !(wipe.variant_id === action.variant_id || +wipe.shopifyVariantId === +action.variant_id);
        }) : state.selectedWipes.filter(function (wipe, index) {
          return index !== action.index;
        })
      });
    case 'CHECKOUT_SUBSCRIBE_WIPE':
      {
        var wipes = state.selectedWipes.map(function (item) {
          if (+item.variant_id === action.variant_id || +item.shopifyVariantId === action.variant_id) return _objectSpread({}, item, {
            purchase_type: item.purchase_type === 'SUBSCRIPTION' || item.purchaseType === 'SUBSCRIPTION' ? 'ONE_TIME' : 'SUBSCRIPTION',
            purchaseType: item.purchaseType === 'SUBSCRIPTION' || item.purchase_type === 'SUBSCRIPTION' ? 'ONE_TIME' : 'SUBSCRIPTION'
          });
          return item;
        });
        return _objectSpread({}, state, {
          selectedWipes: wipes
        });
      }
    case 'CHECKOUT_SUBSCRIBE_UPSELLING':
      {
        var upsellings = state.upselling.map(function (item) {
          if (+item.variantId === action.variantId || +item.shopifyVariantId === action.variantId) return _objectSpread({}, item, {
            purchaseType: item.purchase_type === 'SUBSCRIPTION' || item.purchaseType === 'SUBSCRIPTION' ? 'ONE_TIME' : 'SUBSCRIPTION',
            variantId: +item.shopifyVariantId || action.variantId
          });
          return item;
        });
        return _objectSpread({}, state, {
          upselling: upsellings
        });
      }
    case 'CHECKOUT_SUBSCRIPTION_ALL_PRODUCTS':
      {
        var _diapers = state.selectedDiapers.map(function (item) {
          return _objectSpread({}, item, {
            purchase_type: action.purchaseType
          });
        });
        var _wipes = state.selectedWipes.map(function (item) {
          var offer = haveOfferTag(item);
          return _objectSpread({}, item, {
            purchase_type: !offer ? action.purchaseType : 'ONE_TIME'
          });
        });
        var _upsellings = state.upselling.map(function (item) {
          var variantSelected = item.variants.find(function (variant) {
            return variant.id === +item.variantId;
          });
          var offer = item.isOffer || item.isOffer;
          var setSubscription = +variantSelected.appliedDiscount > 0 && !offer;
          return _objectSpread({}, item, {
            purchaseType: setSubscription ? action.purchaseType : 'ONE_TIME'
          });
        });
        return _objectSpread({}, state, {
          selectedDiapers: _diapers,
          selectedWipes: _wipes,
          upselling: _upsellings
        });
      }
    case 'CHECKOUT_SET_CUSTOMER_INFO':
      return _objectSpread({}, state, {
        customerInfo: action.payload
      });
    case 'CHECKOUT_SET_CUSTOMER_ADDRESS':
      return _objectSpread({}, state, {
        shippingInformation: action.payload
      });
    case 'CHECKOUT_SET_PAY_NEW_CARD':
      return _objectSpread({}, state, {
        payWithNewCard: action.payload
      });
    case 'CHECKOUT_SET_PAY_CASH':
      return _objectSpread({}, state, {
        payWithCOD: action.payload
      });
    case 'CHECKOUT_SET_BABY_DATA':
      return _objectSpread({}, state, {
        babyData: _objectSpread({}, action.payload)
      });
    case 'CHECKOUT_FLUSH':
      return _objectSpread({}, state, initialState, {
        taxAndDiscount: state.taxAndDiscount,
        checkedToSubscribe: state.checkedToSubscribe,
        boxTypePurchase: state.boxTypePurchase
      });
    case 'CHECKOUT_SET_CONTEXT_DATA':
      var _action$payload$data = action.payload.data,
        codPercent = _action$payload$data.codPercent,
        shippingCost = _action$payload$data.shippingCost,
        vatPercent = _action$payload$data.vatPercent,
        menuItems = _action$payload$data.menuItems,
        codAmount = _action$payload$data.codAmount,
        bestSellerAddons = _action$payload$data.bestSellerAddons;
      return _objectSpread({}, state, {
        taxAndDiscount: {
          codPercent: codPercent,
          shippingCost: shippingCost,
          vatPercent: vatPercent,
          codAmount: codAmount
        },
        menuItems: menuItems,
        bestSellerAddons: bestSellerAddons
      });
    case 'CHECKOUT_SET_DISCOUNT':
      return _objectSpread({}, state, {
        promoCode: _objectSpread({}, action.payload.data)
      });
    case 'CHECKOUT_REMOVE_DISCOUNT':
      return _objectSpread({}, state, {
        promoCode: null,
        discount: 0
      });
    case 'CHECKOUT_EDIT_SUBSCRIPTION':
      return _objectSpread({}, state, {
        isEdition: true
      }, action.subscription, {
        prevPromoCode: action.subscription.promoCode
      });
    case 'CHECKOUT_SET_THIS_BOX':
      return _objectSpread({}, state, {
        thisBox: action.box
      });
    case 'CHECKOUT_SET_BOX_IN_EDITION':
      return _objectSpread({}, state, {
        boxInEdition: action.box
      });
    case 'SET_PAYMENT_METHOD':
      return _objectSpread({}, state, {
        payWithCOD: action.payload,
        paymentType: action.payload ? 'COD' : 'CREDIT'
      });
    case 'SET_CHECK_TO_SUBSCRIBE':
      return _objectSpread({}, state, {
        checkedToSubscribe: action.payload
      });
    case 'SET_INCOMING_BOX_INFO':
      return _objectSpread({}, state, {
        boxInfo: action.payload
      });
    case 'UPDATE_CART_PRODUCTS':
      return _objectSpread({}, state, {
        upselling: action.payload.upselling,
        selectedDiapers: action.payload.selectedDiapers,
        selectedWipes: action.payload.selectedWipes
      });
    case 'SET_TOTAL_BEFORE_TAX':
      return _objectSpread({}, state, {
        totalBeforeTax: action.payload
      });
    case 'SET_BOX_TYPE_PURCHASE':
      return _objectSpread({}, state, {
        boxTypePurchase: action.payload
      });
    case 'SET_SHOW_ONEOFFS':
      return _objectSpread({}, state, {
        showOneOffs: action.payload
      });
    case 'SET_PREV_CARDS_QUANTITY':
      return _objectSpread({}, state, {
        prevCardsQuantity: action.payload
      });
    case 'SET_SUBSCRIBE_ALL_PRODUCTS':
      return _objectSpread({}, state, {
        subscribeAllProducts: action.payload
      });
    case 'KLAVIYO_TRACK':
      return _objectSpread({}, state, {
        klaviyoEvent: action.payload.event
      });
    default:
      return state;
  }
};
export default reducer;