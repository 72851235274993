import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'
import FlatButton from '../FlatButton'

const FirstModalCancel = ({
  t,
  handleClose,
  isOneOffBox,
  isSubscribableBox,
  handleOptionSelected,
  hasChangedToOneOff,
  saveNextButton,
  closeAlertModal,
  activeSubscriptions,
  history
}) => {
  const getTitle = () => {
    switch (true) {
      case hasChangedToOneOff:
        return t('boxes.subscriptionToOneOff')

      case isOneOffBox && !hasChangedToOneOff:
        return t('boxes.loseOneOffBenefits1')

      default:
        return t('boxes.loseBenefits')
    }
  }

  return (
    <Grid
      item
      container
      xs={12}
      alignItems="center"
      direction="column"
      className="yalla-modal__container first-modal-cancel">
      <Typography className="title">{getTitle()}</Typography>

      {isOneOffBox && !activeSubscriptions.length && isSubscribableBox && (
        <Typography className="subtitle">{t('boxes.loseOneOffBenefits2')}</Typography>
      )}

      {(!isOneOffBox || (isOneOffBox && !activeSubscriptions.length)) && (
        <Grid className="first-modal-cancel__benefits">
          <Grid item xs={12}>
            {!isOneOffBox && (
              <Grid container direction="row" wrap="nowrap" spacing={2}>
                <Grid item>
                  <IconComponent icon="bulletCheck" />
                </Grid>
                <Grid item>{t('yallaFeatures.rewards')}</Grid>
              </Grid>
            )}
            <Grid container direction="row" wrap="nowrap" spacing={2}>
              <Grid item>
                <IconComponent icon="bulletCheck" />
              </Grid>
              <Grid item>
                <strong>
                  {t('boxes.lifetime')}
                  {t('boxes.discount')}
                </strong>
                {t('boxes.allProducts')}
              </Grid>
            </Grid>
            <Grid container direction="row" wrap="nowrap" spacing={2}>
              <Grid item>
                <IconComponent icon="bulletCheck" />
              </Grid>
              <Grid item>
                <strong>
                  {t('boxes.freeProducts')}
                  {t('boxes.samplesGifts')}
                </strong>
              </Grid>
            </Grid>
            <Grid container direction="row" wrap="nowrap" spacing={2}>
              <Grid item>
                <IconComponent icon="bulletCheck" />
              </Grid>
              <Grid item>
                <strong>{t('boxes.freeDelivery')}</strong>
              </Grid>
            </Grid>
            <Grid container direction="row" wrap="nowrap" spacing={2}>
              <Grid item>
                <IconComponent icon="bulletCheck" />
              </Grid>
              <Grid item>
                <strong>{t('boxes.freeExchange')}</strong>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item container justify="center">
        <Grid item xs={12} md={6}>
          {isOneOffBox ? (
            <FlatButton
              title={!activeSubscriptions.length && isSubscribableBox ? t('boxes.subscribeBox') : t('boxes.keepBox')}
              classes="flat-button--green-jaka"
              onClick={() => {
                if (!activeSubscriptions.length && isSubscribableBox) handleOptionSelected('Subscribe Box')
                else handleClose()
              }}
            />
          ) : (
            <FlatButton
              title={t('boxes.staySubscribed1')}
              classes="flat-button--green-jaka"
              onClick={() => {
                if (!hasChangedToOneOff) {
                  handleClose()
                } else {
                  history.push('boxes')
                  closeAlertModal()
                }
              }}
            />
          )}
        </Grid>
      </Grid>
      {hasChangedToOneOff && (
        <Box mt={2}>
          <FlatButton
            title={hasChangedToOneOff ? t('boxes.convertToOneOff') : t('boxes.yesUnsubscribe')}
            classes="flat-button--link"
            onClick={() => {
              saveNextButton()
            }}
          />
        </Box>
      )}
    </Grid>
  )
}

FirstModalCancel.propTypes = propTypes
export default FirstModalCancel
