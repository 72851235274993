/* eslint-disable react/prop-types */
import React from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import ybimage from '../../../assets/images/ybimage.png'

const CircleItem = ({ item, onClick, collectionHandle }) => {
  const { handle, image, title } = item
  return (
    <Grid key={handle} direction="column" alignContent="center" className="circle-item" onClick={() => onClick(item)}>
      <img
        src={(image && image.url) || ybimage}
        alt={title}
        className={`circle-item__image ${handle === collectionHandle ? 'circle-item__image--selected' : ''}`}
      />
      <Box className="circle-item__title">
        <Typography>{title}</Typography>
      </Box>
    </Grid>
  )
}

export default CircleItem
