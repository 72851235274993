/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const LockIconJaka = ({ className }) => (
  <svg className={className} width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 11.375H18.6875V7.3125C18.6875 3.28016 15.4073 0 11.375 0C7.34266 0 4.0625 3.28016 4.0625 7.3125V11.375H3.25C1.45509 11.375 0 12.83 0 14.625V22.75C0 24.5448 1.45509 26 3.25 26H19.5C21.2949 26 22.75 24.5448 22.75 22.75V14.625C22.75 12.83 21.2949 11.375 19.5 11.375ZM13 19.5C13 20.3974 12.2725 21.125 11.375 21.125C10.4775 21.125 9.75 20.3974 9.75 19.5V17.875C9.75 16.9776 10.4775 16.25 11.375 16.25C12.2725 16.25 13 16.9776 13 17.875V19.5ZM15.4375 11.375H7.3125V7.3125C7.3125 5.07178 9.13509 3.25 11.375 3.25C13.6149 3.25 15.4375 5.07178 15.4375 7.3125V11.375Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
)

export default LockIconJaka
