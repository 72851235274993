/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { translate } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ErrorImage from '../../assets/images/error-payment.svg'
import { FIRST_COLLECTION } from '../../utils/urls'

const ErrorPage = ({ t }) => {
  const history = useHistory()

  const redirect = () => {
    localStorage.clear()
    history.push(`/products/${FIRST_COLLECTION}`)
    window.location.reload()
  }

  setTimeout(redirect, 5000)

  return (
    <>
      <Grid container item justify="center" className="checkout-done-container">
        <Grid
          container
          item
          alignItems="flex-start"
          alignContent="flex-start"
          xs={12}
          md={6}
          lg={4}
          className="checkout-done">
          <Grid item container justify="center" className="done__image">
            <img src={ErrorImage} alt="error" />
          </Grid>
          <Grid item className="done__title">
            <span>{t('errorPage.title')}</span>
          </Grid>

          <Grid item container justify="center" className="done__body">
            <Typography variant="inherit">{t('errorPage.subTitle')}</Typography>
          </Grid>

          <Grid container item justify="center" alignItems="flex-start" className="done__link-boxes">
            <button type="button" onClick={redirect}>
              <strong>{t('errorPage.button')}</strong>
            </button>
          </Grid>
        </Grid>
      </Grid>
      <div id="background" />
    </>
  )
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {}

  render() {
    if (this.state.hasError) {
      return <ErrorPage t={this.props.t} />
    }

    return this.props.children
  }
}

export default translate('common')(ErrorBoundary)
