/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { translate } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import {
  ImageCheck,
  haveOfferTag,
  formatMoney,
  gtmSingleProduct,
  getAddedAmount,
  gtmSelectItem,
  productInEditionQty
} from '../../../utils/helpers'
import OkIcon from '../../../assets/images/YB-ok-green-icon.svg'
import SpecialPriceLabel from '../../../assets/images/special-price-label.svg'
import QuantityModifier from '../../molecules/QuantityModifier'
import FlatButton from '../../molecules/FlatButton'
import ArrivesTodayLabel from '../../atoms/ArrivesTodayLabel'
import OutOfStockLabel from '../../atoms/OutOfStockLabel'

const WipeCard = ({ t, key, cart, wipe, currentLanguage, getDetailPageURI }) => {
  const { title, images, variants, options } = wipe
  const [showOk, setShowOk] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState(variants[0])
  const { userData } = useSelector(state => state.Users)
  const { inStock } = useSelector(state => state.Products.filters)
  const offerTag = haveOfferTag(wipe)

  const selectVariant = size => {
    const variant = variants.find(item => item.option1 === size)
    setSelectedVariant(variant)
  }
  const { price, compare_at_price, appliedDiscount } = selectedVariant

  const selectedVariantImage =
    images && images.length > 0 && images.find(img => selectedVariant && img.id === selectedVariant.image_id)

  useEffect(() => {
    setSelectedVariant(variants[0])
  }, [inStock])

  const dispatch = useDispatch()
  const addWipe = data => dispatch(Actions.Checkout.addWipe(data))
  const removeWipe = (index, id, subscription) => {
    dispatch(Actions.Checkout.gtmRemoveProducts({ ...wipe, variantId: selectedVariant.id, purchaseType: subscription }))
    dispatch(Actions.Checkout.removeWipe(index, id))
  }

  const [alertOutStock, setAlertOutStock] = useState(false)
  const [outOfStock, setOutOfStock] = useState(selectedVariant.inventory_quantity <= 0)

  const handleAlertOutStock = boolean => {
    setAlertOutStock(boolean)
  }
  useEffect(() => {
    setOutOfStock(selectedVariant.inventory_quantity + productInEditionQty(selectedVariant, cart) <= 0)
  }, [wipe, selectedVariant])
  const handleQuantityChange = type => {
    const purchaseType = cart.find(
      item => +item.variant_id === selectedVariant.id || +item.shopifyVariantId === selectedVariant.id
    ).purchase_type
    const bubblesAnimation = document.getElementById('cart-button')
    switch (type) {
      case 'add':
        addWipe({
          ...wipe,
          variant_id: selectedVariant.id,
          purchase_type: purchaseType || 'ONE_TIME',
          price,
          sku: selectedVariant.sku
        })
        bubblesAnimation.classList.remove('animate')
        bubblesAnimation.classList.add('animate')
        setTimeout(() => {
          setShowOk(false)
          bubblesAnimation.classList.remove('animate')
        }, 500)
        setShowOk(true)
        break

      case 'remove':
        for (let index = cart.length - 1; index >= 0; index -= 1) {
          if (+cart[index].variant_id === selectedVariant.id || +cart[index].shopifyVariantId === selectedVariant.id) {
            removeWipe(index, null, purchaseType || 'ONE_TIME')
            break
          }
        }
        break

      default:
        break
    }
  }

  const pushToPdp = () => {
    gtmSelectItem(wipe, selectedVariant)
    getDetailPageURI(wipe.id, title)
  }

  const getSelectedVariantQuantity = variant => {
    let q = 0
    cart.forEach(up => {
      if (+up.variant_id === variant.id || +up.shopifyVariantId === variant.id) {
        q += 1
      }
    })
    return q
  }

  const selectedVariantQuantity = getSelectedVariantQuantity(selectedVariant)

  return (
    <Fragment>
      <Grid
        item
        container
        xs={2}
        sm={3}
        justify="center"
        className="filter__item"
        key={key}
        onClick={() =>
          gtmSingleProduct(wipe, compare_at_price, selectedVariant.sku, userData, { list: 'wipe' }, 'productClick')
        }>
        {showOk && (
          <div className="filter__item__okey">
            <img src={OkIcon} alt="Ok" />
          </div>
        )}
        {offerTag && (
          <>
            <div className="filter__item__offer">
              <img src={SpecialPriceLabel} alt="offer" />
            </div>
            <Grid container item xs={6} direction="row" className="filter__item__special-price-text">
              <Typography variant="inherit">
                <span>{t('default.specialPrice')}</span>
              </Typography>
            </Grid>
          </>
        )}
        {alertOutStock && <OutOfStockLabel inventoryQuantity={selectedVariant.inventory_quantity} t={t} />}
        <Grid item container>
          <ArrivesTodayLabel t={t} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="center"
          direction="row"
          alignItems="center"
          onClick={() => pushToPdp()}
          className="filter__item__image-product-detail">
          <img src={selectedVariantImage ? selectedVariantImage.src : ImageCheck(wipe).src} alt={wipe.title} />
        </Grid>
        <Grid container item xs={12} className="filter__item__desc" direction="column">
          <Typography variant="inherit">{title}</Typography>
        </Grid>
        <Grid container item className="filter__item__variants">
          {options &&
            options[0].values.map(size => (
              <button
                type="button"
                key={size}
                className={`filter__item__variants__variant-chip${
                  selectedVariant && size === selectedVariant.option1 ? '__selected' : ''
                }`}
                onClick={() => selectVariant(size)}>
                <Grid container item direction="column">
                  <span>
                    <strong>{`${size} `}</strong>
                    {'u.'}
                  </span>
                </Grid>
              </button>
            ))}
        </Grid>
        <Grid container item xs={12} className="filter__item__info" justify="flex-start">
          <Grid
            container
            item
            className={`filter__item__info__amount-card ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
            {compare_at_price > 0 && (
              <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
                {`${t('default.aed')} ${formatMoney(
                  price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
                )}`}
              </Typography>
            )}
            <Grid alignItems="center">
              <Typography variant="inherit" className="subscription-price">
                {`${t('default.aed')} ${formatMoney(getAddedAmount(compare_at_price, price, selectedVariantQuantity))}`}
              </Typography>
              {appliedDiscount > 0 && (
                <Typography variant="inherit" className="subscription-text">
                  <strong>
                    {`${t('default.aed')} ${formatMoney(
                      appliedDiscount * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
                    )}
                  ${t('default.off')} `}
                  </strong>
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item container justify="center">
            {selectedVariantQuantity === 0 ? (
              <FlatButton
                idButton="add-wipe"
                cartIcon={outOfStock ? '' : 'cart2'}
                iconClass="cart-icon-2"
                classes={`filter__item__info__add-button ${outOfStock ? 'out-of-stock' : ''}`}
                onClick={() => {
                  if (!outOfStock) {
                    addWipe({
                      ...wipe,
                      variant_id: selectedVariant.id,
                      purchase_type: 'ONE_TIME',
                      price,
                      sku: selectedVariant.sku
                    })
                    const bubblesAnimation = document.getElementById('cart-button')
                    bubblesAnimation.classList.remove('animate')
                    bubblesAnimation.classList.add('animate')
                    setTimeout(() => {
                      setShowOk(false)
                      bubblesAnimation.classList.remove('animate')
                    }, 500)
                    setShowOk(true)
                  }
                }}
                title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
              />
            ) : (
              <QuantityModifier
                quantity={selectedVariantQuantity}
                setQuantity={handleQuantityChange}
                variantSelected={selectedVariant}
                handleAlertOutStock={handleAlertOutStock}
                alertOutStock={alertOutStock}
                stockControl
                cart={cart}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

WipeCard.propTypes = propTypes

export default translate('common')(WipeCard)
