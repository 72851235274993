import React, { useState } from 'react'
import { Grid, Tooltip, ClickAwayListener } from '@material-ui/core'
import propTypes from './props'
import IconComponent from '../IconComponent'
import { hasDeliveryToday } from '../../../utils/helpers'

const ArrivesTodayLabel = ({ t }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <Grid container alignItems="center" className="fast-delivery-label">
      <span className="background">{hasDeliveryToday() ? t('cart.arrivesToday1') : t('cart.arrivesTomorrow')}</span>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-end"
          arrow
          title={t('cart.arrivesLabel')}>
          <div>
            <IconComponent icon="infoButton" t={t} className="arrives-label" color="grey" onClick={handleTooltipOpen} />
          </div>
        </Tooltip>
      </ClickAwayListener>
    </Grid>
  )
}

ArrivesTodayLabel.propTypes = propTypes
export default ArrivesTodayLabel
