import React from 'react'
import { Grid, Box, Link } from '@material-ui/core'
import IconComponent from '../../atoms/IconComponent'
import LogoAr from '../../../assets/images/logo-arabic.svg'
import Logo from '../../../assets/images/logo-pink.svg'

import propTypes from './props'

const LogoMenu = ({ currentLanguage, toggleDrawer }) => {
  return (
    <Grid item className="logo-menu">
      <IconComponent icon="menu" onClick={toggleDrawer(true, 'menu')} />
      <Link target="_self" href="https://yallababy.com/" rel="noreferrer">
        <Box pt={1}>
          <img src={currentLanguage === 'AR' ? LogoAr : Logo} alt="Yalla baby logo" className="brand-logo" />
        </Box>
      </Link>
    </Grid>
  )
}

LogoMenu.propTypes = propTypes
export default LogoMenu
