import React from 'react'
import moment from 'moment'
import { Grid, Typography, Modal } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import BoxImage from '../../../assets/images/paquete.svg'

const AddToSubBoxModal = ({ t, history, handleClose, open, subscribableEditBox, addToExistingBox }) => {
  const dispatch = useDispatch()

  const setOneOffBox = type => dispatch(Actions.Checkout.setBoxTypePurchase(type))

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        className="cancel-modal"
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{ style: { backdropFilter: 'blur(3px)' } }}>
        <Grid>
          <>
            <Grid container className="modal-cancel">
              <Grid container item>
                <Grid container direction="column" className="cancel" justify="center" alignContent="center">
                  <Grid>
                    <img height="183px" width="183px" src={BoxImage} alt={t('home.adjustTime')} />
                  </Grid>
                  <Grid direction="column" justify="center" alignContent="center" item>
                    <Grid className="boxes__modal-subscribe-text">
                      <Typography>{t('boxes.subscribleModalText')}</Typography>
                      <Typography className="boxes__modal-subscribe-text bold">
                        {t('boxes.boxArrivesOn')}
                        {subscribableEditBox && moment(subscribableEditBox.shippingDate).format('Do MMMM')}
                      </Typography>
                    </Grid>
                    <Typography
                      className="boxes__addToSubscribeOptions"
                      onClick={() => {
                        addToExistingBox()
                        setOneOffBox(null)
                        history.push('/cart')
                      }}>
                      {t('boxes.addToMySubscription')}
                    </Typography>
                    <Typography
                      className="boxes__addToSubscribeOptions"
                      onClick={() => {
                        handleClose()
                        setOneOffBox('oneOffs')
                        if (window.location.pathname.includes('checkout')) history.push('/cart')
                      }}>
                      {t('boxes.createOneOffBox')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Modal>
    </>
  )
}

AddToSubBoxModal.propTypes = propTypes
export default AddToSubBoxModal
