import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'
import propTypes from './props'
import Actions from '../../../actions'
import loader from '../../../assets/images/loader3.gif'
import FlatButton from '../FlatButton'

const SecondModalCancel = ({
  t,
  handleClose,
  boxDiscount,
  status,
  setOpenDatePicker,
  setOpenModal,
  loading,
  setLoading,
  setShowTypeForm
}) => {
  const dispatch = useDispatch()

  const applyPromoCode = code => dispatch(Actions.Checkout.applyPromoCode(code))

  const promoCodeSubscription = boxDiscount && {
    value: boxDiscount.value,
    value_type: boxDiscount.type,
    title: boxDiscount.code,
    id: boxDiscount.id,
    productsId: boxDiscount.entitledProductIds,
    variantsId: boxDiscount.entitledVariantIds
  }

  const promoCode = useSelector(state => (status === 'PENDING' ? state.Checkout.promoCode : promoCodeSubscription))

  const getButtonContent = () => {
    if (loading) return <img width="51px" src={loader} alt="Loading..." />
    if (!loading && promoCode && promoCode.error) return t('boxes.benefitUsed')
    if (!loading && promoCode && !promoCode.error) return t('boxes.benefitApplied')
    return t('boxes.buttonDiscount1')
  }

  return (
    <>
      <Grid className="yalla-modal__container">
        <Typography className="title">{t('boxes.staySubscribed2')}</Typography>
        <Typography className="subtitle subtitle--second-modal-cancel">{t('boxes.staySubscribed3')}</Typography>
        <Grid className="offer-buttons-modal__container">
          {!loading && promoCode && promoCode.error && (
            <Typography variant="inherit" gutterBottom className="benefit benefit--used">
              {t('boxes.benefitUsed')}
            </Typography>
          )}
          {!loading && promoCode && !promoCode.error && (
            <Typography variant="inherit" gutterBottom className="benefit benefit--applied">
              {t('boxes.benefitApplied')}
            </Typography>
          )}
          {!promoCode && (
            <FlatButton
              title={getButtonContent()}
              classes="flat-button--green-jaka"
              onClick={() => {
                if (!promoCode) {
                  setLoading(true)
                  applyPromoCode('50DISC')
                }
              }}
            />
          )}

          <Box mt={2}>
            <FlatButton
              title={t('boxes.btnCancelSubsciption')}
              classes="flat-button--link"
              onClick={() => setShowTypeForm(true)}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid className="yalla-modal__container yalla-modal__container--has-background yalla-modal__container--has-background--gray">
        <Grid className="second-modal-cancel">
          <Typography className="title title--change-date">{t('boxes.changeDate')}</Typography>

          <Grid className="offer-buttons-modal__container-pause-rechedule">
            <FlatButton
              title={t('boxes.rescheduleSubscription')}
              classes="flat-button--pink-darker"
              onClick={() => {
                handleClose()
                setOpenDatePicker(true)
              }}
            />

            <Box mt={2} width={1}>
              <FlatButton
                title={t('boxes.pauseBox')}
                classes="flat-button--gray"
                onClick={() => {
                  handleClose()
                  setOpenModal('pause')
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid className="yalla-modal__container yalla-modal__container--has-background yalla-modal__container--has-background--dark-gray">
        <Grid className="second-modal-cancel">
          <Typography className="title title--change-date">{t('boxes.getInTouch')}</Typography>

          <Grid container className="second-modal-cancel__contact-info" spacing={1}>
            <Grid container item alignItems="center" justifyContent="center" lg={4}>
              <Grid>
                <MailOutlineIcon className="icon" />
              </Grid>
              <Grid>
                <a href="mailto:hello@yallababy.com">{t('footer.contactEmail')}</a>
              </Grid>
            </Grid>
            <Grid container item alignItems="center" justifyContent="center" lg={4}>
              <WhatsAppIcon className="icon" />
              <a
                className="get-help__text"
                href={`https://wa.me/${t('footer.whatsapp').substring(1)}`}
                target="_blank"
                rel="noopener noreferrer">
                {t('footer.whatsapp')}
              </a>
            </Grid>
            <Grid container item alignItems="center" justifyContent="center" lg={4}>
              <PhoneIcon className="icon" />
              <Typography>{t('footer.whatsapp')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

SecondModalCancel.propTypes = propTypes
export default SecondModalCancel
