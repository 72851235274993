/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import stringSimilarity from 'string-similarity'
import { translate } from 'react-i18next'
import { Grid, Hidden, SwipeableDrawer, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import useScrollPosition from '../../Hooks/scrollHook'
import Header from '../../components/organisms/Header'
import Actions from '../../actions'
import propTypes from './props'
import UpsellingCardItem from '../../components/organisms/UpsellingCardItem'
import Footer from '../../components/molecules/Footer'
import CollectionsSelector from '../../components/organisms/CollectionsSelector'
import DiaperCard from '../../components/organisms/DiaperCard'
import WipeCard from '../../components/organisms/WipeCard'
import UpvotyModal from '../../components/molecules/UpvotyModal'
import { isOffer, getAllCollections, isSafari } from '../../utils/helpers'
import IconComponent from '../../components/atoms/IconComponent'
import Filters from '../../components/organisms/Filters'
import MySubscription from '../../components/organisms/MySubscription'
import GoToCartButton from '../../components/molecules/GoToCartButton'
import OrderProductsBy from '../../components/molecules/OrderProductsBy'
import UpvotyCard from '../../components/molecules/UpvotyCard'
import MomsReviewsBanner from '../../components/molecules/MomsReviewsBanner'
import ybimage from '../../assets/images/ybimage.png'
import { UPVOTY as UPVOTY_URLS } from '../../utils/urls'

const useStyles = makeStyles(() => ({
  active: {
    visibility: 'visible',
    transition: 'all 300ms ease-in'
  },
  hidden: {
    visibility: 'hidden',
    transition: 'all 300ms ease-out 500ms',
    transform: 'translate(0, -170px)'
  }
}))

const Products = ({ history, t, match }) => {
  const classes = useStyles()

  const [collectionHandle, setCollectionHandle] = useState(match.params.collection && match.params.collection)
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [openFilter, setOpenFilter] = useState(false)
  const [openUpvoty, setOpenUpvoty] = useState(false)
  const [orderBy, setOrderBy] = useState('bestSellers')
  const upsellingCart = useSelector(state => state.Checkout.upselling)
  const collections = useSelector(state => state.Products.collections)
  const diapersCart = useSelector(state => state.Checkout.selectedDiapers)
  const wipesCart = useSelector(state => state.Checkout.selectedWipes)
  const currentLanguage = useSelector(state => state.Language.current)
  const isEdition = useSelector(state => state.Checkout.isEdition)
  const filter = useSelector(state => state.Products.filter)
  const { brands, quick, inStock } = useSelector(state => state.Products.filters)
  const logged = useSelector(state => state.Users.logged)
  const subscriptions = useSelector(state => state.Users.subscriptions)
  const menuItems = useSelector(state => state.Checkout.menuItems)
  const isAnyBoxSubscribed = subscriptions.find(sub => sub.purchaseType === 'SUBSCRIPTION' && sub.status === 'PENDING')
  const [haveSecondLevel, setHaveSecondLevel] = useState(false)
  const [isSecondLevel, setIsSecondLevel] = useState(false)

  const dispatch = useDispatch()
  const getContextData = () => dispatch(Actions.Checkout.getContextData())
  const setFilter = filterValue => dispatch(Actions.Products.filter(filterValue))
  const setSearchResults = results => dispatch(Actions.Products.setSearchResults(results))
  const getSamplings = () => dispatch(Actions.Products.getSamplings())
  const getCollections = handle => dispatch(Actions.Products.getCollections(handle))

  const [collectionToShow, setCollectionToShow] = useState(
    collections.find(col => col.handle === match.params.collection && match.params.collection)
  )
  const collectionProducts = stock => (stock ? collectionToShow.productsInStock : collectionToShow.products)

  const setFilters = newFilter => dispatch(Actions.Products.setFilters(newFilter))
  const isAnyProductOnCart = diapersCart.length > 0 || wipesCart.length > 0 || upsellingCart.length > 0
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = prevPos.y > 60 || currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  useEffect(() => {
    setCollectionToShow(collections.find(col => col.handle === collectionHandle))
  }, [collections])

  useEffect(() => {
    getContextData()
    getSamplings()
    window.scrollTo(0, 0)
    if (match.params.customFilter) setFilters({ brands: [match.params.customFilter] })
    return () => {
      if (
        isEdition &&
        window.location.href.indexOf('/products') === -1 &&
        window.location.href.indexOf('/product') === -1 &&
        window.location.href.indexOf('/cart') === -1
      ) {
        dispatch(Actions.Checkout.flush())
      }
      setFilter('')
      setFilters({ brands: [], quick: [] })
    }
  }, [])

  const handleChangeCollection = collectionSelected => {
    setCollectionHandle(collectionSelected)
  }

  const getDetailPageURI = (productId, productName) => {
    const breadcrumbData = { collectionTitle: collectionToShow.title, collectionHandle: collectionToShow.handle }
    const slug = productName.split(' ').join('-').toLowerCase()
    history.push(`/product/${productId}-${slug}`, breadcrumbData)
  }

  useEffect(() => {
    if (match.params.collection && match.params.collection !== collectionHandle) {
      handleChangeCollection(match.params.collection)
    }
    if (
      match.params &&
      match.params.collection !== 'collections' &&
      !collections.find(collection => collection.handle === collectionHandle)
    ) {
      getCollections(collectionHandle)
    }
    setCollectionToShow(collections.find(col => col.handle === match.params.collection))
  }, [match.params])

  const getFilters = () => {
    const allBrands = []
    const getUniqueVendors = item => !allBrands.includes(item.vendor) && allBrands.push(item.vendor)
    if (collectionToShow) collectionProducts(inStock).forEach(getUniqueVendors)
    return allBrands.sort()
  }

  const getDisplayedVariant = (variant, diaper) =>
    variant.option2 === diaper.presentationsBySize[variant.option1][0].quantity &&
    variant.option3 === diaper.presentationsBySize[variant.option1][0].weight
  const orderCollection = order => {
    switch (true) {
      case order === 'lowPrice':
        collectionProducts(inStock).sort(
          (a, b) =>
            (a.product_type === 'diapers'
              ? +a.variants.find(variant => getDisplayedVariant(variant, a)).price
              : +a.variants[0].price) -
            (b.product_type === 'diapers'
              ? +b.variants.find(variant => getDisplayedVariant(variant, b)).price
              : +b.variants[0].price)
        )
        break

      case order === 'highPrice':
        collectionProducts(inStock).sort(
          (a, b) =>
            (b.product_type === 'diapers'
              ? +b.variants.find(variant => getDisplayedVariant(variant, b)).price
              : +b.variants[0].price) -
            (a.product_type === 'diapers'
              ? +a.variants.find(variant => getDisplayedVariant(variant, a)).price
              : +a.variants[0].price)
        )
        break

      case order === 'newest':
        collectionProducts(inStock).sort((a, b) => moment(b.published_at).unix() - moment(a.published_at).unix())
        break

      default:
        if (collectionToShow) collectionProducts(inStock).sort((a, b) => b.quantitySold - a.quantitySold)
        break
    }
  }

  useEffect(() => {
    history.push(`/products/${collectionHandle}`)
    orderCollection(orderBy)
  }, [collectionHandle, orderBy])

  useEffect(() => {
    if (!collectionHandle || !menuItems) return

    const collectionInMenu = menuItems.find(
      item => item.handle === collectionHandle || item.items.some(i => i.handle === collectionHandle)
    )

    if (!collectionInMenu) {
      setHaveSecondLevel(false)
    } else if (!!collectionInMenu.items && !collectionInMenu.items.length) {
      setHaveSecondLevel(false)
      setIsSecondLevel(false)
    } else {
      setHaveSecondLevel(true)
      setIsSecondLevel(true)
    }
  }, [collectionHandle])

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setOpenFilter(open)
  }

  const isSubscribable = item => {
    return (
      item.product_type === 'diapers' ||
      item.product_type === 'baby wipes' ||
      (!isOffer(item, item.variants[0]) &&
        item.variants[0].compare_at_price !== null &&
        item.variants[0].compare_at_price !== '0.00')
    )
  }

  const quickFilter = item => {
    switch (true) {
      case quick.some(quickItem => quickItem === 'Organic'):
        return item.tags.includes('#organic')

      case quick.some(quickItem => quickItem === 'Subscribable'):
        return isSubscribable(item)

      default:
        return isSubscribable(item) && item.tags.includes('#organic')
    }
  }

  const renderNoMatchMessage = () => {
    return (
      <Grid item container className="products-container__no-match-filter">
        <span>{t('collections.noProducts')}</span>
      </Grid>
    )
  }

  const renderCards = product => {
    switch (product.product_type) {
      case 'diapers':
        return (
          <DiaperCard
            currentLanguage={currentLanguage}
            key={product.id}
            diaper={product}
            cart={diapersCart}
            getDetailPageURI={(id, title) => getDetailPageURI(id, title)}
          />
        )
      case 'baby wipes':
        return (
          <WipeCard
            currentLanguage={currentLanguage}
            key={product.id}
            wipe={product}
            cart={wipesCart}
            getDetailPageURI={(id, title) => getDetailPageURI(id, title)}
          />
        )
      default:
        return (
          <UpsellingCardItem
            currentLanguage={currentLanguage}
            t={t}
            product={product}
            key={product.id}
            cart={upsellingCart}
            getDetailPageURI={(id, title) => getDetailPageURI(id, title)}
          />
        )
    }
  }

  const showProducts = cat => {
    const showItem = item => {
      return (
        (brands.length === 0 || brands.some(brandItem => item.vendor === brandItem)) &&
        (quick.length === 0 || quickFilter(item))
      )
    }

    switch (true) {
      case filter.length > 0: {
        const products = collectionProducts(inStock)
        const filteredProducts = []

        products.forEach(prod => {
          const matching = stringSimilarity.findBestMatch(filter.toLowerCase(), prod.title.toLowerCase().split(' '))

          if (matching.bestMatch.rating > 0.43) {
            filteredProducts.push(prod)
          }
        })
        setSearchResults(filteredProducts)

        return (
          <Grid item container justify="center">
            <Grid item container xs={12} alignItems="center" className="subcategory-title">
              {filteredProducts.length > 0 ? (
                <span>{`${filteredProducts.length} ${t('filter.filterTitle')} "${filter}"`}</span>
              ) : (
                <span>{`${t('filter.filterNoResults')} "${filter}"`}</span>
              )}
            </Grid>
            {filteredProducts.map(item => renderCards(item))}
          </Grid>
        )
      }

      case collectionToShow && collectionToShow.handle === cat:
        if (collectionProducts(inStock).some(item => showItem(item))) {
          return collectionProducts(inStock).map(item => showItem(item) && renderCards(item))
        }
        return renderNoMatchMessage()

      case collectionHandle === 'collections':
        return (
          <>
            <Grid className="all-collections">
              {menuItems &&
                getAllCollections(menuItems).map(collection => (
                  <Grid
                    key={collection.handle}
                    className="all-collections__collection"
                    onClick={() => handleChangeCollection(collection.handle)}>
                    <img
                      src={(collection.image && collection.image.url) || ybimage}
                      alt={collection.title}
                      className="all-collections__collection__image"
                    />
                    <span className="all-collections__collection__title">{collection.title}</span>
                  </Grid>
                ))}
            </Grid>
          </>
        )

      default:
        return <></>
    }
  }

  return (
    <Fragment>
      <Header
        t={t}
        showSideCartIcon
        bg
        history={history}
        showSearch
        openLogin={match && !match.params.customFilter && match.params.login}
        showLogin
      />
      <Grid container item xs={12} className={`category-subcategory ${hideOnScroll ? classes.active : classes.hidden}`}>
        <CollectionsSelector
          collectionHandle={collectionHandle}
          handleChangeCollection={handleChangeCollection}
          t={t}
          toggleDrawer={toggleDrawer}
        />
      </Grid>
      {(collectionToShow || collectionHandle === 'collections') && (
        <Grid
          container
          item
          direction="row"
          className={`TabContent ${isSafari() ? 'TabContent--safari' : ''} ${
            collectionHandle === 'collections' ? 'collections-category' : ''
          } ${haveSecondLevel || isSecondLevel ? 'TabContent--with-sublevel' : ''}`}>
          <Hidden smDown>
            <Grid container item justify="center" alignContent="flex-start" className="filter-container">
              {logged && isAnyBoxSubscribed && (
                <Grid>
                  <MySubscription isEdition={isEdition} t={t} subscriptions={subscriptions} history={history} />
                </Grid>
              )}
              {collectionToShow && collectionHandle !== 'collections' && filter.length === 0 && (
                <Filters allBrands={getFilters()} t={t} />
              )}
            </Grid>
          </Hidden>
          {logged && isAnyBoxSubscribed && (
            <Hidden mdUp>
              <MySubscription
                allCollections={collectionHandle === 'collections'}
                isEdition={isEdition}
                history={history}
                t={t}
                subscriptions={subscriptions}
                mobile
              />
            </Hidden>
          )}

          <Grid
            container
            item
            className={`products-container ${
              collectionHandle === 'collections' && !logged ? 'collections-not-logged' : ''
            }`}>
            {collectionHandle !== 'collections' && filter.length === 0 && (
              <Grid item container alignItems="center" xs={12} className={`subcategory-title`}>
                <Hidden xsDown>
                  <>
                    <span className="subcategory-title__title">{collectionToShow.title}</span>
                    <span className="subcategory-title__quantity">{collectionProducts(inStock).length}</span>
                  </>

                  <OrderProductsBy orderBy={orderBy} setOrderBy={setOrderBy} />
                </Hidden>
              </Grid>
            )}
            <Grid item container justify="flex-start">
              {showProducts(collectionHandle)}
              {collectionHandle !== 'collections' && <UpvotyCard t={t} setOpenUpvoty={setOpenUpvoty} />}
            </Grid>
          </Grid>
          <MomsReviewsBanner history={history} t={t} />
          <UpvotyModal upvotyPath={UPVOTY_URLS.PRODUCT_REQUEST} openUpvoty={openUpvoty} setOpenUpvoty={setOpenUpvoty} />
        </Grid>
      )}
      <Hidden smUp>
        <SwipeableDrawer anchor="right" open={openFilter} onOpen={toggleDrawer(true)} onClose={toggleDrawer(false)}>
          <Grid item container className="filter-container-mobile">
            <Grid item container className="filter-container-mobile__title">
              <Grid item onClick={toggleDrawer(false)} className="filter-container-mobile__title__image">
                <IconComponent icon="pinkArrow" />
              </Grid>
              <span>{t('products.filters.filters')}</span>
            </Grid>
            {filter.length === 0 && <Filters allBrands={getFilters()} t={t} />}
          </Grid>
        </SwipeableDrawer>
      </Hidden>
      <Footer t={t} currentLanguage={currentLanguage} history={history} />
      <Slide direction="left" timeout={800} mountOnEnter unmountOnExit in={isAnyProductOnCart}>
        <div className={`go-to-cart ${isAnyProductOnCart && 'shake-animation'}`}>
          <GoToCartButton history={history} t={t} />
        </div>
      </Slide>
    </Fragment>
  )
}

Products.propTypes = propTypes
export default translate('common')(Products)
