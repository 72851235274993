/* eslint-disable max-len */
import React from 'react';

const SvgCreditCard = props => (
  <svg
    viewBox="0 0 201.526 201.526"
    width="3rem"
    {...props}
  >
    <path d="M184.136 34.746H17.39C7.801 34.746 0 42.525 0 52.085v97.356c0 9.56 7.801 17.339 17.39 17.339h166.746c9.589 0 17.39-7.779 17.39-17.339V52.085c-.001-9.56-7.801-17.339-17.39-17.339zm10.441 114.695h-.001c0 5.729-4.684 10.39-10.441 10.39H17.39c-5.757 0-10.441-4.66-10.441-10.39V52.085c0-5.729 4.684-10.39 10.441-10.39h166.746c5.757 0 10.441 4.66 10.441 10.39v97.356z" />
    <path d="M24.322 121.61h72.966v6.949H24.322zM104.237 121.61h72.966v6.949h-72.966zM34.717 100.763H72.995c5.731 0 10.395-4.676 10.395-10.422V69.49c0-5.746-4.664-10.422-10.395-10.422H34.717c-5.731 0-10.395 4.676-10.395 10.422v20.85c0 5.747 4.664 10.423 10.395 10.423zm27.825-34.746h10.453c1.9 0 3.446 1.557 3.446 3.473v3.476H62.542v-6.949zm0 13.898H76.44v10.426h.001c0 1.948-1.513 3.473-3.446 3.473H62.542V79.915zM31.271 69.49c0-1.948 1.513-3.473 3.446-3.473h20.876v13.898H31.271V69.49zm0 17.374h24.322v6.949H34.717c-1.9 0-3.446-1.557-3.446-3.473v-3.476z" />
  </svg>
);

export default SvgCreditCard;
