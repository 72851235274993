import React from 'react'
import propTypes from './props'

const LinkButton = ({ text, onClick, classNames }) => (
  <button className={classNames} type="button" onClick={onClick}>
    {text}
  </button>
)

LinkButton.propTypes = propTypes
export default LinkButton
