/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { translate } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import { Grid, useMediaQuery, Collapse, Typography, Hidden } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import FlatButton from '../../molecules/FlatButton'
import CircleList from '../../molecules/CircleList'
import IconComponent from '../../atoms/IconComponent'

const CollectionsSelector = ({ collectionHandle, handleChangeCollection, toggleDrawer }) => {
  const filter = useSelector(state => state.Products.filter)
  const menuItems = useSelector(state => state.Checkout.menuItems)
  const [submenu, setSubmenu] = useState([])
  const [hoverSubmenu, setHoverSubmenu] = useState([])

  const dispatch = useDispatch()
  const setFilter = filterValue => dispatch(Actions.Products.filter(filterValue))
  const setFilters = newFilters => dispatch(Actions.Products.setFilters(newFilters))
  const [showDropdown, setShowDropDown] = useState(false)
  const [categoryOnHover, setCategoryOnHover] = useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const haveSublevel = col => col && col.items && col.items.length

  useEffect(() => {
    setShowDropDown(false)
    const collectionInMenu =
      collectionHandle &&
      menuItems &&
      menuItems.find(
        item =>
          item.handle === collectionHandle ||
          (item.items.length !== 0 && item.items.some(i => i.handle === collectionHandle))
      )

    if (collectionInMenu && !!collectionInMenu.items && !collectionInMenu.items.length) {
      setSubmenu([])
    } else {
      setSubmenu(prevState => (haveSublevel(collectionInMenu) ? collectionInMenu.items : prevState))
    }
  }, [collectionHandle])

  const selectCategory = col => {
    setFilter('')
    setFilters({ brands: [], quick: [], price: [] })
    if (collectionHandle !== col) handleChangeCollection(col)
  }

  const handleMouseEnter = hoverCollection => {
    if (!isMobile) setShowDropDown(true)
    setCategoryOnHover(hoverCollection.handle)
    setHoverSubmenu(hoverCollection.items)
  }

  const handleMouseLeave = () => {
    setShowDropDown(false)
    setHoverSubmenu([])
  }

  const handleOnClick = selectedCollection => {
    if (isMobile && !haveSublevel(selectedCollection)) selectCategory(selectedCollection.handle)
    else if (isMobile && !showDropdown) setShowDropDown(true)
    else {
      selectCategory(selectedCollection.handle)
      setSubmenu(hoverSubmenu)
    }
  }

  const getParentCollection = (collection, handle) => {
    if (collection.handle === handle) return null

    const { items } = collection
    const parentCollection = collection.handle
    const founded = items.some(i => i.handle === handle)

    return founded ? parentCollection : null
  }

  return (
    <>
      <Grid item container xs={12} lg={12} justify="center" className="filter-category">
        <Grid item container lg={12} className="filter-category__buttons">
          {menuItems &&
            menuItems.length > 0 &&
            menuItems.map(collection => (
              <Grid
                onMouseEnter={() => handleMouseEnter(collection)}
                onMouseLeave={handleMouseLeave}
                key={`filter-subcategory__button-${collection.handle}`}>
                <FlatButton
                  onClick={() => handleOnClick(collection)}
                  customIcon={!!collection.items.length && <KeyboardArrowDownIcon />}
                  title={collection.title}
                  classes={`flat-button--desktop filter-category__button${
                    (collectionHandle === collection.handle && !filter.length) ||
                    (getParentCollection(collection, collectionHandle) && !filter.length)
                      ? '-selected'
                      : ''
                  }`}
                />
                <Collapse
                  className="filter-subcategory__dropdown"
                  in={!!collection.items.length && showDropdown && collection.handle === categoryOnHover}>
                  {collection.items.map(item => (
                    <Typography
                      key={item.handle}
                      onClick={() => handleOnClick(item)}
                      className="filter-subcategory__dropdown__item">
                      {item.title}
                    </Typography>
                  ))}
                </Collapse>
              </Grid>
            ))}
        </Grid>

        {!!submenu.length && (
          <Grid item xs={12} justify="center" className="submenu">
            <CircleList items={submenu} onClick={handleOnClick} collectionHandle={collectionHandle} />
          </Grid>
        )}
        {collectionHandle !== 'collections' && (
          <Hidden smUp>
            <Grid item onClick={toggleDrawer(true)} className="filter-container-mobile__title__filter-icon">
              <IconComponent icon="filtersIcon" />
            </Grid>
          </Hidden>
        )}
      </Grid>
    </>
  )
}

CollectionsSelector.propTypes = propTypes
export default translate('common')(CollectionsSelector)
