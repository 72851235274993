/* eslint-disable no-case-declarations */
import React, { useState } from 'react'
import { Grid, Modal } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'
import PriceDetail from '../PriceDetail'
import LogoComponent from '../../atoms/LogoComponent'

const PriceDetailModal = ({
  t,
  setOpenModal,
  openModal,
  currentLanguage,
  subscription,
  selectedDiapers,
  selectedWipes,
  upselling,
  boxes
}) => {
  const { id, status, paymentType, boxDiscount, vatPercent, codPercent, shippingCost, codAmount } = subscription

  const promoCodeSubscription = boxDiscount && {
    value: boxDiscount.value,
    value_type: boxDiscount.type,
    title: boxDiscount.code,
    id: boxDiscount.id,
    productsId: boxDiscount.entitledProductIds,
    variantsId: boxDiscount.entitledVariantIds
  }

  const [saveDisabled, setSaveDisabled] = useState(true)

  const promoCode = useSelector(state => (status === 'PENDING' ? state.Checkout.promoCode : promoCodeSubscription))
  const { isEdition } = useSelector(state => state.Checkout)

  const dispatch = useDispatch()

  const setInvoiceStatus = invoiceStatus => dispatch(Actions.Users.setInvoiceStatus(invoiceStatus))
  const savePromoCode = (idSubs, promoCodeToSave) => dispatch(Actions.Checkout.savePromoCode(idSubs, promoCodeToSave))
  const removePromoCode = () => dispatch(Actions.Checkout.removePromoCode())
  const flushCheckout = () => dispatch(Actions.Checkout.flush())

  const handleSaveDisabled = boolean => {
    setSaveDisabled(boolean)
  }
  const handleClose = () => {
    flushCheckout()
    setInvoiceStatus(null)
    setOpenModal(false)
    handleSaveDisabled(true)
  }

  return (
    <Modal
      open={openModal === 'detail'}
      onClose={() => {
        if (status === 'PENDING') {
          removePromoCode()
        }
        handleClose()
      }}
      className="price-detail-modal">
      <div tabIndex={-1}>
        <Grid container item xs={12} direction="column" justify="flex-start" className="price-detail-modal__content">
          <Grid container item xs={12} className="price-detail-modal__price-detail">
            <Grid
              item
              container
              alignContent="center"
              alignItems="center"
              justify="space-between"
              className="price-detail-modal__price-detail__header">
              <Grid item container justify="center" xs={7} onClick={() => handleClose()}>
                <LogoComponent xs={10} justify="center" language={currentLanguage} />
              </Grid>
              <Grid item xs={2}>
                <IconComponent
                  fill="#FFFFFF"
                  icon="close"
                  onClick={() => {
                    if (promoCode) {
                      removePromoCode()
                    }
                    handleClose()
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item className="price-detail-modal__price-detail__content">
              <Grid item container className="price-detail-modal__price-detail__add-margin">
                <PriceDetail
                  t={t}
                  idButton="info-price_button"
                  handleButtonClick={() => {
                    if (status === 'PENDING') {
                      if (
                        (promoCode && !promoCodeSubscription) ||
                        (promoCodeSubscription && !promoCode) ||
                        (promoCode && promoCodeSubscription && promoCodeSubscription.id !== promoCode.id)
                      ) {
                        savePromoCode(id, promoCode)
                      }
                    }
                    if (promoCode) {
                      removePromoCode()
                    }
                    handleClose()
                  }}
                  handleCancel={() => {
                    if (promoCode) {
                      removePromoCode()
                    }
                    handleClose()
                  }}
                  titleButton={status === 'PENDING' ? t('default.save') : t('default.ok')}
                  selectedDiapers={selectedDiapers}
                  selectedWipes={selectedWipes}
                  upselling={upselling}
                  promoCode={promoCode}
                  vatPercent={vatPercent}
                  codPercent={codPercent}
                  paymentType={paymentType}
                  showCancelEdition={isEdition && status === 'PENDING'}
                  saveDisabled={saveDisabled}
                  shippingCostInBox={shippingCost}
                  isBox
                  boxes={boxes}
                  boxStatus={status}
                  codFixedAmount={codAmount}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

PriceDetailModal.propTypes = propTypes
export default PriceDetailModal
