/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const CartIcon2 = ({height='36px',width='37px',className}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 37 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Header/-nuevo-gris2/-web2" transform="translate(-1145.000000, -20.000000)" fill="#FFFFFF">
        <g id="Fill-70-Copy-2" transform="translate(1145.000000, 20.000000)">
          <path d="M24.1667 21.6667C22.786 21.6667 21.6667 22.786 21.6667 24.1667C21.6667 25.5474 22.786 26.6667 24.1667 26.6667C25.5474 26.6667 26.6667 25.5474 26.6667 24.1667C26.6667 22.786 25.5474 21.6667 24.1667 21.6667ZM9.16668 21.6667C7.78595 21.6667 6.66668 22.786 6.66668 24.1667C6.66668 25.5474 7.78595 26.6667 9.16668 26.6667C10.5474 26.6667 11.6667 25.5474 11.6667 24.1667C11.6667 22.786 10.5474 21.6667 9.16668 21.6667ZM29.663 2.3299C29.3448 1.90839 28.8598 1.66667 28.3317 1.66667H6.35194L6.22803 1.01615C6.11574 0.426563 5.60027 0 5.00011 0H1.25C0.559636 0 0 0.559636 0 1.25C0 1.94037 0.559636 2.5 1.25 2.5H3.96568L7.10543 18.9839C7.21772 19.5735 7.73319 20 8.33335 20H25.4167C26.1071 20 26.6667 19.4404 26.6667 18.75C26.6667 18.0596 26.1071 17.5 25.4167 17.5H9.36767L8.89153 15H25.4743C26.2187 15 26.873 14.5065 27.0776 13.7907L29.9348 3.79151C30.0797 3.2837 29.9804 2.75146 29.663 2.3299Z" fill="white" fillOpacity="0.5" />
        </g>
      </g>
    </g>
  </svg>
)

export default CartIcon2
