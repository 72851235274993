/* eslint-disable max-len */

export var conversionRatio = 2.205;
export var holidays = ['01-01', '18-02', '23-03', '20-04', '21-04', '22-04', '23-04', '20-06', '27-06', '28-06', '29-06', '30-06', '21-07', '29-09', '30-11', '01-12', '02-12', '03-12', '31-12'];
export var allEmirates = [{
  label: 'shipping.dubai',
  value: 'Dubai'
}, {
  label: 'shipping.abuDhabi',
  value: 'Abu Dhabi'
}, {
  label: 'shipping.fujairah',
  value: 'Fujairah'
}, {
  label: 'shipping.rasAlKhaimah',
  value: 'Ras al-Khaimah'
}, {
  label: 'shipping.ummAlQuwain',
  value: 'Umm Al Quwain'
}, {
  label: 'shipping.sharjah',
  value: 'Sharjah'
}, {
  label: 'shipping.ajman',
  value: 'Ajman'
}];
export var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export var emirates = [{
  name: 'Abu Dhabi',
  code: 'AZ',
  standardRate: 'AUH',
  daysEnabledForShipping: {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: false,
    6: 'Saturday'
  }
}, {
  name: 'Ajman',
  code: 'AJ',
  standardRate: 'AJM',
  daysEnabledForShipping: {
    0: false,
    1: false,
    2: 'Tuesday',
    3: false,
    4: 'Thursday',
    5: false,
    6: false
  }
}, {
  name: 'Dubai',
  code: 'DU',
  standardRate: 'DXB',
  daysEnabledForShipping: {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: false,
    6: 'Saturday'
  }
}, {
  name: 'Fujairah',
  code: 'FU',
  standardRate: 'FUJ',
  daysEnabledForShipping: {
    0: false,
    1: 'Monday',
    2: false,
    3: false,
    4: 'Thursday',
    5: false,
    6: false
  }
}, {
  name: 'Ras al-Khaimah',
  code: 'RK',
  standardRate: 'RAK',
  daysEnabledForShipping: {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: false,
    6: false
  }
}, {
  name: 'Sharjah',
  code: 'SH',
  standardRate: 'SHJ',
  daysEnabledForShipping: {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: false,
    6: 'Saturday'
  }
}, {
  name: 'Umm al-Quwain',
  code: 'UQ',
  standardRate: 'UAQ',
  daysEnabledForShipping: {
    0: false,
    1: 'Monday',
    2: false,
    3: false,
    4: false,
    5: false,
    6: false
  }
}];
export var sizes = [{
  title: 'NB',
  weight: '0-4 kg',
  size: 'NB'
}, {
  title: 'Size 1',
  weight: 'up to 5kg',
  size: '1'
}, {
  title: 'Size 2',
  weight: '4-6kg',
  size: '2'
}, {
  title: 'Size 3',
  weight: '4-9kg',
  size: '3'
}, {
  title: 'Size 4',
  weight: '8-14kg',
  size: '4'
}, {
  title: 'Size 5',
  weight: '12-22kg',
  size: '5'
}, {
  title: 'Size 6',
  weight: '20+kg',
  size: '6'
}];