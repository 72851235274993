import React from 'react'

const Trash = () => (
  <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g id="10--Products-List" transform="translate(-196.000000, -148.000000)" stroke="#C38A9F">
        <g id="Group-14" transform="translate(197.000000, 149.000000)">
          <path d="M4,6 L5.5,11.8568377" id="Path-10" strokeLinecap="round" />
          <path d="M7.5,6 L7.5,11.8568377" id="Path-10-Copy" strokeLinecap="round" />
          <path d="M11,6 L9.5,11.8568377" id="Path-10-Copy-2" strokeLinecap="round" />
          <polygon id="Path-9" strokeWidth="1.5" points="2.96651153 15 12.1976462 15 15 3 -2.13162821e-13 3" />
          <polyline id="Path-12" strokeWidth="1.5" strokeLinecap="round" points="4 3 4 0 11 0 11 3" />
        </g>
      </g>
    </g>
  </svg>
)

export default Trash
