import _defineProperty from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
var initialState = {};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'FORMS_SET_DEFAULT_VALUES':
      return _objectSpread({}, state, _defineProperty({}, action.form, action.value));
    case 'FORMS_UPDATE_FIELD_VALUE':
      return _objectSpread({}, state, _defineProperty({}, action.form, _objectSpread({}, state[action.form], _defineProperty({}, action.key, action.value))));
    default:
      return state;
  }
};
export default reducer;