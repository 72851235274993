/* eslint-disable max-len */
import React from 'react'
import propTypes from './props'

const EditIcon = ({ fill }) => (
  <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="07--CURRENT-BOXIES" transform="translate(-229.000000, -264.000000)" fill={fill || '#8D8C8C'}>
        <path
          d="M229,273.467842 L229,276 L231.532073,276 L239,268.532158 L236.467927,266 L229,273.467842 L229,273.467842 Z M240.800766,266.753433 C241.066411,266.488111 241.066411,266.058965 240.800766,265.793303 L239.206897,264.198991 C238.941252,263.93367 238.512133,263.93367 238.246488,264.198991 L237,265.445558 L239.554278,268 L240.800766,266.753433 L240.800766,266.753433 Z"
          id="Fill-76"
        />
      </g>
    </g>
  </svg>
)

EditIcon.propTypes = propTypes
export default EditIcon
