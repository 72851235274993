/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import _ from 'lodash'
import propTypes from './props'
import { getVariantPrice } from '../../utils/helpers'
import Header from '../../components/organisms/Header'
import Actions from '../../actions'
import LoginSigninContainer from '../LoginSigninContainer'
import StepContactInfo from '../../components/organisms/StepContactInfo'
import StepShipping from '../../components/organisms/StepShipping'
import StepPayment from '../../components/organisms/StepPayment'
import StepEditCards from '../../components/organisms/StepEditCards'
import HurryUpCounter from '../../components/molecules/HurryUpCounter'

const Checkout = ({ history, t }) => {
  const isCheckoutPaymentEdition = window.location.pathname === '/checkout/editpayment'
  const currentLanguage = useSelector(state => state.Language.current)
  const logged = useSelector(state => state.Users.logged)
  const firstName = useSelector(state => state.Users.userData && state.Users.userData.first_name)
  const email = useSelector(state => state.Users.userData && state.Users.userData.email)
  const selectedDiapers = useSelector(state => state.Checkout.selectedDiapers)
  const selectedWipes = useSelector(state => state.Checkout.selectedWipes)
  const upselling = useSelector(state => state.Checkout.upselling)

  const cart = [...selectedDiapers, ...selectedWipes, ...upselling]

  const isAccount = window.document.URL.includes('/account')

  const [openLoginSignin, setOpenLoginSignin] = useState(false)
  const [formType, setFormType] = useState('login')
  const [formValues, setValues] = useState({ deliveryTime: 'Anytime' })
  const [stockProblemAlert, setStockProblemAlert] = useState(false)

  const dispatch = useDispatch()
  const resetErrorRegister = () => dispatch(Actions.Users.resetErrorRegister())
  const klaviyoTrack = info => dispatch(Actions.Checkout.klaviyoTrack(info))

  const uniqueCartProducts = _.uniqBy(cart, e => e.variantId || e.variant_id)
  const uniqueCartProductsQty = _.countBy(cart, e => e.variantId || e.variant_id)

  const klaviyoCart = () =>
    uniqueCartProducts.map(prod => ({
      title: prod.title,
      id: prod.id,
      image: prod.image.src,
      quantity: uniqueCartProductsQty[prod.variant_id || prod.variantId],
      totalPrice:
        uniqueCartProductsQty[prod.variant_id || prod.variantId] *
        getVariantPrice(prod.variant_id || prod.variantId, prod.variants)
    }))

  const handleOpenLoginSignin = type => {
    setFormType(type)
    setOpenLoginSignin(true)
  }

  const handleCloseLoginSignin = () => {
    resetErrorRegister()
    setOpenLoginSignin(false)
  }

  const renderForm = () => {
    switch (true) {
      case window.location.pathname === '/checkout': {
        return (
          <>
            <StepPayment
              stockProblemAlert={stockProblemAlert}
              setStockProblemAlert={setStockProblemAlert}
              t={t}
              history={history}
              formValues={formValues}
              uniqueCartProducts={uniqueCartProducts}
              uniqueCartProductsQty={uniqueCartProductsQty}
              isAccount={isAccount}
              setValues={setValues}
              logged={logged}
              setTypeLoginSignin={setOpenLoginSignin}
            />
          </>
        )
      }

      case window.document.URL.includes('/account/editshipping'):
        return (
          <>
            <Grid item container className={`information-steps__form__shipping${isAccount ? '__account' : ''}`}>
              <StepShipping
                t={t}
                history={history}
                isAccount={isAccount}
                formValues={formValues}
                setValues={setValues}
              />
            </Grid>
          </>
        )

      case window.document.URL.includes('/account/editpayment'):
        return (
          <Grid item container>
            <StepEditCards t={t} history={history} isAccount={isAccount} />
          </Grid>
        )

      case window.location.pathname === '/checkout/editpayment':
        return (
          <Grid item container>
            <StepEditCards t={t} history={history} isAccount={false} />
          </Grid>
        )

      case window.document.URL.includes('/account/editcustomer'):
        return (
          <Grid item container className={`information-steps__form__contact-info${isAccount ? '__account' : ''}`}>
            <StepContactInfo
              t={t}
              history={history}
              isAccount={isAccount}
              handleOpenLoginSignin={handleOpenLoginSignin}
            />
          </Grid>
        )

      default:
        return <Grid item container />
    }
  }

  useEffect(() => {
    if (cart.length > 0 && !isAccount)
      klaviyoTrack({
        event: 'started_checkout',
        userName: firstName,
        email,
        products: klaviyoCart()
      })
  }, [])

  return (
    <>
      <Header t={t} bg history={history} showHelpBtn />
      {!isAccount && !isCheckoutPaymentEdition && (
        <Box className="hurry-up__container">
          <HurryUpCounter t={t} />
        </Box>
      )}
      <Grid
        className={`checkout-container ${(isAccount || isCheckoutPaymentEdition) && 'in-account'}`}
        md={isAccount || isCheckoutPaymentEdition ? 4 : 10}
        xs={12}
        justify={'flex-start'}>
        <Grid className={`information-steps${isAccount || isCheckoutPaymentEdition ? '__account' : ''}`}>
          <Grid className="information-steps__form">{renderForm()}</Grid>
        </Grid>
      </Grid>

      <LoginSigninContainer
        openModal={openLoginSignin}
        handleClose={handleCloseLoginSignin}
        typeLoginSignin={formType}
        setTypeLoginSignin={setFormType}
        t={t}
        history={history}
        language={currentLanguage}
        showFormFooter
      />
    </>
  )
}

Checkout.propTypes = propTypes
export default translate('common')(Checkout)
