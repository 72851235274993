/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Modal, Typography, Box, Hidden } from '@material-ui/core'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import InfoModal from '../InfoModal'
import { ImageCheck, isOffer, formatMoney } from '../../../utils/helpers'
import QuantityModifier from '../QuantityModifier'
import SubscriptionToggle from '../../atoms/SubscriptionToggle'
import UpgradeSize from '../../organisms/UpgradeSize'
import OutOfStockLabel from '../../atoms/OutOfStockLabel'
import IconComponent from '../../atoms/IconComponent'

const CartProductItem = ({
  products,
  productItem,
  t,
  addProduct,
  removeProduct,
  isSubscribed,
  setSubscribed,
  showToggle,
  offer,
  subscribeAll
}) => {
  const { variants, images, variant_id, variantId, product_type, title, presentationsBySize, shopifyVariantId } =
    productItem

  const DIAPERS = 'diapers'
  const ONEOFFS = 'oneOffs'
  const purchase_type = productItem.purchase_type || productItem.purchaseType
  const currentLanguage = useSelector(state => state.Language.current)

  const { isEdition, boxTypePurchase } = useSelector(state => state.Checkout)

  const selectedVariant = variants.find(
    variant => +variant.id === +variant_id || +variant.id === +variantId || +variant.id === +shopifyVariantId
  )

  const selectedVariantImage =
    images && images.length > 0 && images.find(img => selectedVariant && img.id === selectedVariant.image_id)

  const sizeRanges = product_type === 'diapers' && productItem.options.find(opt => opt.name === 'Weight').values

  const [openInfo, setOpenInfo] = useState(false)
  const [openUpgradeSize, setOpenUpgradeSize] = useState(false)
  const [alertOutStock, setAlertOutStock] = useState(false)

  const { price, compare_at_price, appliedDiscount } = selectedVariant

  const handleAlertOutStock = boolean => setAlertOutStock(boolean)
  const dispatch = useDispatch()
  const { shippingDate, selectedDiapers, selectedWipes, upselling, paymentType, promoCode } = useSelector(
    state => state.Checkout
  )
  const cart = [...selectedDiapers, ...selectedWipes, ...upselling]

  const boxInEdition = {
    selectedDiapers,
    selectedWipes,
    upselling,
    paymentType,
    promoCode: promoCode ? promoCode.id : '',
    shippingDate
  }

  const setThisBox = box => {
    dispatch(Actions.Checkout.setThisBox(box))
  }

  useEffect(() => {
    if (isEdition) setThisBox(boxInEdition)
  }, [])

  const handleQuantityChange = type => {
    switch (type) {
      case 'add':
        if (product_type === 'diapers' || product_type === 'baby wipes') {
          addProduct({
            ...productItem,
            variant_id: selectedVariant.id,
            price
          })
        } else {
          addProduct({ ...productItem, variantId: selectedVariant.id, price })
        }
        break

      case 'remove':
        for (let index = products.length - 1; index >= 0; index -= 1) {
          if (
            +products[index].variantId === selectedVariant.id ||
            +products[index].variant_id === selectedVariant.id ||
            +products[index].shopifyVariantId === selectedVariant.id
          ) {
            removeProduct(index)
            break
          }
        }
        break

      case 'remove-all':
        removeProduct(null, selectedVariant.id, selectedVariant.option2)

        break

      default:
        break
    }
  }

  const getSelectedVariantQuantity = variant => {
    let q = 0
    products.forEach(prod => {
      if (+prod.variantId === variant.id || +prod.variant_id === variant.id || +prod.shopifyVariantId === variant.id) {
        q += 1
      }
    })
    return q
  }
  const selectedVariantQuantity = getSelectedVariantQuantity(selectedVariant)

  const itemOptions = () => {
    const { option1, option2, option3 } = selectedVariant
    let options = ''

    const handleOption2 = type => {
      return (
        {
          diapers: `${option1} - ${option3}`,
          'baby wipes': `${option1} units`
        }[type] || `${option1} ${option2}`
      )
    }

    options = handleOption2(product_type)
    options = `(${options})`

    return options
  }

  const subscriptionToggle = () =>
    showToggle &&
    boxTypePurchase !== ONEOFFS &&
    !offer && (
      <SubscriptionToggle
        action={() =>
          setSubscribed(
            selectedVariant.id,
            product_type === DIAPERS ? selectedVariant.option2 : { price, compare_at_price, appliedDiscount }
          )
        }
        subscribed={isSubscribed(purchase_type)}
        discountAmount={(appliedDiscount * selectedVariantQuantity * 100) / (price * selectedVariantQuantity)}
        t={t}
      />
    )

  const quantityModifier = () => (
    <>
      <Grid item>
        <QuantityModifier
          quantity={selectedVariantQuantity}
          setQuantity={handleQuantityChange}
          variantSelected={selectedVariant}
          stockControl
          handleAlertOutStock={handleAlertOutStock}
          alertOutStock={alertOutStock}
          cart={cart}
          inCart
        />
      </Grid>
      {product_type === DIAPERS && (
        <Grid item className="cart-item__content__quantity-modifier__text">
          <Typography className="diaper-quantity">
            {`(${+selectedVariant.option2 * selectedVariantQuantity} diapers)`}
          </Typography>
        </Grid>
      )}
    </>
  )

  const formatedPrices = () => (
    <>
      {showToggle && compare_at_price > 0 && (
        <span className="cart-item__content__info__prices--regular">
          {`${t('default.aed')} ${formatMoney(
            (purchase_type !== 'SUBSCRIPTION' && !offer ? compare_at_price : price) * selectedVariantQuantity
          )}`}
        </span>
      )}
      <span className="cart-item__content__info__prices--subscription">
        {` ${t('default.aed')} ${formatMoney(
          ((purchase_type === 'SUBSCRIPTION' || offer) && compare_at_price > 0 ? compare_at_price : price) *
            selectedVariantQuantity
        )}`}
      </span>
      {purchase_type === 'SUBSCRIPTION' && <span> /month</span>}
    </>
  )

  useEffect(() => {
    if (subscribeAll && (productItem.purchaseType === 'ONE_TIME' || productItem.purchase_type === 'ONE_TIME'))
      setSubscribed(
        selectedVariant.id,
        product_type === DIAPERS ? selectedVariant.option2 : { price, compare_at_price, appliedDiscount }
      )
  }, [subscribeAll])

  return (
    <Fragment>
      <Grid container className="cart-item">
        <Grid container item className="cart-item__content" spacing={1}>
          <Grid item xs={3} lg={2} className="cart-item__content__image" onClick={() => setOpenInfo(true)}>
            <Box className="cart-item__content__image__wrapper">
              <Box
                component="img"
                src={selectedVariantImage ? selectedVariantImage.src : ImageCheck(productItem).src}
                alt={selectedVariant.title}
              />
            </Box>
            <Hidden mdDown>
              <Box className="delete-btn">
                <Typography align="center" onClick={() => handleQuantityChange('remove-all')}>
                  <span>
                    <IconComponent icon="trashJaka" />
                    {t('cart.remove')}
                  </span>
                </Typography>
              </Box>
            </Hidden>
          </Grid>

          <Grid item xs={9} lg={6} className="cart-item__content__info">
            <Box className="cart-item__content__info__title">
              <Typography>
                {title}
                <Hidden mdDown>
                  <span>{itemOptions()}</span>
                </Hidden>
              </Typography>
            </Box>
            {alertOutStock && <OutOfStockLabel cart inventoryQuantity={selectedVariant.inventory_quantity} t={t} />}
            <Hidden lgUp>
              <Box className="cart-item__content__info__options">
                <Typography>{itemOptions()}</Typography>
              </Box>
              <Box className="cart-item__content__info__delete">
                <Typography onClick={() => handleQuantityChange('remove-all')}>
                  <span>
                    <IconComponent icon="trashJaka" />
                    {t('cart.remove')}
                  </span>
                </Typography>
              </Box>
              <Box className="cart-item__content__info__prices">{formatedPrices()}</Box>
            </Hidden>
            <Hidden mdDown>
              <Box className="cart-item__content__info__toggle">{subscriptionToggle()}</Box>
            </Hidden>
            {isEdition && product_type === 'diapers' && (
              <Grid item container xs={12} lg={12} className="cart-item__content__upgrade-size">
                <button type="button" onClick={() => setOpenUpgradeSize(true)}>
                  {t(
                    `${
                      productItem.product_size === '6' ? 'cart.upgradeSize.changeBrand' : 'cart.upgradeSize.upgradeSize'
                    }`
                  )}
                </button>
              </Grid>
            )}
          </Grid>
          <Hidden mdDown>
            <Grid item lg={2} className="cart-item__content__quantity">
              {quantityModifier()}
            </Grid>
            <Grid item lg={2} className="cart-item__content__prices">
              {formatedPrices()}
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item container className="cart-item__content__quantity-modifier" spacing={2}>
              {quantityModifier()}
            </Grid>

            {subscriptionToggle()}
          </Hidden>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openInfo}
        onClose={() => setOpenInfo(false)}>
        <div tabIndex={-1}>
          <InfoModal
            t={t}
            product={product_type !== 'diapers' && productItem}
            wipe={product_type === 'baby wipes' && productItem}
            diaper={product_type === 'diapers' && productItem}
            selectedVariant={selectedVariant}
            selectedAmount={
              product_type === 'diapers' &&
              ((presentationsBySize && presentationsBySize[selectedVariant.option1][0]) || selectedVariant.option1)
            }
            prices={{ price, compare_at_price, appliedDiscount }}
            sizeRanges={sizeRanges}
            img={selectedVariantImage ? selectedVariantImage.src : ImageCheck(productItem).src}
            handleClose={() => setOpenInfo(false)}
            onlyInfo
            isEdition={isEdition}
            offer={product_type !== 'diapers' && product_type !== 'baby wipes' && isOffer(productItem, selectedVariant)}
            currentLanguage={currentLanguage}
          />
        </div>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openUpgradeSize}
        onClose={() => setOpenUpgradeSize(false)}>
        <div tabIndex={-1}>
          <UpgradeSize t={t} handleClose={() => setOpenUpgradeSize(false)} diaperToReplace={productItem} />
        </div>
      </Modal>
    </Fragment>
  )
}

CartProductItem.propTypes = propTypes
export default translate('common')(CartProductItem)
