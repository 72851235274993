/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { InstantSearch, Configure, Highlight, SearchBox, Hits, connectStateResults } from 'react-instantsearch-dom'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import algoliasearch from 'algoliasearch/lite'
import { getDetailPageURI, gtmSubmitSearch, gtmViewSearchResults } from '../../../utils/helpers'
import Actions from '../../../actions'
import config from '../../../config'
import IconComponent from '../../atoms/IconComponent'
import { FIRST_COLLECTION } from '../../../utils/urls'

const AlgoliaSearch = ({ inputSearchVisible, setInputSearchVisible, t }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showTopSearches, setShowTopSearchs] = useState(inputSearchVisible)
  const [showProducts, setShowProducts] = useState(false)
  const setFilter = data => dispatch(Actions.Products.filter(data))
  const filterParam = useSelector(state => state.Products.filter)
  const searchSubmitResults = useSelector(state => state.Products.searchResults)
  const [searchParam, setSearchParam] = useState(filterParam)
  const getPrice = (regularPrice, discountPrice) => (discountPrice > 0 ? discountPrice : regularPrice).toFixed(2)
  const searchClient = algoliasearch('OEAY8H1VPK', config.ALGOLIA_KEY)
  const StateResults = ({ searchResults }) => {
    const hasResults = searchResults && searchResults.nbHits !== 0
    return (
      <div className="algolia__results__not-found" hidden={hasResults}>
        <p>
          {`${t('search.noResults')}  
          "${searchResults.query}"`}
        </p>
      </div>
    )
  }
  const CustomStateResults = connectStateResults(StateResults)

  const Hit = ({ hit }) => {
    const getInfo = type => {
      switch (type) {
        case 'Diapers':
          return `${hit.option2}u./${hit.option3}`
        case 'Baby Wipes':
          return `${hit.option1}u.`
        default:
          return `${hit.option1} ${hit.option2}`
      }
    }

    return (
      <Grid
        className="algolia__results__hit-container"
        onClick={() => {
          getDetailPageURI(hit.id, hit.title, history)
          setShowProducts(false)
          setShowTopSearchs(false)
          if (setInputSearchVisible) setInputSearchVisible(false)
        }}
        style={{ display: 'flex', justifyItems: 'flex-start', alignItems: 'center', listStyle: 'none' }}
        key={hit.objectID}>
        <div className="algolia__results__description">
          <div className="algolia__results__description__img-container">
            <img src={hit.image} alt="" />
          </div>
          <Highlight hit={hit} attribute="title" separator=" - " tagName="mark" />
          <p>{getInfo(hit.product_type)}</p>
        </div>
        <p>
          <strong>{`AED ${getPrice(hit.price, hit.compare_at_price)}`}</strong>
        </p>
      </Grid>
    )
  }
  const Search = ({ hit }) => (
    <Grid
      onClick={() => {
        setFilter(hit.query)
        setSearchParam(hit.query)
        if (setInputSearchVisible) setInputSearchVisible(false)
        history.push(`/products/${FIRST_COLLECTION}`)
      }}
      className="algolia__top-searches__search">
      <p>{hit.query}</p>
    </Grid>
  )
  useEffect(() => {
    if (filterParam.length) gtmViewSearchResults(searchParam, searchSubmitResults)
  }, [filterParam])

  return (
    <div
      className="algolia"
      onMouseLeave={() => {
        setShowTopSearchs(false)
        setShowProducts(false)
        if (setInputSearchVisible) setInputSearchVisible(false)
      }}>
      <InstantSearch indexName="shopify_products" searchClient={searchClient}>
        <SearchBox
          autoFocus
          onSubmit={e => {
            e.preventDefault()
            gtmSubmitSearch(searchParam)
            setFilter(searchParam)
            setSearchParam(searchParam)
            setShowProducts(false)
            setShowTopSearchs(false)
            if (setInputSearchVisible) setInputSearchVisible(false)
          }}
          defaultRefinement={filterParam}
          onClick={e => {
            setShowTopSearchs(true)
            setShowProducts(e.target.value.length > 0)
          }}
          onChange={e => {
            setShowProducts(e.target.value.length > 0)
            if (!setInputSearchVisible) setShowTopSearchs(e.target.value.length > 0)
            setSearchParam(e.target.value)
          }}
          onReset={() => {
            if (setInputSearchVisible) setInputSearchVisible(false)
            setShowTopSearchs(false)
            setShowProducts(false)
            setFilter('')
          }}
          className="algolia__search-box"
          translations={{
            submitTitle: t('search.submit'),
            resetTitle: t('search.reset'),
            placeholder: t('search.placeholder')
          }}
        />
        <Configure filters="inventory_quantity>0" hitsPerPage={5} />
        {showProducts && (
          <Grid className="algolia__results" onMouseEnter={() => setShowTopSearchs(true)}>
            <CustomStateResults />
            <Hits hitComponent={Hit} />
          </Grid>
        )}
      </InstantSearch>
      {showTopSearches && (
        <InstantSearch indexName="shopify_products_query_suggestions" searchClient={searchClient}>
          <Grid className={'algolia__top-searches__container'}>
            <p className="algolia__top-searches__title">{t('search.popular')}</p>
            <Hits className="algolia__top-searches" hitComponent={Search} />
            {inputSearchVisible && (
              <IconComponent height={12} icon="close" fill={'#b5889b'} onClick={() => setInputSearchVisible(false)} />
            )}
          </Grid>
          <Configure hitsPerPage={6} />
        </InstantSearch>
      )}
    </div>
  )
}
export default AlgoliaSearch
