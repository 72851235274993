/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Collapse } from '@material-ui/core'
import { translate } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import _ from 'lodash'
import moment from 'moment'
import BoxItem from '../../molecules/BoxItem'
import BoxEditionAlertModal from '../BoxEditionAlertModal'
import boxSubscribed from '../../../assets/images/my-subscription.svg'
import Actions from '../../../actions'
import { FIRST_COLLECTION } from '../../../utils/urls'
import propTypes from './props'
import { haveOfferTag, editSelectedSubscription, getDifferentProducts } from '../../../utils/helpers'

const MySubscription = ({ subscriptions, mobile, t, history, isEdition, allCollections }) => {
  const [showProducts, setShowProducts] = useState(false)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [cancelSelected, setCancelSelected] = useState(false)

  const dispatch = useDispatch()
  const flushCheckout = () => dispatch(Actions.Checkout.flush())
  const setThisBox = box => dispatch(Actions.Checkout.setThisBox(box))
  const setOneOffBox = type => dispatch(Actions.Checkout.setBoxTypePurchase(type))

  const diapersInCart = useSelector(state => state.Checkout.selectedDiapers)
  const wipesInCart = useSelector(state => state.Checkout.selectedWipes)
  const upsellingInCart = useSelector(state => state.Checkout.upselling)
  const { status } = useSelector(state => state.Checkout.status || {})

  const activeSubscriptions = subscriptions.filter(
    sub => sub.purchaseType === 'SUBSCRIPTION' && sub.status === 'PENDING'
  )

  const nearSubscription = _.orderBy(activeSubscriptions, [el => moment(el.shippingDate)], ['asc'])[0]

  const selectedDiapers = []
  const selectedWipes = []

  nearSubscription.boxProducts.forEach(prod => {
    if (prod.product_type === 'diapers' || prod.type === 'diapers') {
      selectedDiapers.push({ ...prod, ...prod.shopifyProduct })
    }
    if (prod.product_type === 'baby wipes' || prod.type === 'baby wipes') {
      for (let i = 0; i < prod.quantity; i += 1) {
        selectedWipes.push({ ...prod, ...prod.shopifyProduct })
      }
    }
  })

  const upselling = []

  nearSubscription.boxAddOns.forEach(prod => upselling.push({ ...prod, ...prod.shopifyProduct }))

  const getMonthlyAndOneTimeProducts = () => {
    const monthlyProducts = []
    const oneTimeProducts = []

    const products = nearSubscription.boxProducts.concat(nearSubscription.boxAddOns)
    products.forEach(prod =>
      prod.purchase_type === 'SUBSCRIPTION' || prod.purchaseType === 'SUBSCRIPTION'
        ? monthlyProducts.push({ ...prod, ...prod.shopifyProduct })
        : oneTimeProducts.push({ ...prod, ...prod.shopifyProduct })
    )

    const monthlyProductsUnique = _.uniqWith(monthlyProducts, _.isEqual)
    const oneTimeProductsUnique = _.uniqWith(oneTimeProducts, _.isEqual)

    return {
      monthlyProducts,
      oneTimeProducts,
      monthlyProductsUnique,
      oneTimeProductsUnique
    }
  }

  const { monthlyProducts, oneTimeProducts, monthlyProductsUnique, oneTimeProductsUnique } =
    getMonthlyAndOneTimeProducts()

  const isSubscribable = oneTimeProduct => {
    const variant = oneTimeProduct.variants[0]
    return variant.appliedDiscount > 0 && !haveOfferTag(oneTimeProduct)
  }

  const boxInEdition = {
    selectedDiapers,
    selectedWipes,
    upselling,
    paymentType: nearSubscription.paymentType,
    promoCode: nearSubscription.promoCode ? nearSubscription.promoCode.id : '',
    shippingDate: nearSubscription.shippingDate
  }

  const editBox = () => {
    setThisBox(boxInEdition)

    const subs = editSelectedSubscription(nearSubscription)
    dispatch(Actions.Checkout.editSubscription(subs))
    dispatch(Actions.Checkout.setStatus())
    history.push('/cart')
  }
  const boxInEditionCart = {
    selectedDiapers: diapersInCart,
    selectedWipes: wipesInCart,
    upselling: upsellingInCart
  }

  useEffect(() => {
    if (status === 'ok') {
      history.push('/boxes')
    }
  }, [status])

  return (
    <Grid item container className={`my-subscription ${allCollections ? 'all-collections' : ''}`}>
      <Grid className="my-subscription__header" onClick={() => setShowProducts(!showProducts)}>
        <img src={boxSubscribed} alt="Box Subscribed" className="my-subscription__header__image" />
        <Typography item className="my-subscription__header__title">
          {t('cart.mySubscription')}
          <span>{`${t('shipping.date')} ${moment(nearSubscription.shippingDate).format('DD/MM/YYYY')}`}</span>
        </Typography>
        {mobile && (
          <Grid className="my-subscription__header__icon-grid">
            {showProducts ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        )}
      </Grid>
      <Collapse in={!mobile || (mobile && showProducts)}>
        <Grid className="my-subscription__content">
          <Grid className="my-subscription__content__products">
            {monthlyProductsUnique.map((prod, index) => {
              return (
                <BoxItem
                  key={`${prod.id}${index}`}
                  t={t}
                  item={prod}
                  subscribed
                  products={monthlyProducts}
                  inDashboard
                />
              )
            })}
            {oneTimeProductsUnique.map((prod, index) => {
              return (
                <BoxItem
                  t={t}
                  key={`${prod.id}${index}`}
                  item={prod}
                  isSubscribable={isSubscribable(prod)}
                  products={oneTimeProducts}
                  inDashboard
                />
              )
            })}
          </Grid>
        </Grid>

        <Grid container justify="center" alignContent="center">
          <Typography
            onClick={() => {
              if (isEdition) setOpenAlertModal(true)
              else {
                setOneOffBox(null)
                editBox(nearSubscription.id)
              }
            }}
            item
            className={`my-subscription__content__edit-button ${isEdition && 'cancel-button'}`}>
            {isEdition ? t('cart.cancelEdition') : t('cart.editMyBox')}
          </Typography>
        </Grid>
      </Collapse>
      <BoxEditionAlertModal
        inDashboard
        flushCheckout={flushCheckout}
        handleClose={() => setOpenAlertModal(false)}
        history={history}
        pathToPush={`/products/${FIRST_COLLECTION}`}
        openAlertModal={openAlertModal}
        setOpenAlertModal={setOpenAlertModal}
        differentProducts={openAlertModal && getDifferentProducts(boxInEdition, boxInEditionCart)}
        t={t}
        cancelSelected={cancelSelected}
        setCancelSelected={setCancelSelected}
        handleBtnSave={() => dispatch(Actions.Checkout.processNewBox())}
      />
    </Grid>
  )
}

MySubscription.propTypes = propTypes
export default translate('common')(MySubscription)
