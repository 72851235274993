/* eslint-disable max-len */
import React from 'react'
import propTypes from './props'

const YallaTruck = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 462.522 462.522"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 432.958 240.26 C 431.506 240.565 430.135 240.852 428.916 241.169 C 415.095 244.763 408.787 246.733 404.123 255.738 L 386.456 291.506 C 380.778 302.467 366.117 311.385 353.774 311.385 L 322.321 311.385 L 322.321 352.688 C 322.321 360.104 316.287 366.138 308.869 366.138 L 89.799 365.918 C 82.581 365.918 77.138 360.182 77.138 352.575 L 77.138 340.367 L 21.018 340.367 C 9.429 340.366 0 330.938 0 319.348 C 0 307.758 9.429 298.33 21.018 298.33 L 77.137 298.33 L 77.137 278.185 L 40.394 278.185 C 28.805 278.185 19.376 268.756 19.376 257.167 C 19.376 245.578 28.805 236.149 40.394 236.149 L 77.137 236.149 L 77.137 216.004 L 59.77 216.004 C 48.181 216.004 38.752 206.575 38.752 194.986 C 38.752 183.397 48.181 173.968 59.77 173.968 L 77.137 173.968 L 77.137 152.898 C 77.137 145.482 83.171 139.448 90.587 139.448 L 113.375 139.448 C 116.924 115.125 137.917 96.384 163.212 96.384 C 188.509 96.384 209.503 115.125 213.053 139.448 L 305.277 139.448 C 305.756 139.448 306.247 139.48 306.737 139.512 C 310.259 115.158 331.265 96.384 356.582 96.384 C 381.879 96.384 402.873 115.125 406.423 139.448 L 439.155 139.448 C 452.04 139.448 462.523 149.93 462.523 162.814 L 462.523 202.462 C 462.522 234.057 444.73 237.79 432.958 240.26 Z M 356.582 165.062 C 366.682 165.062 374.899 156.848 374.899 146.751 C 374.899 136.654 366.682 128.44 356.582 128.44 C 346.486 128.44 338.272 136.654 338.272 146.751 C 338.272 156.848 346.486 165.062 356.582 165.062 Z M 322.321 243.108 L 322.321 291.878 L 346.357 291.878 C 355.595 291.878 366.991 284.946 371.221 276.784 L 386.942 244.955 C 387.275 244.311 387.621 243.697 387.98 243.109 L 322.321 243.109 L 322.321 243.108 Z M 181.529 146.752 C 181.529 136.656 173.312 128.441 163.212 128.441 C 153.116 128.441 144.903 136.655 144.903 146.752 C 144.903 156.849 153.116 165.063 163.212 165.063 C 173.312 165.062 181.529 156.848 181.529 146.752 Z"
      transform="matrix(-1, 0, 0, -1, 462.52301, 462.522003)"
      style={{ fill: 'rgb(115, 187, 144)' }}
    />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)

YallaTruck.propTypes = propTypes
export default YallaTruck
