import React, {useState,useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import { translate } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Typography,Fade } from '@material-ui/core'
import propTypes from './props'

const BottomNotification = ({t}) => {
  const isEdition = useSelector(state => state.Checkout.isEdition)
  const oneOffBox = useSelector(state => state.Checkout.boxTypePurchase) === 'oneOffs'
  const [show,setShow] = useState(false)
  const location = useLocation()
  const getLocation = location.pathname.includes('product')
  const productDetail= location.pathname.includes('product/')

  const getText = () =>{
    switch (true) {
      case isEdition: return t('notifications.edition')
      case oneOffBox: return t('notifications.oneOff') 
      default: return null;
    }
     }
     
  useEffect(() => {
    setShow(isEdition || oneOffBox)
  }, [isEdition,oneOffBox])

return (
  <Fade timeout={500} in={getLocation && show}>
    <Grid onClick={()=>setShow(false)} container item className={`bottom-notification ${productDetail && 'product-detail'}`}>
      <Typography className={`bottom-notification__text ${productDetail && 'product-detail'}`}>
        {getText()}
      </Typography>
    </Grid>
  </Fade>
)}

BottomNotification.propTypes = propTypes
export default translate('common')(BottomNotification)