import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Snackbar, Grid, Hidden } from '@material-ui/core'
import proptypes from './props'
import MainContent from '../../components/organisms/MainContent'
import Footer from '../../components/molecules/Footer'
import Actions from '../../actions'
import Header from '../../components/organisms/Header'
import CreateBoxButton from '../../components/molecules/CreateBoxButton'

const Home = ({ history, t, children }) => {
  const [videoOn, setVideoOn] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const helpdeskSessionObject = useSelector(state => state.Universal.helpdeskSession)
  const currentLanguage = useSelector(state => state.Language.current)
  const userData = useSelector(state => state.Users.userData)
  const isUserLoggedIn = useSelector(state => state.Users.logged)
  const userEmail = useSelector(state => state.Users.userData && state.Users.userData.email)
  const babyData = useSelector(state => state.Checkout.babyData)

  const { isHelpdeskSession, status } = helpdeskSessionObject

  const dispatch = useDispatch()
  const updateUser = () => dispatch(Actions.Users.fetchUpdateUser())
  const getContextData = () => dispatch(Actions.Checkout.getContextData())

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isHelpdeskSession) {
      updateUser()
    }
    getContextData()
  }, [])
  useEffect(() => {
    if (isHelpdeskSession && status) {
      setOpenSnackbar(true)
    }
  }, [helpdeskSessionObject])

  useEffect(() => {
    if (isUserLoggedIn && window.FS)
      window.FS.identify(userData.email, { displayname: userData.email, email: userData.email })
  }, [isUserLoggedIn])

  return (
    <>
      <Header t={t} headerAdd bg history={history} showSideCartIcon />
      <MainContent t={t} videoOn={videoOn} setVideoOn={setVideoOn} />
      <Footer t={t} withMargin currentLanguage={currentLanguage} history={history} />
      {children && children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        variant="info"
        open={openSnackbar}
        autoHideDuration={4000}
        className={status === 'ok' ? 'notify--success' : 'notify--error'}
        onClose={() => setOpenSnackbar(false)}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={
          <span id="message-id">{status === 'ok' ? `${t('helpdesk.loggedIn')}${userEmail}` : t('helpdesk.error')}</span>
        }
      />
      <Hidden smUp>
        <Grid container alignItems="center" className="fixed-menu">
          <CreateBoxButton
            buttonColor="green"
            t={t}
            title={t('default.createYourBox')}
            icon
            onClick={() => {
              if (babyData) history.push('/products')
            }}
          />
        </Grid>
      </Hidden>
    </>
  )
}

Home.propTypes = proptypes
export default translate('common')(Home)
