import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import upvotyjs from '../../../utils/upvoty';

const UpvotyWidget = ({ ssoToken, baseUrl }) => {
  useEffect(() => {

    upvotyjs.init('render', { ssoToken, baseUrl});
    return () => {
      upvotyjs.destroy();
    };
  }, []);
  return (
    <div data-upvoty />
  );
}

UpvotyWidget.defaultProps = {
  ssoToken: null,
};

UpvotyWidget.propTypes = {
  ssoToken: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
};

export default UpvotyWidget;