/* eslint-disable max-len */
import React from 'react'

const CartIcon = () => (
  <svg width="37px" height="36px" viewBox="0 0 37 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Header/-nuevo-gris/-web" transform="translate(-1145.000000, -20.000000)" fill="#FFFFFF">
        <g id="Fill-70-Copy-2" transform="translate(1145.000000, 20.000000)">
          <path
            d="M10.7826511,29 C8.79922027,29 7.19444444,30.575875 7.19444444,32.5 C7.19444444,34.425875 8.79922027,36 10.7826511,36 C12.7660819,36 14.3888889,34.425875 14.3888889,32.5 C14.3888889,30.575875 12.7660819,29 10.7826511,29 L10.7826511,29 Z M0,3.6 L3.7,3.6 L10.36,17.2629 L7.8625,21.6729 C7.5665,22.176 7.4,22.77 7.4,23.4 C7.4,25.3809 9.065,27 11.1,27 L33.3,27 L33.3,23.4 L11.877,23.4 C11.618,23.4 11.4145,23.202 11.4145,22.95 L11.47,22.734 L13.135,19.8 L26.9175,19.8 C28.305,19.8 29.526,19.0629 30.155,17.9469 L36.778,6.2649 C36.926,6.0129 37,5.706 37,5.4 C37,4.41 36.1675,3.6 35.15,3.6 L7.7885,3.6 L6.0495,0 L0,0 L0,3.6 Z M30.3104288,29 C28.3269981,29 26.7222222,30.575875 26.7222222,32.5 C26.7222222,34.425875 28.3269981,36 30.3104288,36 C32.2938596,36 33.9166667,34.425875 33.9166667,32.5 C33.9166667,30.575875 32.2938596,29 30.3104288,29 L30.3104288,29 Z"
            id="Fill-70-Copy"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default CartIcon
