/* eslint-disable max-len */
import React from 'react'
import propTypes from './props'

const Lightning = ({ fill = '#FFFFFF' }) => (
  <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Card/Accesories/nukpetrol/OFERS/WEB---" transform="translate(-178.000000, -197.000000)" fill={fill}>
        <g id="Group-26-Copy" transform="translate(76.000000, 195.000000)">
          <path
            d="M106.665318,2 L110.453431,2 C110.619117,2 110.753431,2.13431458 110.753431,2.3 C110.753431,2.35706569 110.737155,2.41294768 110.706513,2.46108866 L107.928623,6.82535467 C107.839656,6.96512794 107.880843,7.15055841 108.020616,7.23952522 C108.068757,7.27016734 108.124639,7.28644332 108.181705,7.28644332 L109.512315,7.28644332 C109.678001,7.28644332 109.812315,7.4207579 109.812315,7.58644332 C109.812315,7.64952427 109.792431,7.71100017 109.755489,7.76213224 L104.080018,15.6176354 C103.982987,15.7519365 103.795456,15.7821504 103.661155,15.6851201 C103.558853,15.6112089 103.51375,15.481174 103.548313,15.3597906 L105.215523,9.50455287 C105.260897,9.34520135 105.168499,9.17923894 105.009148,9.13386545 C104.982425,9.12625664 104.954776,9.12239691 104.926992,9.12239691 L102.544404,9.12239691 C102.378719,9.12239691 102.244404,8.98808233 102.244404,8.82239691 C102.244404,8.76567307 102.260486,8.71011154 102.290784,8.66215716 L106.411698,2.13976026 C106.466669,2.0527547 106.562402,2 106.665318,2 Z"
            id="Path-3"
          />
        </g>
      </g>
    </g>
  </svg>
)

Lightning.propTypes = propTypes
export default Lightning
