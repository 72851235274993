import React, { useState, useEffect } from 'react'
import { Grid, Stepper, Step, StepLabel } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

const CheckoutStepper = () => {
  const [step, setStep] = useState(0)
  const location = useLocation()
  const steps = ['Cart', 'Checkout', 'Thank You']

  const getStep = () => (location.pathname.includes('done') ? 2 : 1)

  useEffect(() => {
    setStep(getStep())
  }, [location.pathname])

  return (
    <Grid item justify="center" className="checkout-stepper">
      <Stepper activeStep={step} alternativeLabel>
        {steps.map(el => {
          return (
            <Step key={el}>
              <StepLabel>{el}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Grid>
  )
}
export default CheckoutStepper
