/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
// import CheckoutFrameContainer from '../../atoms/CheckoutFrameContainer'
import PaymentCard from '../../molecules/PaymentCard'
import IconComponent from '../../atoms/IconComponent'
import StripePayment from '../../atoms/StripePayment'

const CardsEdition = ({
  history,
  t,
  cards,
  addNewCard,
  setAddNewCard,
  selectedCard,
  setSelectedCard
  // setPayWithNewCard
}) => {
  const [cardError, setCardError] = useState(false)
  const [disableSaveCard, setDisableSaveCard] = useState(true)
  const [showAlert, setShowAlert] = useState(false)

  const handleAlert = () => {
    setShowAlert(!showAlert)
  }
  const status = useSelector(state => state.Checkout.status)

  const paymentMethod = useSelector(state => state.Users.paymentMethod)

  const dispatch = useDispatch()
  const setStatus = stat => dispatch(Actions.Checkout.setStatus(stat))

  useEffect(() => {
    const isError = status && status.status === 'error'
    setCardError(isError)
    if (!isError && cards.length > 0) setAddNewCard(false)
  }, [status])

  useEffect(() => {
    if (paymentMethod && paymentMethod.length > 0) {
      paymentMethod.forEach(card => {
        if (card.id !== selectedCard && card.active) setSelectedCard(card.id)
      })
    }
  }, [paymentMethod])

  useEffect(() => {
    setStatus(null)
    return () => {
      setStatus(null)
    }
  }, [])

  return (
    <Fragment>
      <Grid container item xs={12} lg={9} direction="column" alignItems="center" justify="center">
        {cards.length > 0 &&
          cards.map(
            item =>
              item.id && (
                <PaymentCard
                  t={t}
                  key={item.id}
                  item={item}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  cards={cards}
                  handleAlert={handleAlert}
                  showAlert={showAlert}
                />
              )
          )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        className="payment-method__change-card"
        onClick={() => {
          setAddNewCard(!addNewCard)
        }}>
        <span>{t('boxes.addNewCard')}</span>
        <Grid item className={`payment-method__arrow-add-card${addNewCard ? '-close' : ''}`}>
          <IconComponent icon="arrow" />
        </Grid>
      </Grid>
      {addNewCard && (
        <Grid item container className="payment-method__add-new-container">
          <Grid item container className="payment-method__checkout-frame">
            <Grid item container className="payment-method__add-new-title">
              <span>{t('payment.addCard')}</span>
            </Grid>
            <StripePayment
              history={history}
              disableSaveCard={disableSaveCard}
              setDisableSaveCard={setDisableSaveCard}
              setCardError={setCardError}
            />
            {/* <CheckoutFrameContainer
              history={history}
              setDisableSaveCard={setDisableSaveCard}
              setCardError={setCardError}
            /> */}
          </Grid>
          <Grid item container direction="row">
            {/* <Grid item container justify="center">
              <button
                type="submit"
                className={`payment-method__save-card${disableSaveCard ? '__disabled' : ''}`}
                id="pay-button"
                disabled={disableSaveCard}
                onClick={() => !disableSaveCard && setPayWithNewCard(true)}>
                {t('checkout.saveCard')}
                <IconComponent icon="floppyDisk" />
              </button>
            </Grid> */}
            <Grid item container justify="center">
              {addNewCard && (
                <button
                  type="button"
                  className="payment-method__cancel-add-card"
                  onClick={() => {
                    setAddNewCard(!addNewCard)
                    setStatus(null)
                  }}>
                  {t('default.cancel')}
                </button>
              )}
            </Grid>
          </Grid>
          <Grid>
            <Typography className="payment-method__alert-charge">{t('checkout.chargeRefundOnCard')}</Typography>
          </Grid>
        </Grid>
      )}
      {cardError && (
        <Grid item container className="payment-method__error-message">
          {t('payment.errorCard')}
        </Grid>
      )}
    </Fragment>
  )
}

CardsEdition.propTypes = propTypes
export default translate('common')(CardsEdition)
