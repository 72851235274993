/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Fade, Grid, Typography } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import { translate } from 'react-i18next'
import heart from '../../../assets/images/heart.svg'
import reviewstars from '../../../assets/images/review-stars.svg'
import commentBox from '../../../assets/images/comment-box.svg'
import commentBoxMobile from '../../../assets/images/comment-box-mobile.svg'
import hollyMom from '../../../assets/images/hollymom.png'
import anikaMom from '../../../assets/images/anikamom.png'
import TrustPilot from '../TrustPilot'
import propTypes from './props'

const MomsReviewsBanner = ({ t }) => {
  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 800
  const [showReview, setShowReview] = useState(isMobile ? 1 : 0)
  const slidesNumber = 2
  let timer = 0

  useEffect(() => {
    if (isMobile)
      timer = setInterval(() => {
        setShowReview(showReview === 1 ? 2 : 1)
      }, 3000)
    return () => clearInterval(timer)
  }, [showReview, isMobile])

  const MomReview = ({ text, photo, name, babyName }) => {
    return (
      <>
        <Grid
          onClick={() => {
            if (isMobile) {
              setShowReview(showReview === 1 ? 2 : 1)
              clearInterval(timer)
            }
          }}
          className="moms-reviews-banner__slider__comment-box">
          <img className="moms-reviews-banner__slider__comment-box__img" src={commentBox} alt="comment box" />
          <img
            className="moms-reviews-banner__slider__comment-box__img mobile"
            src={commentBoxMobile}
            alt="comment box"
          />
          <Typography className="moms-reviews-banner__slider__comment-box__text">{text}</Typography>
          <img className="moms-reviews-banner__slider__comment-box__photo" src={photo} alt="babys mom" />
          <Grid className="moms-reviews-banner__slider__comment-box__names">
            <Typography>{name}</Typography>
            <Typography>{babyName}</Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <Grid className="moms-reviews-banner">
      <Grid>
        <Typography className="moms-reviews-banner__title">{t('momsReviews.title1')}</Typography>
        <Typography className="moms-reviews-banner__title">
          {t('momsReviews.title2')}
          <img className="moms-reviews-banner__icon" src={heart} alt="red heart" />
          {t('momsReviews.title3')}
        </Typography>
        <img className="moms-reviews-banner__reviews" src={reviewstars} alt="best reviews" />
      </Grid>
      <Grid className="moms-reviews-banner__slider">
        <Fade timeout={{ enter: 1500, exit: 500 }} in={showReview === 1 || !isMobile}>
          <Grid style={{ position: `${isMobile ? 'absolute' : 'relative'}` }}>
            <MomReview
              text={t('momsReviews.mom1.text')}
              photo={anikaMom}
              name={t('momsReviews.mom1.name')}
              babyName={t('momsReviews.mom1.momOf')}
            />
          </Grid>
        </Fade>
        <Fade timeout={{ enter: 1500, exit: 500 }} in={showReview === 2 || !isMobile}>
          <Grid style={{ position: `${isMobile ? 'absolute' : 'relative'}` }}>
            <MomReview
              text={t('momsReviews.mom2.text')}
              photo={hollyMom}
              name={t('momsReviews.mom2.name')}
              babyName={t('momsReviews.mom2.momOf')}
            />
          </Grid>
        </Fade>
        {isMobile && (
          <Grid className="moms-reviews-banner__slider__control">
            <ArrowBack
              onClick={() => {
                if (isMobile) {
                  setShowReview(1)
                  clearInterval(timer)
                }
              }}
            />
            {[...Array(slidesNumber)].map((el, i) => {
              return (
                <FiberManualRecordIcon
                  className={`moms-reviews-banner__slider__control__dot ${i + 1 === showReview && 'selected'}`}
                />
              )
            })}
            <ArrowForward
              onClick={() => {
                if (isMobile) {
                  setShowReview(2)
                  clearInterval(timer)
                }
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid className="moms-reviews-banner__trustpilot">
        <TrustPilot inMomsReviewsBanner />
      </Grid>
    </Grid>
  )
}

MomsReviewsBanner.propTypes = propTypes
export default translate('common')(MomsReviewsBanner)
