/* eslint-disable react/prop-types */
import React from 'react'
import { translate } from 'react-i18next'
import { Modal, Grid, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import IconComponent from '../../atoms/IconComponent'
import FirstModalCancel from '../../molecules/FirstModalCancel'
import Actions from '../../../actions'
import propTypes from './props'
import { FIRST_COLLECTION } from '../../../utils/urls'
import FlatButton from '../../molecules/FlatButton'

const BoxEditionAlertModal = ({
  t,
  history,
  openAlertModal,
  setOpenAlertModal,
  pathToPush,
  flushCheckout,
  handleClose,
  isRememberModal,
  inDashboard,
  hasChangedToOneOff,
  saveNextButton,
  cancelSelected,
  setCancelSelected,
  differentProducts,
  handleBtnSave
}) => {
  const isInProducts = window.document.URL.includes('/products')
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    setOpenAlertModal(false)
  }

  const gtmRemoveDiffProducts = () => {
    differentProducts.forEach(prod => {
      dispatch(
        Actions.Checkout.gtmRemoveProducts({
          ...prod,
          variantId: +prod.shopifyVariantId || +prod.variantId
        })
      )
    })
  }

  const handleOnClickLeftBtn = () => {
    history.push(pathToPush)
    if (differentProducts) gtmRemoveDiffProducts()
    if (inDashboard) {
      flushCheckout()
      handleClose()
    }
    if (flushCheckout && pathToPush === '/account') flushCheckout()
  }

  const handleOnClickRightBtn = () => {
    if (isInProducts && handleClose) handleClose()
    if (isRememberModal) {
      history.push(`/products/${FIRST_COLLECTION}`)
      handleCloseModal()
    } else if (hasChangedToOneOff) {
      setCancelSelected(false)
    } else {
      handleBtnSave()
      setCancelSelected(false)
      handleCloseModal()
      if (handleClose) handleClose()
    }
  }

  return (
    <Modal
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openAlertModal}
      onClose={handleCloseModal}>
      <div tabIndex={-1}>
        <Grid container className="yalla-modal">
          <Grid
            item
            xs={2}
            container
            justify="center"
            alignItems="center"
            direction="column"
            className="yalla-modal__close">
            <IconComponent
              width="15px"
              height="15px"
              fill="#999"
              icon="close"
              onClick={() => {
                setCancelSelected(false)
                handleCloseModal()
                if (handleClose) handleClose()
              }}
            />
          </Grid>
          {hasChangedToOneOff && !cancelSelected ? (
            <FirstModalCancel
              t={t}
              saveNextButton={saveNextButton}
              handleClose={handleClose}
              closeAlertModal={() => {
                setCancelSelected(false)
                handleCloseModal()
              }}
              hasChangedToOneOff={hasChangedToOneOff}
              history={history}
            />
          ) : (
            <Grid item container xs={12} alignItems="center" direction="column" className="yalla-modal__container">
              <Typography className="title">
                {isRememberModal ? t('cart.cancelModal.message2') : t('cart.cancelModal.message1')}
              </Typography>
              <Grid container spacing={2} className="edition-options-container yalla-modal__container__actions">
                <Grid item container sm={6}>
                  <FlatButton
                    onClick={() => handleOnClickLeftBtn()}
                    classes="flat-button--gray"
                    title={isRememberModal ? t('cart.cancelModal.cancelEdition') : t('cart.cancelModal.leaveEdition')}
                  />
                </Grid>
                <Grid item container sm={6}>
                  <FlatButton
                    onClick={() => handleOnClickRightBtn()}
                    classes="flat-button--green-jaka"
                    title={isRememberModal ? t('cart.cancelModal.addProducts') : t('cart.cancelModal.saveEdition')}
                    cartIcon={!isRememberModal ? 'floppyDisk' : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Modal>
  )
}

BoxEditionAlertModal.propTypes = propTypes
export default translate('common')(BoxEditionAlertModal)
