import React, { useState } from 'react'
import { Grid, Typography, Divider, Hidden } from '@material-ui/core'
import NewsLetter from '../../molecules/NewsLetter'
import PrepBox from '../../../assets/images/prep-box.svg'
import ShipDate from '../../../assets/images/ship-date.svg'
import Adjust from '../../../assets/images/adjust.svg'
import HomeTopDesktop from '../../../assets/images/home-top-desktop.svg'
import HomeBottomDesktop from '../../../assets/images/home-bottom-desktop.svg'
import HomeTopMobile from '../../../assets/images/home-top-mobile.svg'
import HomeBottomMobile from '../../../assets/images/home-bottom-mobile.svg'
import Diapering from '../../../assets/images/diapering.svg'
import Essentials from '../../../assets/images/essentials.svg'
import Nutrition from '../../../assets/images/nutrition.svg'
import Mums from '../../../assets/images/mums.svg'
import propTypes from './props'
import YTVideo from '../../molecules/YTVideo'

const MainContent = ({ t }) => {
  const [open, setOpen] = useState(false)
  return (
    <Grid container className="main-content" justify="center">
      <Grid container className="hero-image">
        <Grid container className="hero-text" alignContent="flex-start">
          <Grid item xs={12} className="hero-text__title">
            <Typography variant="inherit">{t('home.mumsLife')}</Typography>
            <Typography variant="inherit">{t('home.madeEasier')}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} className="hero-text__subtitle">
            <Typography variant="inherit">
              <span>{t('default.yallaBabyBox')}</span>
              {t('home.homeTex1')}
            </Typography>
          </Grid>
          <YTVideo t={t} open={open} setOpen={setOpen} />
        </Grid>
      </Grid>

      <Grid container className="how-works">
        <Grid item xs={12}>
          <Typography variant="inherit" className="how-works__title">
            {t('home.howWorks')}
          </Typography>
        </Grid>

        <Grid xs={12} sm={4} item container className="how-works__items">
          <Grid item container xs={8} alignContent="center" className="how-works__items__first">
            <Typography variant="inherit">{t('home.prepBox')}</Typography>
            <Typography variant="inherit">{t('home.prepText')}</Typography>
          </Grid>
          <Grid item xs={4} className="home-img-container">
            <img className="img-jig" src={PrepBox} alt={t('home.prepBox')} />
          </Grid>
          <Grid item xs={10} className="how-works__items__divider hidden-lg">
            <Divider />
          </Grid>
        </Grid>

        <Grid xs={12} sm={4} item container className="how-works__items">
          <Grid item xs={4}>
            <img className="img-jig" src={ShipDate} alt={t('home.shipDate')} />
          </Grid>
          <Grid item container xs={8} alignContent="center" className="how-works__items__second">
            <Typography variant="inherit">{t('home.shipDate')}</Typography>
            <Typography variant="inherit">{t('home.shipText')}</Typography>
          </Grid>
          <Grid item xs={10} className="how-works__items__divider hidden-lg">
            <Divider />
          </Grid>
        </Grid>

        <Grid xs={12} sm={4} item container className="how-works__items">
          <Grid item container xs={8} alignContent="center" className="how-works__items__third">
            <Typography variant="inherit">{t('home.adjustTime')}</Typography>
            <Typography variant="inherit">{t('home.adjustText')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <img className="img-jig" src={Adjust} alt={t('home.adjustTime')} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="how-works">
        <Hidden smDown>
          <Grid item container xs={12}>
            <img className="stickers" src={HomeTopDesktop} alt={t('home.homeTop')} />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item container xs={12}>
            <img className="stickers" src={HomeTopMobile} alt={t('home.homeTop')} />
          </Grid>
        </Hidden>
        <Grid item container xs={12} className="how-works__text-centered">
          <Typography variant="inherit">{t('home.homeText2')}</Typography>
          <Typography variant="inherit">{t('home.homeText3')}</Typography>
          <Typography variant="inherit">{t('home.homeText4')}</Typography>
        </Grid>
        <Hidden smDown>
          <Grid item container xs={12}>
            <img className="stickers" src={HomeBottomDesktop} alt={t('home.homeBottom')} />
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item container xs={12}>
            <img className="stickers" src={HomeBottomMobile} alt={t('home.homeBottom')} />
          </Grid>
        </Hidden>
      </Grid>

      <Grid container className="our-products">
        <Grid item xs={12}>
          <Typography className="our-products__title">{t('home.ourProducts')}</Typography>
        </Grid>

        <Grid item container xs={12} sm={6} md={3} justify="center" alignItems="center" direction="column" className="our-products__items">
          <img src={Diapering} alt={t('home.diapering')} />
          <Typography variant="inherit" className="our-products__items__title">
            {t('home.diapering')}
          </Typography>
          <Typography variant="inherit">{t('home.diaperingText')}</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={3} justify="center" alignItems="center" direction="column" className="our-products__items">
          <img src={Essentials} alt={t('home.essentials')} />
          <Typography variant="inherit" className="our-products__items__title">
            {t('home.essentials')}
          </Typography>
          <Typography variant="inherit">{t('home.essentialsText')}</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={3} justify="center" alignItems="center" direction="column" className="our-products__items">
          <img src={Nutrition} alt={t('home.nutrition')} />
          <Typography variant="inherit" className="our-products__items__title">
            {t('home.nutrition')}
          </Typography>
          <Typography variant="inherit">{t('home.nutritionText')}</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={3} justify="center" alignItems="center" direction="column" className="our-products__items">
          <img src={Mums} alt={t('home.mums')} />
          <Typography variant="inherit" className="our-products__items__title">
            {t('home.mums')}
          </Typography>
          <Typography id="about" variant="inherit">
            {t('home.mumsText')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className="about">
        <Grid item xs={12}>
          <Typography className="about__title">{t('default.aboutUs')}</Typography>
        </Grid>
        <Grid item xs={12} className="text-center">
          <Typography variant="inherit">{t('home.aboutUsText1')}</Typography>
          <Typography variant="inherit">{t('home.aboutUsText2')}</Typography>
          <Typography variant="inherit">{t('home.aboutUsText3')}</Typography>
          <Typography variant="inherit">{t('home.aboutUsText4')}</Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} className="hero-bottom" alignItems="flex-end">
        <NewsLetter t={t} />
        <Typography variant="inherit" />
      </Grid>
    </Grid>
  )
}

MainContent.propTypes = propTypes
export default MainContent
