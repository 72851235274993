/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Grid } from '@material-ui/core'
import propTypes from './props'

const HeaderSiteMap = ({ t, xs, md, history }) => (
  <Grid
    item
    container
    justify="flex-end"
    alignItems="center"
    direction="row"
    xs={xs}
    md={md}
    className="header-site-map">
    <Grid item xs>
      <button
        type="button"
        onClick={() => {
          history.push('/about-us')
        }}>
        {t('default.aboutUs')}
      </button>
    </Grid>
    <Grid item xs>
      <a href="https://yallababy.com/blog" target="_blank">
        {t('default.mumsBlog')}
      </a>
    </Grid>
    <Grid item xs>
      <button type="button" onClick={() => history.push('/faq')}>
        {t('default.faqs')}
      </button>
    </Grid>
  </Grid>
)

HeaderSiteMap.propTypes = propTypes
export default HeaderSiteMap
