import React from 'react'
import { Grid, Typography, Slide } from '@material-ui/core'
import { translate } from 'react-i18next'
import propTypes from './props'

const CantEditAlert = ({openAlert,t}) => {
  return (
    <Slide in={openAlert}>
      <Grid className='box-processed-alert'>
        <Typography className='box-processed-alert__text'>
          {t('cart.cantEditAlert')}
        </Typography>
      </Grid>
    </Slide>
  )
}

CantEditAlert.propTypes = propTypes
export default translate('common')(CantEditAlert)