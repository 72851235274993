import React from 'react';
import propTypes from './props';
import Recovery from '../../../containers/Recovery';
import GenericModal from '../../templates/GenericModal';

const RecoveryModal = ({
  currentLanguage,
  openModal,
  handleClose,
  history,
  t,
}) => (
  <GenericModal openModal={openModal} handleClose={handleClose} currentLanguage={currentLanguage}>
    <Recovery history={history} t={t} closeModal={handleClose} />
  </GenericModal>
);

RecoveryModal.propTypes = propTypes;
export default RecoveryModal;
