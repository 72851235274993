/* eslint-disable react/button-has-type */
import React from 'react'
import { Typography , Grid} from '@material-ui/core'
import CartIcon from '../../atoms/IconComponent/Icons/CartIcon'
import propTypes from './props'

const GoToCartButton = ({history, inProductPage,t}) => {
  
  return (
    <div>
      <Grid className={`go-to-cart__component ${inProductPage && 'product-page'}`} onClick={()=>history.push('/cart')}>
        <Grid className={`go-to-cart__component__icon-text ${inProductPage && 'product-page'}`}>
          <CartIcon />
          <Typography className='go-to-cart__component__text' variant="inherit">
            {t('cart.goToCart')} 
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

GoToCartButton.propTypes = propTypes
export default GoToCartButton
