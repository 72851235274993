/* eslint-disable no-useless-escape */

/* eslint-disable no-unused-expressions */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { translate } from 'react-i18next'
import Actions from '../../actions'
import GenericModal from '../../components/templates/GenericModal'
import Spinner from '../../components/atoms/Spinner'
import proptypes from './props'
import { FIRST_COLLECTION } from '../../utils/urls'

const Helpdesk = ({ history, t, match }) => {
  const userToken = match.params.token
  const dispatch = useDispatch()
  const setHelpdeskToken = token => dispatch(Actions.Users.setHelpdeskUserToken(token))
  const setHelpdeskSession = () =>
    dispatch(Actions.Universal.setHelpdeskSession({ isHelpdeskSession: true, status: null }))
  const updateUserData = () => dispatch(Actions.Users.fetchUpdateUser())
  const stateToken = useSelector(state => state.Users.token)
  useEffect(() => {
    if (userToken) {
      setHelpdeskToken(userToken)
      setHelpdeskSession()
    }
  }, [])
  useEffect(() => {
    if (stateToken) {
      updateUserData()
      setTimeout(() => history.push(`/products/${FIRST_COLLECTION}`), 2000)
    }
  }, [stateToken])
  return (
    <GenericModal showCloseButton={false} openModal>
      <Grid container item xs={12} alignItems="center" justify="center" direction="column">
        <Spinner />
        <Typography variant="inherit">{t('helpdesk.redirecting')}</Typography>
      </Grid>
    </GenericModal>
  )
}

Helpdesk.propTypes = proptypes
export default translate('common')(Helpdesk)
