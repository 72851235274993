/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Box, Typography } from '@material-ui/core'
import propTypes from './props'
import IconComponent from '../IconComponent'

const HelpButton = () => {
  return (
    <Box className="help-button">
      <IconComponent icon="phone" />
      <Typography>
        Need help? Call us: <span>+971 43 835 390</span>
      </Typography>
    </Box>
  )
}

HelpButton.propTypes = propTypes
export default HelpButton
