/* eslint-disable react/prop-types */
import React from 'react'

const BundleIcon = ({ color }) => (
  <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <path
        d="M9,19 L15,19 L15,12 L9,12 L9,19 Z M1,19 L7,19 L7,1 L1,1 L1,19 Z M17,19 L23,19 L23,12 L17,12 L17,19 Z M9,8 L23,8 L23,1 L9,1 L9,8 Z"
        id="Fill-32"
        stroke={color || '#393939'}
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default BundleIcon
