/* eslint-disable max-len */
import React from 'react'

const SvgVisa = () => (
  <svg width="49px" height="28px" viewBox="0 0 49 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-WEB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="10-CHECK-OUT-CREDICT-CARD-USER-REGISTRED"
        transform="translate(-768.000000, -524.000000)"
        fillRule="nonzero">
        <g id="001-visa-copy" transform="translate(768.000000, 524.000000)">
          <polygon
            id="Path"
            fill="#3C58BF"
            points="17 14.6766635 19.7317073 1.33552015 24 1.33552015 21.3536585 14.6766635"
          />
          <polygon
            id="Path"
            fill="#293688"
            points="17 14.6766635 20.5 1.33552015 24 1.33552015 21.3536585 14.6766635"
          />
          <path
            d="M36,0.957431066 C35.2156863,0.633354708 33.9607843,0.309278351 32.3921569,0.309278351 C28.4705882,0.309278351 25.6470588,2.33475559 25.6470588,5.25144281 C25.6470588,7.43895822 27.6078431,8.57322547 29.1764706,9.30239728 C30.745098,10.0315691 31.2156863,10.5176836 31.2156863,11.1658363 C31.2156863,12.1380654 29.9607843,12.6241799 28.8627451,12.6241799 C27.2941176,12.6241799 26.4313725,12.3811227 25.0980392,11.813989 L24.5490196,11.5709318 L24,14.8927144 C24.9411765,15.2978099 26.6666667,15.7029053 28.4705882,15.7029053 C32.627451,15.7029053 35.372549,13.6774281 35.372549,10.5987027 C35.372549,8.89730183 34.3529412,7.6009964 32,6.54774824 C30.5882353,5.81857643 29.7254902,5.41348098 29.7254902,4.68430918 C29.7254902,4.03615646 30.4313725,3.38800375 32,3.38800375 C33.3333333,3.38800375 34.2745098,3.63106102 34.9803922,3.95513737 L35.372549,4.11717555 L36,0.957431066 L36,0.957431066 Z"
            id="Path"
            fill="#3C58BF"
          />
          <path
            d="M36,0.957431066 C35.2156863,0.633354708 33.9607843,0.309278351 32.3921569,0.309278351 C28.4705882,0.309278351 26.3529412,2.33475559 26.3529412,5.25144281 C26.3529412,7.43895822 27.6078431,8.57322547 29.1764706,9.30239728 C30.745098,10.0315691 31.2156863,10.5176836 31.2156863,11.1658363 C31.2156863,12.1380654 29.9607843,12.6241799 28.8627451,12.6241799 C27.2941176,12.6241799 26.4313725,12.3811227 25.0980392,11.813989 L24.5490196,11.5709318 L24,14.8927144 C24.9411765,15.2978099 26.6666667,15.7029053 28.4705882,15.7029053 C32.627451,15.7029053 35.372549,13.6774281 35.372549,10.5987027 C35.372549,8.89730183 34.3529412,7.6009964 32,6.54774824 C30.5882353,5.81857643 29.7254902,5.41348098 29.7254902,4.68430918 C29.7254902,4.03615646 30.4313725,3.38800375 32,3.38800375 C33.3333333,3.38800375 34.2745098,3.63106102 34.9803922,3.95513737 L35.372549,4.11717555 L36,0.957431066 L36,0.957431066 Z"
            id="Path"
            fill="#293688"
          />
          <path
            d="M43.2142857,1.33552015 C42.3571429,1.33552015 41.7142857,1.40963761 41.3571429,2.29904717 L36,14.6766635 L39.8571429,14.6766635 L40.5714286,12.4531396 L45.1428571,12.4531396 L45.5714286,14.6766635 L49,14.6766635 L46,1.33552015 L43.2142857,1.33552015 Z M41.5714286,10.2296157 C41.7857143,9.56255858 43,6.30139019 43,6.30139019 C43,6.30139019 43.2857143,5.48609809 43.5,4.96727585 L43.7142857,6.22727273 C43.7142857,6.22727273 44.4285714,9.56255858 44.5714286,10.3037332 L41.5714286,10.3037332 L41.5714286,10.2296157 Z"
            id="Shape"
            fill="#3C58BF"
          />
          <path
            d="M44.0714286,1.33552015 C43.2142857,1.33552015 42.5714286,1.40963761 42.2142857,2.29904717 L36,14.6766635 L39.8571429,14.6766635 L40.5714286,12.4531396 L45.1428571,12.4531396 L45.5714286,14.6766635 L49,14.6766635 L46,1.33552015 L44.0714286,1.33552015 Z M41.5714286,10.2296157 C41.8571429,9.48844111 43,6.30139019 43,6.30139019 C43,6.30139019 43.2857143,5.48609809 43.5,4.96727585 L43.7142857,6.22727273 C43.7142857,6.22727273 44.4285714,9.56255858 44.5714286,10.3037332 L41.5714286,10.3037332 L41.5714286,10.2296157 Z"
            id="Shape"
            fill="#293688"
          />
          <path
            d="M10.4166667,10.6002031 L10.0555556,8.67314902 C9.40555556,6.44962512 7.31111111,4.00374883 5,2.81786942 L8.25,14.6766635 L12.15,14.6766635 L18,1.33552015 L14.1,1.33552015 L10.4166667,10.6002031 Z"
            id="Path"
            fill="#3C58BF"
          />
          <path
            d="M10.4166667,10.6002031 L10.0555556,8.67314902 C9.40555556,6.44962512 7.31111111,4.00374883 5,2.81786942 L8.25,14.6766635 L12.15,14.6766635 L18,1.33552015 L14.8222222,1.33552015 L10.4166667,10.6002031 Z"
            id="Path"
            fill="#293688"
          />
          <path
            d="M0,1.33552015 L0.73880597,1.49971884 C5.99253731,2.731209 9.60447761,5.85098407 11,9.54545455 L9.52238806,2.56701031 C9.2761194,1.58181818 8.53731343,1.33552015 7.63432836,1.33552015 L0,1.33552015 L0,1.33552015 Z"
            id="Path"
            fill="#FFBC00"
          />
          <path
            d="M0,1.33552015 L0,1.33552015 C5.25373134,2.5794496 9.60447761,5.81366618 11,9.54545455 L9.60447761,3.6575218 C9.35820896,2.66237823 8.53731343,2.08187782 7.63432836,2.08187782 L0,1.33552015 Z"
            id="Path"
            fill="#F7981D"
          />
          <path
            d="M0,1.33552015 L0,1.33552015 C5.25373134,2.5794496 9.60447761,5.81366618 11,9.54545455 L10.0149254,6.31123797 C9.76865672,5.3160944 9.44029851,4.32095084 8.29104478,3.90630769 L0,1.33552015 Z"
            id="Path"
            fill="#ED7C00"
          />
          <g id="Group" transform="translate(5.000000, 1.335520)" fill="#051244">
            <path
              d="M9.65663717,8.06487164 L7.00884956,5.33645736 L5.76283186,8.38586156 L5.45132743,6.37967458 C4.75044248,3.97225021 2.4920354,1.3240834 0,0.0401237395 L3.50442478,12.8797204 L7.70973451,12.8797204 L9.65663717,8.06487164 Z"
              id="Path"
            />
            <polygon id="Path" points="16.899115 12.8797204 13.5504425 9.34883131 12.9274336 12.8797204" />
            <path
              d="M25.8548673,7.90437669 L25.8548673,7.90437669 C26.1663717,8.2253666 26.3221239,8.46610904 26.2442478,8.78709896 C26.2442478,9.75006871 24.9982301,10.2315536 23.9079646,10.2315536 C22.3504425,10.2315536 21.4938053,9.99081114 20.1699115,9.42907879 L19.6247788,9.18833635 L19.079646,12.478483 C20.0141593,12.8797204 21.7274336,13.2809578 23.5185841,13.2809578 C26.0106195,13.2809578 28.0353982,12.5587305 29.2035398,11.2747708 L25.8548673,7.90437669 Z"
              id="Path"
            />
            <path
              d="M30.3716814,12.8797204 L34.0318584,12.8797204 L34.8106195,10.472296 L39.7946903,10.472296 L40.2619469,12.8797204 L44,12.8797204 L42.6761062,7.02165442 L38.0035398,2.36730063 L38.2371681,3.6512603 C38.2371681,3.6512603 39.0159292,7.26239685 39.1716814,8.06487164 L35.900885,8.06487164 C36.2123894,7.26239685 37.4584071,3.81175526 37.4584071,3.81175526 C37.4584071,3.81175526 37.7699115,2.92903299 38.0035398,2.36730063"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgVisa
