import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Modal, Fade } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab/'
import IconComponent from '../../atoms/IconComponent'
import propTypes from './props'
import Actions from '../../../actions'
import RemoveCardModal from '../../organisms/RemoveCardModal'
import CardRequestModal from '../../organisms/CardRequestModal'

const PaymentCard = ({ t, item, selectedCard, setSelectedCard, cards, handleAlert, showAlert }) => {
  const { last4, brand, id, expYear, expMonth } = item

  const [openRemoveModal, setOpenRemoveModal] = useState(false)

  const subscriptions = useSelector(state => state.Users.subscriptions || [])

  const statusCardRequest = useSelector(state => state.Users.statusCardRequest)

  const isCart = window.document.URL.includes('/cart')

  const isExpired = (year, month) => {
    const today = new Date()
    const expireDate = new Date(+year, +month, 1)
    return today >= expireDate
  }

  const expiredCard = isExpired(expYear, expMonth)
  const dispatch = useDispatch()
  const removeCard = (paymentMethodId, cb) => dispatch(Actions.Checkout.removeCard(paymentMethodId, cb))
  const setStatusCardRequest = status => dispatch(Actions.Users.setStatusCardRequest(status))

  const pendingWithCredit =
    subscriptions.length > 0 &&
    subscriptions.some(sub => sub.paymentType === 'CREDIT' && (sub.status === 'PENDING' || sub.status === 'PAUSED'))

  const isOtherValidCard =
    cards &&
    cards.length >= 1 &&
    cards.filter(card => !isExpired(card.expYear, card.expMonth) && id !== card.id).length > 0

  const showRemoveLink = (!pendingWithCredit || isOtherValidCard) && !isCart
  const ableToShow = !showRemoveLink && showAlert && selectedCard === id
  const errorCardRequest = statusCardRequest && statusCardRequest.status === 'error'

  useEffect(() => {
    setStatusCardRequest(null)
  }, [])

  useEffect(() => {
    if (errorCardRequest) setOpenRemoveModal(false)
  }, [statusCardRequest])

  return (
    <>
      <Grid
        container
        item
        key={id}
        alignItems="center"
        justify="space-between"
        className={selectedCard === id ? 'payment-card--active' : 'payment-card--deactivated'}
        onClick={() => {
          if (setSelectedCard) setSelectedCard(id)
          if (handleAlert) handleAlert()
        }}>
        <Grid item xs={2} lg={2} container justify="center" alignItems="baseline">
          <IconComponent icon={brand || 'credit card'} />
        </Grid>
        <Grid item xs={8} lg={8} container alignItems="center" justify="space-evenly">
          <Grid item xs={12} lg={12} className="payment-card__number">
            <Typography variant="inherit">
              <span>{t('payment.cardFinished')}</span>
              <span>{!last4 ? '0000' : last4}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} className="payment-card__expiration-date">
            {expMonth && expYear && (
              <Typography variant="inherit">
                <span>{t('payment.expirationDate')}</span>
                <span>{`${expMonth}/${expYear}`}</span>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} lg={12} className="payment-card__delete-button">
            {(showRemoveLink || expiredCard) && (
              <button type="button" onClick={() => setOpenRemoveModal(true)}>
                {t('payment.deleteCard')}
              </button>
            )}
          </Grid>

          <Fade in={ableToShow}>
            <Grid item className="payment-card__alert-info-container">
              <Alert className="payment-card__alert-info" severity="info">
                <Typography className="payment-card__x-close">x</Typography>
                <AlertTitle>
                  <strong>{t('payment.alertInfoTitle')}</strong>
                </AlertTitle>
                {t('payment.alertInfoText')}
              </Alert>
            </Grid>
          </Fade>
        </Grid>
      </Grid>
      {selectedCard === id && (
        <Grid item container justify="center" className="payment-card__card-selected-text">
          {t('payment.cardPurchase')}
        </Grid>
      )}

      <Modal
        aria-labelledby="remove-card-modal"
        aria-describedby="remove-card-modal"
        open={openRemoveModal}
        onClose={() => setOpenRemoveModal(false)}>
        <div tabIndex={-1}>
          <RemoveCardModal
            expiredCard={expiredCard}
            t={t}
            handleClose={() => setOpenRemoveModal(false)}
            ableRemove={!pendingWithCredit || isOtherValidCard}
            cardInfo={`${brand || ''} ${t('payment.cardFinished')}${!last4 ? '0000' : last4}`}
            removeCard={() => removeCard(id, () => setOpenRemoveModal(false))}
          />
        </div>
      </Modal>
      <Modal
        aria-labelledby="card-request-backend"
        aria-describedby="card-request-backend"
        open={errorCardRequest}
        onClose={() => setStatusCardRequest(null)}>
        <div tabIndex={-1}>
          <CardRequestModal t={t} setStatusCardRequest={setStatusCardRequest} />
        </div>
      </Modal>
    </>
  )
}

PaymentCard.propTypes = propTypes
export default PaymentCard
