/* eslint-disable no-unused-vars */
import React from 'react'
import { Box, InputBase } from '@material-ui/core'
import AlgoliaSearch from '../AlgoliaSearch'
import propTypes from './props'

const NavSearch = ({ show, setInputSearchVisible, inputSearchVisible, t }) => {
  return (
    <>
      {show && (
        <Box className="nav-search">
          <AlgoliaSearch t={t} inputSearchVisible={inputSearchVisible} setInputSearchVisible={setInputSearchVisible} />
        </Box>
      )}
    </>
  )
}
NavSearch.propTypes = propTypes
export default NavSearch
