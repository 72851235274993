import React, { useState } from 'react'
import { Grid, Hidden, Tooltip, Zoom, ClickAwayListener } from '@material-ui/core'
import { useSelector } from 'react-redux'
import propTypes from './props'
import IconComponent from '../IconComponent'

const ShoppingCartIcon = ({ xs, lg, handleClick = () => {}, t }) => {
  const [open, setOpen] = useState(false)

  const totalProducts = useSelector(
    state =>
      state.Checkout.selectedDiapers.length + state.Checkout.selectedWipes.length + state.Checkout.upselling.length
  )

  const showCartFullIcon = (
    <>
      <Hidden smDown>
        <IconComponent icon="cartFullJaka" />
      </Hidden>
      <Hidden mdUp>
        <IconComponent icon="cartMobileFullJaka" />
      </Hidden>
    </>
  )

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const showCartEmptyIcon = (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={t('header.cartEmptyIcon')}
        TransitionComponent={Zoom}
        placement="bottom-end"
        arrow>
        <div>
          <IconComponent icon="cartJaka" />
        </div>
      </Tooltip>
    </ClickAwayListener>
  )

  return (
    <Grid
      item
      xs={xs}
      lg={lg}
      justify="center"
      alignItems="center"
      direction="column"
      className="header-toolbar__cart-container">
      <button
        id="cart-button"
        type="button"
        className="header-toolbar__cart-container__cart__shopping"
        onClick={totalProducts > 0 ? handleClick : handleTooltipOpen}>
        <span className="header-toolbar__cart-container__cart__shopping__counter">{totalProducts}</span>
        {totalProducts > 0 ? showCartFullIcon : showCartEmptyIcon}
      </button>
    </Grid>
  )
}

ShoppingCartIcon.propTypes = propTypes
export default ShoppingCartIcon
