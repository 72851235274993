import _toConsumableArray from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { getInStockProducts } from '../utils/helpers';
var initialState = {
  samplings: [],
  collections: [],
  detailProduct: null,
  filter: '',
  filters: {
    brands: [],
    quick: [],
    inStock: true
  },
  categories: [],
  searchResults: []
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'PRODUCTS_SET_COLLECTIONS':
      return _objectSpread({}, state, {
        collections: [].concat(_toConsumableArray(state.collections), [_objectSpread({}, action.payload.data, {
          productsInStock: getInStockProducts(action.payload.data)
        })])
      });
    case 'CLEAR_WIPES_DIAPERS':
      return initialState;
    case 'SET_FILTER':
      return _objectSpread({}, state, {
        filter: action.payload
      });
    case 'SET_SEARCH_RESULTS':
      return _objectSpread({}, state, {
        searchResults: action.payload
      });
    case 'SET_DETAILED_PRODUCT':
      return _objectSpread({}, state, {
        detailProduct: action.payload && action.payload.data
      });
    case 'SET_CATEGORIES':
      return _objectSpread({}, state, {
        categories: action.payload
      });
    case 'SET_FILTERS':
      return _objectSpread({}, state, {
        filters: _objectSpread({}, state.filters, action.payload)
      });
    case 'PRODUCTS_SET_SAMPLINGS':
      return _objectSpread({}, state, {
        samplings: action.payload.data
      });
    default:
      return state;
  }
};
export default reducer;