/* eslint-disable max-len */
import React from 'react'

const SearchJakaIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.6705 21.8423L17.8417 16.0135C18.8884 14.4595 19.5 12.588 19.5 10.5735C19.5 5.18872 15.1347 0.823486 9.74999 0.823486C4.36523 0.823486 0 5.18872 0 10.5735C0 15.9582 4.36523 20.3235 9.74999 20.3235C11.7645 20.3235 13.636 19.7119 15.19 18.6652L21.0188 24.494C21.4582 24.9334 22.1705 24.9334 22.6098 24.494L23.6705 23.4334C24.1098 22.994 24.1098 22.2817 23.6705 21.8423ZM3.74999 10.5735C3.74999 7.26505 6.44155 4.57349 9.74999 4.57349C13.0584 4.57349 15.75 7.26505 15.75 10.5735C15.75 13.8818 13.0584 16.5735 9.74999 16.5735C6.44155 16.5735 3.74999 13.8818 3.74999 10.5735Z"
      fill="#B5889B"
    />
  </svg>
)

export default SearchJakaIcon
