import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
var initialState = {
  version: 0,
  updated: new Date('2020-01-01').toISOString(),
  underMaintenance: false
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'UPDATE_VERSION':
      return _objectSpread({}, state, {
        version: action.payload,
        updated: new Date().toISOString()
      });
    case 'SET_UNDER_MAINTENANCE_MODE':
      return _objectSpread({}, state, {
        underMaintenance: action.payload
      });
    default:
      return state;
  }
};
export default reducer;