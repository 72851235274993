/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-tabs */
import React from 'react'
import propTypes from './props'

const SearchIcon = props => (
  <button type="button" {...props}>
    <svg width={props.width || '25px'} height={props.height || '30px'} viewBox="0 0 30 32" cursor="pointer" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Simbolage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Header/-nuevo-gris/-web" transform="translate(-1072.000000, -18.000000)" stroke={props.color || 'white'} strokeWidth="3">
          <g id="Group-12-Copy-3" transform="translate(1074.000000, 20.000000)">
            <g id="Group-12-Copy-2">
              <ellipse id="Oval-Copy" cx="10.15625" cy="10" rx="10.15625" ry="10" />
              <path d="M17.875,18.4 L26,28" id="Line-2-Copy" strokeLinecap="round" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
)

SearchIcon.propTypes = propTypes
export default SearchIcon
