/* eslint-disable max-len */
import React from 'react'

const UserIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2615 13.7211L15.0238 11.9047C14.5245 11.6886 13.938 11.8339 13.5986 12.255L11.878 14.3571C9.17294 13.029 6.97384 10.8305 5.64634 8.12656L7.74899 6.40414C8.16892 6.06114 8.31235 5.48008 8.09993 4.98078L6.28231 0.740041C6.04427 0.196799 5.45528 -0.101052 4.88157 0.0320335L0.946024 0.940237C0.389383 1.06719 -2.86102e-05 1.55668 -2.86102e-05 2.12922C-2.86102e-05 11.984 8.01692 20.0005 17.8704 20.0005C18.4436 20.0005 18.9331 19.6111 19.0606 19.0544L19.9688 15.1176C20.1007 14.5475 19.8047 13.9555 19.2615 13.7211Z"
      fill="#B68A9C"
    />
  </svg>
)

export default UserIcon
