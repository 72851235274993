/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Grid, Typography, Modal } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import propTypes from './props'
import Header from '../../components/organisms/Header'
import BreadCrumbs from '../../components/atoms/BreadCrumbs'
import PastOrder from '../../components/molecules/PastOrder'
import Actions from '../../actions'
import warning from '../../assets/images/warning.svg'
import { FIRST_COLLECTION } from '../../utils/urls'

const pastOrders = ({ history, t, i18n }) => {
  const breadCrumbs = [
    {
      label: t('products.products'),
      link: `/products/${FIRST_COLLECTION}`
    },
    {
      label: t('menu.pastOrders'),
      link: '/past-orders'
    }
  ]
  const dispatch = useDispatch()
  const getSubscriptions = type => dispatch(Actions.Users.setFetchSubscriptions(type))
  const downloadInvoice = (shippingDate, subIs) => dispatch(Actions.Users.downloadInvoice(shippingDate, subIs))
  const setInvoiceStatus = status => dispatch(Actions.Users.setInvoiceStatus(status))

  const currentLanguage = useSelector(state => state.Language.current)
  const pastSubscriptions = useSelector(state => state.Users.pastSubscriptions || [])
    .sort((a, b) => b.id - a.id)
    .filter(sub => sub.order && sub.order.orderNumber)

  const loading = useSelector(state => state.Users.loading)
  const { invoiceStatus } = useSelector(state => state.Users)

  const [sortOrders, setSortOrders] = useState(false)
  const [showInvoiceError, setShowInvoiceError] = useState(false)

  useEffect(() => {
    const isError = invoiceStatus && (invoiceStatus === 'downloadError' || invoiceStatus === 'userError')
    setShowInvoiceError(isError)
  }, [invoiceStatus])

  useEffect(() => {
    getSubscriptions('PAST')
    return () => {
      setInvoiceStatus(null)
    }
  }, [])

  const sortOrdersBy = sort => pastSubscriptions.sort((a, b) => (!sort ? b.id - a.id : a.id - b.id))

  return (
    <>
      <Header t={t} i18n={i18n} bg history={history} showSideCartIcon showLogin />
      <BreadCrumbs crumbs={breadCrumbs} currentLanguage={currentLanguage} history={history} />
      <Grid container item justify="center" className="past-orders">
        <Grid container item xs={12} md={7} lg={5} className="orders">
          <Grid item container className="orders__title">
            <span>{t('menu.pastOrders')}</span>
          </Grid>
          <Grid item container className="orders__item">
            <Grid item container xs={12} justify="space-between" className="orders__subtitle">
              <Grid item className="orders__subtitle__title">
                <span>All my orders</span>
              </Grid>
              <Grid onClick={() => setSortOrders(!sortOrders)} item className="orders__subtitle__edit">
                <button type="button">
                  <span>
                    {t('pastOrders.sort')}
                    <KeyboardArrowDownIcon className="sort" />
                    <KeyboardArrowUpIcon className="sort" />
                  </span>
                </button>
              </Grid>
            </Grid>
          </Grid>

          {(!loading && sortOrdersBy(sortOrders).length === 0) || invoiceStatus === 'userError' ? (
            <Grid className="past-orders__no-boxes">
              {invoiceStatus ? (
                <Grid direction="column">
                  <Typography display="block">{t('cart.priceDetail.invoiceError.mailText')}</Typography>
                  <a
                    className="past-orders__no-boxes"
                    href={`https://wa.me/${t('footer.whatsapp').substring(1)}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {`Whatsapp ${t('footer.whatsapp')}`}
                  </a>
                </Grid>
              ) : (
                <Typography>{t('pastOrders.noBoxes')}</Typography>
              )}
            </Grid>
          ) : (
            sortOrdersBy(sortOrders).map(order => (
              <PastOrder key={order.id} order={order} t={t} downloadInvoice={downloadInvoice} />
            ))
          )}
        </Grid>
      </Grid>

      <Modal open={showInvoiceError}>
        <Grid className="error-invoice-modal">
          <img
            className="error-invoice-modal__warning"
            src={warning}
            height="140"
            alt={t('cart.priceDetail.invoiceError.issue')}
          />

          <Grid container item className="price-detail__invoice__error">
            <Typography variant="inherit">
              {invoiceStatus === 'downloadError' && t('cart.priceDetail.invoiceError.downloadIssue')}
              {invoiceStatus === 'userError' && t('cart.priceDetail.invoiceError.userIssue')}
              <Typography display="block" variant="inherit">
                {t('cart.priceDetail.invoiceError.mailText')}
              </Typography>
            </Typography>
            <Typography variant="inherit">
              <a href="mailto:hello@yallababy.com">{`${t('footer.contactEmail')}`}</a>
            </Typography>

            <Typography variant="inherit">
              {t('cart.priceDetail.invoiceError.whatsAppText')}
              <a href={`https://wa.me/${t('footer.whatsapp').substring(1)}`} target="_blank" rel="noopener noreferrer">
                {t('footer.whatsapp')}
              </a>
            </Typography>
            <Typography
              className="price-detail__invoice__error__close"
              onClick={() => setShowInvoiceError(false)}
              variant="inherit">
              Close
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

pastOrders.propTypes = propTypes
export default translate('common')(pastOrders)
