/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from './props'
import Actions from '../../../actions'
import SubscriptionMessage from '../../atoms/SubscriptionMessage'
import CartProductItem from '../../molecules/CartProductItem'
import { gtmSubscription, haveOfferTag, productsToSubscribe } from '../../../utils/helpers'
import SamplesAndGifts from '../../../containers/SamplesAndGifts'
import AddMoreProducts from '../../molecules/AddMoreProducts'

const DiaperCard = ({ product, selectedDiapers, isSubscribed, t, subscribeAll }) => {
  const dispatch = useDispatch()
  const addDiaper = diaper => dispatch(Actions.Checkout.addDiaper(diaper))
  const userData = useSelector(state => state.Users.userData)

  const removeDiaper = (index, variant_id, quantity) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({
        ...product,
        variant_id: +product.shopifyVariantId || +product.variant_id,
        removeAll: index === null && true
      })
    )
    dispatch(Actions.Checkout.removeDiaper(index, variant_id, quantity))
  }
  const setSubscribedDiaper = (variant_id, amount) => {
    dispatch(Actions.Checkout.setSubscribedDiaper(variant_id, amount))
    gtmSubscription(
      [product],
      userData,
      'subscription',
      !(product.purchase_type === 'ONE_TIME' || product.purchaseType === 'ONE_TIME') ? 'unsubscribed' : 'subscribed'
    )
  }
  return (
    <Box key={`${product.price}${product.netPrice}`}>
      <CartProductItem
        productItem={product}
        t={t}
        addProduct={addDiaper}
        removeProduct={removeDiaper}
        products={selectedDiapers}
        isSubscribed={isSubscribed}
        setSubscribed={setSubscribedDiaper}
        showToggle
        subscribeAll={subscribeAll}
      />
    </Box>
  )
}

const WipeCard = ({ product, selectedWipes, t, isSubscribed, subscriptionDiscount, subscribeAll }) => {
  const dispatch = useDispatch()
  const addWipe = wipe => dispatch(Actions.Checkout.addWipe(wipe))
  const userData = useSelector(state => state.Users.userData)

  const removeWipe = (index, variant_id) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({
        ...product,
        variant_id: +product.shopifyVariantId || +product.variant_id,
        removeAll: index === null && true
      })
    )
    dispatch(Actions.Checkout.removeWipe(index, variant_id))
  }
  const setSubscribedWipe = (variant_id, prices) => {
    dispatch(Actions.Checkout.setSubscribedWipe(variant_id, prices))
    gtmSubscription(
      [product],
      userData,
      'subscription',
      !(product.purchase_type === 'ONE_TIME' || product.purchaseType === 'ONE_TIME') ? 'unsubscribed' : 'subscribed'
    )
  }
  return (
    <Grid key={`${product.price}${product.netPrice}`} container item xs={12} lg={12} justify="space-between">
      <CartProductItem
        productItem={product}
        t={t}
        addProduct={addWipe}
        removeProduct={removeWipe}
        products={selectedWipes}
        subscriptionDiscount={subscriptionDiscount}
        isSubscribed={isSubscribed}
        setSubscribed={setSubscribedWipe}
        showToggle
        subscribeAll={subscribeAll}
      />
    </Grid>
  )
}

const UpsellingCard = ({ product, upsellings, t, isSubscribed, subscribeAll }) => {
  const userData = useSelector(state => state.Users.userData)

  const variantSelected = product.variants.find(
    variant => variant.id === +product.variantId || variant.id === +product.shopifyVariantId
  )
  const offer = haveOfferTag(product)
  const dispatch = useDispatch()
  const addUpselling = data => dispatch(Actions.Checkout.addUpselling(data))
  const removeUpselling = (index, id) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({
        ...product,
        variantId: +product.shopifyVariantId || +product.variantId,
        removeAll: index === null && true
      })
    )
    dispatch(Actions.Checkout.removeUpselling(index, id))
  }
  const setSubscribedUpselling = (variantId, prices) => {
    dispatch(Actions.Checkout.setSubscribedUpselling(variantId, prices))
    gtmSubscription(
      [product],
      userData,
      'subscription',
      !(product.purchase_type === 'ONE_TIME' || product.purchaseType === 'ONE_TIME') ? 'unsubscribed' : 'subscribed'
    )
  }

  return (
    <Grid
      key={`${product.price}${product.netPrice + product.variantId}`}
      container
      item
      xs={12}
      lg={12}
      justify="space-between">
      <CartProductItem
        productItem={product}
        t={t}
        addProduct={addUpselling}
        removeProduct={removeUpselling}
        products={upsellings}
        isSubscribed={isSubscribed}
        setSubscribed={setSubscribedUpselling}
        showToggle={(variantSelected.appliedDiscount > 0 || product.appliedDiscount > 0) && !haveOfferTag(product)}
        offer={offer}
        stockControl
        subscribeAll={subscribeAll}
      />
    </Grid>
  )
}

const ProductsDetail = ({
  t,
  uniqueDiaper,
  selectedDiapers,
  uniqueWipe,
  selectedWipes,
  uniqueUpselling,
  upsellings,
  history,
  isEdition,
  samplesInfo,
  setSamplesInfo,
  subscriptions,
  isAnySubscribed,
  totalProducts
}) => {
  const isSubscribed = subs => subs === 'SUBSCRIPTION'

  const shippingDate = useSelector(state => state.Checkout.shippingDate)
  const boxId = useSelector(state => state.Checkout.id)
  const promoCode = useSelector(state => state.Checkout.promoCode)
  const isInflu = promoCode && promoCode.title && promoCode.title.toUpperCase().includes('INFLU')
  const boxRewarded = () => {
    const { subscriptionOrderForReward } = subscriptions.find(box => box.id === boxId)
    const rewardedBoxs = [3, 6, 9, 12, 15, 18, 21, 24]
    return rewardedBoxs.includes(subscriptionOrderForReward)
  }
  const [subscribeAll, setSubscribeAll] = useState(false)

  const productsDetailHeader = () => {
    const headerTitle = isEdition ? (
      t('cart.boxEdition')
    ) : (
      <>
        {t('checkout.myCart')} <span>(x{totalProducts})</span>
      </>
    )
    return (
      <Grid container className="products-detail__header">
        <Grid item>
          <Typography>{headerTitle}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Box className="products-detail">
        {productsDetailHeader()}
        <Box>
          {uniqueDiaper.length > 0 &&
            uniqueDiaper.map(
              product =>
                isSubscribed(product.purchase_type ? product.purchase_type : product.purchaseType) && (
                  <DiaperCard
                    key={`${product.shopifyVariantId}${product.variant_id}`}
                    product={product.shopifyProduct ? { ...product, ...product.shopifyProduct } : product}
                    selectedDiapers={selectedDiapers}
                    t={t}
                    isSubscribed={isSubscribed}
                  />
                )
            )}
        </Box>
        <Box>
          {uniqueWipe.length > 0 &&
            uniqueWipe.map(
              product =>
                isSubscribed(product.purchase_type ? product.purchase_type : product.purchaseType) && (
                  <WipeCard
                    key={`${product.shopifyVariantId}${product.variant_id}`}
                    product={product.shopifyProduct ? { ...product, ...product.shopifyProduct } : product}
                    selectedWipes={selectedWipes}
                    t={t}
                    isSubscribed={isSubscribed}
                  />
                )
            )}
        </Box>
        <Box>
          {uniqueUpselling.length > 0 &&
            uniqueUpselling.map(
              product =>
                isSubscribed(product.purchaseType) && (
                  <UpsellingCard
                    key={`${product.shopifyVariantId}${product.variantId}`}
                    product={product.shopifyProduct ? { ...product, ...product.shopifyProduct } : product}
                    upsellings={upsellings}
                    t={t}
                    isSubscribed={isSubscribed}
                  />
                )
            )}
        </Box>
        <Box>
          {uniqueDiaper.length > 0 &&
            uniqueDiaper.map(
              product =>
                !isSubscribed(product.purchase_type ? product.purchase_type : product.purchaseType) && (
                  <DiaperCard
                    key={`${product.shopifyVariantId}${product.variant_id}`}
                    product={product}
                    selectedDiapers={selectedDiapers}
                    t={t}
                    isSubscribed={isSubscribed}
                    subscribeAll={subscribeAll}
                  />
                )
            )}
        </Box>
        <Box item xs={12}>
          {uniqueWipe.length > 0 &&
            uniqueWipe.map(
              product =>
                !isSubscribed(product.purchase_type ? product.purchase_type : product.purchaseType) && (
                  <WipeCard
                    key={`${product.shopifyVariantId}${product.variant_id}`}
                    product={product}
                    selectedWipes={selectedWipes}
                    t={t}
                    isSubscribed={isSubscribed}
                    subscribeAll={subscribeAll}
                  />
                )
            )}
        </Box>
        <Box item xs={12}>
          {uniqueUpselling.length > 0 &&
            uniqueUpselling.map(
              product =>
                !isSubscribed(product.purchaseType) && (
                  <UpsellingCard
                    key={`${product.shopifyVariantId}${product.variantId}`}
                    product={product}
                    upsellings={upsellings}
                    t={t}
                    isSubscribed={isSubscribed}
                    subscribeAll={subscribeAll}
                  />
                )
            )}
        </Box>
        {(!isEdition || (isEdition && boxRewarded())) && !isInflu && (
          <SamplesAndGifts
            t={t}
            isAnySubscribed={isAnySubscribed}
            samplesInfo={samplesInfo}
            setSamplesInfo={setSamplesInfo}
          />
        )}
        <AddMoreProducts history={history} t={t} />
        <SubscriptionMessage
          productsToSubscribe={productsToSubscribe(uniqueDiaper, uniqueWipe, uniqueUpselling)}
          shippingDate={shippingDate}
          t={t}
          isAnySubscribed={isAnySubscribed}
          setSubscribeAll={setSubscribeAll}
          subscribeAll={subscribeAll}
        />
      </Box>
    </>
  )
}

ProductsDetail.propTypes = propTypes
export default ProductsDetail
