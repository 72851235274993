import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
/* eslint-disable camelcase */

var setStatus = function setStatus(status) {
  return {
    type: 'CHECKOUT_SET_STATUS',
    payload: {
      status: status
    }
  };
};
var removeCardTokenData = function removeCardTokenData() {
  return {
    type: 'REMOVE_CARD_TOKEN_DATA'
  };
};
var setStatusPaymentError = function setStatusPaymentError(status) {
  return {
    type: 'CHECKOUT_SET_STATUS_PAYMENT_ERROR',
    payload: {
      status: status
    }
  };
};
var setActiveStep = function setActiveStep(step) {
  return {
    type: 'CHECKOUT_SET_ACTIVE_STEP',
    payload: {
      step: step
    }
  };
};
var resetCheckoutProcess = function resetCheckoutProcess() {
  return {
    type: 'RESET_CHECKOUT_PROCESS'
  };
};
var setBabyData = function setBabyData(data) {
  return {
    type: 'CHECKOUT_SET_BABY_DATA',
    payload: _objectSpread({}, data)
  };
};

// Select Custom Diapes & Wipes
var addDiaper = function addDiaper(diaper) {
  return {
    type: 'CHECKOUT_ADD_DIAPER',
    payload: diaper
  };
};
var removeDiaper = function removeDiaper(index, variant_id, quantity) {
  return {
    type: 'CHECKOUT_REMOVE_DIAPERS',
    index: index,
    variant_id: variant_id,
    quantity: quantity
  };
};
var setSubscribedDiaper = function setSubscribedDiaper(variant_id, amount) {
  return {
    type: 'CHECKOUT_SUBSCRIBE_DIAPER',
    variant_id: variant_id,
    amount: amount
  };
};
var addWipe = function addWipe(wipe) {
  return {
    type: 'CHECKOUT_ADD_WIPE',
    payload: wipe
  };
};
var removeWipe = function removeWipe(index, variant_id) {
  return {
    type: 'CHECKOUT_REMOVE_WIPE',
    index: index,
    variant_id: variant_id
  };
};
var gtmRemoveProducts = function gtmRemoveProducts(prod) {
  return {
    type: 'GTM_REMOVE_PRODUCTS',
    payload: prod
  };
};
var setSubscribedWipe = function setSubscribedWipe(variant_id, prices) {
  return {
    type: 'CHECKOUT_SUBSCRIBE_WIPE',
    variant_id: variant_id,
    prices: prices
  };
};
var setSubscribedUpselling = function setSubscribedUpselling(variantId, prices) {
  return {
    type: 'CHECKOUT_SUBSCRIBE_UPSELLING',
    variantId: variantId,
    prices: prices
  };
};
var setSubscriptionAllProducts = function setSubscriptionAllProducts(purchaseType) {
  return {
    type: 'CHECKOUT_SUBSCRIPTION_ALL_PRODUCTS',
    purchaseType: purchaseType
  };
};
var addUpselling = function addUpselling(data) {
  return {
    type: 'CHECKOUT_UPSELLING_ADD',
    payload: data
  };
};
var removeUpselling = function removeUpselling(index, variantId) {
  return {
    type: 'CHECKOUT_UPSELLING_REMOVE',
    index: index,
    variantId: variantId
  };
};
var flush = function flush() {
  return {
    type: 'CHECKOUT_FLUSH'
  };
};
var setTokenPayment = function setTokenPayment(token) {
  return {
    type: 'CHECKOUT_SET_PAYMENT_TOKEN',
    payload: token
  };
};
var setTokenCard = function setTokenCard(token) {
  return {
    type: 'CHECKOUT_SET_PAYMENT_CARD_TOKEN',
    payload: token
  };
};
var setTokenVerifyCard = function setTokenVerifyCard(token) {
  return {
    type: 'CHECKOUT_SET_PAYMENT_CARD_VERIFY_CARD_TOKEN',
    payload: token
  };
};
var cancelSubscription = function cancelSubscription(id, cb) {
  return {
    type: 'CHECKOUT_CANCEL_SUBSCRIPTION_FETCH',
    payload: {
      id: id
    },
    cb: cb
  };
};
var pauseSubscription = function pauseSubscription(id, cb) {
  return {
    type: 'CHECKOUT_PAUSE_SUBSCRIPTION_FETCH',
    payload: {
      id: id
    },
    cb: cb
  };
};
var activateSubscription = function activateSubscription(id, shippingDate, cb) {
  return {
    type: 'CHECKOUT_ACTIVATE_SUBSCRIPTION_FETCH',
    payload: {
      id: id,
      shippingDate: shippingDate
    },
    cb: cb
  };
};
var updateSubscription = function updateSubscription(data) {
  return {
    type: 'CHECKOUT_UPDATE_SUBSCRIPTION_FETCH',
    payload: _objectSpread({}, data)
  };
};
var setCustomerInfo = function setCustomerInfo(data) {
  return {
    type: 'CHECKOUT_SET_CUSTOMER_INFO',
    payload: _objectSpread({}, data)
  };
};
var setCutstomerAddress = function setCutstomerAddress(data) {
  return {
    type: 'CHECKOUT_SET_CUSTOMER_ADDRESS',
    payload: _objectSpread({}, data)
  };
};
var processNewBox = function processNewBox(formValues) {
  return {
    type: 'CHECKOUT_PROCESS_NEW_BOX',
    payload: {
      formValues: formValues
    }
  };
};
var setPayWithNewCard = function setPayWithNewCard(value) {
  return {
    type: 'CHECKOUT_SET_PAY_NEW_CARD',
    payload: value
  };
};
var setPayWithCOD = function setPayWithCOD(value) {
  return {
    type: 'CHECKOUT_SET_PAY_CASH',
    payload: value
  };
};
var savePayWithCOD = function savePayWithCOD() {
  return {
    type: 'CHECKOUT_SAVE_COD'
  };
};
var setShippingDate = function setShippingDate(value) {
  return {
    type: 'CHECKOUT_SET_SHIPPING_DATE',
    payload: value
  };
};
var completeOrder = function completeOrder(data) {
  return {
    type: 'CHECKOUT_COMPLETE_ORDER_RESPONSE',
    payload: _objectSpread({}, data)
  };
};
var applyPromoCode = function applyPromoCode(code) {
  return {
    type: 'CHECKOUT_GET_DISCOUNT',
    payload: {
      code: code
    }
  };
};
var removePromoCode = function removePromoCode() {
  return {
    type: 'CHECKOUT_REMOVE_DISCOUNT'
  };
};
var savePromoCode = function savePromoCode(idSubs, promoCode) {
  return {
    type: 'CHECKOUT_SAVE_DISCOUNT',
    payload: {
      idSubs: idSubs,
      promoCode: promoCode
    }
  };
};
var setPromoCode = function setPromoCode(promoCode) {
  return {
    type: 'CHECKOUT_SET_DISCOUNT',
    payload: {
      data: _objectSpread({}, promoCode)
    }
  };
};
var getContextData = function getContextData() {
  return {
    type: 'GET_CONTEXT_DATA'
  };
};
var editSubscription = function editSubscription(subscription) {
  return {
    type: 'CHECKOUT_EDIT_SUBSCRIPTION',
    subscription: subscription
  };
};
var setThisBox = function setThisBox(box) {
  return {
    type: 'CHECKOUT_SET_THIS_BOX',
    box: box
  };
};
var setBoxInEdition = function setBoxInEdition(box) {
  return {
    type: 'CHECKOUT_SET_BOX_IN_EDITION',
    box: box
  };
};
var goToCart = function goToCart() {
  return {
    type: 'GO_TO_CART'
  };
};
var startCheckout = function startCheckout() {
  return {
    type: 'INITIATE_CHECKOUT'
  };
};
var completeContactInfo = function completeContactInfo() {
  return {
    type: 'COMPLETE_CONTACT_INFO'
  };
};
var completeShippingInfo = function completeShippingInfo() {
  return {
    type: 'COMPLETE_SHIPPING_INFO'
  };
};
var setPaymentMethod = function setPaymentMethod(payWithCod) {
  return {
    type: 'SET_PAYMENT_METHOD',
    payload: payWithCod
  };
};
var removeCard = function removeCard(paymentMethodId, cb) {
  return {
    type: 'REMOVE_CREDIT_CARD',
    payload: paymentMethodId,
    cb: cb
  };
};
var setCheckedToSubscribe = function setCheckedToSubscribe(isChecked) {
  return {
    type: 'SET_CHECK_TO_SUBSCRIBE',
    payload: isChecked
  };
};
var setIncomingBoxInfo = function setIncomingBoxInfo(info) {
  return {
    type: 'SET_INCOMING_BOX_INFO',
    payload: info
  };
};
var updateCartProducts = function updateCartProducts(cartUpdated) {
  return {
    type: 'UPDATE_CART_PRODUCTS',
    payload: cartUpdated
  };
};
var selectSamplings = function selectSamplings(sample) {
  return {
    type: 'CHECKOUT_SELECT_SAMPLINGS',
    payload: sample
  };
};
var removeSample = function removeSample(sample) {
  return {
    type: 'REMOVE_SAMPLE',
    payload: sample
  };
};
var setTotalBeforeTax = function setTotalBeforeTax(value) {
  return {
    type: 'SET_TOTAL_BEFORE_TAX',
    payload: value
  };
};
var setBoxTypePurchase = function setBoxTypePurchase(boxType) {
  return {
    type: 'SET_BOX_TYPE_PURCHASE',
    payload: boxType
  };
};
var setShowOneOffs = function setShowOneOffs(boxType) {
  return {
    type: 'SET_SHOW_ONEOFFS',
    payload: boxType
  };
};
var setPrevCardsQuantity = function setPrevCardsQuantity(quantity) {
  return {
    type: 'SET_PREV_CARDS_QUANTITY',
    payload: quantity
  };
};
var setSubscribeAllProducts = function setSubscribeAllProducts(boolean) {
  return {
    type: 'SET_SUBSCRIBE_ALL_PRODUCTS',
    payload: boolean
  };
};
var klaviyoTrack = function klaviyoTrack(info) {
  return {
    type: 'KLAVIYO_TRACK',
    payload: info
  };
};
export default {
  completeContactInfo: completeContactInfo,
  completeShippingInfo: completeShippingInfo,
  startCheckout: startCheckout,
  goToCart: goToCart,
  setStatus: setStatus,
  setActiveStep: setActiveStep,
  getContextData: getContextData,
  setBabyData: setBabyData,
  addDiaper: addDiaper,
  removeDiaper: removeDiaper,
  setSubscribedDiaper: setSubscribedDiaper,
  addWipe: addWipe,
  removeWipe: removeWipe,
  setSubscribedWipe: setSubscribedWipe,
  setSubscribedUpselling: setSubscribedUpselling,
  setSubscriptionAllProducts: setSubscriptionAllProducts,
  addUpselling: addUpselling,
  removeUpselling: removeUpselling,
  flush: flush,
  setTokenPayment: setTokenPayment,
  cancelSubscription: cancelSubscription,
  pauseSubscription: pauseSubscription,
  activateSubscription: activateSubscription,
  updateSubscription: updateSubscription,
  setCustomerInfo: setCustomerInfo,
  setCutstomerAddress: setCutstomerAddress,
  processNewBox: processNewBox,
  setShippingDate: setShippingDate,
  completeOrder: completeOrder,
  setTokenCard: setTokenCard,
  setTokenVerifyCard: setTokenVerifyCard,
  resetCheckoutProcess: resetCheckoutProcess,
  applyPromoCode: applyPromoCode,
  removePromoCode: removePromoCode,
  savePromoCode: savePromoCode,
  setPromoCode: setPromoCode,
  setPayWithNewCard: setPayWithNewCard,
  setPayWithCOD: setPayWithCOD,
  savePayWithCOD: savePayWithCOD,
  editSubscription: editSubscription,
  setPaymentMethod: setPaymentMethod,
  removeCard: removeCard,
  gtmRemoveProducts: gtmRemoveProducts,
  setThisBox: setThisBox,
  setBoxInEdition: setBoxInEdition,
  setStatusPaymentError: setStatusPaymentError,
  setCheckedToSubscribe: setCheckedToSubscribe,
  setIncomingBoxInfo: setIncomingBoxInfo,
  updateCartProducts: updateCartProducts,
  selectSamplings: selectSamplings,
  setTotalBeforeTax: setTotalBeforeTax,
  removeSample: removeSample,
  setBoxTypePurchase: setBoxTypePurchase,
  setShowOneOffs: setShowOneOffs,
  setPrevCardsQuantity: setPrevCardsQuantity,
  setSubscribeAllProducts: setSubscribeAllProducts,
  klaviyoTrack: klaviyoTrack,
  removeCardTokenData: removeCardTokenData
};