/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react'
import {
  Accordion,
  AccordionSummary,
  Grid,
  Typography,
  Box,
  AccordionDetails,
  List,
  ListItem,
  Link,
  Hidden
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import propTypes from './props'

const FooterMobile = ({
  t,
  firstColumn,
  siteMap,
  history,
  mainMenu,
  legal,
  Phone,
  Whatsapp,
  Email,
  phoneHref,
  whatsappHref
}) => {
  return (
    <Box className="footer-mobile">
      {firstColumn && firstColumn}
      <Grid item className="footer__column">
        <Accordion className="footer__accordion">
          <AccordionSummary expandIcon={<ExpandMore />} className="footer__summary">
            <Typography variant="h6">{t('default.mainMenu')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {mainMenu.map(item => (
                <ListItem key={`mobile${item.name}`} className="footer__list-item">
                  <Link
                    component="button"
                    onClick={() => {
                      history.push(`/products/${item.path}`)
                      window.scrollTo(0, 0)
                    }}>
                    {item.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item className="footer__column">
        <Accordion className="footer__accordion">
          <AccordionSummary expandIcon={<ExpandMore />} className="footer__summary">
            <Typography variant="h6">{t('home.siteMap')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {siteMap.map(item => (
                <ListItem className="footer__list-item">
                  <Link
                    href={`${item.link ? item.path : ''}`}
                    target={`${item.link ? '_blank' : '_self'}`}
                    onClick={() => {
                      if (!item.link) {
                        history.push(`${item.path}`)
                        window.scrollTo(0, 0)
                      }
                    }}>
                    {item.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item className="footer__column">
        <Accordion className="footer__accordion">
          <AccordionSummary expandIcon={<ExpandMore />} className="footer__summary">
            <Typography variant="h6">{t('footer.deliveryAndReturns')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {legal.map(item => (
                <ListItem className="footer__list-item">
                  <Link
                    target="_blank"
                    href={item.url || 'https://yallababy.com/legal-yalla-baby-box.html'}
                    rel="noreferrer">
                    {item.name}
                  </Link>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item className="footer__column">
        <Accordion className="footer__accordion">
          <AccordionSummary expandIcon={<ExpandMore />} className="footer__summary">
            <Typography variant="h6">{t('footer.needHelp')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem className="footer__list-item">
                <Hidden only="md">
                  <img src={Phone} alt="Phone Number" className="list-icon" />
                </Hidden>
                <a href={phoneHref} target="_blank" rel="noopener noreferrer">
                  {t('footer.phone')}
                </a>
              </ListItem>
              <ListItem className="footer__list-item">
                <Hidden only="md">
                  <img src={Whatsapp} alt="Whatsapp Logo" className="list-icon" />
                </Hidden>
                <a href={whatsappHref} target="_blank" rel="noopener noreferrer">
                  {t('footer.whatsapp')}
                </a>
              </ListItem>
              <ListItem className="footer__list-item">
                <Hidden only="md">
                  <img src={Email} alt="Email" className="list-icon" />
                </Hidden>
                <a href={`mailto:${t('footer.contactEmail')}`} target="_blank" rel="noopener noreferrer">
                  {t('footer.contactEmail')}
                </a>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Box>
  )
}

FooterMobile.propTypes = propTypes
export default FooterMobile
