import React, { Fragment } from 'react'
import { Grid, Modal, Typography } from '@material-ui/core'
import { translate } from 'react-i18next'
import IconComponent from '../../components/atoms/IconComponent'
import LogIn from '../LogIn'
import propTypes from './props'
import SignIn from '../SignIn'
import LinkButton from '../../components/atoms/LinkButton'

const LoginSigninContainer = ({
  openModal,
  handleClose,
  t,
  showFormFooter,
  typeLoginSignin,
  setTypeLoginSignin,
  history,
  shouldRedirect,
  closeMenu
}) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={openModal}
    onClose={handleClose}
    className="login-signin-modal">
    <div tabIndex={-1}>
      <Fragment>
        <Grid container className="modal-login-signin">
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            direction="column"
            className="modal-login-signin__close">
            <IconComponent width="15px" height="15px" fill="black" icon="close" onClick={() => handleClose()} />
          </Grid>
          <Grid item container className="modal-login-signin__form">
            {typeLoginSignin === 'login' ? (
              <LogIn
                t={t}
                history={history}
                shouldRedirect={shouldRedirect}
                onClose={handleClose}
                closeMenu={closeMenu}
              />
            ) : (
              <SignIn
                t={t}
                history={history}
                shouldRedirect={shouldRedirect}
                onClose={handleClose}
                setTypeLoginSignin={setTypeLoginSignin}
              />
            )}
          </Grid>
          {showFormFooter && (
            <Grid item container className="modal-login-signin__footer" alignItems="center" justify="center">
              <Grid item container xs={12} justify="center">
                <Typography align="right">
                  {t(`default.${typeLoginSignin === 'login' ? 'dontHaveAccount' : 'IHaveAccount'}`)}
                </Typography>
                <LinkButton
                  classNames="login-signup-footer-button"
                  xs={3}
                  md={2}
                  text={t(`default.${typeLoginSignin === 'login' ? 'signup' : 'login'}`)}
                  onClick={() => {
                    setTypeLoginSignin(typeLoginSignin === 'login' ? 'signin' : 'login')
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Fragment>
    </div>
  </Modal>
)

LoginSigninContainer.propTypes = propTypes
export default translate('common')(LoginSigninContainer)
