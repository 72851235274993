import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import config from '../config';
var fetch = function fetch(module, payload) {
  var microservice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'service';
  return _objectSpread({}, payload, {
    type: 'UNIVERSAL_FETCH_MODULE',
    host: config.ENDPOINT_URL,
    microservice: microservice,
    module: module
  });
};
var fetchSuccess = function fetchSuccess(module, payload) {
  return {
    type: 'FETCH_MODULE_SUCCESS',
    module: module,
    payload: payload
  };
};
var requestError = function requestError(module, payload) {
  return {
    type: 'FETCH_MODULE_ERROR',
    module: module,
    payload: payload
  };
};
var remove = function remove(module, payload) {
  var microservice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'service';
  return _objectSpread({}, payload, {
    type: 'UNIVERSAL_REMOVE_MODULE',
    host: config.ENDPOINT_URL,
    module: module,
    method: 'delete',
    microservice: microservice
  });
};
var update = function update(module, payload) {
  var microservice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'service';
  return {
    type: 'UNIVERSAL_UPDATE_MODULE',
    host: config.ENDPOINT_URL,
    module: module,
    method: 'patch',
    microservice: microservice,
    data: payload.data
  };
};
var updateSuccess = function updateSuccess(payload) {
  return {
    type: 'UPDATE_MODULE_SUCCESS',
    host: config.ENDPOINT_URL,
    module: payload.module,
    data: payload.data
  };
};
var insert = function insert(module, payload) {
  var microservice = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'service';
  return {
    type: 'UNIVERSAL_INSERT_MODULE',
    host: config.ENDPOINT_URL,
    method: 'put',
    module: module,
    microservice: microservice,
    data: payload.data
  };
};
var upload = function upload(data) {
  return {
    type: 'UNIVERSAL_UPLOAD_FILE',
    data: data
  };
};
var toggleProp = function toggleProp(key) {
  return {
    type: 'TOGGLE_PROP',
    key: key
  };
};
var addToTemporal = function addToTemporal(storage, item) {
  return {
    type: 'ADD_TO_TEMPORAL_STORAGE',
    item: item,
    storage: storage
  };
};
var removeToTemporal = function removeToTemporal(storage, item, key) {
  return {
    type: 'REMOVE_TO_TEMPORAL_STORAGE',
    storage: storage,
    item: item,
    key: key
  };
};
var setState = function setState(state, value) {
  return {
    type: 'SET_TEMPORAL_STATE',
    state: state,
    value: value
  };
};
var setHelpdeskSession = function setHelpdeskSession(sessionObject) {
  return {
    type: 'SET_HELPDESK_SESSION',
    payload: sessionObject
  };
};
export default {
  fetch: fetch,
  fetchSuccess: fetchSuccess,
  requestError: requestError,
  remove: remove,
  update: update,
  insert: insert,
  toggleProp: toggleProp,
  addToTemporal: addToTemporal,
  updateSuccess: updateSuccess,
  removeToTemporal: removeToTemporal,
  setState: setState,
  upload: upload,
  setHelpdeskSession: setHelpdeskSession
};