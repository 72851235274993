import { useRef, useLayoutEffect } from 'react';
var getScrollPosition = function getScrollPosition(element, useWindow) {
  var position = document.body.getBoundingClientRect();
  return useWindow ? {
    x: window.scrollX,
    y: window.scrollY
  } : {
    x: position.left,
    y: position.top
  };
};
var useScrollPosition = function useScrollPosition(effect, deps, element, useWindow, wait) {
  var position = useRef(getScrollPosition({
    useWindow: useWindow
  }));
  var throttleTimeout = null;
  var callBack = function callBack() {
    var currPos = getScrollPosition({
      element: element,
      useWindow: useWindow
    });
    effect({
      prevPos: position.current,
      currPos: currPos
    });
    position.current = currPos;
    throttleTimeout = null;
  };
  useLayoutEffect(function () {
    var handleScroll = function handleScroll() {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return function () {
      return window.removeEventListener('scroll', handleScroll);
    };
  }, deps);
};
export default useScrollPosition;