/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, Grid, Typography, Box, Container, useMediaQuery, useTheme, Hidden } from '@material-ui/core'
import { translate } from 'react-i18next'
import propTypes from './props'
import Actions from '../../../actions'
import { CalcTotalPriceMKT, formatMoney, gtmBeginCheckout } from '../../../utils/helpers'
import InputPromoCode from '../../molecules/InputPromoCode'
import FlatButton from '../../molecules/FlatButton'
import SecurePay from '../../molecules/SecurePay'

const PriceDetail = ({
  handleButtonClick,
  payLoader,
  titleButton,
  idButton,
  t,
  selectedDiapers,
  selectedWipes,
  upselling,
  promoCode,
  vatPercent,
  codPercent,
  paymentType,
  handleCancel,
  disabledPayButton,
  emirate = 'Dubai',
  showCancelEdition,
  saveDisabled,
  realChange,
  history,
  stepPay,
  cardExpired,
  isCart,
  formValues,
  shippingCost,
  shippingCostInBox,
  isBox,
  boxes,
  promoCodeSubscription,
  boxStatus,
  uniqueCartProducts,
  uniqueCartProductsQty,
  logged,
  signupFormRef,
  showInfo,
  setShowInfo,
  shippingFormRef,
  codFixedAmount
}) => {
  const [showCode, setShowCode] = useState(() => !!promoCode)

  const { isEdition } = useSelector(state => state.Checkout)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    totalProducts,
    totalPrice,
    totalPriceWithDiscounts,
    totalBeforeTax,
    subscriptionDiscount,
    discount,
    finalPrice,
    vatAmount,
    codAmount,
    shippingPrice,
    totalPriceWithSubscription,
    totalPriceSubscription
  } = CalcTotalPriceMKT(
    selectedDiapers,
    selectedWipes,
    upselling,
    vatPercent,
    emirate,
    promoCode,
    codPercent,
    paymentType,
    isBox ? shippingCostInBox : shippingCost,
    boxes,
    codFixedAmount
  )

  const PENDING = 'PENDING'
  const isPayment = window.document.URL.includes('/checkout')
  const noProducts = selectedDiapers.length === 0 && selectedWipes.length === 0 && upselling.length === 0
  const dispatch = useDispatch()
  const setTotalBeforeTax = value => dispatch(Actions.Checkout.setTotalBeforeTax(value))
  const isBoxes = window.document.URL.includes('/boxes')
  const pendingOrNew = boxStatus === PENDING || !boxStatus
  const creditCardExpired = cardExpired && paymentType === 'CREDIT'
  const disableButton =
    ((formValues && !formValues.address1) ||
      creditCardExpired ||
      realChange ||
      (isPayment && disabledPayButton) ||
      (saveDisabled && titleButton !== t('default.ok')) ||
      noProducts) &&
    !boxes

  useEffect(() => {
    setTotalBeforeTax(totalBeforeTax)
  }, [totalBeforeTax])

  useEffect(() => {
    if (stepPay)
      gtmBeginCheckout(
        uniqueCartProducts,
        uniqueCartProductsQty,
        totalProducts,
        +discount.toFixed(2),
        paymentType,
        +subscriptionDiscount.toFixed(2),
        +totalPriceSubscription.toFixed(2),
        +vatAmount.toFixed(2),
        +totalPriceWithDiscounts.toFixed(2),
        shippingPrice,
        finalPrice
      )
  }, [])

  useEffect(() => {
    if (logged && !showInfo) shippingFormRef.current.submit()
  }, [logged, showInfo])

  const handleClickCode = () => setShowCode(prevState => !prevState)

  const hasIcon = titleButton === 'Proceed to secure checkout'

  const cancelEditionBtn = showCancelEdition && !isBoxes && (
    <Grid container justify="center" alignItems="center">
      <FlatButton title={t('cart.cancelEdition')} classes="total-button-cancel" onClick={() => handleCancel()} />
    </Grid>
  )
  const showPayNextButton = (isCart && isMobile) || (isPayment && !showInfo && logged) || boxes
  const showContinueButton = (isPayment && showInfo) || (!showInfo && !logged && !isBoxes && !isCart)

  const getMobileBtnTitle = () => {
    if (isEdition) {
      return t('cart.saveChanges')
    }
    if ((!showCancelEdition && !isCart) || boxes) {
      return titleButton
    }
    return t('default.next')
  }

  return (
    <Grid item className="price-detail">
      <Container>
        <Box className="price-detail__title">
          <Typography>{t('cart.priceDetail.title')}</Typography>
        </Box>
        <Box className="price-detail__wrapper">
          <Grid container className="price-detail__item">
            <Grid container className="price-detail__item__wrapper">
              <Typography variant="inherit">{`${totalProducts}x ${t('cart.priceDetail.products')}`}</Typography>
              <Typography variant="inherit">
                {`${t('cart.priceDetail.positiveAED')}${formatMoney(totalPrice)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="price-detail__item">
            <Grid container className="price-detail__item__wrapper">
              <Typography variant="inherit">{t('cart.priceDetail.subscriptionDiscount')}</Typography>
              <Typography variant="inherit">
                {`${t('cart.priceDetail.negativeAED')}${formatMoney(subscriptionDiscount)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="price-detail__item">
            <Grid container className="price-detail__item__wrapper">
              <Typography variant="inherit">{t('cart.priceDetail.promoCode')}</Typography>
              <Typography variant="inherit">
                {`${
                  discount === 0 ? t('cart.priceDetail.positiveAED') : t('cart.priceDetail.negativeAED')
                }${formatMoney(discount)}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="price-detail__item price-detail__item--bold">
            <Grid container className="price-detail__item__wrapper">
              <Typography variant="inherit">{t('cart.priceDetail.subtotal')}</Typography>
              <Typography variant="inherit">
                {`${t('cart.priceDetail.positiveAED')}${formatMoney(totalPriceWithDiscounts)}`}
              </Typography>
            </Grid>
          </Grid>
          {paymentType === 'COD' && (+codFixedAmount !== 0 || +codPercent !== 0) && (
            <Grid container className="price-detail__item">
              <Grid container className="price-detail__item__wrapper">
                <Typography variant="inherit">
                  {`${t('cart.priceDetail.cod')} ${+codFixedAmount === 0 ? `( ${codPercent}% )` : ''} `}
                </Typography>
                <Typography variant="inherit">
                  {`${t('cart.priceDetail.positiveAED')}${formatMoney(codAmount)}`}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container className="price-detail__item">
            <Grid container className="price-detail__item__wrapper">
              <Typography variant="inherit">{t('cart.priceDetail.shipping')}</Typography>
              <Typography variant="inherit">
                {`${t('cart.priceDetail.positiveAED')} ${shippingPrice.toFixed(2)}`}
              </Typography>
            </Grid>
          </Grid>
          {+vatPercent !== 0 && (
            <>
              <Grid container item className="price-detail__item price-detail__item--bold">
                <Grid container className="price-detail__item__wrapper">
                  <Typography variant="inherit">{t('cart.priceDetail.beforeTax')}</Typography>
                  <Typography variant="inherit">
                    {`${t('cart.priceDetail.positiveAED')}${formatMoney(totalBeforeTax)}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item className="price-detail__item">
                <Grid container className="price-detail__item__wrapper price-detail__item__wrapper--no-line">
                  <Typography variant="inherit">{`${t('cart.priceDetail.vat')} ( ${vatPercent}% )`}</Typography>
                  <Typography variant="inherit">
                    {`${t('cart.priceDetail.positiveAED')}${formatMoney(vatAmount)}`}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Box className="price-detail__extra">
          <Grid container justifyContent={pendingOrNew ? 'space-between' : 'flex-end'} alignItems="center">
            {pendingOrNew && (
              <Box className="price-detail__extra--code">
                <Typography onClick={() => handleClickCode()}>Have a promo code?</Typography>
              </Box>
            )}
            <Box className="price-detail__extra--total">
              <Typography>
                {`${t('checkout.total')}: `}
                <span>{`${t('cart.priceDetail.positiveAED')}${formatMoney(finalPrice)}`}</span>
              </Typography>
            </Box>
          </Grid>
        </Box>
        {showCode && (
          <Box className="price-detail__promo-code">
            <Grid container>
              <InputPromoCode
                promoCode={promoCode}
                promoCodeSubscription={promoCodeSubscription}
                t={t}
                history={history}
                cart
                totalPriceWithSubscription={totalPriceWithSubscription}
                discount={discount}
              />
            </Grid>
          </Box>
        )}

        {isCart && (
          <>
            <Box className="price-detail__btn-checkout">
              <Grid className="next-button-mobile-container" alignItems="center" justify="center">
                <FlatButton
                  payLoader={payLoader}
                  idButton={idButton}
                  title={titleButton}
                  type={'submit'}
                  classes={`next-button-mobile ${disableButton ? 'next-button-mobile--disabled' : ''}`}
                  disabled={disableButton || payLoader}
                  onClick={() => handleButtonClick()}
                  cartIcon={hasIcon && 'arrowRight'}
                  iconClass="arrow-icon"
                />
              </Grid>
            </Box>
            <Box className="price-detail__btn-cancel">{cancelEditionBtn}</Box>
            <Box className="price-detail__secure-pay">{<SecurePay t={t} />}</Box>
          </>
        )}
      </Container>

      <Grid
        alignItems="center"
        wrap="wrap"
        className={`price-detail__buttons ${stepPay ? 'price-detail__buttons--payment' : ''}`}>
        {isCart && showCancelEdition && (
          <Hidden smUp>
            <Grid item xs={6} className="btn--cancel">
              {cancelEditionBtn}
            </Grid>
          </Hidden>
        )}
        {isCart && !showCancelEdition && (
          <Grid xs={6}>
            <Typography className="price-detail__buttons__total-price-mobile" variant="inherit">
              {t('checkout.total')}
            </Typography>
            <Typography className="price-detail__buttons__total-price-mobile" variant="inherit">
              {`${t('cart.priceDetail.positiveAED')}${formatMoney(finalPrice)}`}
            </Typography>
          </Grid>
        )}

        {showPayNextButton && (
          <>
            <Grid item className="total" xs={isCart ? 5 : 10} md={10} container alignItems="center" justify="center">
              <FlatButton
                payLoader={payLoader}
                idButton={idButton}
                title={getMobileBtnTitle()}
                type={'submit'}
                classes={`total-button${disableButton ? '__disabled' : ''}`}
                disabled={disableButton || payLoader}
                onClick={() => handleButtonClick()}
                cartIcon={!showCancelEdition && !isCart && !payLoader && !boxes && 'LockIconJaka'}
                iconClass="lock-icon"
              />
            </Grid>

            {!isMobile && <Box className="price-detail__secure-pay">{<SecurePay t={t} />}</Box>}
          </>
        )}
        {showContinueButton && (
          <Grid item className="total" xs={isCart ? 4 : 10} md={10} container alignItems="center" justify="center">
            <FlatButton
              payLoader={payLoader}
              onClick={e => {
                shippingFormRef.current.validate(shippingFormRef.current.childs[0], true)
                if (!logged) {
                  signupFormRef.current.validate(signupFormRef.current.childs[2], true)
                  signupFormRef.current.validate(signupFormRef.current.childs[3], true)
                }
                if (showInfo && logged) {
                  setShowInfo(!formValues.address1)
                } else if (formValues.address1) {
                  e.preventDefault()
                  signupFormRef.current.submit()
                }
              }}
              title={t('loginSignUp.continue')}
              type={'submit'}
              classes={`total-button`}
            />
          </Grid>
        )}
      </Grid>
      <Grid direction="column" alignItems="center">
        {logged && (
          <Collapse in={disabledPayButton}>
            <Typography
              display="block"
              align="center"
              className="information-steps__limit-info warning"
              variant="inherit">
              {t('cart.priceDetail.warning2')}
            </Typography>
          </Collapse>
        )}
      </Grid>

      {cardExpired && paymentType === 'CREDIT' && (
        <Grid item xs={12} className="information-steps__limit-info" container alignItems="center" justify="center">
          <Typography variant="inherit">{t('cart.cardExpiredText')}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

PriceDetail.propTypes = propTypes
export default translate('common')(PriceDetail)
