/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Grid, AppBar, Toolbar, SwipeableDrawer, Hidden, Container } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { editSelectedSubscription } from '../../../utils/helpers'
import propTypes from './props'
import SideMenu from '../../../containers/SideMenu'
import UpvotyModal from '../../molecules/UpvotyModal'
import LoginSigninContainer from '../../../containers/LoginSigninContainer'
import CreateBoxButton from '../../molecules/CreateBoxButton'
import Actions from '../../../actions'
import HeaderSiteMap from '../../molecules/HeaderSiteMap'
import AddToSubBoxModal from '../../molecules/AddToSubBoxModal'
import MaintenanceModeModal from '../../molecules/MaintenanceModeModal'
import CantEditAlert from '../../molecules/CantEditAlert'
import TopBar from '../../molecules/TopBar'
import LogoMenu from '../../molecules/LogoMenu'
import HeaderLeftItems from '../../molecules/HeaderLeftItems'
import NavSearch from '../../molecules/NavSearch'
import BoxEditionAlertModal from '../BoxEditionAlertModal'
import CheckoutStepper from '../../molecules/CheckoutStepper'
import { FIRST_COLLECTION } from '../../../utils/urls'

const Header = ({ showSideCartIcon, bg, history, t, headerAdd, showSearch, openLogin, showHelpBtn, showLogin }) => {
  const [searching, setSearching] = useState(false)
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const [openLoginSignin, setOpenLoginSignin] = useState(false)
  const [formType, setFormType] = useState('login')
  const [openModal, setOpenModal] = useState(false)
  const [inputSearchVisible, setInputSearchVisible] = useState(false)
  const [cancelSelected, setCancelSelected] = useState(false)

  const version = useSelector(state => state.Version)
  const underMaintenance = useSelector(state => state.Version.underMaintenance)

  const isEdition = useSelector(state => state.Checkout.isEdition)
  const checked = useSelector(state => state.Checkout.checkedToSubscribe)
  const currentLanguage = useSelector(state => state.Language.current)
  const filterParam = useSelector(state => state.Products.filter)
  const isUserLoggedIn = useSelector(state => state.Users.logged)
  const subscriptions = useSelector(state => state.Users.subscriptions)
  const { upselling, selectedDiapers, selectedWipes, id } = useSelector(state => state.Checkout)
  const subscribeOneOffBox = useSelector(state => state.Checkout.subscribeAllProducts)

  const products = useSelector(state => state.Products)
  const cart = upselling.concat(selectedWipes).concat(selectedDiapers)

  const [filterValue, setFilterValue] = useState(filterParam)
  const [openUpvoty, setOpenUpvoty] = useState(false)
  const [upvotyPath, setUpvotyPath] = useState('')
  const userData = useSelector(state => state.Users.userData)

  const isCheckout = window.location.pathname === '/checkout'
  const isSuccessPurchase = window.location.pathname === '/checkout/done'

  const dispatch = useDispatch()
  const setChecked = isChecked => dispatch(Actions.Checkout.setCheckedToSubscribe(isChecked))
  const setOneOffBox = type => dispatch(Actions.Checkout.setBoxTypePurchase(type))
  const setSubscribeAllProducts = boolean => dispatch(Actions.Checkout.setSubscribeAllProducts(boolean))

  const updateCartProducts = cartUpdated => dispatch(Actions.Checkout.updateCartProducts(cartUpdated))

  const getSubscriptions = type => dispatch(Actions.Users.setFetchSubscriptions(type))
  const activeSubscriptions = subscriptions.filter(
    sub => sub.purchaseType === 'SUBSCRIPTION' && sub.status === 'PENDING'
  )

  const subscribableEditBox = _.orderBy(activeSubscriptions, [el => moment(el.shippingDate)], ['asc'])[0]

  // const changeLanguage = language => dispatch(Actions.Language.changeLanguage(language))

  const setFilter = data => dispatch(Actions.Products.filter(data))
  const resetErrorRegister = () => dispatch(Actions.Users.resetErrorRegister())
  const pendingSubscriptions = useSelector(state => state.Users.pendingSubscriptions)

  const checkVersion = () => {
    const today = new Date()
    const updated = new Date(version.updated)
    updated.setDate(updated.getDate() + 1)

    if (today > updated) {
      dispatch(Actions.Version.getVersion())
    }
  }
  const handleClose = () => {
    setOpenModal(false)
    setChecked(true)
  }

  useEffect(() => {
    if (isUserLoggedIn && window.FS)
      window.FS.identify(userData.email, { displayname: userData.email, email: userData.email })
  }, [isUserLoggedIn])

  const toggleDrawer = (open, type) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (type === 'menu') {
      setOpenSideMenu(open)
    }
  }

  useEffect(() => {
    if (isEdition) getSubscriptions('PENDING')
    if (isUserLoggedIn && subscriptions.length === 0 && !checked && !isEdition) getSubscriptions('CURRENT')
    checkVersion()
    if (filterParam.length > 0) setSearching(true)
    if (openLogin) setOpenLoginSignin(true)
  }, [])

  const checkEditingBoxStatus = () => {
    return pendingSubscriptions.find(box => box.id === id)
  }
  const boxIsNoMorePending = isEdition && pendingSubscriptions && !checkEditingBoxStatus()

  useEffect(() => {
    if (boxIsNoMorePending) {
      setTimeout(() => {
        history.push('/boxes')
      }, 5000)
    }
  }, [pendingSubscriptions])

  useEffect(() => {
    setFilterValue(filterParam)
  }, [filterParam])

  const handleOpenLoginSignin = type => {
    setFormType(type)
    setOpenLoginSignin(true)
  }
  const handleCloseLoginSignin = () => {
    resetErrorRegister()
    setOpenLoginSignin(false)
  }

  useEffect(() => {
    setOpenModal(isUserLoggedIn && cart.length > 0 && !checked && !isEdition && subscribableEditBox)
    if (cart.length === 0 && checked && isUserLoggedIn) {
      setChecked(false)
      setOneOffBox(null)
    }
  }, [cart])

  useEffect(() => {
    if (isUserLoggedIn) setOpenModal(cart.length > 0 && !checked && !isEdition && subscribableEditBox)
    if (!isUserLoggedIn) setOneOffBox(null)
  }, [isUserLoggedIn])

  useEffect(() => {
    if (isEdition) setOneOffBox(null)
    if (!isEdition && subscribeOneOffBox) setSubscribeAllProducts(null)
  }, [isEdition])

  const addToExistingBox = () => {
    const box = subscribableEditBox
    dispatch(Actions.Checkout.setThisBox(box))
    dispatch(Actions.Checkout.setBoxInEdition(box))
    const subs = editSelectedSubscription(box)
    dispatch(Actions.Checkout.editSubscription(subs))
    dispatch(Actions.Checkout.setStatus())

    if (selectedDiapers) selectedDiapers.forEach(diaper => dispatch(Actions.Checkout.addDiaper(diaper)))

    if (selectedWipes) selectedWipes.forEach(wipe => dispatch(Actions.Checkout.addWipe(wipe)))

    if (upselling) upselling.forEach(up => dispatch(Actions.Checkout.addUpselling(up)))
  }

  const [pathToPush, setPathToPush] = useState('')
  const [openAlertModal, setOpenAlertModal] = useState(false)

  return (
    <>
      <Grid container className={currentLanguage === 'AR' ? 'invertAr' : ''}>
        <AppBar color="default" className="header">
          <TopBar
            t={t}
            handleOpenLoginSignin={handleOpenLoginSignin}
            setOpenUpvoty={setOpenUpvoty}
            setUpvotyPath={setUpvotyPath}
          />
          <Container maxWidth="xl">
            <Grid className="header-settings">
              {inputSearchVisible && (
                <Hidden lgUp>
                  <NavSearch
                    t={t}
                    show={showSearch}
                    inputSearchVisible={inputSearchVisible}
                    setInputSearchVisible={setInputSearchVisible}
                  />
                </Hidden>
              )}
              <LogoMenu currentLanguage={currentLanguage} toggleDrawer={toggleDrawer} />
              {(isCheckout || isSuccessPurchase) && <CheckoutStepper />}
              <Hidden mdDown>
                <NavSearch show={showSearch} t={t} />
              </Hidden>
              <HeaderLeftItems
                t={t}
                showLogin={showLogin}
                showSideCartIcon={showSideCartIcon}
                toggleDrawer={toggleDrawer}
                cart={cart}
                history={history}
                handleOpenLoginSignin={handleOpenLoginSignin}
                setOpenAlertModal={setOpenAlertModal}
                setPathToPush={setPathToPush}
                showHelpBtn={showHelpBtn}
                showSearch={showSearch}
                setInputSearchVisible={setInputSearchVisible}
              />
            </Grid>
          </Container>
          {headerAdd && (
            <Hidden smDown>
              <Toolbar variant="regular" className="header-toolbar">
                <Grid item xs={11} justify="center" container direction="row" className="header-desktop">
                  <HeaderSiteMap t={t} xs={4} md={5} history={history} />
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={3}
                    lg={3}
                    container
                    alignItems="center"
                    direction="row"
                    justify="space-between">
                    <Grid item xs={10}>
                      <CreateBoxButton
                        t={t}
                        icon
                        buttonColor="green"
                        title={t('default.createNewBox')}
                        onClick={() => {
                          history.push(`/products/${FIRST_COLLECTION}`)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </Hidden>
          )}
          <LoginSigninContainer
            openModal={openLoginSignin}
            handleClose={handleCloseLoginSignin}
            typeLoginSignin={formType}
            setTypeLoginSignin={setFormType}
            showFormFooter
            t={t}
            history={history}
            language={currentLanguage}
          />
        </AppBar>
        <CantEditAlert openAlert={boxIsNoMorePending} t={t} />
      </Grid>
      <SwipeableDrawer
        anchor={currentLanguage === 'AR' ? 'right' : 'left'}
        open={openSideMenu}
        onOpen={toggleDrawer(true, 'menu')}
        onClose={toggleDrawer(false, 'menu')}>
        <SideMenu
          t={t}
          history={history}
          handleClose={toggleDrawer(false, 'menu')}
          setChecked={setChecked}
          setOpenUpvoty={setOpenUpvoty}
          setUpvotyPath={setUpvotyPath}
          handleOpenLoginSignin={handleOpenLoginSignin}
          setPathToPush={setPathToPush}
          setOpenAlertModal={setOpenAlertModal}
        />
      </SwipeableDrawer>

      <AddToSubBoxModal
        t={t}
        history={history}
        open={openModal}
        handleClose={handleClose}
        subscribableEditBox={subscribableEditBox}
        addToExistingBox={addToExistingBox}
      />
      <UpvotyModal
        setOpenSideMenu={setOpenSideMenu}
        upvotyPath={upvotyPath}
        openUpvoty={openUpvoty}
        setOpenUpvoty={setOpenUpvoty}
      />
      <MaintenanceModeModal t={t} history={history} open={underMaintenance} />
      <BoxEditionAlertModal
        isRememberModal
        handleClose={toggleDrawer(false, 'menu')}
        history={history}
        pathToPush={pathToPush}
        openAlertModal={openAlertModal}
        setOpenAlertModal={setOpenAlertModal}
        t={t}
        cancelSelected={cancelSelected}
        setCancelSelected={setCancelSelected}
      />
    </>
  )
}

Header.propTypes = propTypes
export default Header
