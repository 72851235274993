import React from 'react'
// Tools
import propTypes from './props'
import InfoGrey from '../../../../assets/images/icons/info.svg'
import infoWhite from '../../../../assets/images/icons/infoWhite.svg'
import infoBlack from '../../../../assets/images/icons/infoBlack.svg'
import InfoBlue from '../../../../assets/images/icons/info-blue.svg'

const InfoButton = ({ t, onClick, color = 'grey', className }) => (
  <button type="button" onClick={() => onClick()} className={`info-button ${className}`}>
    {color === 'grey' && <img src={InfoGrey} alt={t('default.info')} />}
    {color === 'blue' && <img src={InfoBlue} alt={t('default.info')} />}
    {color === 'white' && <img src={infoWhite} alt={t('default.info')} />}
    {color === 'black' && <img src={infoBlack} alt={t('default.info')} />}
  </button>
)

InfoButton.propTypes = propTypes
export default InfoButton
