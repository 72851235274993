import React from 'react'
import { useSelector } from 'react-redux'
import propTypes from './props'

const Loader = ({ loading }) => {
  const working = useSelector(state => loading || state.Universal.request.process)
  return (
    <div
      className="general-loader"
      style={{
        display: working ? 'block' : 'none'
      }}
    >
      <div className="cssload-loader-inner">
        <div id="circularG">
          <div id="circularG_1" className="circularG" />
          <div id="circularG_2" className="circularG" />
          <div id="circularG_3" className="circularG" />
          <div id="circularG_4" className="circularG" />
          <div id="circularG_5" className="circularG" />
          <div id="circularG_6" className="circularG" />
          <div id="circularG_7" className="circularG" />
          <div id="circularG_8" className="circularG" />
        </div>
      </div>
    </div>
  )
}

Loader.propTypes = propTypes
export default Loader
