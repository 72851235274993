import _defineProperty from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
var initialState = {
  request: {
    error: false,
    process: false
  },
  helpdeskSession: {},
  status: null
};
var Universal = function Universal() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'FETCH_MODULE':
      return _objectSpread({}, state, {
        request: {
          error: false,
          process: true
        }
      });
    case 'FETCH_REQUEST_END':
      return _objectSpread({}, state, {
        request: _objectSpread({}, state.request, {
          process: false
        })
      });
    case 'UPDATE_MODULE_SUCCESS':
      return state;
    case 'FETCH_MODULE_SUCCESS':
      return _objectSpread({}, state, _defineProperty(_defineProperty({}, action.module === 'uniques' ? "unique-".concat(action.payload.query.prop) : action.module, {
        docs: action.payload.data.docs,
        page: action.payload.data.page,
        limit: action.payload.data.limit,
        totalPages: action.payload.data.totalPages,
        query: action.payload.query,
        count: action.payload.data.count
      }), "request", {
        error: false,
        process: false
      }));
    case 'FETCH_MODULE_ERROR':
      return _objectSpread({}, state, {
        request: {
          error: true,
          msg: action.payload.error,
          process: false
        }
      });
    case 'SET_HELPDESK_SESSION':
      return _objectSpread({}, state, {
        helpdeskSession: action.payload
      });
    default:
      return state;
  }
};
export default Universal;