export var setVersion = function setVersion(newVersion) {
  return {
    type: 'UPDATE_VERSION',
    payload: {
      newVersion: newVersion
    }
  };
};
export var getVersion = function getVersion() {
  return {
    type: 'GET_VERSION'
  };
};
export var setUnderMaintenanceMode = function setUnderMaintenanceMode(boolean) {
  return {
    type: 'SET_UNDER_MAINTENANCE_MODE',
    payload: boolean
  };
};
export default {
  setVersion: setVersion,
  getVersion: getVersion
};