/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const PromotionsIcon = ({ color }) => (
  <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M11.9305,1.609 C11.5525,1.231 11.0275,1 10.45,1 L3.1,1 C1.945,1 1,1.945 1,3.1 L1,10.45 C1,11.0275 1.231,11.5525 1.6195,11.941 L11.0695,21.391 C11.4475,21.769 11.9725,22 12.55,22 C13.1275,22 13.6525,21.769 14.0305,21.3805 L21.3805,14.0305 C21.769,13.6525 22,13.1275 22,12.55 C22,11.9725 21.7585,11.437 21.3805,11.059 L11.9305,1.609 Z M5,6 C4.44666667,6 4,5.55333333 4,5 C4,4.44666667 4.44666667,4 5,4 C5.55333333,4 6,4.44666667 6,5 C6,5.55333333 5.55333333,6 5,6 L5,6 Z"
        id="Fill-240-Copy"
        stroke={color || '#393939'}
        strokeWidth="1.5"
      />
    </g>
  </svg>
)

export default PromotionsIcon
