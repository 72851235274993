/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { translate } from 'react-i18next'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Actions from '../../actions'
import gift from '../../assets/images/gift.svg'

const PureReactCarousel = ({ products, visibleSlides, totalBeforeTax }) => {
  const subscriptions = useSelector(state => state.Users.subscriptions)
  const id = useSelector(state => state.Checkout.id)
  const isEdition = useSelector(state => state.Checkout.isEdition)

  const getSamplesInBox = () => {
    const editingBox = subscriptions.find(box => box.id === id)
    const samples = editingBox.boxSamples
    return samples
  }

  const selectedSamplings = useSelector(state => state.Checkout.selectedSamplings) || []
  const dispatch = useDispatch()
  const selectSamplings = samples => dispatch(Actions.Checkout.selectSamplings(samples))
  const removeSample = sampleId => dispatch(Actions.Checkout.removeSample(sampleId))

  const isSelected = sampleId => {
    return selectedSamplings.find(sampling => sampling.variant_id === sampleId)
  }

  const samplesAllowed = totalBeforeTax >= 500 ? 2 : 1

  const selectSample = sample => {
    const sampleExist = selectedSamplings.find(samp => samp.variant_id === sample.variant_id)

    if (sampleExist) removeSample(sample.variant_id)
    else selectSamplings(sample)
  }

  useEffect(() => {
    if (totalBeforeTax < 500 && selectedSamplings.length === 2) removeSample(selectedSamplings[1].variant_id)
  }, [totalBeforeTax])

  useEffect(() => {
    if (selectedSamplings.length > samplesAllowed) removeSample(selectedSamplings[0].variant_id)
  }, [selectedSamplings])

  useEffect(() => {
    if (isEdition)
      getSamplesInBox().forEach(sample => {
        selectSamplings({
          product_id: +sample.shopifyProductId,
          variant_id: +sample.shopifyVariantId,
          product: sample
        })
      })
  }, [])

  return (
    <CarouselProvider
      className="samples-gifts__carousel"
      totalSlides={products.length}
      visibleSlides={visibleSlides}
      infinite
      step={visibleSlides}
      dragStep={visibleSlides}>
      <Slider className="samples-gifts__slider">
        {products.map((sample, i) => (
          <Slide
            key={sample.id}
            onClick={() =>
              selectSample({
                product_id: sample.product_id,
                variant_id: sample.id,
                product: sample.product
              })
            }
            className="samples-gifts__product"
            index={i}>
            <img
              className={`samples-gifts__product__image ${isSelected(sample.id) && 'selected'}`}
              src={(sample && sample.variantImage) || gift}
              alt="Gifts and Samples"
            />
            <Typography className="samples-gifts__product__title">{`${sample.productTitle}`}</Typography>
          </Slide>
        ))}
      </Slider>
      {products.length > visibleSlides && (
        <>
          <DotGroup className="samples-gifts__dot-group" />

          <ButtonBack>
            <ChevronLeftIcon className="samples-gifts__back-button" />
          </ButtonBack>

          <ButtonNext>
            <ChevronRightIcon className="samples-gifts__next-button" />
          </ButtonNext>
        </>
      )}
    </CarouselProvider>
  )
}

export default translate('common')(PureReactCarousel)
