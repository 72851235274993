import _slicedToArray from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useEffect, useState, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import sjcl from 'sjcl';
import { useSelector } from 'react-redux';
import { pageName } from '../utils/helpers';
import config from '../config';
var useTagManagerLocation = function useTagManagerLocation() {
  var userData = useSelector(function (state) {
    return state.Users.userData;
  });
  var location = useLocation();
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    origin = _useState2[0],
    setOrigin = _useState2[1];
  var hashEmail = useMemo(function () {
    if (!userData) {
      return null;
    }
    var bitArray = sjcl.hash.sha256.hash(userData.email);
    return sjcl.codec.hex.fromBits(bitArray);
  }, [userData]);
  var userDataObject = userData ? {
    email: userData.email,
    email_sha256: hashEmail,
    id: userData.id
  } : undefined;
  useEffect(function () {
    setOrigin(document.location.href);
  }, []);
  useEffect(function () {
    var dataLayer = {
      _clear: true,
      category: 'navigation',
      environment: config.TAG_MANAGER_ENV,
      event: 'view_page',
      id: uuidv4(),
      page: {
        locale: navigator.languages[0],
        name: pageName(location.pathname),
        path: location.pathname,
        original_location: origin
      },
      user: userDataObject
    };
    TagManager.dataLayer({
      dataLayer: dataLayer,
      dataLayerName: 'YallaBaby'
    });
  }, [location.pathname]);
};
export default useTagManagerLocation;