/* eslint-disable prefer-destructuring */
import React, { useEffect, useState, useRef } from 'react'
import { Container, Grid, Typography, Box, Hidden } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next'
import _ from 'lodash'
import propTypes from './props'
import Header from '../../components/organisms/Header'
import Loader from '../../components/atoms/Loader'
import IconComponent from '../../components/atoms/IconComponent'
import ProductsDetail from '../../components/organisms/ProductsDetail'
import PriceDetail from '../../components/organisms/PriceDetail'
import Actions from '../../actions'
import {
  CalcTotalPriceMKT,
  gtmCheckoutSteps,
  isAnyProductSubscribed,
  haveOfferTag,
  formatMoney,
  capitalize,
  getDifferentProducts
} from '../../utils/helpers'
import BreadCrumbs from '../../components/atoms/BreadCrumbs'
import PaymentCard from '../../components/molecules/PaymentCard'
import BoxEditionAlertModal from '../../components/organisms/BoxEditionAlertModal'
import CantEditAlert from '../../components/molecules/CantEditAlert'
import MomsAlsoBought from '../../components/molecules/MomsAlsoBought'
import HurryUpCounter from '../../components/molecules/HurryUpCounter'
import FreeDeliveryBar from '../../components/molecules/FreeDeliveryBar'
import FlatButton from '../../components/molecules/FlatButton'
import ArrivesTodayNotification from '../../components/molecules/ArrivesTodayNotification'

const CartCheck = ({ history, t }) => {
  const {
    isEdition,
    selectedDiapers,
    selectedWipes,
    upselling,
    promoCode,
    payWithCOD,
    paymentType,
    codPercent,
    id,
    boxTypePurchase
  } = useSelector(state => state.Checkout)

  const { userData, subscriptions, logged } = useSelector(state => state.Users)

  const currentLanguage = useSelector(state => state.Language.current)

  const currentCodPercent = useSelector(
    state => (state.Checkout.taxAndDiscount && state.Checkout.taxAndDiscount.codPercent) || {}
  )
  const { vatPercent, shippingCost, codAmount } = useSelector(state => state.Checkout.taxAndDiscount || {})

  const { status } = useSelector(state => state.Checkout.status || {})
  const { thisBox, shippingDate, boxInEdition, selectedSamplings } = useSelector(state => state.Checkout)

  const boxIsNoMorePending = useSelector(
    state =>
      state.Checkout.status && state.Checkout.status.err && state.Checkout.status.err.response.data.appCode === 404
  )

  const subscribeOneOffBox = useSelector(state => state.Checkout.subscribeAllProducts)

  const hasCreditCards = useSelector(state => state.Users.paymentMethod.length > 0)
  const selectedCard = useSelector(state => state.Users.paymentMethod.filter(card => card.active)[0])
  const subscription = useSelector(state => state.Users.subscriptions.find(box => box.id === id))
  const babyInfo = subscription && subscription.babyInfo

  const [promoCodeSubscription, setPromoCodeSubscription] = useState(null)

  const [samplesInfo, setSamplesInfo] = useState({
    gender: isEdition ? babyInfo.gender : '',
    diaperSize: isEdition ? babyInfo.diaperSize : null,
    name: isEdition ? babyInfo.name : ''
  })
  const showCodAdded = payWithCOD && +codPercent === 0

  const dispatch = useDispatch()
  const updateBabyInfo = babyId => dispatch(Actions.Users.fetchSetSubscriptionBabyInfo(babyId))

  const flushCheckout = () => dispatch(Actions.Checkout.flush())

  const getUserPaymentMethod = cb => dispatch(Actions.Users.getUserPaymentMethod(cb))
  const setBabyData = data => dispatch(Actions.Checkout.setBabyData(data))

  const [openDatePicker, setOpenDatePicker] = useState(false)

  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [pathToPush, setPathToPush] = useState('')
  const [isRememberModal, setIsRememberModal] = useState(true)
  const [cancelSelected, setCancelSelected] = useState(false)

  const handleOpenDatePicker = () => {
    setOpenDatePicker(true)
  }
  const handleCloseDatePicker = () => {
    setOpenDatePicker(false)
  }

  const setThisBox = box => {
    dispatch(Actions.Checkout.setThisBox(box))
  }

  const setBoxInEdition = box => {
    dispatch(Actions.Checkout.setBoxInEdition(box))
  }

  const boxInEditionCart = {
    selectedDiapers,
    selectedWipes,
    upselling,
    paymentType,
    promoCode: promoCode ? promoCode.id : '',
    shippingDate,
    samplesInfo,
    selectedSamplings
  }

  const setToOneOff = products =>
    products.forEach(product => {
      const prod = product
      if (prod.purchase_type) prod.purchase_type = 'ONE_TIME'
      else prod.purchaseType = 'ONE_TIME'
    })

  const isSubscribable = oneTimeProduct => {
    const variant = oneTimeProduct.variants[0]
    return variant.compare_at_price && variant.compare_at_price > 0 && !haveOfferTag(oneTimeProduct)
  }

  const setToSubscribed = products =>
    products.forEach(product => {
      const prod = product
      if (isSubscribable(product)) {
        if (prod.purchase_type) prod.purchase_type = 'SUBSCRIPTION'
        else prod.purchaseType = 'SUBSCRIPTION'
      }
    })

  if (boxTypePurchase === 'oneOffs') {
    if (selectedDiapers) setToOneOff(selectedDiapers)
    if (selectedWipes) setToOneOff(selectedWipes)
    if (upselling) setToOneOff(upselling)
  }

  useEffect(() => {
    if (boxIsNoMorePending) {
      setTimeout(() => {
        history.push('/boxes')
      }, 5000)
    }
  }, [boxIsNoMorePending])

  useEffect(() => {
    if (isEdition) {
      if (!boxInEdition) setBoxInEdition(boxInEditionCart)
    }

    dispatch(Actions.Checkout.getContextData())

    if (!promoCodeSubscription && promoCode) {
      setPromoCodeSubscription(promoCode)
    }
    window.scrollTo(0, 0)
    getUserPaymentMethod()
    if (!isEdition) {
      gtmCheckoutSteps(
        [...selectedDiapers, ...selectedWipes, ...upselling],
        userData,
        'precart',
        { step: '1', action: 'checkout' },
        promoCode && promoCode.title
      )
    } else if (!thisBox) setThisBox(boxInEditionCart)

    if (isEdition && subscribeOneOffBox) {
      if (selectedDiapers) setToSubscribed(selectedDiapers)
      if (selectedWipes) setToSubscribed(selectedWipes)
      if (upselling) setToSubscribed(upselling)
      setBoxInEdition([{ ...boxInEditionCart, selectedDiapers, selectedWipes, upselling }])
    }

    return () => {
      if (
        isEdition &&
        window.location.href.indexOf('/products') === -1 &&
        window.location.href.indexOf('/account/editpayment') === -1
      ) {
        dispatch(Actions.Checkout.flush())
      }
    }
  }, [])

  const hasChangedToOneOff =
    isEdition &&
    !subscribeOneOffBox &&
    boxInEdition &&
    boxInEditionCart &&
    isAnyProductSubscribed(
      boxInEdition.selectedDiapers || boxInEdition.boxProducts,
      boxInEdition.selectedWipes || [],
      boxInEdition.upselling || boxInEdition.boxAddOns
    ) &&
    !isAnyProductSubscribed(
      boxInEditionCart.selectedDiapers,
      boxInEditionCart.selectedWipes,
      boxInEditionCart.upselling
    )

  useEffect(() => {
    if (status === 'ok') {
      history.push('/boxes')
    }
  }, [status])

  const uniqueDiaper = _.uniqBy(selectedDiapers, e => e.shopifyVariantId || e.variant_id)
  const uniqueWipe = _.uniqBy(selectedWipes, e => e.shopifyVariantId || e.variant_id)
  const uniqueUpselling = _.uniqBy(upselling, e => e.shopifyVariantId || e.variantId)

  const breadCrumbs = [
    {
      label: t('default.boxes'),
      link: '/boxes'
    },
    {
      label: t('cart.edition'),
      link: '/cart'
    }
  ]
  const isAnySubscribed = isAnyProductSubscribed(uniqueDiaper, uniqueWipe, uniqueUpselling)
  const haveProducts = () => !!selectedDiapers.length || !!selectedWipes.length || !!upselling.length

  const getCodPercent = () => {
    if (showCodAdded) {
      return currentCodPercent
    }
    return codPercent
  }

  const saveNextButton = () => {
    if (logged && promoCode && promoCode.error && promoCode.appCode === 302) return

    if (isEdition) {
      const data = {
        id: babyInfo.id,
        diaperSize: samplesInfo.diaperSize,
        gender: samplesInfo.gender,
        name: samplesInfo.name
      }
      updateBabyInfo(data)
      dispatch(Actions.Checkout.processNewBox())
    } else {
      setBabyData({
        gender: samplesInfo.gender,
        name: samplesInfo.name,
        diaperSize: samplesInfo.diaperSize
      })
      dispatch(Actions.Checkout.startCheckout())
      history.push(`/checkout`)
    }
  }

  const getButtonTitle = () => {
    switch (true) {
      case isEdition && !subscribeOneOffBox:
        return t('cart.saveChanges')

      case isEdition && subscribeOneOffBox:
        return t('boxes.subscribeOneOff')

      default:
        return t('cart.priceDetail.proceedToCheckout')
    }
  }

  const {
    totalProducts,
    amountToFreeShipping,
    rules: { isPriceUnderLimit },
    finalPrice
  } = CalcTotalPriceMKT(
    selectedDiapers,
    selectedWipes,
    upselling,
    vatPercent,
    'Dubai',
    promoCode,
    isEdition && getCodPercent(),
    isEdition && paymentType,
    shippingCost,
    false,
    codAmount
  )

  const checkoutBtnTitle = () =>
    `${capitalize(t('checkout.checkout'))} - AED ${haveProducts() ? formatMoney(finalPrice) : 0}`

  const handleButtonClick = () => {
    if (!hasChangedToOneOff) saveNextButton()
    else setOpenAlertModal(true)
  }

  const cartSummaryRef = useRef(null)
  const momsAlsoBoughtRef = useRef(null)
  const productsDetailRef = useRef(null)
  const realChange = _.isEqual(boxInEdition, boxInEditionCart)

  return (
    <Grid container direction="row" justify="space-around" alignItems="center">
      <CantEditAlert openAlert={boxIsNoMorePending} t={t} />

      <Header t={t} bg history={history} showSideCartIcon showSearch showLogin />
      {isEdition && (
        <BreadCrumbs
          setIsRememberModal={setIsRememberModal}
          setOpenAlertModal={setOpenAlertModal}
          setPathToPush={setPathToPush}
          isEdition={isEdition}
          crumbs={breadCrumbs}
          currentLanguage={currentLanguage}
          history={history}
        />
      )}
      {status === 'loading' ? (
        <Loader loading />
      ) : (
        <>
          {!isEdition && <HurryUpCounter t={t} />}
          <Container classes="cart">
            <Grid container className={`cart${isEdition ? '-edition' : ''}`} spacing={2}>
              <Grid item xs={12} md={8}>
                <Box className="cart__box cart__box--free-delivery">
                  <Container>
                    <Box className="free-delivery-bar__wrapper">
                      <FreeDeliveryBar
                        t={t}
                        amountToFreeShipping={amountToFreeShipping}
                        isPriceUnderLimit={isPriceUnderLimit}
                      />
                    </Box>
                    <Hidden mdUp>
                      <Box mb={2}>
                        <ArrivesTodayNotification t={t} />
                      </Box>
                    </Hidden>
                    {!isEdition && (
                      <Hidden mdUp>
                        <Box className="cart__box cart__box--btn-checkout">
                          <FlatButton
                            idButton="idButton"
                            title={checkoutBtnTitle()}
                            type={'submit'}
                            classes={`button-to-checkout ${!haveProducts() ? 'disabled' : ''}`}
                            cartIcon="arrowRight"
                            iconClass="arrow-icon"
                            onClick={() => haveProducts() && handleButtonClick()}
                          />
                        </Box>
                      </Hidden>
                    )}
                  </Container>
                </Box>
                <Hidden smDown>
                  <Box className="cart__box cart__box--arrives-notification">
                    <ArrivesTodayNotification t={t} />
                  </Box>
                </Hidden>
                <Box className="cart__box" ref={productsDetailRef}>
                  <ProductsDetail
                    t={t}
                    isEdition={isEdition}
                    selectedDiapers={selectedDiapers}
                    selectedWipes={selectedWipes}
                    upsellings={upselling}
                    uniqueDiaper={uniqueDiaper}
                    uniqueWipe={uniqueWipe}
                    uniqueUpselling={uniqueUpselling}
                    history={history}
                    openDatePicker={openDatePicker}
                    handleCloseDatePicker={handleCloseDatePicker}
                    handleOpenDatePicker={handleOpenDatePicker}
                    samplesInfo={samplesInfo}
                    setSamplesInfo={setSamplesInfo}
                    subscriptions={subscriptions}
                    isAnySubscribed={isAnySubscribed}
                    subscribeOneOffBox={subscribeOneOffBox}
                    totalProducts={totalProducts}
                  />
                </Box>
                <Grid container item direction="column" wrap="nowrap">
                  {isEdition && (
                    <Grid container item xs={12} lg={12} className="cart-edit-payment">
                      <Grid item xs={12} lg={12} className="products-detail__header">
                        <Typography>{t('cart.paymentMethod')}</Typography>
                      </Grid>
                      <Grid container item xs={12} className="cart-edit-payment-container" justify="center">
                        <Typography variant="inherit" className="cart-payment-title">
                          {!payWithCOD ? t('checkout.creditCards') : t('cart.priceDetail.cod')}
                        </Typography>
                        {!payWithCOD && hasCreditCards ? (
                          <PaymentCard t={t} item={selectedCard} />
                        ) : (
                          <>
                            <Grid
                              container
                              item
                              className="cod-message"
                              direction="row"
                              xs={12}
                              justify="center"
                              alignItems="center">
                              <IconComponent icon="cash" />
                              <Typography variant="inherit">{t('checkout.COD')}</Typography>
                            </Grid>
                            {showCodAdded && (
                              <Grid item container justify="center" alignItems="center" className="cod-added">
                                {t('account.addedCod')}
                              </Grid>
                            )}
                          </>
                        )}
                        {!hasCreditCards ? (
                          <Link to="/account/editpayment" className="change-payment-link">
                            {t('account.changePaymentCredit')}
                          </Link>
                        ) : (
                          <button
                            type="button"
                            className="change-payment-link"
                            onClick={() => dispatch(Actions.Checkout.setPaymentMethod(!payWithCOD))}>
                            {payWithCOD ? t('account.changePaymentCredit') : t('account.changePaymentCod')}
                          </button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box ref={cartSummaryRef} className="cart__summary">
                  <PriceDetail
                    t={t}
                    history={history}
                    idButton="cart_nextButton"
                    handleButtonClick={() => handleButtonClick()}
                    titleButton={getButtonTitle()}
                    showLimitInfo
                    selectedDiapers={selectedDiapers}
                    selectedWipes={selectedWipes}
                    upselling={upselling}
                    promoCode={promoCode}
                    vatPercent={vatPercent}
                    codPercent={isEdition && getCodPercent()}
                    handleCancel={() => {
                      setCancelSelected(true)
                      setIsRememberModal(false)
                      setOpenAlertModal(true)
                      setPathToPush('/boxes')
                    }}
                    showCancelEdition={isEdition}
                    paymentType={paymentType}
                    realChange={realChange}
                    shippingCost={shippingCost}
                    isCart
                    subscribeOneOffBox={subscribeOneOffBox}
                    promoCodeSubscription={promoCodeSubscription}
                    cardExpired={selectedCard ? selectedCard.expiredCard : false}
                    codFixedAmount={codAmount}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box className="cart__moms-also-bought" ref={momsAlsoBoughtRef}>
              <MomsAlsoBought inCart t={t} history={history} />
            </Box>
          </Container>
        </>
      )}
      <BoxEditionAlertModal
        isRememberModal={isRememberModal}
        flushCheckout={flushCheckout}
        history={history}
        pathToPush={pathToPush}
        openAlertModal={openAlertModal}
        setOpenAlertModal={setOpenAlertModal}
        t={t}
        hasChangedToOneOff={hasChangedToOneOff}
        saveNextButton={saveNextButton}
        cancelSelected={cancelSelected}
        setCancelSelected={setCancelSelected}
        differentProducts={openAlertModal && !realChange && getDifferentProducts(thisBox, boxInEditionCart)}
        handleBtnSave={() => handleButtonClick()}
      />
    </Grid>
  )
}

CartCheck.propTypes = propTypes
export default translate('common')(CartCheck)
