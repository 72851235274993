/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import TagManager from 'react-gtm-module'
import Spinner from '../Spinner'
import Actions from '../../../actions'
import IconComponent from '../IconComponent'
import config from '../../../config'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.STRIPE_KEY)

const cardNumberStyle = {
  base: {
    textAlign: 'center',
    iconColor: '#B5889B',
    color: '#2D3133',
    fontWeight: '500',
    fontSize: '18px',
    '::placeholder': {
      color: '#d2d2d2',
      textAlign: 'center'
    }
  },
  invalid: {
    iconColor: '#F47174 ',
    color: '#F47174 '
  }
}

const expiryStyle = {
  base: {
    textAlign: 'center',
    color: '#2D3133',
    fontWeight: '500',
    fontSize: '18px',
    '::placeholder': {
      color: '#d2d2d2',
      textAlign: 'center'
    }
  },
  invalid: {
    color: '#F47174 '
  }
}
const cvcStyle = {
  base: {
    textAlign: 'center',
    color: '#2D3133',
    fontWeight: '500',
    fontSize: '18px',
    '::placeholder': {
      color: '#d2d2d2',
      textAlign: 'center'
    }
  },
  invalid: {
    color: '#F47174 '
  }
}

const CheckoutForm = ({ history, setCardError }) => {
  const user = useSelector(state => state.Users.userData)
  const isAccount = window.location.pathname === '/account'
  const isPaymentEdition = window.document.URL.includes('/account/editpayment')
  const [saveButtonIsDisabled, setSaveButtonIsDisabled] = useState(true)
  const [elementStates, setElementStates] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false
  })
  const [isProcessing, setIsProcessing] = useState(false)

  const stripe = useStripe()

  const elements = useElements()

  const dispatch = useDispatch()

  const setToken = (token, cb) => {
    dispatch(Actions.Checkout.setTokenCard(token))
    setTimeout(cb, 500)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }
    setCardError('')
    setIsProcessing(true)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)
    })
    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setCardError(error.message)
        if (isAccount) {
          history.push(`${isPaymentEdition ? '/account/editpayment' : '/account'}`)
        } else {
          history.push('/checkout')
        }
      } else {
        setCardError('An unexpected error occured.')
      }
    }

    if (paymentMethod && paymentMethod.card) {
      const { id } = paymentMethod
      const { brand, exp_month, exp_year, last4 } = paymentMethod.card
      setToken({ id, card: { brand, exp_month, exp_year, last4 } }, () => {
        if (isAccount) {
          history.push(`${isPaymentEdition ? '/account/editpayment' : '/account'}`)
        } else {
          history.push('/checkout')
        }
      })
      TagManager.dataLayer({
        dataLayer: {
          event: 'addpaymentinfo',
          userId: user.id
        },
        dataLayerName: 'YallaBaby'
      })
    }
    setIsProcessing(false)
    if (isAccount) {
      window.location.reload()
    }
  }

  const handleChange = event => {
    const { elementType, complete } = event
    setElementStates(prevState => ({
      ...prevState,
      [elementType]: complete
    }))
  }

  useEffect(() => {
    const allElementsComplete = Object.values(elementStates).every(state => state === true)
    setSaveButtonIsDisabled(!allElementsComplete)
  }, [elementStates])

  return (
    <form onSubmit={handleSubmit} className="stripe-form">
      {!stripe && !elements && <Spinner />}

      <CardNumberElement
        onChange={handleChange}
        id="cardNumberElement"
        options={{
          showIcon: true,
          iconStyle: 'solid',
          style: cardNumberStyle
        }}
      />
      <div className="expiry-cvc-container">
        <CardExpiryElement onChange={handleChange} id="expiryElement" options={{ style: expiryStyle }} />
        <CardCvcElement onChange={handleChange} id="cvcElement" options={{ style: cvcStyle }} />
      </div>
      <button
        className={`payment-method__save-card${saveButtonIsDisabled || isProcessing ? '__disabled' : ''}`}
        disabled={saveButtonIsDisabled || isProcessing}
        id="submit"
        type="submit">
        {isProcessing ? 'Processing' : 'Save the card'}
        <IconComponent icon="floppyDisk" />
      </button>
    </form>
  )
}

export default function StripePayment({ history, setCardError }) {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '{{CLIENT_SECRET}}'
  // }
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm history={history} setCardError={setCardError} />
    </Elements>
  )
}
