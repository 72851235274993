/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'
import Actions from '../../../actions'
import propTypes from './props'
import PaymentFrame from '../PaymentFrame'
import CardsEdition from '../CardsEdition'
import FlatButton from '../../molecules/FlatButton'

const StepEditCards = ({ history, t, isAccount }) => {
  const currentLanguage = useSelector(state => state.Language.current)
  const isEdition = useSelector(state => state.Checkout.isEdition)
  const prevCardsQuantity = useSelector(state => state.Checkout.prevCardsQuantity)
  const paymentCardToken = useSelector(state => state.Checkout.paymentCardToken)
  const paymentMethod = useSelector(state => state.Users.paymentMethod)

  const havePaymentMethod = paymentMethod.length > 0

  const cards = havePaymentMethod && paymentMethod.filter(method => method.paymentType === 'CREDIT')
  const [addNewCard, setAddNewCard] = useState(!cards)

  const [selectedCard, setSelectedCard] = useState(cards && cards.find(card => card.active).id)

  const dispatch = useDispatch()
  const getUserPaymentMethod = cb => dispatch(Actions.Users.getUserPaymentMethod(cb))
  const verifyCard = cb => dispatch({ type: 'CHECKOUT_FETCH_VERIFY_CARD', cb })
  const setPayWithNewCard = value => dispatch(Actions.Checkout.setPayWithNewCard(value))
  const setActivePaymentMethod = id => dispatch(Actions.Users.setActivePaymentMethod(id))
  const getSubscriptions = type => dispatch(Actions.Users.setFetchSubscriptions(type))

  useEffect(() => {
    if (paymentCardToken) {
      verifyCard(() =>
        getUserPaymentMethod(() => {
          if (isAccount) history.push('/account/editpayment')
        })
      )
    } else {
      getUserPaymentMethod()
    }
    getSubscriptions('CURRENT')
  }, [paymentCardToken])

  useEffect(() => {
    if (prevCardsQuantity < paymentMethod.length && !isAccount) history.push('/checkout')
  }, [paymentMethod.length])

  const handleSaveButton = () => {
    setPayWithNewCard(false)
    if (cards && cards.length > 0) setActivePaymentMethod(cards.find(c => c.id === selectedCard).id)
    if (isEdition) {
      history.push('/cart')
    } else {
      history.push(isAccount ? '/account' : '/checkout')
    }
  }

  return (
    <PaymentFrame togglePayWithNewCard={value => dispatch(Actions.Checkout.setPayWithNewCard(value))}>
      <Grid container className={currentLanguage === 'AR' ? 'invertAr' : ''}>
        <Grid container item justify="flex-start" alignItems="stretch" direction="row">
          <Grid item className="information-steps__form__title">
            <span>{t('checkout.paymentInfo')}</span>
          </Grid>

          <Grid item container xs={12} justify="center">
            <CardsEdition
              history={history}
              t={t}
              cards={cards}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              addNewCard={addNewCard}
              setAddNewCard={setAddNewCard}
              setPayWithNewCard={setPayWithNewCard}
            />
          </Grid>
          <Grid container justify="center">
            <Grid item xs={6} container justify="center">
              <FlatButton
                idButton="checkout_contactInfo_nextButton"
                classes={'information-steps__next-button'}
                type="button"
                onClick={() => handleSaveButton()}
                bgColor="green"
                title={t('default.save')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaymentFrame>
  )
}

StepEditCards.propTypes = propTypes
export default translate('common')(StepEditCards)
