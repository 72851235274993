/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react'
import { Grid, Typography, Divider, List, ListItem } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import Actions from '../../actions'
import propTypes from './props'
import IconComponent from '../../components/atoms/IconComponent'
import SideMenuItem from '../../components/molecules/SideMenuItem'
import { FIRST_COLLECTION } from '../../utils/urls'

const SideMenu = ({
  history,
  t,
  handleClose,
  setChecked,
  setOpenUpvoty,
  setUpvotyPath,
  handleOpenLoginSignin,
  setPathToPush,
  setOpenAlertModal
}) => {
  const userData = useSelector(state => state.Users.userData)
  const logged = useSelector(state => state.Users.logged)
  const { menuItems } = useSelector(state => state.Checkout)

  const dispatch = useDispatch()
  const flushCheckout = () => dispatch(Actions.Checkout.flush())
  const fetchLogout = () => {
    flushCheckout()
    dispatch(Actions.Users.fetchLogoutKeepCheckout())
    setChecked(false)
    history.push(`/products/${FIRST_COLLECTION}`)
  }
  return (
    <Fragment>
      <Grid container className="side-menu-container">
        <Grid container item justify="center" alignContent="center" className="menu">
          <Grid item container xs={12} justify="space-between" alignItems="center">
            <Typography variant="inherit" className="menu__title greetings">
              {t('default.hi')}
              {logged && <span>{userData.first_name}</span>}
            </Typography>
            <Grid item className="menu__close-button">
              <IconComponent height="16px" width="11px" icon="arrowLeft" onClick={() => handleClose()} fill="#C38A9F" />
            </Grid>
          </Grid>
          <Grid item xs={12} className="how-works__items__divider">
            <Divider />
          </Grid>
          <Grid container className="menu__list">
            <List>
              <Typography variant="inherit" className="section-header-title">
                <IconComponent icon="sideMenu" sideMenuType={'my-account'} color="#C38A9F" />
                {t('menu.accountInfo')}
              </Typography>
              <SideMenuItem
                history={history}
                title={t('default.home')}
                path="/"
                sideMenuType="home"
                handleClose={handleClose}
              />
              <SideMenuItem
                setPathToPush={setPathToPush}
                setOpenAlertModal={setOpenAlertModal}
                history={history}
                title={t('menu.mySubscription')}
                path="/boxes"
                sideMenuType="my-boxes"
                handleClose={handleClose}
                logged={logged}
                ableSelected
                handleOpenLoginSignin={handleOpenLoginSignin}
              />
              <SideMenuItem
                setPathToPush={setPathToPush}
                setOpenAlertModal={setOpenAlertModal}
                history={history}
                title={t('menu.pastOrders')}
                path="/past-orders"
                sideMenuType="past-orders"
                handleClose={handleClose}
                logged={logged}
                ableSelected
                handleOpenLoginSignin={handleOpenLoginSignin}
              />
              <SideMenuItem
                setPathToPush={setPathToPush}
                setOpenAlertModal={setOpenAlertModal}
                history={history}
                title={t('menu.profile')}
                path="/account"
                sideMenuType="my-account"
                handleClose={handleClose}
                logged={logged}
                ableSelected
                handleOpenLoginSignin={handleOpenLoginSignin}
              />
            </List>
          </Grid>
          <Grid item xs={12} className="how-works__items__divider">
            <Divider />
          </Grid>
          <Grid container className="menu__list">
            <List>
              <Typography variant="inherit" className="section-header-title">
                <IconComponent icon="sideMenu" sideMenuType="diapers" color="#C38A9F" />
                {t('products.products')}
              </Typography>
              {menuItems &&
                menuItems.map(cat => (
                  <SideMenuItem
                    history={history}
                    title={cat.title}
                    key={cat.handle}
                    path={`/products/${cat.handle}`}
                    sideMenuType={cat.handle}
                    handleClose={handleClose}
                    ableSelected
                  />
                ))}
              <Grid className="promotion-button-container__side-menu">
                <SideMenuItem
                  history={history}
                  title={' Request a Product'}
                  handleClose={handleClose}
                  ableSelected
                  upvoty
                  setOpenUpvoty={setOpenUpvoty}
                  setUpvotyPath={setUpvotyPath}
                  request
                />
              </Grid>
            </List>
          </Grid>

          <Grid item xs={12} className="how-works__items__divider">
            <Divider />
          </Grid>
          <Grid container className="menu__list">
            <List>
              <Typography variant="inherit" className="section-header-title">
                <IconComponent icon="sideMenu" sideMenuType="about-us" color="#C38A9F" />
                {t('default.yallaBabyBox')}
              </Typography>
              <ListItem key="blog">
                <a href="https://yallababy.com/blog" target="_blank">
                  <Grid className="side-menu-item" item container alignItems="center">
                    {t('default.mumsBlog')}
                  </Grid>
                </a>
              </ListItem>
              <SideMenuItem
                history={history}
                title={t('default.aboutUs')}
                path="/about-us"
                sideMenuType="about-us"
                handleClose={handleClose}
              />
              <SideMenuItem
                history={history}
                title={t('default.faqs')}
                path="/faq"
                sideMenuType="faqs"
                handleClose={handleClose}
              />
              <SideMenuItem
                history={history}
                title={t('default.contactUs')}
                path="/contact-us"
                sideMenuType="contact-us"
                handleClose={handleClose}
                ableSelected
              />
              <SideMenuItem
                upvoty
                history={history}
                title={' Report an Issue'}
                handleClose={handleClose}
                ableSelected
                setOpenUpvoty={setOpenUpvoty}
                setUpvotyPath={setUpvotyPath}
              />
              <ListItem key="return">
                <a href="https://returns.richcommerce.co/return/new?c=yallababybox" target="_blank">
                  <Grid className="side-menu-item pink-color upvoty-button" item container alignItems="center">
                    {t('menu.returnAProduct')}
                  </Grid>
                </a>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} className="how-works__items__divider">
            <Divider />
          </Grid>
          <Grid container className="menu__list__logout">
            <List>
              <ListItem key={logged ? 'logout' : 'login'}>
                <button type="button" onClick={() => (logged ? fetchLogout() : handleOpenLoginSignin('login'))}>
                  <Grid item container alignItems="center">
                    <IconComponent icon="sideMenu" sideMenuType={logged ? 'logout' : 'login'} />
                    {t(`default.${logged ? 'logout' : 'login'}`)}
                  </Grid>
                </button>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}

SideMenu.propTypes = propTypes
export default translate('common')(SideMenu)
