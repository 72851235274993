import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { translate } from 'react-i18next'
import { Hidden, Grid } from '@material-ui/core'
import LoginSigninContainer from '../LoginSigninContainer'
import Header from '../../components/organisms/Header'
import Footer from '../../components/molecules/Footer'
import { FIRST_COLLECTION } from '../../utils/urls'
import propTypes from './props'

const LoginRedirection = ({ history, t }) => {
  const currentLanguage = useSelector(state => (state.Language.current === 'AR' ? 'Arabic' : 'English'))
  const [formType, setFormType] = useState('login')
  return (
    <>
      <Header t={t} bg history={history} />
      <Grid container className="content-height" />
      <LoginSigninContainer
        openModal
        handleClose={() => history.replace(`/products/${FIRST_COLLECTION}`)}
        typeLoginSignin={formType}
        setTypeLoginSignin={setFormType}
        shouldRedirect
        showFormFooter
      />
      <Hidden smDown>
        <Footer t={t} currentLanguage={currentLanguage} history={history} />
      </Hidden>
    </>
  )
}
LoginRedirection.propTypes = propTypes
export default translate('common')(LoginRedirection)
