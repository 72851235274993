import React, { Fragment } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getNextId } from '../../../utils/random'
import propTypes from './props'
import Arrow from '../../../assets/images/icons/dropdown_menu.svg'

const BreadCrumbs = ({
  isEdition,
  crumbs = [],
  currentLanguage,
  history,
  setPathToPush,
  setOpenAlertModal,
  setIsRememberModal
}) => {
  const capitalizeLabel = label => {
    return label[0].toUpperCase() + label.substring(1)
  }

  return (
    <Grid
      item
      container
      className={currentLanguage === 'AR' ? 'breadcrumbs invertAr-ltr' : 'breadcrumbs'}
      xs={12}
      alignItems="center"
      wrap="nowrap">
      {crumbs.map((b, index) => (
        <Fragment key={getNextId()}>
          {index + 1 < crumbs.length &&
            (b.link ? (
              <Link
                onClick={() => {
                  if (isEdition && b.link === '/boxes') {
                    setIsRememberModal(true)
                    setOpenAlertModal(true)
                    setPathToPush('/boxes')
                  }
                }}
                to={!isEdition && b.link !== '/boxes' && b.link}>
                {capitalizeLabel(b.label)}
              </Link>
            ) : (
              <button type="button" onClick={() => history.goBack()}>
                {capitalizeLabel(b.label)}
              </button>
            ))}
          {index + 1 < crumbs.length && <img src={Arrow} alt="" />}

          {index + 1 === crumbs.length && <Typography variant="inherit">{capitalizeLabel(b.label)}</Typography>}
        </Fragment>
      ))}
    </Grid>
  )
}

BreadCrumbs.propTypes = propTypes
export default BreadCrumbs
