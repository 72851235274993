import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import find from '../../../assets/images/find.svg'
import propTypes from './props'

const UpvotyCard = ({t,setOpenUpvoty}) => (
  <Grid className="upvoty-card">
    <img alt="find a product" src={find} />
    <Typography className="upvoty-card__text">
      {t('products.upvoty.text')}
    </Typography>

    <Typography className="upvoty-card__button" onClick={() => setOpenUpvoty(true)}>
      {t('products.upvoty.request')}
    </Typography>
  </Grid>
)

UpvotyCard.propTypes = propTypes
export default UpvotyCard
