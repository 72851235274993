import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
export var removeEditableBoxProduct = function removeEditableBoxProduct(id) {
  return {
    type: 'REMOVE_CURRENT_EDITABLE_BOX_DIAPER',
    payload: {
      id: id
    }
  };
};
export var fetchLogin = function fetchLogin(userName, password, shouldRedirect, lastLocation) {
  return {
    type: 'USER_LOGIN',
    payload: {
      userName: userName,
      password: password
    },
    shouldRedirect: shouldRedirect,
    lastLocation: lastLocation
  };
};
export var resetError = function resetError() {
  return {
    type: 'USER_RESET_ERROR_LOGIN'
  };
};
export var fetchRegister = function fetchRegister(data, cb) {
  return {
    type: 'USER_FETCH_REGISTER',
    payload: _objectSpread({}, data),
    cb: cb
  };
};
export var resetErrorRegister = function resetErrorRegister() {
  return {
    type: 'USER_RESET_ERROR_REGISTER'
  };
};
export var setData = function setData(data) {
  return {
    type: 'USER_SET_DATA',
    payload: _objectSpread({}, data)
  };
};
export var setAuthToken = function setAuthToken(token) {
  return {
    type: 'USER_SET_AUTH_TOKEN',
    payload: {
      token: token
    }
  };
};
export var setRegisterData = function setRegisterData(data) {
  return {
    type: 'USER_SET_REGISTER_DATA',
    payload: _objectSpread({}, data)
  };
};
export var fetchLogout = function fetchLogout() {
  return {
    type: 'USER_FETCH_LOGOUT'
  };
};
export var fetchLogoutKeepCheckout = function fetchLogoutKeepCheckout() {
  return {
    type: 'USER_FETCH_LOGOUT_KEEP_CHECKOUT'
  };
};
export var fetchUpdateUser = function fetchUpdateUser(data) {
  var cb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  return {
    type: 'USER_FETCH_UPDATE_DATA',
    payload: _objectSpread({}, data),
    cb: cb
  };
};
export var fetchRecovery = function fetchRecovery(userName) {
  return {
    type: 'USER_FETCH_RECOVERY_PASSWORD',
    payload: {
      userName: userName
    }
  };
};
export var fetchChangePasswordRecovery = function fetchChangePasswordRecovery(password) {
  return {
    type: 'USER_FETCH_CHANGE_PASSWORD_RECOVERY_PASSWORD',
    payload: {
      password: password
    }
  };
};
export var fetchUpdateAddress = function fetchUpdateAddress(address, cb) {
  return {
    type: 'USER_FETCH_UPDATE_ADDRESS',
    payload: _objectSpread({}, address),
    cb: cb
  };
};
export var setUserSubscription = function setUserSubscription(boxes, isPastSubscriptions, isPendingSubscriptions) {
  return {
    type: 'USER_SET_SUBSCRIPTIONS',
    payload: {
      boxes: boxes,
      isPastSubscriptions: isPastSubscriptions,
      isPendingSubscriptions: isPendingSubscriptions
    }
  };
};
export var cleanUserSubscription = function cleanUserSubscription() {
  return {
    type: 'USER_CLEAN_SUBSCRIPTIONS'
  };
};
export var setFetchSubscriptions = function setFetchSubscriptions(boxType) {
  return {
    type: 'USER_FETCH_SUBSCRIPTIONS',
    boxType: boxType
  };
};
export var setFetchUpvotyToken = function setFetchUpvotyToken() {
  return {
    type: 'USER_FETCH_UPVOTY_TOKEN'
  };
};
export var setLoadingSubscription = function setLoadingSubscription() {
  return {
    type: 'SET_LOADING_SUBSCRIPTIONS'
  };
};
export var getUserByRecoveryToken = function getUserByRecoveryToken(recoveryToken, cb) {
  return {
    type: 'USER_FETCH_USER_BY_RECOVERY_TOKEN',
    payload: {
      recoveryToken: recoveryToken,
      cb: cb
    }
  };
};
export var setUserRecoveryToken = function setUserRecoveryToken(recoveryToken) {
  return {
    type: 'USER_SET_RECOVERY_TOKEN',
    payload: {
      recoveryToken: recoveryToken
    }
  };
};
export var setErrorRecovery = function setErrorRecovery() {
  return {
    type: 'USER_ERROR_RECOVERY'
  };
};
export var retryBoxPayment = function retryBoxPayment(boxId) {
  var cb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  return {
    type: 'RETRY_BOX_PAYMENT',
    payload: boxId,
    cb: cb
  };
};
export var getUserPaymentMethod = function getUserPaymentMethod(cb) {
  return {
    type: 'FETCH_USER_PAYMENT_METHOD',
    cb: cb
  };
};
export var setActivePaymentMethod = function setActivePaymentMethod(id) {
  return {
    type: 'FETCH_SET_ACTIVE_PAYMENT_METHOD',
    payload: id
  };
};
export var fetchSetSubscriptionBabyInfo = function fetchSetSubscriptionBabyInfo(babyInfo) {
  return {
    type: 'FETCH_SET_SUBSCRIPTION_BABY_INFO',
    payload: babyInfo
  };
};
export var updateSubscriptionState = function updateSubscriptionState(babyBoxId, newStatus) {
  return {
    type: 'USER_UPDATE_SUBSCRIPTION_STATE',
    payload: {
      babyBoxId: babyBoxId,
      newStatus: newStatus
    }
  };
};
export var setHelpdeskUserToken = function setHelpdeskUserToken(token) {
  return {
    type: 'SET_HELPDESK_USER_TOKEN',
    payload: token
  };
};
export var updateSubscriptionsShippingDate = function updateSubscriptionsShippingDate(newDate, subId) {
  return {
    type: 'UPDATE_SHIPPING_DATE',
    payload: {
      newDate: newDate,
      subId: subId
    }
  };
};
var setEditedSubscriptionDate = function setEditedSubscriptionDate(newDate, subId) {
  return {
    type: 'SET_EDITED_SUBSCRIPTION_DATE',
    payload: {
      newDate: newDate,
      subId: subId
    }
  };
};
var setNullEditedSubscriptionDate = function setNullEditedSubscriptionDate() {
  return {
    type: 'SET_NULL_EDITED_SUBSCRIPTION_DATE',
    payload: null
  };
};
export var downloadInvoice = function downloadInvoice(shippingDate, subId) {
  return {
    type: 'USER_DOWNLOAD_INVOICE',
    payload: {
      shippingDate: shippingDate,
      subId: subId
    }
  };
};
export var setInvoiceStatus = function setInvoiceStatus(status) {
  return {
    type: 'INVOICE_STATUS',
    payload: status
  };
};
var setShippingUpdate = function setShippingUpdate(shippingDateUpdated) {
  return {
    type: 'SET_SHIPPING_UPDATE',
    payload: shippingDateUpdated
  };
};
var setPhoneVerificationCode = function setPhoneVerificationCode(number) {
  return {
    type: 'PHONE_VERIFICATION_CODE',
    payload: number
  };
};
var verifyPhoneNumberCode = function verifyPhoneNumberCode(phone, verificationCode) {
  return {
    type: 'VERIFY_PHONE_NUMBER_CODE',
    payload: {
      phone: phone,
      verificationCode: verificationCode
    }
  };
};
var setStatusPhoneCode = function setStatusPhoneCode(status) {
  return {
    type: 'SET_STATUS_PHONE_CODE',
    payload: status
  };
};
var setStatusCardRequest = function setStatusCardRequest(status) {
  return {
    type: 'CHECKOUT_SET_STATUS_CARD_REQUEST',
    payload: status
  };
};
export default {
  fetchLogin: fetchLogin,
  resetError: resetError,
  fetchRegister: fetchRegister,
  resetErrorRegister: resetErrorRegister,
  setData: setData,
  setAuthToken: setAuthToken,
  setRegisterData: setRegisterData,
  fetchLogoutKeepCheckout: fetchLogoutKeepCheckout,
  fetchLogout: fetchLogout,
  fetchUpdateUser: fetchUpdateUser,
  fetchUpdateAddress: fetchUpdateAddress,
  fetchRecovery: fetchRecovery,
  setUserSubscription: setUserSubscription,
  cleanUserSubscription: cleanUserSubscription,
  setFetchSubscriptions: setFetchSubscriptions,
  setFetchUpvotyToken: setFetchUpvotyToken,
  setUserRecoveryToken: setUserRecoveryToken,
  getUserByRecoveryToken: getUserByRecoveryToken,
  setErrorRecovery: setErrorRecovery,
  fetchChangePasswordRecovery: fetchChangePasswordRecovery,
  getUserPaymentMethod: getUserPaymentMethod,
  fetchSetSubscriptionBabyInfo: fetchSetSubscriptionBabyInfo,
  removeEditableBoxProduct: removeEditableBoxProduct,
  updateSubscriptionState: updateSubscriptionState,
  setActivePaymentMethod: setActivePaymentMethod,
  setHelpdeskUserToken: setHelpdeskUserToken,
  setLoadingSubscription: setLoadingSubscription,
  updateSubscriptionsShippingDate: updateSubscriptionsShippingDate,
  retryBoxPayment: retryBoxPayment,
  downloadInvoice: downloadInvoice,
  setInvoiceStatus: setInvoiceStatus,
  setShippingUpdate: setShippingUpdate,
  setEditedSubscriptionDate: setEditedSubscriptionDate,
  setNullEditedSubscriptionDate: setNullEditedSubscriptionDate,
  setPhoneVerificationCode: setPhoneVerificationCode,
  verifyPhoneNumberCode: verifyPhoneNumberCode,
  setStatusPhoneCode: setStatusPhoneCode,
  setStatusCardRequest: setStatusCardRequest
};