/* eslint-disable react/prop-types */
import React from 'react';

const TrustBox = ({inMomsReviewsBanner}) => {
  
  const ref = React.useRef(null);
  React.useEffect(() => {

if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (

    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US" 
      data-template-id={`${inMomsReviewsBanner ?'53aa8912dec7e10d38f59f36' :'5419b637fa0340045cd0c936'}`}
      data-businessunit-id={`${inMomsReviewsBanner ?'5ea820847a4aa40001deb268' :'5ea820847a4aa40001deb268'}`}
      data-style-height={`${inMomsReviewsBanner ?'140px' :'20px'}`}
      data-style-width="100%"
      data-theme="light"
    >
      <a href="https://www.trustpilot.com/review/yallababy.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
    </div>
  );
};
export default TrustBox;