import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { productInEditionQty } from '../../../utils/helpers'
import propTypes from './props'

const QuantityModifier = ({
  quantity,
  setQuantity,
  variantSelected,
  stockControl,
  handleAlertOutStock,
  inCart,
  pdp,
  fixedMobile,
  inMomsAlsoBought,
  cart
}) => {
  const inventory = variantSelected.inventory_quantity + productInEditionQty(variantSelected, cart)

  const handleAddButton = () => {
    if (!stockControl) setQuantity('add', variantSelected)
    if (stockControl && quantity < inventory) setQuantity('add', variantSelected)
    if (stockControl && handleAlertOutStock && quantity >= inventory) {
      handleAlertOutStock(true)
      setTimeout(() => {
        handleAlertOutStock(false)
      }, 1500)
    }
  }
  const renderRemoveButton = () => {
    return (
      <button
        type="button"
        className="quantity-modifier__button"
        onClick={() => setQuantity('remove', variantSelected)}>
        -
      </button>
    )
  }

  const renderAddButton = () => {
    return (
      <button
        type="button"
        className={`quantity-modifier__button ${quantity >= inventory ? 'disabled' : ''}`}
        onClick={() => handleAddButton()}>
        +
      </button>
    )
  }

  return (
    <>
      <Grid
        item
        container
        className={`quantity-modifier ${inCart && 'inCart'}  ${pdp && 'pdp'} ${fixedMobile && 'fixed-mobile'} ${
          inMomsAlsoBought && 'in-moms-also-bought'
        }`}>
        {renderRemoveButton()}
        <Typography variant="inherit">{quantity}</Typography>
        {renderAddButton()}
      </Grid>
    </>
  )
}

QuantityModifier.propTypes = propTypes
export default QuantityModifier
