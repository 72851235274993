/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable camelcase */
import React, { Fragment, Suspense, useEffect } from 'react'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import 'react-dates/initialize'
import i18next from 'i18next'
import { Helmet } from 'react-helmet'
import { config as i18nextConfig } from './translations'
import useTagManager from './Hooks/useTagManager'
import Router from './Router'
import store from './store'
import Loader from './components/atoms/Loader'
import ErrorBoundary from './containers/ErrorPage'
import BottomNotification from './components/atoms/BottomNotification'
import 'react-dates/lib/css/_datepicker.css'
import './scss/main.scss'

i18next.init(i18nextConfig)

const Main = () => {
  const language = useSelector(state => state.Language.current)
  return (
    <Fragment>
      <Helmet>
        <title>{language === 'EN' ? 'Yalla Baby' : 'يلا بيبي'}</title>
      </Helmet>
      <Loader />
      <BottomNotification />
      <Router />
    </Fragment>
  )
}

const App = () => {
  useTagManager()
  useEffect(() => {
    store.dispatch({ type: 'INIT_APP_STATE' })
  }, [])

  return (
    <Provider store={store}>
      <Suspense fallback={<Loader loading />}>
        <BrowserRouter basename="/">
          <I18nextProvider i18n={i18next}>
            <ErrorBoundary>
              <Main />
            </ErrorBoundary>
          </I18nextProvider>
        </BrowserRouter>
      </Suspense>
    </Provider>
  )
}

export default App
