/* eslint-disable no-useless-escape */
var createGPjson = function createGPjson(product, variationSelected, productImage) {
  var slug = product.title.replace(/[ \/.]/g, '-').toLowerCase();
  var getProductPrice = function getProductPrice() {
    return variationSelected.compare_at_price;
  };
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.title,
    image: [productImage ? productImage.src : ''],
    description: product.body_html,
    sku: product.sku,
    brand: {
      '@type': 'Thing',
      name: product.vendor
    },
    offers: {
      '@type': 'Offer',
      url: "https://app.yallababy.com/product/".concat(product.id, "-").concat(slug),
      priceCurrency: 'AED',
      price: +getProductPrice(),
      seller: {
        '@type': 'Organization',
        name: 'YallaBaby'
      }
    }
  };
};
export default createGPjson;