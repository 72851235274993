import React from 'react'
import { translate } from 'react-i18next'
import { Grid } from '@material-ui/core'
import propTypes from './props'

const OutOfStockLabel = ({ classes, inPDP, inventoryQuantity, cart, t }) => (
  <Grid container item className={`out-of-stock-label ${classes} ${inPDP ? 'pdp' : ''} ${cart ? 'cart' : ''}`}>
    <span>
      {`${t('outOfStockLabel.last')} ${inventoryQuantity > 1 ? inventoryQuantity : ''} ${
        inventoryQuantity > 1 ? t('default.units') : t('default.unit')
      }`}
    </span>
  </Grid>
)

OutOfStockLabel.propTypes = propTypes
export default translate('common')(OutOfStockLabel)
