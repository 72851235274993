/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import Actions from '../../../actions'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'
import DiaperCard from '../DiaperCard'

const UpgradeSize = ({ t, handleClose, diaperToReplace }) => {
  const dispatch = useDispatch()
  const { size } = diaperToReplace
  const diapersCart = useSelector(state => state.Checkout.selectedDiapers)
  const currentLanguage = useSelector(state => state.Language.current)
  const upgrade = size === 'NB' ? ['NB', '1', '2'] : [`${+size}`, `${+size + 1}`, `${+size + 2}`]
  const [diapers, setDiapers] = useState([])

  const showDiaper = diaper => {
    const sizes = diaper.options.find(opt => opt.name === 'Size').values
    return sizes.some(sizeDiaper => upgrade.includes(sizeDiaper))
  }
  const collections = useSelector(state => state.Products.collections)
  const getCollections = handle => dispatch(Actions.Products.getCollections(handle))
  const diaperCollection = collections.find(collection => collection.handle === 'diapers')
  const toReplace = []

  useEffect(() => {
    if (!diaperCollection) getCollections('diapers')
  }, [])

  useEffect(() => {
    if (diaperCollection && diaperCollection.products) {
      diaperCollection.products.forEach((diaper, index) => {
        if (diaper.id === diaperToReplace.id) {
          toReplace.splice(index, 1)
          toReplace.unshift(diaper)
        }
      })
      setDiapers(toReplace.length && toReplace)
    }
  }, [diaperCollection])

  return (
    <Grid container className="upgrade-modal-content">
      <IconComponent width="16px" height="16px" className="close-modal" icon="close" onClick={() => handleClose()} />
      <Grid item container justify="center" alignContent="flex-start">
        <Grid item className="upgrade-size__title">
          <Typography variant="inherit">{t('cart.upgradeSize.title')}</Typography>
        </Grid>
        <Grid item container className="upgrade-size__diapers-content">
          {diapers.length > 0 &&
            diapers.map(diaper => {
              return (
                showDiaper(diaper) && (
                  <DiaperCard
                    currentLanguage={currentLanguage}
                    key={diaper.id}
                    diaper={diaper}
                    cart={diapersCart}
                    diaperToReplace={diaperToReplace}
                    closeModalUpgrade={() => handleClose()}
                    upgrade={upgrade}
                  />
                )
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}

UpgradeSize.propTypes = propTypes
export default UpgradeSize
