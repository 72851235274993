/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import propTypes from './props'
import fire from '../../../assets/images/fire.png'

const HurryUpseconds = ({ t }) => {
  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(5)

  useEffect(() => {
    if (seconds > -1) {
      setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)
    }

    if (minutes === -1) {
      setMinutes(4)
    }

    if (seconds === -1) {
      setMinutes(minutes - 1)
      setSeconds(59)
    }
  }, [seconds])

  return (
    <Grid container className="hurry-up">
      <Grid container alignItems="center" justifyContent="center" className="hurry-up__wrapper">
        <img src={fire} alt={t('cart.hurryUpMessage')} />
        <Typography className="hurry-up__text">{t('cart.hurryUpMessage')}</Typography>
        <Typography className="hurry-up__time">
          {minutes > 0 && `${minutes}:`}
          {seconds < 10 && minutes > 0 && '0'}
          {seconds}s
        </Typography>
      </Grid>
    </Grid>
  )
}

HurryUpseconds.propTypes = propTypes
export default translate('common')(HurryUpseconds)
