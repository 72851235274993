/* eslint-disable camelcase */
import common_ar from './ar/common.json';
import common_en from './en/common.json';
export default {
  en: {
    common: common_en
  },
  ar: {
    common: common_ar
  }
};