/* eslint-disable max-len */
import React from 'react'

const FloppyDisk = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4978 3.49779L12.5022 0.502214C12.226 0.226 11.6804 0 11.2898 0H2.28571C1.02336 0 0 1.02329 0 2.28571V13.7143C0 14.9766 1.02336 16 2.28571 16H13.7143C14.9766 16 16 14.9766 16 13.7143V4.71025C16 4.31961 15.774 3.774 15.4978 3.49779ZM8 13.7143C6.73771 13.7143 5.71429 12.6909 5.71429 11.4286C5.71429 10.1663 6.73771 9.14286 8 9.14286C9.26229 9.14286 10.2857 10.1663 10.2857 11.4286C10.2857 12.6909 9.26229 13.7143 8 13.7143ZM11.4286 6.28571C11.4286 6.60129 11.1727 6.85714 10.8571 6.85714H2.85714C2.54157 6.85714 2.28571 6.60129 2.28571 6.28571V2.85714C2.28571 2.5415 2.54157 2.28571 2.85714 2.28571H10.8571C11.1727 2.28571 11.4286 2.5415 11.4286 2.85714V6.28571Z"
      fill="white"
      fillOpacity="0.5"
    />
  </svg>
)

export default FloppyDisk
