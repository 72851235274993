import React from 'react'

const Arrow = () => (
  <svg width="17px" height="10px" viewBox="0 0 17 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-WEB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g
        id="10-CHECK-OUT-CREDICT-CARD-SELECT"
        transform="translate(-790.000000, -705.000000)"
        stroke="#393939"
        strokeWidth="2">
        <polyline
          id="Path-6-Copy"
          transform="translate(798.500000, 709.500000) rotate(-90.000000) translate(-798.500000, -709.500000) "
          points="802 702 795 709.762259 801.438854 717"
        />
      </g>
    </g>
  </svg>
)

export default Arrow
