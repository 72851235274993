import _toConsumableArray from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _toArray from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toArray";
import _defineProperty from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "/codebuild/output/src2622386307/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-indent */
import moment from 'moment';
import TagManager from 'react-gtm-module';
import { v4 as uuidv4 } from 'uuid';
import sjcl from 'sjcl';
import noImage from '../assets/images/no-image.svg';
import { conversionRatio, months, emirates, holidays } from './constants';
import config from '../config';
export function ImageCheck(product) {
  if (product) {
    var productImage = product.image;
    if (!product.image) {
      productImage = {
        src: noImage
      };
    }
    return productImage;
  }
}
export var GetDiscount = function GetDiscount(price, boxDiscount) {
  var discount = 0.0;
  if (boxDiscount) {
    var value = Number.parseFloat(boxDiscount.value);
    if (boxDiscount.type.toUpperCase() === 'PERCENTAGE') {
      discount = ((100 - value) / 100 - 1) * price;
    } else if (boxDiscount.type.toUpperCase() === 'FIXED_AMOUNT') {
      discount = value * -1;
    }
  }
  return Number.parseFloat(discount);
};
export var IsPriceUnderLimit = function IsPriceUnderLimit(emirate, price, promoCodeName) {
  var limit = emirate === 'Dubai' ? 150 : 150;
  var isInflu = promoCodeName ? promoCodeName.toUpperCase().includes('INFLU') : false;
  var isGift = promoCodeName ? promoCodeName.toUpperCase().includes('GIFT') : false;
  return !isGift && !isInflu && price < limit;
};
export var haveTwoPrice = function haveTwoPrice(variant) {
  return variant && variant.compare_at_price && parseFloat(variant.compare_at_price) < parseFloat(variant.price);
};
export var haveOfferTag = function haveOfferTag(product) {
  var subcat = product.tags.split(',');
  return subcat.some(function (tag) {
    return tag.trim() === '#offer' || tag.trim() === '#subscribableOffer';
  });
};
export var isOffer = function isOffer(product, variant) {
  var offer = haveOfferTag(product) && haveTwoPrice(variant);
  return !!offer || product.isOffer || product.isOffer;
};
export var CalcTotalPriceMKT = function CalcTotalPriceMKT(diapers, wipes, addOns, vatPercent, emirate, promoCode, codPercent, paymentType, shippingCost, inBoxes, codFixedAmount) {
  var totalPrice = 0;
  var hasAnyProductSubscribed = false;
  var totalPriceSubscription = 0;
  var subscriptionDiscount = 0;
  var totalPriceWithSubscription = 0;
  var totalPriceWithDiscounts = 0;
  var discount = 0;
  var totalBeforeTax = 0;
  var totalProducts = 0;
  var productsPrice = 0;
  var accTotalPrice = 0;
  var productsId = promoCode && !promoCode.error ? promoCode.productsId : [];
  var variantsId = promoCode && !promoCode.error ? promoCode.variantsId : [];
  diapers.forEach(function (diaper) {
    var variantSelected = diaper.variants.find(function (variant) {
      return variant.id === +diaper.variant_id || variant.id === +diaper.shopifyVariantId;
    });
    var prices = {
      price: inBoxes ? +diaper.listPrice : +variantSelected.price,
      discountPrice: inBoxes ? +diaper.netPrice : +variantSelected.compare_at_price,
      productDiscount: inBoxes ? +diaper.appliedDiscount : +variantSelected.appliedDiscount
    };
    var price = prices.price,
      discountPrice = prices.discountPrice,
      productDiscount = prices.productDiscount;
    totalProducts += 1;
    totalPrice += price;
    subscriptionDiscount += diaper.purchase_type === 'SUBSCRIPTION' || diaper.purchaseType === 'SUBSCRIPTION' ? productDiscount : 0;
    totalPriceSubscription += diaper.purchase_type === 'SUBSCRIPTION' || diaper.purchaseType === 'SUBSCRIPTION' ? discountPrice : 0;
    productsPrice += diaper.purchase_type === 'SUBSCRIPTION' || diaper.purchaseType === 'SUBSCRIPTION' ? +diaper.netPrice || +variantSelected.compare_at_price : +diaper.listPrice || +variantSelected.price;
    hasAnyProductSubscribed = hasAnyProductSubscribed || diaper.purchase_type === 'SUBSCRIPTION' || diaper.purchaseType === 'SUBSCRIPTION';
    if (productsId.length !== 0 && productsId.some(function (id) {
      return id === "".concat(diaper.id) || id === diaper.id;
    }) || variantsId.length !== 0 && variantsId.some(function (id) {
      return id === "".concat(variantSelected.id) || id === variantSelected.id;
    })) {
      accTotalPrice += diaper.purchase_type === 'SUBSCRIPTION' || diaper.purchaseType === 'SUBSCRIPTION' ? discountPrice : price;
    }
  });
  wipes.forEach(function (wipe) {
    var variantSelected = wipe.variants.find(function (variant) {
      return variant.id === +wipe.variant_id || variant.id === +wipe.shopifyVariantId;
    });
    totalProducts += 1;
    var offer = isOffer(wipe, variantSelected);
    var prices = {
      price: inBoxes ? +wipe.listPrice : +variantSelected.price,
      discountPrice: inBoxes ? +wipe.netPrice : +variantSelected.compare_at_price,
      productDiscount: inBoxes ? +wipe.appliedDiscount : +variantSelected.appliedDiscount
    };
    var price = prices.price,
      discountPrice = prices.discountPrice,
      productDiscount = prices.productDiscount;
    totalPrice += price;
    subscriptionDiscount += wipe.purchase_type === 'SUBSCRIPTION' || wipe.purchaseType === 'SUBSCRIPTION' ? productDiscount : 0;
    totalPriceSubscription += wipe.purchase_type === 'SUBSCRIPTION' || wipe.purchaseType === 'SUBSCRIPTION' ? discountPrice : 0;
    if (wipe.purchase_type === 'SUBSCRIPTION' || wipe.purchaseType === 'SUBSCRIPTION') {
      discountPrice;
    } else {
      productsPrice += offer ? discountPrice : price;
    }
    hasAnyProductSubscribed = hasAnyProductSubscribed || wipe.purchase_type === 'SUBSCRIPTION' || wipe.purchaseType === 'SUBSCRIPTION';
  });
  addOns.forEach(function (up) {
    totalProducts += 1;
    var variantSelected = up.variants.find(function (variant) {
      return variant.id === +up.variantId || variant.id === +up.shopifyVariantId;
    });
    var offer = up.isOffer;
    var prices = {
      price: inBoxes ? +up.listPrice : +variantSelected.price,
      discountPrice: inBoxes ? +up.netPrice : +variantSelected.compare_at_price,
      productDiscount: inBoxes ? +up.appliedDiscount : +variantSelected.appliedDiscount
    };
    var price = prices.price,
      discountPrice = prices.discountPrice,
      productDiscount = prices.productDiscount;
    totalPrice += offer && discountPrice > 0 ? discountPrice : price;
    subscriptionDiscount += up.purchaseType === 'SUBSCRIPTION' ? productDiscount : 0;
    totalPriceSubscription += up.purchaseType === 'SUBSCRIPTION' ? discountPrice : 0;
    hasAnyProductSubscribed = hasAnyProductSubscribed || up.purchaseType === 'SUBSCRIPTION';
    if (productsId.length !== 0 && productsId.some(function (id) {
      return id === "".concat(up.id) || id === up.id;
    }) || variantsId.length !== 0 && variantsId.some(function (id) {
      return id === "".concat(variantSelected.id) || id === variantSelected.id;
    })) {
      accTotalPrice += (offer ? discountPrice : price) - (up.purchaseType === 'SUBSCRIPTION' ? productDiscount : 0);
    }
  });
  totalPriceWithSubscription = totalPrice - subscriptionDiscount;
  if (promoCode && !promoCode.error) {
    var value = Number.parseFloat(promoCode.value);
    switch (promoCode.value_type.toUpperCase()) {
      case 'PERCENTAGE':
        if (productsId.length === 0 && variantsId.length === 0) {
          discount = ((100 - value) / 100 - 1) * totalPriceWithSubscription;
        } else {
          discount = ((100 - value) / 100 - 1) * accTotalPrice;
        }
        break;
      case 'FIXED_AMOUNT':
        discount = value * -1;
        break;
      default:
        break;
    }
  }
  totalPriceWithDiscounts = totalPriceWithSubscription - discount < 0.0 ? 0.0 : totalPriceWithSubscription - discount;
  var codAmount = +codFixedAmount === 0 ? totalPriceWithDiscounts * (+codPercent / 100) : +codFixedAmount;
  var shippingPrice = IsPriceUnderLimit(emirate, totalPriceWithDiscounts, promoCode && promoCode.title) ? +shippingCost : 0;
  totalBeforeTax = totalPriceWithDiscounts + (paymentType === 'COD' ? codAmount : 0) + shippingPrice;
  var amountToFreeShipping = 150 - totalPriceWithDiscounts;
  var vatAmount = totalBeforeTax * (+vatPercent / 100);
  var finalPrice = totalBeforeTax + vatAmount;
  var rules = {
    ableSubscription: hasAnyProductSubscribed && IsPriceUnderLimit(emirate, totalPriceSubscription, promoCode && promoCode.title),
    ablePurchase: !hasAnyProductSubscribed && IsPriceUnderLimit(emirate, totalBeforeTax, promoCode && promoCode.title),
    isPriceUnderLimit: IsPriceUnderLimit(emirate, totalPriceWithDiscounts, promoCode && promoCode.title)
  };
  return {
    totalProducts: totalProducts,
    totalPrice: totalPrice,
    subscriptionDiscount: subscriptionDiscount,
    totalBeforeTax: totalBeforeTax,
    totalPriceWithDiscounts: totalPriceWithDiscounts,
    totalPriceWithSubscription: totalPriceWithSubscription,
    vatAmount: vatAmount,
    codAmount: codAmount,
    discount: discount,
    finalPrice: finalPrice,
    productsPrice: productsPrice,
    rules: rules,
    shippingPrice: shippingPrice,
    amountToFreeShipping: amountToFreeShipping,
    totalPriceSubscription: totalPriceSubscription
  };
};
export var ConvertTo = function ConvertTo(unit, value) {
  return unit === 'kg' ? (Number.parseFloat(value) * conversionRatio).toFixed(2) : (Number.parseFloat(value) / conversionRatio).toFixed(2);
};
export var editSelectedSubscription = function editSelectedSubscription(subscription) {
  var _subscription$babyInf = subscription.babyInfo,
    name = _subscription$babyInf.name,
    gender = _subscription$babyInf.gender,
    diaperSize = _subscription$babyInf.diaperSize;
  var boxDiscount = subscription.boxDiscount;
  var selectedSubscription = {};
  var selectedDiapers = [];
  var selectedWipes = [];
  var upselling = [];
  selectedSubscription.id = subscription.id;
  selectedSubscription.promoCode = boxDiscount ? {
    value: boxDiscount.value,
    value_type: boxDiscount.type,
    title: boxDiscount.code,
    id: boxDiscount.id,
    productsId: boxDiscount.entitledProductIds,
    variantsId: boxDiscount.entitledVariantIds
  } : null;
  selectedSubscription.shippingDate = subscription.shippingDate;
  selectedSubscription.paymentType = subscription.paymentType;
  selectedSubscription.payWithCOD = selectedSubscription.paymentType === 'COD';
  selectedSubscription.codPercent = subscription.codPercent;
  selectedSubscription.babyData = {
    name: name,
    gender: gender,
    diaperSize: diaperSize
  };
  subscription.boxAddOns.forEach(function (item) {
    for (var i = 0; i < item.quantity; i++) {
      upselling.push(_objectSpread({}, item, {
        variantId: +item.variantId,
        price: item.price
      }, item.shopifyProduct));
    }
  });
  subscription.boxProducts.forEach(function (prod) {
    if (prod.product_type === 'diapers' || prod.type === 'diapers') {
      for (var i = 0; i < prod.quantity; i++) {
        selectedDiapers.push(_objectSpread({}, prod, prod.shopifyProduct));
      }
    }
    if (prod.product_type === 'baby wipes' || prod.type === 'baby wipes') {
      for (var _i = 0; _i < prod.quantity; _i++) {
        selectedWipes.push(_objectSpread({}, prod, {
          price: Number(+prod.price / prod.quantity).toFixed(2)
        }, prod.shopifyProduct));
      }
    }
  });
  selectedSubscription.selectedDiapers = [].concat(selectedDiapers);
  selectedSubscription.selectedWipes = [].concat(selectedWipes);
  selectedSubscription.upselling = [].concat(upselling);
  return selectedSubscription;
};
export var getErrorMessage = function getErrorMessage(error) {
  var EMAIL = 'email';
  var PHONE = 'phone';
  var API_ERRORS = {
    EMAIL: {
      INVALID: 'is invalid',
      ALREADY_TAKEN: 'has already been taken'
    },
    PHONE: {
      INVALID: 'enter a valid phone number to use this delivery method',
      ALREADY_TAKEN: 'phone has already been taken'
    }
  };
  var field = error[0].toLowerCase();
  var message = error[1][0].toLowerCase();
  switch (true) {
    case field === EMAIL && message === API_ERRORS.EMAIL.ALREADY_TAKEN:
      return ['errorNotifications.email.alreadyTaken', 'errorNotifications.email.login', 'errorNotifications.email.continue'];
    case field === EMAIL && message === API_ERRORS.EMAIL.INVALID:
      return ['errorNotifications.email.invalid'];
    case field === PHONE && message === API_ERRORS.PHONE.ALREADY_TAKEN:
      return ['errorNotifications.phone.alreadyTaken'];
    case field === PHONE && message === API_ERRORS.PHONE.INVALID:
      return ['errorNotifications.phone.invalid'];
    default:
      return [''];
  }
};
export var loadDynamicScript = function loadDynamicScript(callback, scriptId, url) {
  var existingScript = document.getElementById(scriptId);
  if (existingScript) existingScript.remove();
  var script = document.createElement('script');
  script.src = url;
  script.id = scriptId;
  document.head.appendChild(script);
  script.onload = function () {
    if (callback) callback();
  };
};
export var isHoliday = function isHoliday(date) {
  var today = moment(date).format('DD-MM');
  return holidays.find(function (holiday) {
    return holiday === today;
  });
};
export var daysToAdd = function daysToAdd(date) {
  var days = [];
  var holiday = date;
  holidays.forEach(function (day) {
    var diffDays = moment("".concat(moment().year(), "-").concat(day), 'YYYY-DD-MM').diff(moment(holiday), 'days');
    if (diffDays === 0 || diffDays === 1) {
      days.push(day);
      holiday = moment("".concat(moment().year(), "-").concat(day), 'YYYY-DD-MM');
    }
  });
  return days.length;
};
export var hasDeliveryToday = function hasDeliveryToday() {
  return moment().hours() < 12;
};
export var getDeliveryDays = function getDeliveryDays(province, customDate) {
  var today = customDate || moment();
  var dayAccordingTime = hasDeliveryToday() ? -1 : 0;
  switch (true) {
    case province === 'Dubai':
      today.date(today.date() + 1 + dayAccordingTime);
      isHoliday(today) && today.date(today.date() + daysToAdd(today));
      today.date(today.day() === 0 ? today.date() + 1 : today.date() + 0);
      return today.format('YYYY-MM-DD');
    case province === 'Abu Dhabi' || province === 'Al Ain' || province === 'Ras al-Khaimah' || province === 'Umm Al Quwain' || province === 'Ajman' || province === 'Fujairah' || province === 'Sharjah':
      today.date(today.day() === 0 ? today.date() + 1 : today.date() + 0);
      today.date(today.date() + 2);
      isHoliday(today) && today.date(today.date() + daysToAdd(today));
      today.date(today.day() === 0 ? today.date() + 1 : today.date() + 0);
      return today.format('YYYY-MM-DD');
    default:
      return today.format('YYYY-MM-DD');
  }
};
export var getDayBlocked = function getDayBlocked(province, day) {
  switch (true) {
    case province === 'Dubai' || province === 'Sharjah' || province === 'Abu Dhabi' || province === 'Al Ain' || province === 'Ras al-Khaimah' || province === 'Umm Al Quwain' || province === 'Ajman' || province === 'Fujairah':
      return day === 0;
    default:
      return false;
  }
};
export var anySubscribableProduct = function anySubscribableProduct(uniqueDiaper, uniqueWipe, uniqueUpselling) {
  var anySubscribableUpselling = uniqueUpselling.some(function (up) {
    var variantSelected = up.variants.find(function (variant) {
      return variant.id === +up.variantId || variant.id === +up.shopifyVariantId;
    });
    return +variantSelected.appliedDiscount > 0 && !up.isOffer;
  });
  return uniqueDiaper.length > 0 || uniqueWipe.length > 0 || uniqueUpselling.length > 0 && anySubscribableUpselling;
};
export var productsToSubscribe = function productsToSubscribe(uniqueDiaper, uniqueWipe, uniqueUpselling) {
  return uniqueDiaper.filter(function (diaper) {
    return diaper.purchase_type === 'ONE_TIME' || diaper.purchaseType === 'ONE_TIME';
  }).length + uniqueWipe.filter(function (wipe) {
    return wipe.purchase_type === 'ONE_TIME' || wipe.purchaseType === 'ONE_TIME';
  }).length + uniqueUpselling.filter(function (up) {
    var variantSelected = up.variants.find(function (variant) {
      return variant.id === +up.variantId || variant.id === +up.shopifyVariantId;
    });
    return +variantSelected.appliedDiscount > 0 && !up.isOffer && up.purchaseType === 'ONE_TIME';
  }).length;
};
export var isAnyProductSubscribed = function isAnyProductSubscribed(selectedDiapers, selectedWipes, upsellings) {
  return selectedDiapers.some(function (diaper) {
    return diaper.purchase_type === 'SUBSCRIPTION' || diaper.purchaseType === 'SUBSCRIPTION';
  }) || selectedWipes.some(function (wipe) {
    return wipe.purchase_type === 'SUBSCRIPTION' || wipe.purchaseType === 'SUBSCRIPTION';
  }) || upsellings.some(function (upselling) {
    return (upselling.purchase_type === 'SUBSCRIPTION' || upselling.purchaseType) === 'SUBSCRIPTION';
  });
};
export var areOneTimeProducts = function areOneTimeProducts(selectedDiapers, selectedWipes, upsellings) {
  return selectedDiapers.find(function (diaper) {
    return diaper.purchase_type === 'ONE_TIME' || diaper.purchaseType === 'ONE_TIME';
  }) || selectedWipes.find(function (wipe) {
    return wipe.purchase_type === 'ONE_TIME' || wipe.purchaseType === 'ONE_TIME';
  }) || upsellings.find(function (upselling) {
    return upselling.purchaseType === 'ONE_TIME' || upselling.purchaseType === 'ONE_TIME';
  });
};
export var formatMoney = function formatMoney(amount) {
  return parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
export var isTomorrow = function isTomorrow(date) {
  var tomorrow = moment();
  tomorrow.date(tomorrow.date() + 1);
  return tomorrow.format('YYYY-MM-DD') === date;
};
export var getMonthDay = function getMonthDay(date) {
  var dateFormated = new Date(date);
  var month = dateFormated.getMonth();
  var day = dateFormated.getDate() + 1;
  return "".concat(months[month], "-").concat(day);
};
export var replaceAmpersandDash = function replaceAmpersandDash(string) {
  return string.replace(/[-&]/g, function (toReplace) {
    return {
      '-': ' ',
      '&': 'and'
    }[toReplace];
  });
};
export var gtmSingleProduct = function gtmSingleProduct(prod, price, sku, userData, actionField, event, transaction, variant, quantity) {
  var dataLayer = {
    _clear: true,
    category: 'ecommerce',
    environment: config.TAG_MANAGER_ENV,
    event: event,
    id: uuidv4(),
    items: [{
      currency: 'AED',
      item_brand: prod.vendor,
      item_category: replaceAmpersandDash(prod.product_type || prod.productType),
      item_id: prod.id,
      item_name: prod.title,
      item_sku: sku,
      item_subscription_status: prod.purchaseType || prod.purchase_type,
      item_variant: variant,
      price: price,
      quantity: quantity
    }]
  };
  if (event === 'add_to_cart') Object.assign(dataLayer, {
    transaction: {
      currency: 'AED',
      subscription_discount: transaction.subscriptionDiscount.toFixed(2),
      subscription_recurring_amount: transaction.totalPriceSubscription.toFixed(2)
    }
  });
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var gtmSubscription = function gtmSubscription(products, userData, event, options) {
  var prods = products.map(function (item) {
    var title = item.title,
      id = item.id,
      price = item.price,
      product_type = item.product_type,
      vendor = item.vendor,
      sku = item.sku;
    return {
      sku: sku,
      name: title,
      id: id,
      price: price,
      category: product_type,
      brand: vendor,
      quantity: 1,
      currency: 'AED'
    };
  });
  var dataLayer = {
    event: event,
    subscriptionStatus: options,
    userId: userData && userData.id,
    ecommerce: _defineProperty({
      currencyCode: 'AED'
    }, event, {
      products: prods
    })
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var gtmCheckoutSteps = function gtmCheckoutSteps(products, userData, event, actionField, coupon) {
  var prods = products.map(function (item) {
    var title = item.title,
      id = item.id,
      price = item.price,
      product_type = item.product_type,
      vendor = item.vendor,
      sku = item.sku;
    return {
      sku: sku,
      name: title,
      id: id,
      price: price,
      category: product_type,
      brand: vendor,
      quantity: 1,
      currency: 'AED',
      subscriptionStatus: item.purchase_type || item.purchaseType
    };
  });
  var dataLayer = {
    event: event,
    userId: userData && userData.id,
    ecommerce: {
      currencyCode: 'AED',
      checkout: {
        coupon: coupon,
        actionField: actionField,
        products: prods
      }
    }
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var gtmPurchase = function gtmPurchase(products, userData, event, coupon, data) {
  var bitArray = sjcl.hash.sha256.hash(userData.email);
  var hashEmail = sjcl.codec.hex.fromBits(bitArray);
  var dataLayer = {
    _clear: true,
    category: 'ecommerce',
    environment: config.TAG_MANAGER_ENV,
    event: event,
    event_data: {
      coupon: coupon
    },
    id: uuidv4(),
    items: products,
    transaction: {
      currency: 'AED',
      order_id: data.id,
      revenue: data.revenue,
      shipping: data.shipping,
      tax: data.tax,
      subtotal: data.subtotal,
      subscription_discount: data.discount,
      payment_method: data.paymentMethod
    },
    user: {
      email: userData.email,
      email_sha256: hashEmail,
      id: userData.id
    }
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var gtmSelectItem = function gtmSelectItem(product, selectedVariant) {
  var index = product.variants.findIndex(function (variant) {
    return +variant.id === +selectedVariant.id;
  }) + 1;
  var price = selectedVariant.compare_at_price > 0 ? selectedVariant.compare_at_price : selectedVariant.price;
  var dataLayer = {
    _clear: true,
    category: 'ecommerce',
    environment: config.TAG_MANAGER_ENV,
    event: 'select_item',
    id: uuidv4(),
    items: [{
      currency: 'AED',
      index: index,
      item_id: product.id,
      item_name: product.title,
      price: price,
      quantity: 1,
      item_brand: product.vendor,
      item_category: replaceAmpersandDash(product.product_type || product.productType)
    }]
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var gtmViewItem = function gtmViewItem(product, selectedVariant) {
  var price = selectedVariant.compare_at_price > 0 ? selectedVariant.compare_at_price : selectedVariant.price;
  var dataLayer = {
    _clear: true,
    category: 'ecommerce',
    environment: config.TAG_MANAGER_ENV,
    event: 'view_item',
    id: uuidv4(),
    items: [{
      currency: 'AED',
      item_id: product.id,
      item_name: product.title,
      price: price,
      quantity: 1,
      item_brand: product.vendor,
      item_category: replaceAmpersandDash(product.product_type || product.productType)
    }]
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var getVariantPrice = function getVariantPrice(variantId, variants) {
  var variantSelected = variants.find(function (variant) {
    return variant.id === variantId;
  });
  return variantSelected.compare_at_price > 0 ? variantSelected.compare_at_price : variantSelected.price;
};
export var getVariantTitle = function getVariantTitle(variantId, variants, type) {
  var selectedVariant = variants.find(function (variant) {
    return variant.id === variantId;
  });
  return {
    diapers: "Size: ".concat(selectedVariant.option1, " - Weight: ").concat(selectedVariant.option3, " - ").concat(selectedVariant.option2, " units"),
    'baby wipes': "".concat(selectedVariant.option1, " units")
  }[type] || "".concat(selectedVariant.option1, " ").concat(selectedVariant.option2);
};
export var gtmBeginCheckout = function gtmBeginCheckout(uniqueCartProducts, uniqueCartProductsQty, cartProducts, discount, paymentType, subscriptionDiscount, totalPriceSubscription, tax, totalPriceWithDiscounts, shippingPrice, finalPrice) {
  var cart = uniqueCartProducts.map(function (prod) {
    return {
      item_name: prod.title,
      item_id: prod.id,
      item_sku: prod.sku,
      item_subscription_status: prod.purchase_type || prod.purchaseType,
      item_category: replaceAmpersandDash(prod.product_type || prod.productType),
      item_brand: prod.vendor,
      currency: 'AED',
      item_variant: getVariantTitle(prod.variant_id || prod.variantId, prod.variants, prod.product_type || prod.productType),
      quantity: uniqueCartProductsQty[prod.variant_id || prod.variantId],
      price: getVariantPrice(prod.variant_id || prod.variantId, prod.variants)
    };
  });
  var transaction = {
    currency: 'AED',
    discount: discount,
    payment_method: paymentType,
    subscription_discount: subscriptionDiscount,
    subscription_recurring_amount: totalPriceSubscription,
    subtotal: totalPriceWithDiscounts,
    tax: tax,
    total_items: uniqueCartProducts.length,
    total_quantity: cartProducts,
    shipping: shippingPrice,
    revenue: +finalPrice.toFixed(2)
  };
  var dataLayer = {
    _clear: true,
    category: 'ecommerce',
    environment: config.TAG_MANAGER_ENV,
    event: 'begin_checkout',
    id: uuidv4(),
    items: cart,
    transaction: transaction
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
var getFirstShownVariant = function getFirstShownVariant(product) {
  return product.product_type && product.product_type === 'diapers' ? product.variants.find(function (item) {
    return item.option2 === product.presentationsBySize[item.option1][0].quantity && item.option3 === product.presentationsBySize[item.option1][0].weight;
  }) : product.variants[0];
};
export var gtmViewSearchResults = function gtmViewSearchResults(query, results) {
  var items = results.map(function (prod) {
    var variant = getFirstShownVariant(prod);
    return {
      item_name: prod.title,
      item_id: prod.id,
      item_sku: variant.sku,
      index: results.indexOf(prod) + 1,
      item_category: replaceAmpersandDash(prod.product_type || prod.productType),
      item_brand: prod.vendor,
      currency: 'AED',
      item_variant: getVariantTitle(+variant.id, prod.variants, prod.product_type || prod.productType),
      quantity: 1,
      price: getVariantPrice(+variant.id, prod.variants)
    };
  });
  var dataLayer = {
    _clear: true,
    category: 'search',
    environment: config.TAG_MANAGER_ENV,
    event: 'view_search_results',
    items: items,
    id: uuidv4(),
    event_data: {
      search_query: query,
      search_results_number: results.length
    }
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var gtmSubmitSearch = function gtmSubmitSearch(query) {
  var dataLayer = {
    _clear: true,
    category: 'search',
    environment: config.TAG_MANAGER_ENV,
    event: 'submit_search',
    id: uuidv4(),
    event_data: {
      search_query: query
    }
  };
  TagManager.dataLayer({
    dataLayer: dataLayer,
    dataLayerName: 'YallaBaby'
  });
};
export var getNewShippingDateWhenCloning = function getNewShippingDateWhenCloning(shippingDate) {
  var customerProvinceName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Dubai';
  shippingDate = new Date(shippingDate);
  shippingDate.setMonth(shippingDate.getMonth() + 1); // next month
  var emirate = emirates.find(function (emir) {
    return emir.name.toLowerCase() === customerProvinceName.toLowerCase();
  }) || 'Dubai';
  while (!emirate.daysEnabledForShipping[shippingDate.getDay()]) {
    shippingDate.setDate(shippingDate.getDate() + 1);
  }
  return shippingDate;
};
export var getAddedAmount = function getAddedAmount(compare_at_price, price, selectedVariantQuantity) {
  return (compare_at_price > 0 ? compare_at_price : price) * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity);
};
export var getDetailPageURI = function getDetailPageURI(productId, productName, history) {
  var slug = productName.split(' ').join('-').toLowerCase();
  history.push("/product/".concat(productId, "-").concat(slug));
};
export var pageName = function pageName(url) {
  switch (true) {
    case url.includes('products'):
      return "Products - ".concat(replaceAmpersandDash(url.slice(url.lastIndexOf('/') + 1)));
    case url.includes('product/'):
      return 'Product Detail Page';
    case url.includes('boxes'):
      return 'My Subscription';
    case url.includes('account'):
      return 'My Profile';
    case url.includes('past'):
      return 'Past Orders';
    case url.includes('cart'):
      return 'Cart';
    case url.includes('checkout/done'):
      return 'Checkout Success';
    case url.includes('checkout/fail'):
      return 'Checkout Failed';
    case url.includes('checkout'):
      return 'Checkout';
    case url.includes('contact'):
      return 'Contact Us';
    default:
      return 'Home';
  }
};
export var getFormatedAmount = function getFormatedAmount(number) {
  var LOCALE = 'en-AE';
  var OPTIONS = {
    style: 'currency',
    currency: 'AED'
  };
  return number.toLocaleString(LOCALE, _objectSpread({}, OPTIONS));
};
export var capitalize = function capitalize(_ref) {
  var _ref2 = _toArray(_ref),
    firstLetter = _ref2[0],
    restOfWord = _ref2.slice(1);
  return firstLetter.toUpperCase() + restOfWord.join('');
};
export var getDifferentProducts = function getDifferentProducts(box, boxToCompare) {
  var cart = [].concat(_toConsumableArray(box.selectedDiapers), _toConsumableArray(box.selectedWipes), _toConsumableArray(box.upselling));
  var cartToCompare = [].concat(_toConsumableArray(boxToCompare.selectedDiapers), _toConsumableArray(boxToCompare.selectedWipes), _toConsumableArray(boxToCompare.upselling));
  cart.forEach(function (el) {
    cartToCompare.splice(cartToCompare.indexOf(cartToCompare.find(function (prod) {
      return el.id === prod.id;
    })), 1);
  });
  return cartToCompare;
};
export var getAllCollections = function getAllCollections(menuItems) {
  var allCollections = [];
  menuItems.forEach(function (item) {
    if (item.items) item.items.forEach(function (subItem) {
      return allCollections.push(subItem);
    });
    if (item.handle !== 'collections') allCollections.push(item);
  });
  return allCollections;
};
export var isSafari = function isSafari() {
  return navigator.vendor.toLowerCase().includes('apple');
};
export var getDiscountAmount = function getDiscountAmount(subscriptionPrice, regularPrice) {
  var discountAmount = subscriptionPrice * 100 / regularPrice;
  var formatDiscountAmount = Math.round(formatMoney(100 - discountAmount));
  return formatDiscountAmount;
};
export var productInEditionQty = function productInEditionQty(variant, cart) {
  return cart.find(function (prod) {
    return +prod.shopifyVariantId === variant.id;
  }) ? cart.find(function (prod) {
    return +prod.shopifyVariantId === variant.id;
  }).quantity : 0;
};
export var getInStockProducts = function getInStockProducts(collection) {
  var newProducts = [];
  collection.products.forEach(function (prod) {
    var variantsInStock = prod.variants.filter(function (variant) {
      return variant.inventory_quantity > 0;
    });
    var productInStock = _objectSpread({}, prod, {
      variants: variantsInStock,
      options: prod.optionsOfProductsInStock,
      presentationsBySize: prod.product_type && prod.product_type === 'diapers' ? prod.presentationsBySizeInStock : {}
    });
    if (productInStock.variants.length) newProducts.push(productInStock);
  });
  return newProducts;
};