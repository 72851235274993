/* eslint-disable react/button-has-type */
import React from 'react'
import { Typography } from '@material-ui/core'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'

const CreateBoxButton = ({ icon, title, buttonColor, onClick = () => {}, type = 'button', disabled = false, id, babyBoxType }) => {
  const getPathnameFromUrl = () => {
    const path = window.location.pathname
    const pathValues = path.split('/').pop(path[0])
    return path === '/' ? 'home-createBoxButton' : pathValues.concat(`${babyBoxType || ''}_button`)
  }
  return (
    <button id={id || getPathnameFromUrl()} type={type} onClick={onClick} disabled={disabled} className={buttonColor ? `main-button--${buttonColor}` : 'main-button'}>
      {icon ? <IconComponent icon="box" fill="#fff" stroke="#fff" strokeWidth={0.5} height="16.5px" /> : ''}
      <Typography variant="inherit">{title}</Typography>
    </button>
  )
}

CreateBoxButton.propTypes = propTypes
export default CreateBoxButton
