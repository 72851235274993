import React, { Fragment } from 'react';
import { Modal, Grid } from '@material-ui/core';
import propTypes from './props';
import IconComponent from '../../atoms/IconComponent';

const GenericModal = 
({
  children, openModal, handleClose, upsellingClose, showCloseButton = true
}) => {
  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={openModal} onClose={handleClose} className="login-signin-modal">
      <div tabIndex={-1}>
        <Fragment>
          <Grid container className="modal-login-signin">
            { showCloseButton && (
            <Grid item xs={2} container justify="center" alignItems="center" direction="column" className="modal-content__close">
              <IconComponent width="15px" height="15px" fill="black" icon="close" onClick={() => upsellingClose ? upsellingClose() : handleClose()} />
            </Grid>
            )}
            <Grid container item>
              {children}
            </Grid>
          </Grid>
        </Fragment>
      </div>
    </Modal>
  );
};

GenericModal.propTypes = propTypes;
export default GenericModal;
