/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Radio } from '@material-ui/core'
import { translate } from 'react-i18next'
import { formatMoney, gtmSingleProduct, getDiscountAmount } from '../../../utils/helpers'
import FlatButton from '../../molecules/FlatButton'
import QuantityModifier from '../../molecules/QuantityModifier'
import OutOfStockLabel from '../../atoms/OutOfStockLabel'
import Actions from '../../../actions'
import propTypes from './props'

const WipeProductDetail = ({
  t,
  wipe,
  selectedVariant,
  setSelectedVariant,
  currentLanguage,
  handleAlertOutStock,
  alertOutStock,
  outOfStock
}) => {
  const { options, variants } = wipe
  const cart = useSelector(state => state.Checkout.selectedWipes)
  const { userData } = useSelector(state => state.Users)

  const { price, compare_at_price, appliedDiscount } = selectedVariant

  const dispatch = useDispatch()
  const addWipe = data => dispatch(Actions.Checkout.addWipe(data))
  const removeWipe = (index, id, subscription) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({ ...wipe, variant_id: selectedVariant.id, purchase_type: subscription })
    )
    dispatch(Actions.Checkout.removeWipe(index, id))
  }

  useEffect(() => {
    gtmSingleProduct(wipe, compare_at_price, selectedVariant.sku, userData, { list: 'productDetail' }, 'detail')
  }, [])

  const productInCart = cart.find(
    item => +item.variant_id === selectedVariant.id || +item.shopifyVariantId === selectedVariant.id
  )
  const purchaseType = productInCart && (productInCart.purchase_type || productInCart.purchaseType)

  const [toSubscribe, setToSubscribe] = useState(false)

  useEffect(() => {
    if (productInCart) setToSubscribe(purchaseType === 'SUBSCRIPTION')
  }, [productInCart])

  const handleQuantityChange = type => {
    const bubblesAnimation = document.getElementById('cart-button')
    switch (type) {
      case 'add':
        addWipe({
          ...wipe,
          variant_id: selectedVariant.id,
          purchase_type: purchaseType || 'SUBSCRIPTION',
          price
        })
        bubblesAnimation.classList.remove('animate')
        bubblesAnimation.classList.add('animate')
        setTimeout(() => {
          bubblesAnimation.classList.remove('animate')
        }, 500)
        break

      case 'remove':
        for (let index = cart.length - 1; index >= 0; index -= 1) {
          if (+cart[index].variant_id === selectedVariant.id || +cart[index].shopifyVariantId === selectedVariant.id) {
            removeWipe(index, null, purchaseType || 'SUBSCRIPTION')
            break
          }
        }
        break

      default:
        break
    }
  }

  const selectVariant = size => {
    const variant = variants.find(item => item.option1 === size)
    setSelectedVariant(variant)
  }

  const getSelectedVariantQuantity = variant => {
    let q = 0
    cart.forEach(up => {
      if (+up.variant_id === variant.id || +up.shopifyVariantId === variant.id) {
        q += 1
      }
    })
    return q
  }

  const selectedVariantQuantity = getSelectedVariantQuantity(selectedVariant)

  const setSubscribedWipe = (variantId, variantPrice) => {
    dispatch(Actions.Checkout.setSubscribedWipe(variantId, variantPrice))
  }

  const subscriptionPriceCross = formatMoney(price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity))

  const subscriptionPrice = formatMoney(
    compare_at_price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
  )

  const subscriptionPriceWrapper = () => (
    <Typography variant="inherit" className="subscription-price">
      <Typography className="subscription-price__discount-cross">
        <span className="subscription-price__cross">{`${t('default.was')} ${subscriptionPriceCross}`}</span>
      </Typography>
      <span className="subscription-price__discount-price">{`${t('default.aed')} ${subscriptionPrice}`}</span>
      <span className="subscription-price__text">{`${t('default.vatNotIncluded')}`}</span>
    </Typography>
  )

  return (
    <>
      <Grid container item className="product-detail-page-diaper__add-space" justify="flex-start">
        <Grid container item xs={12} className="product-detail-page-diaper__subtitle add-space">
          <Typography variant="inherit">{t('vip.quantity')}</Typography>
        </Grid>
        {options
          ? options[0].values.map(size => (
              <button
                type="button"
                key={size}
                className={`filter__item__variants__variant-chip${
                  selectedVariant && size === selectedVariant.option1 ? '__selected' : ''
                } pdp`}
                onClick={() => selectVariant(size)}>
                <Grid container item direction="column">
                  <span>
                    <strong>{`${size} units`}</strong>
                  </span>
                </Grid>
              </button>
            ))
          : options[0].values.map(
              size =>
                size === selectedVariant.option1 && (
                  <button type="button" key={size} className={`filter__item__variants__variant-chip__selected`}>
                    <Grid container item direction="column">
                      <span>
                        <strong>{`${size} `}</strong>
                        {'u.'}
                      </span>
                    </Grid>
                  </button>
                )
            )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="flex-start"
        justify="space-between"
        direction="column"
        wrap="nowrap"
        className={`product-detail-page-content__prices ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
        <Grid
          onClick={() => {
            setToSubscribe(false)
            if (productInCart && purchaseType === 'SUBSCRIPTION')
              setSubscribedWipe(productInCart.variant_id || +productInCart.shopifyVariantId, {
                price,
                compare_at_price,
                appliedDiscount
              })
          }}
          className={`purchase-selector ${!toSubscribe && 'selected'} add-space`}>
          <Radio className={`purchase-selector__radio ${!toSubscribe && 'selected'}`} checked={!toSubscribe} />
          <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
            {`${t('boxes.oneTimeProducts')}`}
            <span>{`${t('default.aed')} ${subscriptionPriceCross}`}</span>
          </Typography>
        </Grid>

        <Grid
          onClick={() => {
            setToSubscribe(true)
            if (productInCart && purchaseType === 'ONE_TIME')
              setSubscribedWipe(productInCart.variant_id || +productInCart.shopifyVariantId, {
                price,
                compare_at_price,
                appliedDiscount
              })
          }}
          className={`purchase-selector subscribe ${toSubscribe && 'selected'}`}>
          <Radio className={`purchase-selector__radio ${toSubscribe && 'selected'}`} checked={toSubscribe} />
          <Typography variant="inherit" className="subscription-text">
            {`${t('default.subscription')} ${t('default.get')} `}
            <span className="discount-amount">
              {`${getDiscountAmount(subscriptionPrice, subscriptionPriceCross)} ${t('default.discountLabel')}`}
            </span>
            <span className="subscription-text__info">
              {`${t('cart.subscribeInformation.get')} ${
                selectedVariant.option1 * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
              } ${t('cart.subscribeInformation.unitsPerMonth')}`}
            </span>
          </Typography>

          {subscriptionPriceWrapper()}
        </Grid>
      </Grid>
      <Grid item container justify="flex-start" className="product-detail-page-diaper__add-space">
        {selectedVariantQuantity === 0 ? (
          <FlatButton
            idButton="add-wipe"
            cartIcon={outOfStock ? '' : 'cart2'}
            classes={`filter__item__info__add-button pdp ${outOfStock ? 'disabled' : ''}`}
            iconClass="cart-icon-2"
            onClick={() => {
              if (!outOfStock) {
                addWipe({
                  ...wipe,
                  variant_id: selectedVariant.id,
                  purchase_type: toSubscribe ? 'SUBSCRIPTION' : 'ONE_TIME',
                  price
                })
                const bubblesAnimation = document.getElementById('cart-button')
                bubblesAnimation.classList.remove('animate')
                bubblesAnimation.classList.add('animate')
                setTimeout(() => {
                  bubblesAnimation.classList.remove('animate')
                }, 500)
              }
            }}
            title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
          />
        ) : (
          <QuantityModifier
            pdp
            quantity={selectedVariantQuantity}
            setQuantity={handleQuantityChange}
            variantSelected={selectedVariant}
            handleAlertOutStock={handleAlertOutStock}
            alertOutStock={alertOutStock}
            stockControl
            cart={cart}
          />
        )}
      </Grid>
      {alertOutStock && (
        <Grid item container>
          <OutOfStockLabel inventoryQuantity={selectedVariant.inventory_quantity} inPDP t={t} />
        </Grid>
      )}
      <Grid item container justify="flex-start" className="product-detail-page-diaper fixed-mobile">
        {selectedVariantQuantity === 0 ? (
          <>
            <Grid
              container
              item
              alignItems="flex-start"
              justify="center"
              direction="column"
              wrap="nowrap"
              className={`product-detail-page-content__prices fixed-mobile ${
                currentLanguage === 'AR' ? 'inverted' : ''
              }`}>
              <Typography
                className={`regular-price fixed-mobile ${currentLanguage === 'AR' ? 'inverted' : ''} ${
                  !toSubscribe ? ' show' : ' cross'
                }`}
                variant="inherit">
                {`${t('default.aed')} ${formatMoney(subscriptionPriceCross)}`}
              </Typography>
              <Typography
                variant="inherit"
                className={`subscription-price fixed-mobile ${toSubscribe ? ' show' : ' cross'}`}>
                {`${t('default.aed')} ${formatMoney(subscriptionPrice)} `}
              </Typography>
            </Grid>
            <FlatButton
              idButton="add-wipe"
              classes={`filter__item__info__add-button fixed-mobile ${outOfStock ? 'disabled' : ''}`}
              iconClass="cart-icon-2"
              cartIcon={outOfStock ? '' : 'cart2'}
              onClick={() => {
                if (!outOfStock) {
                  addWipe({
                    ...wipe,
                    variant_id: selectedVariant.id,
                    purchase_type: toSubscribe ? 'SUBSCRIPTION' : 'ONE_TIME',
                    price
                  })
                  const bubblesAnimation = document.getElementById('cart-button')
                  bubblesAnimation.classList.remove('animate')
                  bubblesAnimation.classList.add('animate')
                  setTimeout(() => {
                    bubblesAnimation.classList.remove('animate')
                  }, 500)
                }
              }}
              title={t('default.addToBundle')}
            />
          </>
        ) : (
          <QuantityModifier
            pdp
            fixedMobile
            quantity={selectedVariantQuantity}
            setQuantity={handleQuantityChange}
            variantSelected={selectedVariant}
            handleAlertOutStock={handleAlertOutStock}
            alertOutStock={alertOutStock}
            stockControl
            cart={cart}
          />
        )}
      </Grid>
    </>
  )
}
WipeProductDetail.propTypes = propTypes
export default translate('common')(WipeProductDetail)
