/* eslint-disable react/prop-types */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { translate } from 'react-i18next'
import delivery from '../../../assets/images/delivery.svg'
import doctor from '../../../assets/images/doctor.svg'
import exchange from '../../../assets/images/exchange.svg'
import prize from '../../../assets/images/prize.svg'


import propTypes from './props'

const YallaFeatures = ({t}) => {

  
const Feature = ({image,text}) => {
  
    return (
      <Grid className='yalla-features-container__module'>
        <Grid className='yalla-features-container__module__image-container'>
          <img className='yalla-features-container__module__image' src={image} alt='Credit Cards' />
        </Grid>
        <Typography className='yalla-features-text'>{text}</Typography>
      </Grid>

    )
  }
  
  return (
    <Grid className='yalla-features-container'>      
      <Feature image={delivery} text={t('yallaFeatures.shipping')} />

      <Feature image={prize} text={t('yallaFeatures.rewards')} />

      <Feature image={doctor} text={t('yallaFeatures.recommended')} />

      <Feature image={exchange} text={t('yallaFeatures.exchange')} />
    </Grid>
  )
}

YallaFeatures.propTypes = propTypes
export default translate('common')(YallaFeatures) 
