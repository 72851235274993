/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Grid, Typography, Link } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { getNewShippingDateWhenCloning } from '../../../utils/helpers'
import propTypes from './props'
import CheckoutImage from '../../../assets/images/checkout-done.svg'

const CheckoutDoneOk = ({ t, history, incomingBoxDate, provinceName, isSubscription }) => {
  const dispatch = useDispatch()
  const [counter, setCounter] = useState(10)

  useEffect(() => {
    return () => {
      dispatch(Actions.Checkout.setStatus())
    }
  }, [])

  useEffect(() => {
    if (counter > -1) {
      setTimeout(() => {
        setCounter(counter - 1)
      }, 1000)
    }

    if (counter === -1) {
      history.push('/boxes')
    }
  }, [counter])

  return (
    <>
      <Grid container item justify="center" className="checkout-done-container">
        <Grid container item alignItems="flex-start" xs={12} md={6} lg={4} className="checkout-done">
          <img src={CheckoutImage} alt="done" />
          <Grid item className="done__title">
            <span>{t('checkout.thankShopping')}</span>
          </Grid>

          <Typography variant="inherit" className="done__shipping-date">
            {t('checkout.comingBox')}
            <span>{moment(incomingBoxDate).format('Do MMMM')}</span>
          </Typography>

          {isSubscription && (
            <Typography variant="inherit" className="done__shipping-date">
              {t('checkout.nextComingBox')}
              <span>{moment(getNewShippingDateWhenCloning(incomingBoxDate, provinceName)).format('Do MMMM')}</span>
            </Typography>
          )}

          <Typography variant="inherit" className="done__body">
            {t('checkout.remember')}
          </Typography>
          <Grid className="done__ambassador-container">
            <hr className="done__ambassador-container__hr" />
            <Typography className="done__ambassador-container__text">
              {t('checkout.ambassadorText1')}
              <span>
                {t('checkout.ambassadorText2')}
                <Link
                  className="done__ambassador-container__link"
                  target="_blank"
                  underline="none"
                  rel="noopener"
                  href="https://yallababy.com/ambassadors.html#howitoworks">
                  {t('checkout.ambassadorLink')}
                </Link>
              </span>
            </Typography>
            <hr className="done__ambassador-container__hr bottom" />
          </Grid>

          <Grid container item justify="center" alignItems="flex-start" className="done__link-boxes">
            <span>
              {counter <= 0 ? (
                <strong>{t('checkout.redirecting')}</strong>
              ) : (
                <strong>
                  {t('checkout.redirectText')}
                  {counter}
                  {t('checkout.seconds')}
                </strong>
              )}
            </span>
          </Grid>
          <Grid container item justify="center" alignItems="flex-start">
            <Typography
              onClick={() => {
                history.push('/boxes')
              }}
              className="done__redirect-link">
              {t('checkout.goToBoxes')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div id="background" />
    </>
  )
}

CheckoutDoneOk.propTypes = propTypes
export default CheckoutDoneOk
