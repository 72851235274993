/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

const DiapersIcon = ({ color }) => (
  <svg width="23px" height="20px" viewBox="0 0 23 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-48" transform="translate(1.000000, 1.000000)">
        <rect id="Rectangle" fill={color || '#393939'} x="0" y="2" width="3" height="3" rx="0.5" />
        <rect id="Rectangle-Copy-64" fill={color || '#393939'} x="18" y="2" width="3" height="3" rx="0.5" />
        <g id="Group-36" fillRule="nonzero" stroke={color || '#393939'} strokeWidth="1.5">
          <path
            d="M20.6876268,0.300086972 C20.4900507,0.108837912 20.2213387,0.000906624351 19.9407708,0 L1.07342799,0 C0.788387424,-0.00352517205 0.513924949,0.103724301 0.312373225,0.297359713 C0.113235294,0.487108781 0.000851926978,0.745175694 0,1.01462892 L0,7.90914049 C0.00937119675,13.4784086 4.70810345,17.991 10.5070994,18 C16.2939554,17.9835001 20.9812576,13.4831131 21,7.92550405 L21,1.01735618 C20.9991481,0.747902954 20.8867647,0.48983604 20.6876268,0.300086972 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default DiapersIcon
