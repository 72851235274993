/* eslint-disable max-len */
import React from 'react'

const Trash = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 3.00012H0.5C0.367392 3.00012 0.240215 3.0528 0.146447 3.14656C0.0526784 3.24033 0 3.36751 0 3.50012C0 3.63272 0.0526784 3.7599 0.146447 3.85367C0.240215 3.94744 0.367392 4.00012 0.5 4.00012H1.052L2.002 12.5551C2.01556 12.6776 2.0739 12.7908 2.16581 12.873C2.25772 12.9551 2.37674 13.0004 2.5 13.0001H9.5C9.62309 13.0001 9.74186 12.9547 9.83356 12.8726C9.92527 12.7905 9.98346 12.6775 9.997 12.5551L10.947 4.00012H11.5C11.6326 4.00012 11.7598 3.94744 11.8536 3.85367C11.9473 3.7599 12 3.63272 12 3.50012C12 3.36751 11.9473 3.24033 11.8536 3.14656C11.7598 3.0528 11.6326 3.00012 11.5 3.00012ZM9.053 12.0001H2.947L2.06 4.00012H9.94L9.052 12.0001H9.053ZM4 2.50012C4.0408 2.50017 4.08145 2.49513 4.121 2.48512L8.121 1.48512C8.18601 1.47049 8.24744 1.44301 8.30168 1.4043C8.35592 1.3656 8.40187 1.31644 8.43684 1.25972C8.47181 1.20299 8.49509 1.13985 8.50531 1.07401C8.51554 1.00816 8.51249 0.940935 8.49636 0.876282C8.48023 0.811629 8.45134 0.750852 8.41139 0.697523C8.37143 0.644194 8.32122 0.599389 8.26371 0.565741C8.20619 0.532093 8.14253 0.510282 8.07647 0.501588C8.0104 0.492894 7.94326 0.497494 7.879 0.515117L3.879 1.51512C3.7607 1.54476 3.65732 1.61663 3.58831 1.71718C3.5193 1.81773 3.48941 1.94003 3.50428 2.06108C3.51915 2.18213 3.57775 2.29356 3.66905 2.37442C3.76034 2.45528 3.87804 2.49998 4 2.50012V2.50012Z"
      fill="#999999"
    />
    <path
      d="M7.21896 10.499L7.25096 10.5C7.37802 10.4998 7.50022 10.4511 7.59272 10.364C7.68522 10.2769 7.74108 10.1579 7.74896 10.031L7.99896 6.03804C8.00717 5.9057 7.96248 5.77552 7.87472 5.67613C7.78695 5.57673 7.6633 5.51627 7.53096 5.50804C7.46514 5.50216 7.39881 5.50974 7.33601 5.53031C7.27321 5.55089 7.21525 5.58403 7.16567 5.62772C7.11608 5.67141 7.07591 5.72473 7.0476 5.78444C7.01928 5.84415 7.00341 5.909 7.00096 5.97504L6.75096 9.96904C6.74275 10.1014 6.78744 10.2316 6.87521 10.331C6.96297 10.4303 7.08662 10.4908 7.21896 10.499ZM4.74896 10.5L4.78096 10.499C4.9133 10.4908 5.03695 10.4303 5.12472 10.331C5.21248 10.2316 5.25717 10.1014 5.24896 9.96904L4.99896 5.97504C4.99622 5.90906 4.98017 5.84431 4.95177 5.78468C4.92338 5.72505 4.88322 5.67179 4.83371 5.62807C4.78421 5.58436 4.72638 5.5511 4.6637 5.5303C4.60102 5.50951 4.53478 5.50159 4.46896 5.50704C4.33662 5.51527 4.21297 5.57573 4.12521 5.67513C4.03744 5.77452 3.99275 5.9047 4.00096 6.03704L4.25096 10.031C4.25884 10.1579 4.3147 10.2769 4.4072 10.364C4.4997 10.4511 4.6219 10.4998 4.74896 10.5Z"
      fill="#999999"
    />
  </svg>
)

export default Trash
