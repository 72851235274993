import React from 'react'
import proptypes from './props'

const Spinner = () => (
  <div className="loading-spinner">
    <div />
    <div />
    <div />
    <div />
  </div>
)

Spinner.propTypes = proptypes
export default Spinner;