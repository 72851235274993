/* eslint-disable camelcase */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { Grid, Typography, Radio } from '@material-ui/core'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { formatMoney, gtmSingleProduct, getDiscountAmount } from '../../../utils/helpers'
import FlatButton from '../../molecules/FlatButton'
import QuantityModifier from '../../molecules/QuantityModifier'
import OutOfStockLabel from '../../atoms/OutOfStockLabel'
import Actions from '../../../actions'
import propTypes from './props'

const DiaperProductDetail = ({
  t,
  diaper,
  selectedVariant,
  setSelectedVariant,
  setSelectedDiaperAmount,
  currentLanguage,
  handleAlertOutStock,
  alertOutStock,
  outOfStock
}) => {
  const { options, presentationsBySize, variants } = diaper

  const { userData } = useSelector(state => state.Users)
  const cart = useSelector(state => state.Checkout.selectedDiapers)

  const [selectedAmount, setSelectedAmount] = useState(presentationsBySize[selectedVariant.option1][0])
  const productInCart = cart.find(
    item => item.variant_id === selectedVariant.id || +item.shopifyVariantId === selectedVariant.id
  )

  const purchaseType = productInCart && (productInCart.purchase_type || productInCart.purchaseType)
  const [toSubscribe, setToSubscribe] = useState(false)

  const dispatch = useDispatch()
  const addDiaper = data => dispatch(Actions.Checkout.addDiaper(data))
  const removeDiaper = (index, id) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({
        ...diaper,
        variant_id: selectedVariant.id,
        purchase_type: purchaseType || 'SUBSCRIPTION'
      })
    )
    dispatch(Actions.Checkout.removeDiaper(index, id))
  }

  const { price, compare_at_price } = selectedVariant

  useEffect(() => {
    if (productInCart) setToSubscribe(purchaseType === 'SUBSCRIPTION')
  }, [productInCart])

  const sizeRanges = diaper.options.find(opt => opt.name === 'Weight').values

  useEffect(() => {
    const variant = variants.find(
      item => item.option2 === selectedAmount.quantity && item.option3 === selectedAmount.weight
    )
    setSelectedVariant(variant)
  }, [selectedAmount, selectedVariant])

  const selectVariant = size => {
    const variant = variants.find(item => item.option1 === size)
    setSelectedVariant(variant)
    setSelectedAmount(presentationsBySize[size][0])
    setSelectedDiaperAmount(presentationsBySize[size][0])
  }

  const handleQuantityChange = type => {
    const bubblesAnimation = document.getElementById('cart-button')
    switch (type) {
      case 'add':
        addDiaper({
          ...diaper,
          variant_id: selectedVariant.id,
          purchase_type: purchaseType || 'SUBSCRIPTION',
          price,
          product_size: selectedVariant.option1
        })
        bubblesAnimation.classList.remove('animate')
        bubblesAnimation.classList.add('animate')
        setTimeout(() => {
          bubblesAnimation.classList.remove('animate')
        }, 500)
        break

      case 'remove':
        for (let index = cart.length - 1; index >= 0; index -= 1) {
          if (+cart[index].variant_id === selectedVariant.id || +cart[index].shopifyVariantId === selectedVariant.id) {
            removeDiaper(index, null)
            break
          }
        }
        break

      default:
        break
    }
  }

  const getSelectedVariantQuantity = variant => {
    let q = 0
    cart.forEach(up => {
      if (+up.variant_id === variant.id || +up.shopifyVariantId === variant.id) {
        q += 1
      }
    })
    return q
  }
  const selectedVariantQuantity = getSelectedVariantQuantity(selectedVariant)

  const setSubscribedDiaper = (variant_id, amount) => {
    dispatch(Actions.Checkout.setSubscribedDiaper(variant_id, amount))
  }

  useEffect(() => {
    setSelectedVariant(variants.find(item => item.option1))
    gtmSingleProduct(diaper, compare_at_price, selectedVariant.sku, userData, { list: 'productDetail' }, 'detail')
  }, [])

  const subscriptionPriceCross = formatMoney(price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity))

  const subscriptionPrice = formatMoney(
    compare_at_price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
  )

  const subscriptionPriceWrapper = () => (
    <Typography variant="inherit" className="subscription-price">
      <Typography className="subscription-price__discount-cross">
        <span className="subscription-price__cross">{`${t('default.was')} ${subscriptionPriceCross}`}</span>
      </Typography>
      <span className="subscription-price__discount-price">{`${t('default.aed')} ${subscriptionPrice}`}</span>
      <span className="subscription-price__text">{`${t('default.vatNotIncluded')}`}</span>
    </Typography>
  )

  return (
    <>
      <Grid container className="product-detail-page-diaper">
        <Grid container item xs={12} className="product-detail-page-diaper__subtitle add-space">
          <Typography variant="inherit">{t('vip.quantity')}</Typography>
        </Grid>
        <Grid container item xs={12} lg={7} className="product-detail-page-diaper__add-space" justify="flex-start">
          <>
            {selectedVariant &&
              presentationsBySize[selectedVariant.option1].map(item => (
                <button
                  type="button"
                  key={item.quantity}
                  className={`filter__item__variants__variant-chip${
                    item.quantity === selectedAmount.quantity ? '__selected' : ''
                  } pdp`}
                  onClick={() => {
                    setSelectedAmount(item)
                    setSelectedDiaperAmount(item)
                  }}>
                  {presentationsBySize[selectedVariant.option1][0].quantity === item.quantity && (
                    <span
                      className={`filter__item__variants__variant-chip__best ${
                        item.quantity === selectedAmount.quantity && 'selected'
                      }`}>
                      {t('default.bestValue')}
                    </span>
                  )}
                  <strong>{`${item.quantity} units`}</strong>

                  {item.quantity === selectedAmount.quantity && (
                    <span className="filter__item__variants__variant-chip__per-unit">
                      {`${t('default.aed')} ${+(
                        (toSubscribe ? subscriptionPrice : subscriptionPriceCross) / item.quantity
                      ).toFixed(2)} ${t('default.per-unit')}`}
                    </span>
                  )}
                </button>
              ))}
          </>
        </Grid>
        <Grid container item xs={12} className="product-detail-page-diaper__subtitle">
          <Typography variant="inherit">{t('vip.selectSize')}</Typography>
        </Grid>
        <Grid container item xs={12} className="product-detail-page-diaper__add-space">
          {options
            ? options[0].values.map((size, index) => (
                <button
                  type="button"
                  key={`${options.product_id}-${size}`}
                  className={`filter__item__variants__button${
                    (selectedVariant && size === selectedVariant.option1) ||
                    (getSelectedVariantQuantity(selectedVariant) > 0 && size === selectedVariant.option1)
                      ? '__selected'
                      : ''
                  } pdp`}
                  onClick={() => {
                    selectVariant(size)
                  }}>
                  <Grid container item direction="column">
                    <span>
                      {size !== 'NB' ? 'Size ' : ''}
                      <strong>{`${size} `}</strong>
                    </span>
                    <span>{sizeRanges[index]}</span>
                  </Grid>
                </button>
              ))
            : options[0].values.map(
                (size, index) =>
                  selectedVariant.option1 === size && (
                    <button
                      type="button"
                      key={`${options.product_id}-${size}`}
                      className={`filter__item__variants__button__selected`}>
                      <Grid container item direction="column">
                        <span>
                          {size !== 'NB' ? 'Size ' : ''}
                          <strong>{`${size} `}</strong>
                        </span>
                        <span>{sizeRanges[index]}</span>
                      </Grid>
                    </button>
                  )
              )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justify="space-between"
          direction="column"
          wrap="nowrap"
          className={`product-detail-page-content__prices ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
          <Grid
            onClick={() => {
              setToSubscribe(false)
              if (productInCart && purchaseType === 'SUBSCRIPTION')
                setSubscribedDiaper(
                  productInCart.variant_id || +productInCart.shopifyVariantId,
                  selectedAmount.suggestedQuantity
                )
            }}
            className={`purchase-selector ${!toSubscribe && 'selected'} add-space`}>
            <Radio className={`purchase-selector__radio ${!toSubscribe && 'selected'}`} checked={!toSubscribe} />
            <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
              {`${t('boxes.oneTimeProducts')}`}
              <span>{`${t('default.aed')} ${subscriptionPriceCross}`}</span>
            </Typography>
          </Grid>

          <Grid
            onClick={() => {
              setToSubscribe(true)
              if (productInCart && purchaseType === 'ONE_TIME')
                setSubscribedDiaper(
                  productInCart.variant_id || +productInCart.shopifyVariantId,
                  selectedAmount.suggestedQuantity
                )
            }}
            className={`purchase-selector subscribe ${toSubscribe && 'selected'}`}>
            <Radio className={`purchase-selector__radio ${toSubscribe && 'selected'}`} checked={toSubscribe} />
            <Typography variant="inherit" className="subscription-text">
              {`${t('default.subscription')} ${t('default.get')} `}
              <span className="discount-amount">
                {`${getDiscountAmount(subscriptionPrice, subscriptionPriceCross)} ${t('default.discountLabel')}`}
              </span>
              <span className="subscription-text__info">
                {`${t('cart.subscribeInformation.get')} ${
                  selectedAmount.quantity * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
                } ${t('cart.subscribeInformation.unitsPerMonth')}`}
              </span>
            </Typography>

            {subscriptionPriceWrapper()}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justify="flex-start" xs={12} lg={12} className="product-detail-page-diaper__add-space">
        {selectedVariantQuantity === 0 ? (
          <FlatButton
            idButton="add-diaper"
            classes={`filter__item__info__add-button pdp ${outOfStock ? 'disabled' : ''}`}
            cartIcon={outOfStock ? '' : 'cart2'}
            iconClass="cart-icon-2"
            onClick={() => {
              if (!outOfStock) {
                addDiaper({
                  ...diaper,
                  variant_id: selectedVariant.id,
                  purchase_type: toSubscribe ? 'SUBSCRIPTION' : 'ONE_TIME',
                  price,
                  product_size: selectedVariant.option1
                })
                const bubblesAnimation = document.getElementById('cart-button')
                bubblesAnimation.classList.remove('animate')
                bubblesAnimation.classList.add('animate')
                setTimeout(() => {
                  bubblesAnimation.classList.remove('animate')
                }, 500)
              }
            }}
            title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
          />
        ) : (
          <QuantityModifier
            pdp
            quantity={selectedVariantQuantity}
            setQuantity={handleQuantityChange}
            variantSelected={selectedVariant}
            handleAlertOutStock={handleAlertOutStock}
            alertOutStock={alertOutStock}
            stockControl
            cart={cart}
          />
        )}
      </Grid>
      {alertOutStock && (
        <Grid item container>
          <OutOfStockLabel inventoryQuantity={selectedVariant.inventory_quantity} inPDP t={t} />
        </Grid>
      )}
      <Grid item container justify="flex-start" xs={12} lg={12} className="product-detail-page-diaper fixed-mobile">
        {selectedVariantQuantity === 0 ? (
          <>
            <Grid
              container
              item
              alignItems="flex-start"
              justify="center"
              direction="column"
              wrap="nowrap"
              className={`product-detail-page-content__prices fixed-mobile ${
                currentLanguage === 'AR' ? 'inverted' : ''
              }`}>
              <Typography
                className={`regular-price fixed-mobile ${currentLanguage === 'AR' ? 'inverted' : ''} ${
                  !toSubscribe ? 'show' : 'cross'
                }`}
                variant="inherit">
                {`${t('default.aed')} ${formatMoney(subscriptionPriceCross)}`}
              </Typography>
              <Typography
                variant="inherit"
                className={`subscription-price fixed-mobile ${toSubscribe ? 'show' : 'cross'}`}>
                {`${t('default.aed')} ${formatMoney(subscriptionPrice)} `}
              </Typography>
            </Grid>
            <FlatButton
              idButton="add-diaper"
              classes={`filter__item__info__add-button fixed-mobile ${outOfStock ? 'disabled' : ''}`}
              cartIcon={outOfStock ? '' : 'cart2'}
              iconClass="cart-icon-2"
              onClick={() => {
                if (!outOfStock) {
                  addDiaper({
                    ...diaper,
                    variant_id: selectedVariant.id,
                    purchase_type: toSubscribe ? 'SUBSCRIPTION' : 'ONE_TIME',
                    price,
                    product_size: selectedVariant.option1
                  })
                  const bubblesAnimation = document.getElementById('cart-button')
                  bubblesAnimation.classList.remove('animate')
                  bubblesAnimation.classList.add('animate')
                  setTimeout(() => {
                    bubblesAnimation.classList.remove('animate')
                  }, 500)
                }
              }}
              title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
            />
          </>
        ) : (
          <QuantityModifier
            pdp
            fixedMobile
            quantity={selectedVariantQuantity}
            setQuantity={handleQuantityChange}
            variantSelected={selectedVariant}
            handleAlertOutStock={handleAlertOutStock}
            alertOutStock={alertOutStock}
            stockControl
            cart={cart}
          />
        )}
      </Grid>
    </>
  )
}
DiaperProductDetail.propTypes = propTypes
export default translate('common')(DiaperProductDetail)
