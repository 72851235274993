/* eslint-disable max-len */
import React from 'react'

const CartFullIconJaka = () => (
  <svg width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.875 22.75C12.3424 22.75 11.1 23.9252 11.1 25.375C11.1 26.8248 12.3424 28 13.875 28C15.4076 28 16.65 26.8248 16.65 25.375C16.65 23.9252 15.4076 22.75 13.875 22.75ZM30.5251 22.75C28.9924 22.75 27.75 23.9252 27.75 25.375C27.75 26.8248 28.9924 28 30.5251 28C32.0577 28 33.3001 26.8248 33.3001 25.375C33.3001 23.9252 32.0577 22.75 30.5251 22.75ZM36.626 2.44639C36.2728 2.0038 35.7344 1.75 35.1482 1.75H10.7507L10.6131 1.06706C10.4885 0.447945 9.91625 0 9.25002 0H5.08751C4.3212 0 3.70001 0.587617 3.70001 1.3125C3.70001 2.03738 4.3212 2.625 5.08751 2.625H8.10192L11.587 19.9331C11.7117 20.5522 12.2838 21 12.95 21H31.9126C32.6789 21 33.3001 20.4124 33.3001 19.6875C33.3001 18.9626 32.6789 18.375 31.9126 18.375H14.0981L13.5696 15.75H31.9775C32.8032 15.75 33.529 15.2322 33.7558 14.4811L36.9277 3.98109C37.0885 3.44788 36.9783 2.88903 36.626 2.44639ZM1.3875 7.875H6.01251C6.77887 7.875 7.40001 7.28733 7.40001 6.5625C7.40001 5.83756 6.77887 5.25 6.01251 5.25H1.3875C0.621254 5.25 0 5.83756 0 6.5625C0 7.28733 0.621254 7.875 1.3875 7.875ZM1.3875 12.25H6.93751C7.70388 12.25 8.32501 11.6623 8.32501 10.9375C8.32501 10.2126 7.70388 9.625 6.93751 9.625H1.3875C0.621254 9.625 0 10.2126 0 10.9375C0 11.6623 0.621254 12.25 1.3875 12.25ZM7.86251 14H1.3875C0.621254 14 0 14.5876 0 15.3125C0 16.0373 0.621254 16.625 1.3875 16.625H7.86251C8.62888 16.625 9.25002 16.0373 9.25002 15.3125C9.25002 14.5876 8.62888 14 7.86251 14Z"
      fill="#00672A"
    />
  </svg>
)

export default CartFullIconJaka
