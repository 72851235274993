import React, { Fragment, useState } from 'react'
import { Grid, Typography, TextField, Snackbar, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { translate } from 'react-i18next'
import { useDispatch } from 'react-redux'
import propTypes from './props'
import Actions from '../../actions'

const Recovery = ({ t, history, closeModal }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [userName, setUserName] = useState('')
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()

  const fetchRecovery = usrName => {
    dispatch(Actions.Users.fetchRecovery(usrName))
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  const useStyles = makeStyles({
    root: {
      '& label.Mui-focused': {
        color: 'grey'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green'
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#54CE8A'
        }
      }
    }
  })
  const classes = useStyles()
  return (
    <Fragment>
      <Grid container alignItems="center" justify="center" className="modal-login-signin">
        <Grid container className="recovery-content" alignItems="center">
          <Typography variant="inherit" className="login__title">
            {t('default.pleaseEmail')}
          </Typography>
          <Grid item container xs={12} alignItems="flex-end" justify="center" className="login__field recovery">
            <TextField
              className={classes.root}
              variant="outlined"
              id="mail"
              label={t('default.email')}
              value={userName}
              onChange={e => setUserName(e.target.value)}
            />
          </Grid>
          <Grid item container xs={12} className="login__buttons" alignItems="center" justify="center">
            <>
              <button
                type="button"
                className="login-signin-button main-button--green"
                onClick={() => {
                  fetchRecovery(userName)
                  handleClick()
                  setTimeout(() => (!isDesktop ? history.push('/') : closeModal()), 3000)
                }}>
                {t('default.send')}
              </button>
            </>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        variant="success"
        open={open}
        autoHideDuration={3000}
        className="notify--success"
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{t('default.recoveryCheckEmail')}</span>}
      />
    </Fragment>
  )
}

Recovery.propTypes = propTypes
export default translate('common')(Recovery)
