/* eslint-disable react/prop-types */
import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

const MainWrapper = ({ children }) => {
  const currentLanguage = useSelector(state => state.Language.current)

  const wrapperClass = classNames({
    wrapper: true
  })

  if (currentLanguage === 'AR') {
    document.body.classList.add('arab')
  } else {
    document.body.classList.remove('arab')
  }
  return (
    <div>
      <div className={wrapperClass}>{children}</div>
    </div>
  )
}

export default MainWrapper
