/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react'

const SvgCash = ({ width, height }) => (
  <svg
    width={width || '43px'}
    height={height || '32px'}
    viewBox="0 0 43 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon id="path-1" points="0 0.0306082796 42.970087 0.0306082796 42.970087 21.8937198 0 21.8937198" />
    </defs>
    <g id="Flow-Transition-WEB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="10-CHECK-OUT-CREDIT-CARD-SELECT" transform="translate(-584.000000, -620.000000)">
        <g id="Group-20" transform="translate(584.000000, 620.000000)">
          <g id="Group-3" transform="translate(0.000000, 10.000000)">
            <mask id="mask-6" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path
              d="M42.2579903,4.03864734 C42.6508068,4.03907246 42.9700874,3.71364251 42.9700874,3.31169082 L42.9700874,3.30999034 L42.9700874,0.7592657 C42.9700874,0.35731401 42.6524686,0.0310338164 42.2596522,0.0306082796 L42.2579903,0.0306082796 L0.712096618,0.0306082796 C0.319280193,0.0301835749 -4.06583254e-07,0.355613527 -4.06583254e-07,0.757565217 L-4.06583254e-07,0.7592657 L-4.06583254e-07,21.1650628 C-4.06583254e-07,21.5670145 0.317618357,21.8932947 0.710434783,21.8937198 L0.712096618,21.8937198 L28.9633043,21.8937198 C29.3565362,21.8937198 29.675401,21.5674396 29.675401,21.1650628 C29.675401,20.762686 29.3565362,20.4364058 28.9633043,20.4364058 L1.42419324,20.4364058 L1.42419324,1.48792271 L41.5458937,1.48792271 L41.5458937,3.30999034 C41.5454783,3.71194203 41.8635121,4.03822222 42.2563285,4.03864734 L42.2579903,4.03864734 Z"
              id="Fill-1"
              fill="#393939"
              mask="url(#mask-6)"
            />
          </g>
          <path
            d="M3.97533809,0.11149969 C3.81711642,0.214294104 3.70511564,0.376728178 3.66404183,0.563417114 L2.01697167,8.12337401 C1.93235344,8.5116618 2.17241392,8.89637961 2.55309301,8.98268911 C2.9337721,9.06899862 3.31095117,8.82414002 3.3955694,8.43585223 L4.8886385,1.58065131 L37.1399193,8.98184912 C37.1908756,8.99392405 37.24317,8.99980402 37.2955674,8.99948902 C37.6854084,8.99875402 38.0007194,8.67588086 38,8.27835313 C37.9994841,7.94225005 37.7709531,7.6512967 37.4495685,7.57737713 L4.50816529,0.0174202265 C4.3253405,-0.0242645359 4.13376565,0.00954527138 3.97533809,0.11149969"
            id="Fill-4"
            fill="#393939"
          />
          <path
            d="M20.5,15.3636364 C23.8890625,15.3636364 26.6363636,18.111044 26.6363636,21.5 C26.6363636,24.8890625 23.8890625,27.6363636 20.5,27.6363636 C17.1109375,27.6363636 14.3636364,24.8890625 14.3636364,21.5 C14.3673651,18.1125355 17.1125355,15.3673651 20.5,15.3636364 M20.5,29 C24.642152,29 28,25.642152 28,21.5 C28,17.357848 24.642152,14 20.5,14 C16.357848,14 13,17.357848 13,21.5 C13.0042614,25.6403409 16.3596591,28.9957386 20.5,29"
            id="Fill-6"
            fill="#393939"
          />
          <path
            d="M17.7620048,11.2265254 C19.1130606,9.45011095 19.7378725,8.5 23.3728532,8.77436775 C24.9052643,9 25.6364566,10 26.6363636,11.4287034 L17.7620048,11.2265254 Z M28.4639514,11.4964139 C27.8184532,10.0306083 26.9689376,7.98799886 23.509228,7.4901832 C19.2948859,6.88378393 18,8.03254886 15.7834124,11.4964139"
            id="Fill-8"
            fill="#393939"
          />
          <path
            d="M7.96361707,15.4514772 L14.2501641,15.4514772 C14.6643456,15.4514772 15,15.1266099 15,14.7257391 C15,14.3248683 14.6643456,14 14.2501641,14 L7.65913295,14 C7.46303381,13.9996835 7.27469988,14.073464 7.13416034,14.2057821 L3.22573923,17.8954448 C3.07973122,18.0605777 2.9996729,18.2709105 3,18.4882297 L3,24.5109244 C3,24.6919355 3.06988798,24.8665954 3.19599078,25.0003954 L6.75093033,28.7637328 C6.89300104,28.9142578 7.09402181,29 7.30477644,29 L14.2501641,29 C14.6643456,29 15,28.6750267 15,28.2742618 C15,27.873391 14.6643456,27.5485237 14.2501641,27.5485237 L7.63638414,27.5485237 L4.50054773,24.2289282 L4.50054773,18.7244968 L7.96361707,15.4514772 Z"
            id="Fill-10"
            fill="#393939"
          />
          <path
            d="M6.11336898,9.10398025 L10.0732149,7.1379137 L16.1259491,8.01200765 C16.5247324,8.0695605 16.9100049,7.87328433 16.9866733,7.57359037 C17.0632346,7.2738158 16.8021329,6.98419761 16.4033496,6.92656416 L10.0573451,6.01015193 C9.86872992,5.9825846 9.6731449,6.01168345 9.51262365,6.09108058 L5.04440681,8.30678464 C4.87219764,8.40996061 4.75499675,8.55609971 4.71371373,8.71868247 L4,11 L5.34507588,11 L6.11336898,9.10398025 Z"
            id="Fill-14"
            fill="#393939"
          />
          <path
            d="M34.0363829,27.5485221 L27.7498359,27.5485221 C27.3356544,27.5485221 27,27.8733893 27,28.2742601 C27,28.6751309 27.3356544,29 27.7498359,29 L34.3407577,29 C34.5368568,29.0004216 34.7253001,28.9265352 34.8658397,28.7942172 L38.7741514,25.1045547 C38.9201594,24.9394217 39.0003271,24.7290889 39,24.5117698 L39,18.4890753 C39,18.3079584 38.9300026,18.1334044 38.8040092,17.9996044 L35.2490697,14.2362672 C35.1068896,14.0856363 34.9058688,14 34.6951142,14 L27.7498359,14 C27.3356544,14 27,14.3249732 27,14.7257382 C27,15.1265031 27.3356544,15.4514762 27.7498359,15.4514762 L34.3635065,15.4514762 L37.4994523,18.7710716 L37.4994523,24.2755027 L34.0363829,27.5485221 Z"
            id="Fill-16"
            fill="#393939"
          />
          <path
            d="M20.9166667,22.3333333 L18.8333333,22.3333333 C18.373125,22.3333333 18,22.7313333 18,23.2222222 C18,23.7131111 18.373125,24.1111111 18.8333333,24.1111111 L19.6666667,24.1111111 C19.6666667,24.602 20.0397917,25 20.5,25 C20.9602083,25 21.3333333,24.602 21.3333333,24.1111111 L21.3333333,24.0711111 C22.2576042,23.9318889 22.9578125,23.1102222 23,22.1155556 C22.9403125,20.9501111 22.0096875,20.0545556 20.9166667,20.1111111 L20.0833333,20.1111111 C19.8241667,20.1111111 19.6666667,19.9564444 19.6666667,19.8888889 C19.6666667,19.8213333 19.8241667,19.6666667 20.0833333,19.6666667 L22.1666667,19.6666667 C22.626875,19.6666667 23,19.2686667 23,18.7777778 C23,18.2868889 22.626875,17.8888889 22.1666667,17.8888889 L21.3333333,17.8888889 C21.3333333,17.398 20.9602083,17 20.5,17 C20.0397917,17 19.6666667,17.398 19.6666667,17.8888889 L19.6666667,17.9288889 C18.7423958,18.0681111 18.0421875,18.8897778 18,19.8844444 C18.0596875,21.0498889 18.9903125,21.9454444 20.0833333,21.8888889 L20.9166667,21.8888889 C21.1758333,21.8888889 21.3333333,22.0435556 21.3333333,22.1111111 C21.3333333,22.1786667 21.1758333,22.3333333 20.9166667,22.3333333"
            id="Fill-18"
            fill="#393939"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgCash
