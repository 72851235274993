import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, TextField, Fade } from '@material-ui/core'
import { useLastLocation } from 'react-router-last-location'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from './props'
import Actions from '../../actions'
import FlatButton from '../../components/molecules/FlatButton'
import RecoveryModal from '../../components/organisms/RecoveryModal'
import IconComponent from '../../components/atoms/IconComponent'

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#54CE8A'
      }
    }
  }
})

const LogIn = ({ t, onClose, history, shouldRedirect, closeMenu, inCart }) => {
  const classes = useStyles()

  const lastLocation = useLastLocation()
  const dispatch = useDispatch()
  const [openRecoveryModal, setOpenRecoveryModal] = useState(false)
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [showInfo, setShowInfo] = useState(false)
  const logged = useSelector(state => state.Users.logged)
  const errorLogin = useSelector(state => state.Users.errorLogin)
  const currentLanguage = useSelector(state => state.Language.current)
  const fetchLogin = (username, pass, redirectToPrevPage, prevPage) =>
    dispatch(Actions.Users.fetchLogin(username, pass, redirectToPrevPage, prevPage))
  const resetError = () => dispatch(Actions.Users.resetError())

  useEffect(() => {
    resetError()
  }, [])

  useEffect(() => {
    if (logged && closeMenu) closeMenu()
  }, [logged])

  const handleOpenRecoveryModal = () => {
    setOpenRecoveryModal(true)
  }
  const handleCloseRecoveryModal = () => {
    setOpenRecoveryModal(false)
  }
  const handleKeyPress = ev => {
    if (ev.key === 'Enter') {
      fetchLogin(userName, password, shouldRedirect, lastLocation)
    }
  }

  return (
    <Fragment>
      {!errorLogin && logged && !inCart && onClose()}
      <Grid container alignItems="center" justify="center" className={`login ${inCart && 'in-cart'}`}>
        {!inCart && (
          <Typography variant="inherit" className="login__title">
            {t('default.pleaseLogin')}
          </Typography>
        )}
        <Grid container>
          <Grid item container xs={12} justify="center" className="login__field">
            <TextField
              className={classes.root}
              id="mail"
              variant="outlined"
              label={t('default.email')}
              value={userName}
              onChange={e => setUserName(e.target.value)}
            />
          </Grid>
          <Grid item container xs={12} justify="center" className="login__field">
            <TextField
              id="pass"
              className={classes.root}
              variant="outlined"
              label={t('default.password')}
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyPress={e => handleKeyPress(e)}
            />
          </Grid>
          {errorLogin && (
            <Grid item container xs={12} justify="center">
              <Typography variant="inherit" className={'notify--danger notify--block'}>
                {t('default.wrongUserPass')}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={`login__links ${inCart && 'in-cart'}`}
          alignItems="center"
          justify="center">
          <Grid item container xs={12} justify="center">
            <FlatButton
              classes="login-signin-button"
              bgColor="green"
              title={inCart ? t('loginSignUp.continue') : t('default.login')}
              onClick={() => {
                fetchLogin(userName.trim(), password.trim(), shouldRedirect, lastLocation)
              }}
            />
          </Grid>
          <Grid item container xs={12} justify="center" className="login__links__forgot">
            <button type="button" onClick={() => handleOpenRecoveryModal()}>
              {t('default.forgotPass')}
            </button>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            className="login__links__forgot">
            <Grid container justifyContent="center" alignContent="center" alignItems="center">
              <button type="button" onClick={() => handleOpenRecoveryModal()}>
                {t('default.dontSetMyAccount')}
              </button>
              <IconComponent
                className="login__info-icon"
                icon="infoButton"
                color="grey"
                t={t}
                onClick={() => setShowInfo(!showInfo)}
              />
            </Grid>
            <Fade in={showInfo}>
              <Grid onClick={() => setShowInfo(false)} xs={10} className="login__info-alert">
                <Typography variant="inherit" className={'login__info-alert__text'}>
                  {t('default.setAccountInfo')}
                </Typography>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
      </Grid>
      <RecoveryModal
        openModal={openRecoveryModal}
        handleClose={handleCloseRecoveryModal}
        t={t}
        history={history}
        currentLanguage={currentLanguage}
      />
    </Fragment>
  )
}

LogIn.propTypes = propTypes
export default translate('common')(LogIn)
