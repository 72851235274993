/* eslint-disable consistent-return */
import React from 'react'
import propTypes from './props'

const PaymentFrame = ({
  children,
  togglePayWithNewCard,
  setCutstomerAddress,
  formValues,
  fetchUpdateAddress,
  paymentType,
  userAddress
}) => {
  const isAccount = window.document.URL.includes('/account')
  const isPaymentEdition = window.document.URL.includes('/account/editpayment')
  const isInCheckout = window.document.URL.includes('/checkout')
  return (
    <>
      <form
        id="payment-form"
        method="get"
        onSubmit={evt => {
          evt.preventDefault()
          togglePayWithNewCard(true)
          if (isInCheckout && paymentType === 'CREDIT' && userAddress !== formValues.address1) {
            setCutstomerAddress(formValues)
            fetchUpdateAddress(formValues)
          }
        }}
        action={() => {
          if (isAccount) {
            return `${isPaymentEdition ? '/account/editpayment' : '/account'}`
          }
          return '/checkout'
        }}>
        {children}
      </form>
    </>
  )
}

PaymentFrame.propTypes = propTypes

export default PaymentFrame
