import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import propTypes from './props'

const CardRequestModal = ({ t, setStatusCardRequest }) => {
  return (
    <Grid container item className="remove-card-modal error">
      <Grid container item className="remove-card-modal__check error">
        {t('payment.cardRemoveError')}
      </Grid>
      <Typography className="remove-card-modal__description error">{t('payment.contactSupport')}</Typography>
      <Grid className="get-help__container">
        <WhatsAppIcon className="get-help__whatsapp-icon" />
        <a
          className="get-help__text"
          href={`https://wa.me/${t('footer.whatsapp').substring(1)}`}
          target="_blank"
          rel="noopener noreferrer">
          {t('boxes.getHelp')}
        </a>
      </Grid>
      <button
        type="button"
        className="remove-card-modal__buttons__remove error"
        onClick={() => setStatusCardRequest(null)}>
        {t('default.close')}
      </button>
    </Grid>
  )
}

CardRequestModal.propTypes = propTypes
export default CardRequestModal
