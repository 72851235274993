/* eslint-disable max-len */
import React from 'react'

const SvgMastercard = () => (
  <svg width="36px" height="28px" viewBox="0 0 36 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-WEB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="10-CHECK-OUT-CREDICT-CARD-USER-REGISTRED"
        transform="translate(-829.000000, -523.000000)"
        fillRule="nonzero">
        <g id="002-tarjeta-mastercard-copy" transform="translate(829.000000, 523.000000)">
          <path
            d="M36,10.9938789 C36,16.7009235 31.3333333,21.3653351 25.5,21.3653351 C19.7222222,21.3653351 15,16.7009235 15,10.9938789 L15,10.9938789 C15,5.28683419 19.6666667,0.62242268 25.4444444,0.62242268 C31.3333333,0.62242268 36,5.28683419 36,10.9938789 L36,10.9938789 Z"
            id="Path"
            fill="#FFB600"
          />
          <path
            d="M25.5,0.62242268 C31.2777778,0.62242268 36,5.28683419 36,10.9938789 L36,10.9938789 C36,16.7009235 31.3333333,21.3653351 25.5,21.3653351 C19.7222222,21.3653351 15,16.7009235 15,10.9938789"
            id="Path"
            fill="#F7981D"
          />
          <path
            d="M25,0.62242268 C31.0529101,0.62242268 36,5.28683419 36,10.9938789 L36,10.9938789 C36,16.7009235 31.1111111,21.3653351 25,21.3653351"
            id="Path"
            fill="#FF8500"
          />
          <path
            d="M10.4164456,0.62242268 C4.67904509,0.67729811 0,5.28683419 0,10.9938789 C0,16.7009235 4.67904509,21.3653351 10.5278515,21.3653351 C13.2572944,21.3653351 15.7082228,20.3227019 17.602122,18.676439 L17.602122,18.676439 L17.602122,18.676439 C17.9920424,18.3471864 18.3262599,17.9630584 18.6604775,17.5789304 L16.4880637,17.5789304 C16.2095491,17.2496778 15.9310345,16.8655498 15.7082228,16.5362973 L19.4403183,16.5362973 C19.66313,16.2070447 19.8859416,15.8229167 20.0530504,15.4387887 L15.0954907,15.4387887 C14.928382,15.1095361 14.7612732,14.7254081 14.6498674,14.3412801 L20.4429708,14.3412801 C20.7771883,13.2986469 21,12.2011383 21,11.0487543 C21,10.2804983 20.8885942,9.5671177 20.7771883,8.85373711 L14.3156499,8.85373711 C14.3713528,8.46960911 14.4827586,8.14035653 14.5941645,7.75622852 L20.3872679,7.75622852 C20.2758621,7.37210052 20.1087533,6.98797251 19.9416446,6.65871993 L15.0397878,6.65871993 C15.2068966,6.27459192 15.4297082,5.94533935 15.6525199,5.56121134 L19.3846154,5.56121134 C19.1618037,5.17708333 18.8832891,4.79295533 18.5490716,4.46370275 L16.4880637,4.46370275 C16.8222812,4.07957474 17.1564987,3.75032216 17.5464191,3.42106959 C15.7082228,1.71993127 13.2015915,0.73217354 10.4721485,0.73217354 C10.4721485,0.62242268 10.4721485,0.62242268 10.4164456,0.62242268 Z"
            id="Path"
            fill="#FF5050"
          />
          <path
            d="M0,10.9387115 C0,16.6761128 4.67904509,21.3653351 10.5278515,21.3653351 C13.2572944,21.3653351 15.7082228,20.317156 17.602122,18.6621364 L17.602122,18.6621364 L17.602122,18.6621364 C17.9920424,18.3311324 18.3262599,17.9449612 18.6604775,17.55879 L16.4880637,17.55879 C16.2095491,17.227786 15.9310345,16.8416148 15.7082228,16.5106109 L19.4403183,16.5106109 C19.66313,16.179607 19.8859416,15.7934357 20.0530504,15.4072645 L15.0954907,15.4072645 C14.928382,15.0762606 14.7612732,14.6900893 14.6498674,14.3039181 L20.4429708,14.3039181 C20.7771883,13.255739 21,12.1523926 21,10.9938789 C21,10.2215364 20.8885942,9.50436122 20.7771883,8.78718606 L14.3156499,8.78718606 C14.3713528,8.40101482 14.4827586,8.0700109 14.5941645,7.68383966 L20.3872679,7.68383966 C20.2758621,7.29766842 20.1087533,6.91149718 19.9416446,6.58049326 L15.0397878,6.58049326 C15.2068966,6.19432201 15.4297082,5.86331809 15.6525199,5.47714685 L19.3846154,5.47714685 C19.1618037,5.09097561 18.8832891,4.70480437 18.5490716,4.37380045 L16.4880637,4.37380045 C16.8222812,3.98762921 17.1564987,3.65662529 17.5464191,3.32562137 C15.7082228,1.61543444 13.2015915,0.62242268 10.4721485,0.62242268 L10.4164456,0.62242268"
            id="Path"
            fill="#E52836"
          />
          <path
            d="M10.1157895,21.3653351 C12.9526316,21.3653351 15.5,20.317156 17.4684211,18.6621364 L17.4684211,18.6621364 L17.4684211,18.6621364 C17.8736842,18.3311324 18.2210526,17.9449612 18.5684211,17.55879 L16.3105263,17.55879 C16.0210526,17.227786 15.7315789,16.8416148 15.5,16.5106109 L19.3789474,16.5106109 C19.6105263,16.179607 19.8421053,15.7934357 20.0157895,15.4072645 L14.8631579,15.4072645 C14.6894737,15.0762606 14.5157895,14.6900893 14.4,14.3039181 L20.4210526,14.3039181 C20.7684211,13.255739 21,12.1523926 21,10.9938789 C21,10.2215364 20.8842105,9.50436122 20.7684211,8.78718606 L14.0526316,8.78718606 C14.1105263,8.40101482 14.2263158,8.0700109 14.3421053,7.68383966 L20.3631579,7.68383966 C20.2473684,7.29766842 20.0736842,6.91149718 19.9,6.58049326 L14.8052632,6.58049326 C14.9789474,6.19432201 15.2105263,5.86331809 15.4421053,5.47714685 L19.3210526,5.47714685 C19.0894737,5.09097561 18.8,4.70480437 18.4526316,4.37380045 L16.3105263,4.37380045 C16.6578947,3.98762921 17.0052632,3.65662529 17.4105263,3.32562137 C15.5,1.61543444 12.8947368,0.62242268 10.0578947,0.62242268 L10,0.62242268"
            id="Path"
            fill="#CB2026"
          />
          <g id="Group" transform="translate(1.000000, 8.524485)" fill="#FFFFFF">
            <path
              d="M14.0294118,4.78025964 L14.2058824,3.95103093 C14.1470588,3.95103093 14.0294118,3.99980909 13.9117647,3.99980909 C13.5,3.99980909 13.4411765,3.80469645 13.5,3.70714013 L13.8529412,1.99990454 L14.5,1.99990454 L14.6764706,1.07311951 L14.0882353,1.07311951 L14.2058824,0.487781596 L13.0294118,0.487781596 C13.0294118,0.487781596 12.3235294,3.70714013 12.3235294,4.09736541 C12.3235294,4.68270332 12.7352941,4.92659412 13.2647059,4.92659412 C13.6176471,4.92659412 13.9117647,4.8290378 14.0294118,4.78025964 Z"
              id="Path"
            />
            <path
              d="M14.4411765,3.21935853 C14.4411765,4.585147 15.5588235,4.92659412 16.5,4.92659412 C17.3823529,4.92659412 17.7352941,4.78025964 17.7352941,4.78025964 L17.9705882,3.85347461 C17.9705882,3.85347461 17.3235294,4.09736541 16.7352941,4.09736541 C15.4411765,4.09736541 15.6764706,3.31691485 15.6764706,3.31691485 L18.0882353,3.31691485 C18.0882353,3.31691485 18.2647059,2.68279878 18.2647059,2.43890798 C18.2647059,1.80479191 17.8529412,1.02434135 16.5588235,1.02434135 C15.3235294,0.926785032 14.4411765,1.99990454 14.4411765,3.21935853 Z M16.5,1.80479191 C17.1470588,1.80479191 17.0294118,2.43890798 17.0294118,2.48768614 L15.7352941,2.48768614 C15.7352941,2.43890798 15.8529412,1.80479191 16.5,1.80479191 Z"
              id="Shape"
            />
            <path
              d="M23.9705882,4.78025964 L24.2058824,3.70714013 C24.2058824,3.70714013 23.6176471,3.95103093 23.2058824,3.95103093 C22.3823529,3.95103093 22.0294118,3.41447117 22.0294118,2.82913326 C22.0294118,1.65845743 22.7352941,1.02434135 23.5588235,1.02434135 C24.1470588,1.02434135 24.6176471,1.31701031 24.6176471,1.31701031 L24.7941176,0.292668958 C24.7941176,0.292668958 24.0882353,0.0487781596 23.4411765,0.0487781596 C22.0882353,0.0487781596 20.7352941,1.02434135 20.7352941,2.87791142 C20.7352941,4.09736541 21.4411765,4.92659412 22.8529412,4.92659412 C23.3235294,4.92659412 23.9705882,4.78025964 23.9705882,4.78025964 Z"
              id="Path"
            />
            <path
              d="M7.55882353,0.926785032 C6.73529412,0.926785032 6.14705882,1.12189767 6.14705882,1.12189767 L5.97058824,1.95112638 C5.97058824,1.95112638 6.5,1.75601375 7.26470588,1.75601375 C7.67647059,1.75601375 8.02941176,1.80479191 8.02941176,2.09746086 C8.02941176,2.2925735 7.97058824,2.34135166 7.97058824,2.34135166 C7.97058824,2.34135166 7.61764706,2.34135166 7.44117647,2.34135166 C6.44117647,2.34135166 5.32352941,2.68279878 5.32352941,3.80469645 C5.32352941,4.68270332 6.02941176,4.87781596 6.44117647,4.87781596 C7.26470588,4.87781596 7.61764706,4.43881252 7.67647059,4.43881252 L7.61764706,4.8290378 L8.67647059,4.8290378 L9.14705882,2.14623902 C9.14705882,0.975563192 7.97058824,0.926785032 7.55882353,0.926785032 Z M7.79411765,3.12180221 C7.79411765,3.26813669 7.67647059,4.04858725 6.97058824,4.04858725 C6.61764706,4.04858725 6.5,3.80469645 6.5,3.65836197 C6.5,3.41447117 6.67647059,3.07302405 7.55882353,3.07302405 C7.73529412,3.12180221 7.79411765,3.12180221 7.79411765,3.12180221 Z"
              id="Shape"
            />
            <path
              d="M10.2647059,4.87781596 C10.5588235,4.87781596 12.0294118,4.92659412 12.0294118,3.60958381 C12.0294118,2.39012982 10.6176471,2.63402062 10.6176471,2.14623902 C10.6176471,1.90234822 10.8529412,1.80479191 11.2647059,1.80479191 C11.4411765,1.80479191 12.0882353,1.85357006 12.0882353,1.85357006 L12.2647059,0.975563192 C12.2647059,0.975563192 11.8529412,0.878006873 11.1470588,0.878006873 C10.2647059,0.878006873 9.38235294,1.17067583 9.38235294,2.14623902 C9.38235294,3.26813669 10.8529412,3.17058037 10.8529412,3.60958381 C10.8529412,3.90225277 10.4411765,3.95103093 10.1470588,3.95103093 C9.61764706,3.95103093 9.08823529,3.80469645 9.08823529,3.80469645 L8.91176471,4.68270332 C8.97058824,4.78025964 9.26470588,4.87781596 10.2647059,4.87781596 Z"
              id="Path"
            />
            <path
              d="M33.7352941,0.146334479 L33.5,1.46334479 C33.5,1.46334479 33.0294118,0.975563192 32.3823529,0.975563192 C31.3235294,0.975563192 30.3823529,2.0486827 30.3823529,3.31691485 C30.3823529,4.09736541 30.8529412,4.92659412 31.8529412,4.92659412 C32.5588235,4.92659412 32.9705882,4.53636884 32.9705882,4.53636884 L32.9117647,4.87781596 L34.0882353,4.87781596 L34.9705882,0.195112638 L33.7352941,0.146334479 Z M33.2058824,2.73157694 C33.2058824,3.26813669 32.9117647,3.95103093 32.2647059,3.95103093 C31.8529412,3.95103093 31.6176471,3.65836197 31.6176471,3.17058037 C31.6176471,2.39012982 32.0294118,1.90234822 32.5588235,1.90234822 C32.9705882,1.90234822 33.2058824,2.14623902 33.2058824,2.73157694 Z"
              id="Shape"
            />
            <polygon
              id="Path"
              points="1.14705882 4.8290378 1.85294118 1.31701031 1.97058824 4.8290378 2.79411765 4.8290378 4.32352941 1.31701031 3.67647059 4.8290378 4.91176471 4.8290378 5.85294118 0.146334479 3.91176471 0.146334479 2.73529412 3.0242459 2.67647059 0.146334479 0.970588235 0.146334479 0.0294117647 4.8290378"
            />
            <path
              d="M19.3823529,4.8290378 C19.7352941,3.21935853 19.7941176,1.90234822 20.6176471,2.14623902 C20.7352941,1.51212295 20.9117647,1.26823215 21.0294118,1.02434135 C21.0294118,1.02434135 20.9705882,1.02434135 20.7941176,1.02434135 C20.2647059,1.02434135 19.8529412,1.60967927 19.8529412,1.60967927 L19.9705882,1.07311951 L18.8529412,1.07311951 L18.0882353,4.87781596 L19.3823529,4.87781596 L19.3823529,4.8290378 Z"
              id="Path"
            />
            <path
              d="M26.6764706,0.926785032 C25.8529412,0.926785032 25.2647059,1.12189767 25.2647059,1.12189767 L25.0882353,1.95112638 C25.0882353,1.95112638 25.6176471,1.75601375 26.3823529,1.75601375 C26.7941176,1.75601375 27.1470588,1.80479191 27.1470588,2.09746086 C27.1470588,2.2925735 27.0882353,2.34135166 27.0882353,2.34135166 C27.0882353,2.34135166 26.7352941,2.34135166 26.5588235,2.34135166 C25.5588235,2.34135166 24.4411765,2.68279878 24.4411765,3.80469645 C24.4411765,4.68270332 25.1470588,4.87781596 25.5588235,4.87781596 C26.3823529,4.87781596 26.7352941,4.43881252 26.7941176,4.43881252 L26.7352941,4.8290378 L27.7941176,4.8290378 L28.2647059,2.14623902 C28.3235294,0.975563192 27.0882353,0.926785032 26.6764706,0.926785032 Z M26.9705882,3.12180221 C26.9705882,3.26813669 26.8529412,4.04858725 26.1470588,4.04858725 C25.7941176,4.04858725 25.6764706,3.80469645 25.6764706,3.65836197 C25.6764706,3.41447117 25.8529412,3.07302405 26.7352941,3.07302405 C26.9117647,3.12180221 26.9117647,3.12180221 26.9705882,3.12180221 Z"
              id="Shape"
            />
            <path
              d="M29.2647059,4.8290378 C29.6176471,3.21935853 29.6764706,1.90234822 30.5,2.14623902 C30.6176471,1.51212295 30.7941176,1.26823215 30.9117647,1.02434135 C30.9117647,1.02434135 30.8529412,1.02434135 30.6764706,1.02434135 C30.1470588,1.02434135 29.7352941,1.60967927 29.7352941,1.60967927 L29.8529412,1.07311951 L28.7352941,1.07311951 L27.9705882,4.87781596 L29.2647059,4.87781596 L29.2647059,4.8290378 Z"
              id="Path"
            />
          </g>
          <g id="Group" transform="translate(1.000000, 8.524485)" fill="#DCE5E5">
            <path
              d="M12.2058824,4.09736541 C12.2058824,4.68270332 12.6176471,4.92659412 13.1470588,4.92659412 C13.5588235,4.92659412 13.9117647,4.8290378 14.0294118,4.78025964 L14.2058824,3.95103093 C14.1470588,3.95103093 14.0294118,3.99980909 13.9117647,3.99980909 C13.5,3.99980909 13.4411765,3.80469645 13.5,3.70714013 L13.8529412,1.99990454 L14.5,1.99990454 L14.6764706,1.07311951 L14.0882353,1.07311951 L14.2058824,0.487781596"
              id="Path"
            />
            <path
              d="M15.0294118,3.21935853 C15.0294118,4.585147 15.5588235,4.92659412 16.5,4.92659412 C17.3823529,4.92659412 17.7352941,4.78025964 17.7352941,4.78025964 L17.9705882,3.85347461 C17.9705882,3.85347461 17.3235294,4.09736541 16.7352941,4.09736541 C15.4411765,4.09736541 15.6764706,3.31691485 15.6764706,3.31691485 L18.0882353,3.31691485 C18.0882353,3.31691485 18.2647059,2.68279878 18.2647059,2.43890798 C18.2647059,1.80479191 17.8529412,1.02434135 16.5588235,1.02434135 C15.3235294,0.926785032 15.0294118,1.99990454 15.0294118,3.21935853 Z M16.5,1.80479191 C17.1470588,1.80479191 17.2647059,2.43890798 17.2647059,2.48768614 L15.7352941,2.48768614 C15.7352941,2.43890798 15.8529412,1.80479191 16.5,1.80479191 Z"
              id="Shape"
            />
            <path
              d="M23.9705882,4.78025964 L24.2058824,3.70714013 C24.2058824,3.70714013 23.6176471,3.95103093 23.2058824,3.95103093 C22.3823529,3.95103093 22.0294118,3.41447117 22.0294118,2.82913326 C22.0294118,1.65845743 22.7352941,1.02434135 23.5588235,1.02434135 C24.1470588,1.02434135 24.6176471,1.31701031 24.6176471,1.31701031 L24.7941176,0.292668958 C24.7941176,0.292668958 24.0882353,0.0487781596 23.4411765,0.0487781596 C22.0882353,0.0487781596 21.3235294,1.02434135 21.3235294,2.87791142 C21.3235294,4.09736541 21.4411765,4.92659412 22.8529412,4.92659412 C23.3235294,4.92659412 23.9705882,4.78025964 23.9705882,4.78025964 Z"
              id="Path"
            />
            <path
              d="M5.97058824,1.99990454 C5.97058824,1.99990454 6.5,1.80479191 7.26470588,1.80479191 C7.67647059,1.80479191 8.02941176,1.85357006 8.02941176,2.14623902 C8.02941176,2.34135166 7.97058824,2.39012982 7.97058824,2.39012982 C7.97058824,2.39012982 7.61764706,2.39012982 7.44117647,2.39012982 C6.44117647,2.39012982 5.32352941,2.73157694 5.32352941,3.85347461 C5.32352941,4.73148148 6.02941176,4.92659412 6.44117647,4.92659412 C7.26470588,4.92659412 7.61764706,4.48759068 7.67647059,4.48759068 L7.61764706,4.87781596 L8.67647059,4.87781596 L9.14705882,2.19501718 C9.14705882,1.07311951 7.97058824,1.02434135 7.5,1.02434135 L5.97058824,1.99990454 Z M8.38235294,3.12180221 C8.38235294,3.26813669 7.67647059,4.04858725 6.97058824,4.04858725 C6.61764706,4.04858725 6.5,3.80469645 6.5,3.65836197 C6.5,3.41447117 6.67647059,3.07302405 7.55882353,3.07302405 C7.73529412,3.12180221 8.38235294,3.12180221 8.38235294,3.12180221 Z"
              id="Shape"
            />
            <path
              d="M8.97058824,4.78025964 C8.97058824,4.78025964 9.32352941,4.87781596 10.3235294,4.87781596 C10.6176471,4.87781596 12.0882353,4.92659412 12.0882353,3.60958381 C12.0882353,2.39012982 10.6764706,2.63402062 10.6764706,2.14623902 C10.6764706,1.90234822 10.9117647,1.80479191 11.3235294,1.80479191 C11.5,1.80479191 12.1470588,1.85357006 12.1470588,1.85357006 L12.3235294,0.975563192 C12.3235294,0.975563192 11.9117647,0.878006873 11.2058824,0.878006873 C10.3235294,0.878006873 10.0294118,1.17067583 10.0294118,2.14623902 C10.0294118,3.26813669 10.9117647,3.17058037 10.9117647,3.60958381 C10.9117647,3.90225277 10.5,3.95103093 10.2058824,3.95103093"
              id="Path"
            />
            <path
              d="M33.5,1.46334479 C33.5,1.46334479 33.0294118,0.975563192 32.3823529,0.975563192 C31.3235294,0.975563192 30.9705882,2.0486827 30.9705882,3.31691485 C30.9705882,4.09736541 30.8529412,4.92659412 31.8529412,4.92659412 C32.5588235,4.92659412 32.9705882,4.53636884 32.9705882,4.53636884 L32.9117647,4.87781596 L34.0882353,4.87781596 L34.9705882,0.195112638 L33.5,1.46334479 Z M33.4411765,2.73157694 C33.4411765,3.26813669 32.9117647,3.95103093 32.2647059,3.95103093 C31.8529412,3.95103093 31.6176471,3.65836197 31.6176471,3.17058037 C31.6176471,2.39012982 32.0294118,1.90234822 32.5588235,1.90234822 C32.9705882,1.90234822 33.4411765,2.14623902 33.4411765,2.73157694 Z"
              id="Shape"
            />
            <polygon
              id="Path"
              points="1.14705882 4.8290378 1.85294118 1.31701031 1.97058824 4.8290378 2.79411765 4.8290378 4.32352941 1.31701031 3.67647059 4.8290378 4.91176471 4.8290378 5.85294118 0.146334479 4.38235294 0.146334479 2.73529412 3.0242459 2.67647059 0.146334479 2.02941176 0.146334479 0.0294117647 4.8290378"
            />
            <path
              d="M18.1470588,4.8290378 L19.3823529,4.8290378 C19.7352941,3.21935853 19.7941176,1.90234822 20.6176471,2.14623902 C20.7352941,1.51212295 20.9117647,1.26823215 21.0294118,1.02434135 C21.0294118,1.02434135 20.9705882,1.02434135 20.7941176,1.02434135 C20.2647059,1.02434135 19.8529412,1.60967927 19.8529412,1.60967927 L19.9705882,1.07311951"
              id="Path"
            />
            <path
              d="M25.0882353,1.99990454 C25.0882353,1.99990454 25.6176471,1.80479191 26.3823529,1.80479191 C26.7941176,1.80479191 27.1470588,1.85357006 27.1470588,2.14623902 C27.1470588,2.34135166 27.0882353,2.39012982 27.0882353,2.39012982 C27.0882353,2.39012982 26.7352941,2.39012982 26.5588235,2.39012982 C25.5588235,2.39012982 24.4411765,2.73157694 24.4411765,3.85347461 C24.4411765,4.73148148 25.1470588,4.92659412 25.5588235,4.92659412 C26.3823529,4.92659412 26.7352941,4.48759068 26.7941176,4.48759068 L26.7352941,4.87781596 L27.7941176,4.87781596 L28.2647059,2.19501718 C28.2647059,1.07311951 27.0882353,1.02434135 26.6176471,1.02434135 L25.0882353,1.99990454 Z M27.5,3.12180221 C27.5,3.26813669 26.7941176,4.04858725 26.0882353,4.04858725 C25.7352941,4.04858725 25.6176471,3.80469645 25.6176471,3.65836197 C25.6176471,3.41447117 25.7941176,3.07302405 26.6764706,3.07302405 C26.9117647,3.12180221 27.5,3.12180221 27.5,3.12180221 Z"
              id="Shape"
            />
            <path
              d="M28.0294118,4.8290378 L29.2647059,4.8290378 C29.6176471,3.21935853 29.6764706,1.90234822 30.5,2.14623902 C30.6176471,1.51212295 30.7941176,1.26823215 30.9117647,1.02434135 C30.9117647,1.02434135 30.8529412,1.02434135 30.6764706,1.02434135 C30.1470588,1.02434135 29.7352941,1.60967927 29.7352941,1.60967927 L29.8529412,1.07311951"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgMastercard
