import config from '../config';
var apiKlaviyo = function apiKlaviyo(params) {
  var _params$eventData$pay = params.eventData.payload,
    products = _params$eventData$pay.products,
    message = _params$eventData$pay.message,
    event = _params$eventData$pay.event,
    email = _params$eventData$pay.email,
    userName = _params$eventData$pay.userName;
  var getProperty = function getProperty(evt) {
    switch (true) {
      case evt === 'started_checkout':
        return "\"products\": ".concat(JSON.stringify(products));
      case evt === 'Contact us':
        return "\"message\": ".concat(JSON.stringify(message));
      default:
        return 'null';
    }
  };
  var options = {
    method: params.method.toLowerCase(),
    headers: {
      Accept: 'text/html',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      data: "{\"token\": \"".concat(config.KLAVIYO_API_KEY, "\",\n      \"event\": \"").concat(event, "\",\n      \"customer_properties\": {\"$email\":\"").concat(email, "\",\"$first_name\":\"").concat(userName, "\"}, \"properties\": {").concat(getProperty(event), "}}")
    })
  };
  fetch('https://a.klaviyo.com/api/track', options).then(function (response) {
    return response.json();
  }).catch(function (err) {
    return console.error(err);
  });
};
export default apiKlaviyo;