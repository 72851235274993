/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import { Grid, FormControlLabel, Checkbox, Divider } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import propTypes from './props'
import Actions from '../../../actions'

const PinkCheckbox = withStyles({
  root: {
    color: '#c38a9f',
    '&$checked': {
      color: '#c38a9f'
    }
  },
  checked: {}
})(props => <Checkbox color="default" size="small" {...props} />)

const quickFilters = [
  { id: 'Subscribable', name: 'Subscribable products', label: 'products.filters.subscribable' },
  { id: 'Organic', name: 'Organic products', label: 'products.filters.organic' }
]

const Filters = ({ allBrands, t }) => {
  const { brands, quick, inStock } = useSelector(state => state.Products.filters)

  const dispatch = useDispatch()
  const setFilters = newFilters => dispatch(Actions.Products.setFilters(newFilters))

  const handleChange = (event, type, filter) => {
    switch (type) {
      case 'inStock':
        setFilters({ inStock: event.target.checked })
        break

      case 'brands':
        setFilters({ brands: event.target.checked ? brands.concat([filter]) : brands.filter(item => item !== filter) })
        break

      case 'quick':
        setFilters({ quick: event.target.checked ? quick.concat([filter]) : quick.filter(item => item !== filter) })
        break

      default:
        break
    }
  }

  const isChecked = filter => {
    return (brands && brands.includes(filter)) || (quick && quick.includes(filter)) || (filter === 'inStock' && inStock)
  }

  return (
    <Grid item container className="filter-box">
      <Grid item className="filter-box__title">
        <span>{t('products.filters.quick')}</span>
      </Grid>
      <Grid item className="filter-box__filter">
        <FormControlLabel
          key="inStock"
          control={
            <PinkCheckbox checked={inStock} onChange={ev => handleChange(ev, 'inStock', !inStock)} name={'inStock'} />
          }
          label={t('products.filters.inStock')}
          labelPlacement="start"
        />
      </Grid>
      <Grid item className="filter-box__filter">
        {quickFilters.map(quickFilter => (
          <FormControlLabel
            key={quickFilter.id}
            control={
              <PinkCheckbox
                checked={isChecked(quickFilter.id)}
                onChange={ev => handleChange(ev, 'quick', quickFilter.id)}
                name={quickFilter.name}
              />
            }
            label={t(quickFilter.label)}
            labelPlacement="start"
          />
        ))}
      </Grid>
      {allBrands && allBrands.length > 0 && (
        <>
          <Grid item xs={12} className="filter-box__divider">
            <Divider />
          </Grid>
          <Grid item className="filter-box__title">
            <span>{t('products.filters.brands')}</span>
          </Grid>
          <Grid item className="filter-box__filter">
            {allBrands.map(brand => (
              <FormControlLabel
                key={brand}
                control={
                  <PinkCheckbox
                    checked={isChecked(brand)}
                    onChange={ev => handleChange(ev, 'brands', brand)}
                    name={brand}
                  />
                }
                label={brand}
                labelPlacement="start"
              />
            ))}
          </Grid>
        </>
      )}
    </Grid>
  )
}

Filters.propTypes = propTypes
export default translate('common')(Filters)
