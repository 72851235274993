import React from 'react'

const HomeIcon = () => (
  <svg width="23px" height="20px" viewBox="0 0 23 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      id="Flow-Transition-MOB"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <polyline
        id="Fill-135-Copy"
        stroke="#393939"
        strokeWidth="1.5"
        points="9.4 19 9.4 12.6470588 13.6 12.6470588 13.6 19 18.85 19 18.85 10.5294118 22 10.5294118 11.5 1 1 10.5294118 4.15 10.5294118 4.15 19 9.4 19"
      />
    </g>
  </svg>
)

export default HomeIcon
