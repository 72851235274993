import React, { Fragment, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import propTypes from './props'
import Actions from '../../actions'
import GenericModal from '../../components/templates/GenericModal'
import Home from '../Home'

const VerifyRecovery = ({ t, history }) => {
  const hasErrorRecover = useSelector(state => state.Users.errorRecovery)
  const currentLanguage = useSelector(state => state.Language.current)

  const dispatch = useDispatch()

  const fetchGetUserInfoByToken = (token, cb) => dispatch(Actions.Users.getUserByRecoveryToken(token, cb))
  const errorRecovery = () => dispatch(Actions.Users.setErrorRecovery())

  const handleCloseVerifyRecoveryModal = () => {
    history.push('/')
  }

  useEffect(() => {
    if (history.location.search.indexOf('?token') === -1) {
      return errorRecovery()
    }

    const submap = history.location.search.replace('?', '').split('&')

    const map = submap.reduce((acc, current) => {
      const kv = current.split('=')
      const [key, value] = kv
      acc[key] = value
      return acc
    }, {})

    return fetchGetUserInfoByToken(map.token, () => history.push('/account/changepassword'))
  })

  return (
    <Home>
      <Fragment>
        <GenericModal openModal={hasErrorRecover} handleClose={handleCloseVerifyRecoveryModal} currentLanguage={currentLanguage}>
          <Grid container direction="column" alignItems="center" justify="center" className="login">
            {hasErrorRecover && (
              <Typography variant="inherit" className="login__title">
                {t('default.invalidtoken')}
              </Typography>
            )}
          </Grid>
        </GenericModal>
      </Fragment>
    </Home>
  )
}

VerifyRecovery.propTypes = propTypes

export default translate('common')(VerifyRecovery)
