import React from 'react'

const BlogIcon = () => (
  <svg width="22px" height="26px" viewBox="0 0 22 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-57" stroke="#393939">
        <rect id="Rectangle" x="5.5" y="4.5" width="12" height="7" />
        <path d="M5.40625,15.5 L17.6285109,15.5" id="Line-14" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.40625,18.5 L17.6285109,18.5" id="Line-14-Copy-2" strokeLinecap="round" strokeLinejoin="round" />
        <rect id="Rectangle" strokeWidth="1.5" x="0.75" y="0.75" width="20.5" height="24.5" rx="0.5" />
      </g>
    </g>
  </svg>
)

export default BlogIcon
