/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import propTypes from './props'
import { getFormatedAmount } from '../../../utils/helpers'

const useStylesLinearProgress = makeStyles(() => ({
  root: {
    height: 18,
    borderRadius: 14
  },
  colorPrimary: {
    backgroundColor: '#e1dedb'
  },
  bar: {
    borderRadius: 14,
    backgroundColor: '#f8bd26'
  },
  barFull: {
    borderRadius: 14,
    backgroundColor: '#73bb90'
  }
}))

const LinearProgressWithLabel = props => {
  const { value, freeShippingMinimumAmount } = props
  const classes = useStylesLinearProgress()

  const percentage = value < freeShippingMinimumAmount ? (value * 100) / freeShippingMinimumAmount : 100
  const hasFreeShipping = () => percentage >= 100

  return (
    <Box className="linear-progress" mt={2}>
      <Box minWidth={15}>
        <Typography className="linear-progress__text">0</Typography>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          classes={{
            root: classes.root,
            colorPrimary: classes.colorPrimary,
            bar: hasFreeShipping() ? classes.barFull : classes.bar
          }}
          value={percentage}
        />
      </Box>
      <Box minWidth={115}>
        <Typography className="linear-progress__text">
          {`${percentage.toFixed(2)}% (AED ${freeShippingMinimumAmount})`}
        </Typography>
      </Box>
    </Box>
  )
}

const useStylesLinearWithValueLabel = makeStyles({
  root: {
    width: '100%'
  }
})

const ProgressBar = ({ freeShippingMinimumAmount, amountToFreeShipping }) => {
  const classes = useStylesLinearWithValueLabel()

  return (
    <div className={`${classes.root} progress-bar`}>
      <LinearProgressWithLabel
        variant="determinate"
        value={freeShippingMinimumAmount - amountToFreeShipping}
        freeShippingMinimumAmount={freeShippingMinimumAmount}
      />
    </div>
  )
}

ProgressBar.propTypes = propTypes
export default ProgressBar
