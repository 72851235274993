/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Hidden, Popover, Typography } from '@material-ui/core'
import Actions from '../../../actions'
import propTypes from './props'
import IconComponent from '../IconComponent'

const LoginButton = ({ t, handleOpenLoginSignin, setOpenAlertModal, setPathToPush, history }) => {
  const LOGIN = 'login'
  const ONEOFFS = 'oneOffs'

  const [anchorEl, setAnchorEl] = React.useState(null)

  const { logged, userData } = useSelector(state => state.Users)
  const isEdition = useSelector(state => state.Checkout.isEdition)

  const dispatch = useDispatch()
  const setOneOffBox = type => dispatch(Actions.Checkout.setBoxTypePurchase(type))

  const handleClick = e => {
    if (logged) {
      setAnchorEl(e.currentTarget)
    } else {
      handleOpenLoginSignin(LOGIN)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const loginMenuItems = [
    { name: t('menu.mySubscription'), path: '/boxes' },
    { name: t('loginMenu.oneTimeOrders'), path: '/boxes', boxType: ONEOFFS },
    { name: t('menu.pastOrders'), path: '/past-orders' },
    { name: t('loginMenu.paymentDetails'), path: '/account/editpayment' },
    { name: t('menu.profile'), path: '/account' }
  ]

  const handleClickMenuItem = item => {
    const { path, boxType } = item
    handleCloseMenu()
    if (isEdition && (path === '/boxes' || path === '/account')) {
      setOpenAlertModal(true)
      setPathToPush(path)
    } else if (path === '/boxes' && boxType) {
      history.push(path, { showOneOffs: true })
    } else {
      history.push(path)
    }
  }

  const popover = (
    <Popover
      className="login-popover"
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}>
      <ul className="items-list">
        {loginMenuItems.map(item => (
          <li key={item.name}>
            <a
              href="#"
              onClick={() => {
                handleClickMenuItem(item)
              }}>
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </Popover>
  )

  const loginBtnValue = logged ? (
    <Typography>{`${t('default.Hi')}, ${userData.first_name ? userData.first_name : 'there'}`}</Typography>
  ) : (
    <Typography>{t('default.login')}</Typography>
  )

  return (
    <>
      <Box className="login-button">
        <a href="#" aria-describedby={id} onClick={handleClick}>
          <IconComponent icon="user" />
          <Hidden smDown>{loginBtnValue}</Hidden>
        </a>
      </Box>
      {popover}
    </>
  )
}

LoginButton.propTypes = propTypes
export default LoginButton
