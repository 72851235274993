/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import { Grid, Typography, Checkbox, withStyles, Hidden } from '@material-ui/core'
import propTypes from './props'
import { formatMoney, haveOfferTag } from '../../../utils/helpers'

const BoxItemMyAccount = ({ itemShow, subscribed, isSubscribable, t }) => {
  const DIAPERS = 'diapers'

  const {
    image,
    title: alt,
    title,
    listPrice,
    netPrice,
    quantity,
    selectedVariant,
    type: productType,
    chipInfo
  } = itemShow

  const itemOptions = () => {
    const { option1, option2, option3 } = selectedVariant
    let options = ''

    const handleOption2 = type => {
      return (
        {
          diapers: `${option1} - ${option3}`,
          'baby wipes': `${option1} units`
        }[type] || `${option1} ${option2}`
      )
    }

    options = handleOption2(productType)
    options = `(${options})`

    return options
  }

  return (
    <Grid container spacing={2} className="my-account">
      <Grid container justifyContent="center" alignContent="center" item xs={4} md={3}>
        <img src={image} alt={alt} />
      </Grid>
      <Grid item xs={8} md={7} className="my-account__info">
        <Typography className="title">
          {title} <span>(x{quantity})</span>
        </Typography>
        {productType === DIAPERS && <Typography className="chip-info">{chipInfo}</Typography>}
        <Typography className="size">{itemOptions()}</Typography>
        {(subscribed || isSubscribable) && (
          <Typography className="subscription">
            <span>{t(`default.${subscribed ? 'subscribed' : 'notSubscribed'}`)}</span>
          </Typography>
        )}
        <Hidden mdUp>
          <Typography className="prices">
            <span className="strikethrough">
              {`${t('cart.priceDetail.positiveAED')} ${formatMoney(listPrice * quantity)}`}
            </span>{' '}
            <span className="net-price">
              {`${t('cart.priceDetail.positiveAED')} ${formatMoney(netPrice * quantity)}`}
            </span>{' '}
            {subscribed && t('default.byMonth')}
          </Typography>
        </Hidden>
      </Grid>
      <Hidden smDown>
        <Grid item md={2} className="my-account__info my-account__info--prices">
          <Typography className="prices">
            {subscribed && (
              <span className="strikethrough">
                {`${t('cart.priceDetail.positiveAED')} ${formatMoney(listPrice * quantity)}`}
              </span>
            )}
            <span className="net-price">
              {`${t('cart.priceDetail.positiveAED')} ${formatMoney(netPrice * quantity)}`}
            </span>
            {subscribed && <span>{t('default.byMonth')}</span>}
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  )
}

const BoxItem = ({ item, t, subscribed, isSubscribable, inDashboard }) => {
  const selectedVariant = item.variants.find(variant => {
    return variant.id === +item.shopifyVariantId || variant.id === +item.variantId || variant.id === +item.variant_id
  })

  const isOffer = haveOfferTag(item)
  const getImage = () => {
    const { images, image } = item
    const selectedVariantImage =
      images && images.length > 0 && images.find(img => selectedVariant && img.id === selectedVariant.image_id)

    return selectedVariantImage ? selectedVariantImage.src : image && image.src
  }

  const getChipInfo = type => {
    switch (type) {
      case 'diapers':
        return `${selectedVariant && selectedVariant.option2} units`

      case 'baby wipes':
        return `${selectedVariant && selectedVariant.title} units`

      default:
        return `${selectedVariant && selectedVariant.option1} ${selectedVariant && selectedVariant.option2}`
    }
  }

  const itemShow = {
    image: getImage(),
    title: item.title,
    size: item.size,
    type: item.product_type,
    chipInfo: getChipInfo(item.product_type),
    quantity: +item.quantity,
    listPrice: item.listPrice,
    netPrice: item.netPrice,
    selectedVariant
  }

  const SubscriptionCheck = withStyles({
    root: {
      color: '#575757',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      marginLeft: 5,
      marginTop: -7,
      '&$checked': {
        color: '#00E999'
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />)

  const propsItemMyAccount = { itemShow, subscribed, isSubscribable, t }

  return (
    <Grid container item className="box-item">
      {inDashboard ? (
        <>
          <Grid
            container
            item
            justify="center"
            alignContent="center"
            xs={3}
            lg={3}
            className={`box-item__image${itemShow.type === 'baby wipes' ? '-wipes' : ''} ${
              inDashboard && 'inDashboard'
            }`}>
            <img src={itemShow.image} alt={itemShow.title} />
          </Grid>
          <Grid
            container
            item
            xs={inDashboard ? 9 : 5}
            lg={inDashboard ? 9 : 6}
            direction="column"
            justify="space-evenly"
            className="box-item__information">
            <Typography variant="inherit" className={`box-item__information__title ${inDashboard && 'inDashboard'}`}>
              {itemShow.title}
            </Typography>
            <Grid container item direction="column" alignItems="flex-start">
              {itemShow.type === 'diapers' && !inDashboard && (
                <Typography variant="inherit" className="box-item__information__detail">
                  {t('default.size')}
                  <span>{` ${itemShow.size === 'NB' ? 'New born' : itemShow.size}`}</span>
                </Typography>
              )}
              <Typography variant="inherit" className={`box-item__information__detail ${inDashboard && 'inDashboard'}`}>
                {t('vip.quantity')}
                <span>{`: ${itemShow.chipInfo}`}</span>
                {inDashboard && itemShow.quantity > 1 && (
                  <span>
                    <b>{` x ${itemShow.quantity}`}</b>
                  </span>
                )}
              </Typography>
              {!inDashboard && itemShow.quantity > 1 && (
                <Typography variant="inherit" className="box-item__information__quantity">
                  {`X ${itemShow.quantity}`}
                </Typography>
              )}
              {(subscribed || isSubscribable) && !inDashboard && (
                <Grid className="subscription-toggle-container">
                  <label htmlFor="sub-toggle" className="toggle-label">
                    {t(`default.${subscribed ? 'subscribed' : 'notSubscribed'}`)}
                  </label>
                  <SubscriptionCheck checked={subscribed} disabled />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={inDashboard ? 12 : 4}
            lg={inDashboard ? 12 : 3}
            className="box-item__price"
            justify="flex-start"
            alignContent="flex-end"
            alignItems="flex-end"
            direction="column">
            {subscribed && !inDashboard && (
              <Grid container item direction="row" justify="flex-end">
                <Typography variant="inherit" className="box-item__regular">
                  {`${t('cart.priceDetail.positiveAED')} ${formatMoney(itemShow.listPrice * itemShow.quantity)}`}
                </Typography>
              </Grid>
            )}
            <Grid
              className={`${inDashboard && 'box-item__price-detail-dashboard'}`}
              container
              item
              direction="row"
              justify={`${inDashboard ? 'flex-start' : 'flex-end'}`}>
              <Typography variant="inherit" className={`box-item__aed ${inDashboard && 'inDashboard'}`}>
                {t('cart.priceDetail.positiveAED')}
              </Typography>
              <Typography variant="inherit" className={`box-item__price ${inDashboard && 'inDashboard'}`}>
                {formatMoney(
                  `${
                    subscribed || isOffer
                      ? itemShow.netPrice * itemShow.quantity
                      : itemShow.listPrice * itemShow.quantity
                  }`
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <BoxItemMyAccount {...propsItemMyAccount} />
      )}
    </Grid>
  )
}

BoxItem.propTypes = propTypes
export default BoxItem
