/* eslint-disable max-len */
import React from 'react'

const LoginIcon = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-62" fill="#393939" fillRule="nonzero">
        <path
          d="M11.0769431,20.1667062 L2.76921407,20.1667062 C2.25967035,20.1667062 1.84615719,19.7560215 1.84615719,19.2500593 L1.84615719,2.75002694 C1.84615719,2.24402164 2.25971376,1.83338004 2.76921407,1.83338004 L11.0769431,1.83338004 C11.5873985,1.83338004 12,1.42364379 12,0.916733131 C12,0.409822477 11.5873985,0 11.0769431,0 L2.76921407,0 C1.24244968,0 0,1.23386485 0,2.75002694 L0,19.2500162 C0,20.7661783 1.24244968,22 2.76921407,22 L11.0769431,22 C11.5873985,22 12,21.5902637 12,21.0833531 C12,20.5764424 11.5873985,20.1667062 11.0769431,20.1667062 Z"
          id="Path"
        />
        <path
          d="M21.7405557,10.8388299 L16.4470079,5.26733265 C16.1057093,4.90703594 15.553735,4.91170888 15.2159169,5.27663486 C14.8780989,5.64156083 14.8815794,6.2293467 15.2246388,6.58964341 L19.007587,10.5713806 L8.87062884,10.5713806 C8.38916642,10.5713806 8,10.9864424 8,11.4999417 C8,12.0134409 8.38916642,12.4285464 8.87062884,12.4285464 L19.007587,12.4285464 L15.2246388,16.4102836 C14.8816204,16.7705803 14.8789997,17.3583662 15.2159169,17.7232921 C15.3865458,17.9071526 15.6111846,18 15.8358233,18 C16.0569816,18 16.2780989,17.9108648 16.4470079,17.7325507 L21.7405557,12.1610534 C21.9059842,11.9864951 22,11.7487429 22,11.499898 C22,11.2511404 21.9068441,11.014349 21.7405557,10.8388299 Z"
          id="Path"
          transform="translate(15.000000, 11.500000) rotate(-180.000000) translate(-15.000000, -11.500000) "
        />
      </g>
    </g>
  </svg>
)

export default LoginIcon
