export var getCollections = function getCollections(handle) {
  return {
    type: 'GET_COLLECTIONS',
    payload: handle
  };
};
export var getSamplings = function getSamplings() {
  return {
    type: 'GET_SAMPLINGS',
    payload: {}
  };
};
export var filter = function filter(param) {
  return {
    type: 'SET_FILTER',
    payload: param
  };
};
export var setFilters = function setFilters(filters) {
  return {
    type: 'SET_FILTERS',
    payload: filters
  };
};
export var setSearchResults = function setSearchResults(results) {
  return {
    type: 'SET_SEARCH_RESULTS',
    payload: results
  };
};
export var getDetailedProduct = function getDetailedProduct(productId) {
  return {
    type: 'GET_DETAILED_PRODUCT',
    payload: productId
  };
};
var setDetailedProduct = function setDetailedProduct(product) {
  return {
    type: 'SET_DETAILED_PRODUCT',
    payload: product
  };
};
export var setCategories = function setCategories(cat) {
  return {
    type: 'SET_CATEGORIES',
    payload: cat
  };
};
export default {
  getSamplings: getSamplings,
  filter: filter,
  setFilters: setFilters,
  getDetailedProduct: getDetailedProduct,
  setDetailedProduct: setDetailedProduct,
  setCategories: setCategories,
  setSearchResults: setSearchResults,
  getCollections: getCollections
};