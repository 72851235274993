/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react'
import { Hidden, Grid, Typography, Slide, Box } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import Loader from '../../components/atoms/Loader'
import { ImageCheck, gtmViewItem, productInEditionQty } from '../../utils/helpers'
import Actions from '../../actions'
import propTypes from './props'
import BreadCrumbs from '../../components/atoms/BreadCrumbs'
import Header from '../../components/organisms/Header'
import Footer from '../../components/molecules/Footer'
import DiaperProductDetail from '../../components/organisms/DiaperProductDetail'
import WipeProductDetail from '../../components/organisms/WipeProductDetail'
import UpsellingProductDetail from '../../components/organisms/UpsellingProductDetail'
import createGPjson from '../../utils/jsonHelper'
import SecurePay from '../../components/molecules/SecurePay'
import YallaFeatures from '../../components/molecules/YallaFeatures'
import MomsAlsoBought from '../../components/molecules/MomsAlsoBought'
import GoToCartButton from '../../components/molecules/GoToCartButton'
import MomsReviewsBanner from '../../components/molecules/MomsReviewsBanner'
import ArrivesTodayNotification from '../../components/molecules/ArrivesTodayNotification'

const ProductPage = ({ history, t }) => {
  const dispatch = useDispatch()

  const breadcrumbs = useRef()

  useEffect(() => {
    dispatch(Actions.Products.getDetailedProduct(+window.location.pathname.split('/')[2].split('-')[0]))
    dispatch(Actions.Checkout.getContextData())
    return () => {
      dispatch(Actions.Products.setDetailedProduct())
    }
  }, [])

  const isDiaper = item => {
    return item.product_type === 'diapers'
  }
  const isWipe = item => {
    return item.product_type === 'baby wipes'
  }
  const currentLanguage = useSelector(state => state.Language.current)
  const product = useSelector(state => state.Products.detailProduct)
  const { selectedDiapers, selectedWipes, upselling } = useSelector(state => state.Checkout)
  const cart = [...selectedDiapers, ...selectedWipes, ...upselling]
  const isAnyProductOnCart = selectedDiapers.length > 0 || selectedWipes.length > 0 || upselling.length > 0

  const [selectedVariant, setSelectedVariant] = useState()
  const [selectedAmount, setSelectedAmount] = useState()
  const [selectedVariantImage, setSelectedVariantImage] = useState('')
  const [gp, setGP] = useState({})

  const [alertOutStock, setAlertOutStock] = useState(false)
  const [outOfStock, setOutOfStock] = useState(product && product.variants[0].inventory_quantity <= 0)

  const handleAlertOutStock = boolean => {
    setAlertOutStock(boolean)
  }

  useEffect(() => {
    if (product) {
      const variant = product.variants[0]
      const imageVariant =
        product.images &&
        product.images.length > 0 &&
        product.images.find(img => variant && img.id === variant.image_id)
      setSelectedVariant(product.variants[0])
      setSelectedAmount(isDiaper(product) && product.presentationsBySize[variant.option1][0])
      setSelectedVariantImage(imageVariant)
      breadcrumbs.current = [
        {
          label: (history.location.state && history.location.state.collectionTitle) || `All categories`,
          link:
            (history.location.state && `/products/${history.location.state.collectionHandle}`) ||
            `/products/collections`
        },
        {
          label: `${product.title}`,
          link: `${window.location.pathname}`
        }
      ]
      if (selectedVariant) gtmViewItem(product, variant)
    }
  }, [product])

  useEffect(() => {
    if (product) {
      const imageVariant =
        product.images &&
        product.images.length > 0 &&
        product.images.find(img => selectedVariant && img.id === selectedVariant.image_id)

      setGP(
        selectedVariant &&
          createGPjson(product, isDiaper(product) ? selectedAmount : selectedVariant, imageVariant || null)
      )
    }
    setOutOfStock(
      selectedVariant && selectedVariant.inventory_quantity + productInEditionQty(selectedVariant, cart) <= 0
    )
  }, [product, selectedVariant, selectedAmount])

  useEffect(() => {
    setSelectedVariantImage(
      product &&
        product.images &&
        product.images.length > 0 &&
        product.images.find(img => selectedVariant && img.id === selectedVariant.image_id)
    )
  }, [selectedVariant])

  const renderDetail = () => {
    switch (product.product_type) {
      case 'diapers':
        return (
          <DiaperProductDetail
            diaper={product}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            setSelectedDiaperAmount={setSelectedAmount}
            currentLanguage={currentLanguage}
            handleAlertOutStock={handleAlertOutStock}
            alertOutStock={alertOutStock}
            outOfStock={outOfStock}
          />
        )
      case 'baby wipes':
        return (
          <WipeProductDetail
            wipe={product}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            currentLanguage={currentLanguage}
            handleAlertOutStock={handleAlertOutStock}
            alertOutStock={alertOutStock}
            outOfStock={outOfStock}
          />
        )

      default:
        return (
          <UpsellingProductDetail
            product={product}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            currentLanguage={currentLanguage}
            handleAlertOutStock={handleAlertOutStock}
            alertOutStock={alertOutStock}
            outOfStock={outOfStock}
          />
        )
    }
  }

  const ArrivesTodayFirstColumn = { xs: 12, sm: 1 }
  const ArrivesTodaySecondColumn = { xs: 12, sm: 11 }

  return (
    <>
      <Header t={t} showSideCartIcon bg history={history} showSearch showLogin />
      <BreadCrumbs crumbs={breadcrumbs.current} currentLanguage={currentLanguage} history={history} />
      {!product || !selectedVariant || (isDiaper(product) && !selectedAmount) ? (
        <Loader loading />
      ) : (
        <>
          <Grid container className="product-detail-page-content">
            <Grid item container className="product-detail-main-container">
              <Grid justifyContent="center" md={6}>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                  className={isDiaper(product) ? 'product-detail-page-img__diaper' : 'product-detail-page-img'}>
                  <img
                    src={selectedVariantImage ? selectedVariantImage.src : ImageCheck(product).src}
                    alt={t('default.info')}
                  />

                  {isDiaper(product) && (
                    <>
                      <div className="product-detail-page-img__diaper__icon">
                        <span>{selectedAmount.quantity}</span>
                        <span className="product-detail-page-img__diaper__icon__units">{t('default.units')}</span>
                      </div>
                    </>
                  )}
                </Grid>
                <Hidden smDown>
                  <div
                    className="info-details"
                    dangerouslySetInnerHTML={{
                      __html: product.body_html
                    }}
                  />
                </Hidden>
              </Grid>
              <Grid
                className="product-detail-main-container__info"
                item
                container
                direction="column"
                wrap="nowrap"
                xs={12}
                md={6}>
                <Typography variant="inherit" className="info-title">
                  {product && product.title}
                </Typography>
                <Typography variant="inherit" className="info-unit">
                  {isDiaper(product) ? `(${selectedAmount.quantity} ${t('default.units')})` : ''}
                  {isWipe(product) && `(${selectedVariant.option1} ${t('default.units')})`}
                </Typography>

                <Box mb={2} className="arrives-wrapper">
                  <ArrivesTodayNotification
                    t={t}
                    columnSizesIcon={ArrivesTodayFirstColumn}
                    columnSizesText={ArrivesTodaySecondColumn}
                  />
                </Box>

                <Grid item xs={12} lg={10} className="info-description">
                  {renderDetail()}
                  <YallaFeatures t={t} />
                  <SecurePay t={t} />
                </Grid>
                <Hidden mdUp>
                  <div
                    className="info-details"
                    dangerouslySetInnerHTML={{
                      __html: product.body_html
                    }}
                  />
                </Hidden>
              </Grid>
            </Grid>
            <script type="application/ld+json">{JSON.stringify(gp)}</script>
          </Grid>

          <MomsAlsoBought history={history} t={t} />
          <MomsReviewsBanner history={history} t={t} />
          <Footer t={t} currentLanguage={currentLanguage} history={history} />
        </>
      )}
      <Slide direction="left" timeout={800} mountOnEnter unmountOnExit in={isAnyProductOnCart}>
        <div className={`go-to-cart ${isAnyProductOnCart && 'shake-animation'} product-page`}>
          <GoToCartButton inProductPage history={history} t={t} />
        </div>
      </Slide>
    </>
  )
}
ProductPage.propTypes = propTypes
export default translate('common')(ProductPage)
