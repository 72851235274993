/* eslint-disable consistent-return */
import { Grid, Hidden, Typography, Link, Container } from '@material-ui/core'
import React from 'react'
import { translate } from 'react-i18next'
import propTypes from './props'
import Phone from '../../../assets/images/icons/phone-header.svg'
import { UPVOTY as UPVOTY_URLS } from '../../../utils/urls'

const TopBar = ({ t, setUpvotyPath, setOpenUpvoty }) => {
  const menu = [
    { name: t('default.mumsBlog'), url: 'https://yallababy.com/blog', isLink: true },
    { name: t('footer.ambassadors'), url: 'https://yallababy.com/ambassadors.html', isLink: true },
    { name: t('default.aboutUs'), url: 'https://yallababy.com/index.html', isLink: true },
    { name: t('default.faqs'), url: 'https://yallababy.com/index.html#faqs', isLink: true },
    { name: t('default.contactUs'), url: 'https://app.yallababy.com/contact-us', isLink: true },
    { name: t('header.reportAnIssue'), url: '#', isLink: false }
  ]

  const handleOnClick = isLink => {
    if (isLink) return false
    setUpvotyPath(UPVOTY_URLS.REPORT_AN_ISSUE)
    setOpenUpvoty(true)
  }

  return (
    <Container maxWidth="xl" className="topbar">
      <Grid container className="topbar__wrapper">
        <Grid item className="topbar__column topbar__column--phone">
          <img src={Phone} alt="Phone Number" />
          <Typography>{`Need help? Call us: ${t('footer.phone')}`}</Typography>
        </Grid>
        <Hidden smDown>
          <Grid item className="topbar__column topbar__column--nav">
            {menu.map(item => {
              const { name, url, isLink } = item

              return (
                <Link
                  key={item.name}
                  href={url}
                  target={isLink ? '_blank' : '_self'}
                  rel="noreferrer noopener"
                  onClick={() => handleOnClick(isLink, name)}>
                  {name}
                </Link>
              )
            })}
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  )
}

TopBar.propTypes = propTypes
export default translate('common')(TopBar)
