/* eslint-disable react/no-unknown-property */
/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
import React from 'react'
import MenuIcon from '../../../../assets/images/icons/menu-pink.svg'

const SvgMenu = props => (
  <button type="button" {...props} className="menu-icon">
    <img src={MenuIcon} alt="Menu" />
  </button>
)

export default SvgMenu
