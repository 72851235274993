import React from 'react'
import { Grid, ListItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import { UPVOTY as UPVOTY_URLS } from '../../../utils/urls'

const SideMenuItem = ({
  title,
  path,
  sideMenuType,
  handleClose,
  logged = true,
  ableSelected = false,
  history,
  handleOpenLoginSignin,
  setOpenAlertModal,
  setPathToPush,
  upvoty,
  setOpenUpvoty,
  setUpvotyPath,
  request
}) => {
  const dispatch = useDispatch()
  const setFilter = filterValue => dispatch(Actions.Products.filter(filterValue))
  const setFilters = newFilters => dispatch(Actions.Products.setFilters(newFilters))
  const setOneOffBox = type => dispatch(Actions.Checkout.setBoxTypePurchase(type))

  const isEdition = useSelector(state => state.Checkout.isEdition)
  return (
    <ListItem key={sideMenuType} className={ableSelected && window.location.pathname.includes(path) ? 'selected' : ''}>
      <button
        type="button"
        onClick={() => {
          if (upvoty) {
            if (!request) setUpvotyPath(UPVOTY_URLS.REPORT_AN_ISSUE)
            else setUpvotyPath(UPVOTY_URLS.PRODUCT_REQUEST)
            setOpenUpvoty(true)
            return
          }

          setFilter('')
          setFilters({ brands: [], quick: [], price: [] })
          if (logged) {
            if (path === '/boxes') setOneOffBox(null)
            if (isEdition && (path === '/boxes' || path === '/account')) {
              setOpenAlertModal(true)
              setPathToPush(path)
            } else {
              history.push(path)
              window.scrollTo(0, 0)
              handleClose()
            }
          } else if (handleOpenLoginSignin) handleOpenLoginSignin('login')
        }}
        className={`${logged ? 'side-menu-item' : 'side-menu-item-disabled'} ${upvoty && 'upvoty-button'}`}>
        <Grid item container alignItems="center">
          {title[0].toUpperCase() + title.substring(1)}
        </Grid>
      </button>
    </ListItem>
  )
}

SideMenuItem.propTypes = propTypes
export default SideMenuItem
