import React from 'react'

const Filter = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8718 0.5H0.878212C0.139931 0.5 -0.267569 1.33984 0.198056 
      1.90145L5.00001 7.55605V13.1562C5.00001 13.3856 5.11169 13.6007 5.30036 13.7321L7.64403 15.3723C8.10638 15.6959 8.75001 15.3682 8.75001 14.7963V7.55605L13.552 1.90145C14.0176 1.33984 13.61 0.5 12.8718 0.5Z"
      fill="#B5889B"
    />
  </svg>
)

export default Filter
