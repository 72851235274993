/* eslint-disable max-len */
import React from 'react'

const UserIcon = () => (
  <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 12C13.8141 12 16.5 9.31359 16.5 6C16.5 2.68641 13.8141 0 10.5 0C7.18594 0 4.5 2.68641 4.5 6C4.5 9.31359 7.18594 12 10.5 12ZM12.8766 14.25H8.12344C3.63797 14.25 0 17.8875 0 22.3734C0 23.2706 0.7275 23.9986 1.62469 23.9986H19.3762C20.2734 24 21 23.2734 21 22.3734C21 17.8875 17.3625 14.25 12.8766 14.25Z"
      fill="#B5889B"
    />
  </svg>
)

export default UserIcon
