import React from 'react'
import { Grid, Typography, Link, Box, Container, useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import propTypes from './props'
import Facebook from '../../../assets/images/facebook.svg'
import Instagram from '../../../assets/images/instagram.svg'
import Phone from '../../../assets/images/icons/phone1.svg'
import Whatsapp from '../../../assets/images/icons/whatsapp1.svg'
import Email from '../../../assets/images/icons/email1.svg'
import FooterLogo from '../../../assets/images/footer-logo.svg'
import EurekaLabs from '../../../assets/images/eurekalabs.png'
import LogoAr from '../../../assets/images/footer-logo-arabic.svg'
import SecurePay from '../SecurePay'
import YallaFeaturesBanner from '../YallaFeaturesBanner'
import FooterRegular from '../FooterRegular'
import FooterMobile from '../FooterMobile'
import Copyright from '../Copyright'

const Footer = ({ t, currentLanguage, history }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const menuItems = useSelector(state => state.Checkout.menuItems) || []

  const mainMenu = menuItems.map(item => ({ name: item.title, path: item.handle }))

  const legal = [
    { name: t('footer.deliveryPolicy'), url: null },
    { name: t('footer.cancellationPolicy'), url: null },
    { name: t('footer.ambassadors'), url: 'https://yallababy.com/ambassadors.html' },
    { name: t('footer.giftABox'), url: 'https://yallababy.com/gifts/' }
  ]

  const siteMap = [
    { name: t('default.home'), path: '/', link: false },
    { name: t('default.mumsBlog'), path: 'https://yallababy.com/blog', link: true },
    { name: t('default.aboutUs'), path: '/', link: false },
    { name: t('default.faqs'), path: 'https://yallababy.com/#faq', link: true },
    { name: t('default.contactUs'), path: '/contact-us', link: false }
  ]

  const facebookLink = 'https://facebook.com/yallababybox'
  const instagramLinks = {
    EN: 'https://instagram.com/yalla.baby?igshid=lk8si3qcouo7',
    AR: 'https://instagram.com/yalla.baby.ar?igshid=ahkithrdboaw'
  }

  const firstColumn = (
    <Grid item className="footer__column logo">
      <Box className="wrapper-column">
        <img src={currentLanguage === 'AR' ? LogoAr : FooterLogo} alt="Logo Footer" />
        <Box className="logo__address" pt={2}>
          <Typography>
            <span>{t('footer.address1')}</span>
            <span>{t('footer.address2')}</span>
            <span>{t('footer.address3')}</span>
          </Typography>
        </Box>
        <Box className="logo__social-media">
          <Link href={facebookLink} target="_blank" rel="noopener noreferrer">
            <img src={Facebook} alt={t('default.facebook')} />
          </Link>
          <Link href={instagramLinks[currentLanguage]} target="_blank" rel="noopener noreferrer">
            <img src={Instagram} alt={t('default.instagram')} />
          </Link>
        </Box>
      </Box>
    </Grid>
  )

  const whatsappHref = `https://wa.me/${t('footer.whatsapp').substring(1).replace(/ /g, '')}`
  const phoneHref = `tel:${t('footer.phone').replace(/ /g, '')}`

  return (
    <>
      <YallaFeaturesBanner t={t} />
      <footer>
        <Container>
          <Grid container className="footer">
            {isMobile ? (
              <FooterMobile
                t={t}
                firstColumn={firstColumn}
                siteMap={siteMap}
                history={history}
                mainMenu={mainMenu}
                legal={legal}
                Phone={Phone}
                Whatsapp={Whatsapp}
                Email={Email}
                whatsappHref={whatsappHref}
                phoneHref={phoneHref}
              />
            ) : (
              <FooterRegular
                firstColumn={firstColumn}
                t={t}
                currentLanguage={currentLanguage}
                LogoAr={LogoAr}
                FooterLogo={FooterLogo}
                Facebook={Facebook}
                Instagram={Instagram}
                history={history}
                Phone={Phone}
                Footer={Footer}
                Whatsapp={Whatsapp}
                Email={Email}
                mainMenu={mainMenu}
                legal={legal}
                siteMap={siteMap}
                whatsappHref={whatsappHref}
                phoneHref={phoneHref}
              />
            )}
          </Grid>
        </Container>
      </footer>
      <Container>
        <SecurePay inFooter t={t} />
      </Container>
      <Copyright t={t} EurekaLabs={EurekaLabs} />
    </>
  )
}

Footer.propTypes = propTypes
export default Footer
