/* eslint-disable react/no-unknown-property */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, Chip, Collapse } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import TagManager from 'react-gtm-module'
import propTypes from './props'
import Actions from '../../../actions'
import LoginSigninContainer from '../../../containers/LoginSigninContainer'
import horizontalLoader from '../../../assets/images/loader2.gif'

const InputPromoCode = ({
  t,
  promoCode,
  promoCodeSubscription,
  history,
  cart,
  totalPriceWithSubscription,
  discount,
  handleSaveDisabled
}) => {
  const { logged, userData, token } = useSelector(state => state.Users)
  const currentLanguage = useSelector(state => state.Language.current)

  const dispatch = useDispatch()
  const applyPromoCode = code => dispatch(Actions.Checkout.applyPromoCode(code))
  const removePromoCode = () => dispatch(Actions.Checkout.removePromoCode())
  const setPromoCode = promoCodeToSet => dispatch(Actions.Checkout.setPromoCode(promoCodeToSet))
  const resetErrorRegister = () => dispatch(Actions.Users.resetErrorRegister())

  const [inputPromoCodeValue, setInputPromoCodeValue] = useState(promoCode && promoCode.title ? promoCode.title : false)
  const [openLoginSignin, setOpenLoginSignin] = useState(false)
  const [formType, setFormType] = useState('login')
  const [showAlertText, setShowAlertText] = useState(false)
  const [loadingCode, setLoadingCode] = useState(false)

  const handleOpenLoginSignin = type => {
    setFormType(type)
    setOpenLoginSignin(true)
  }

  const handleCloseLoginSignin = () => {
    resetErrorRegister()
    setOpenLoginSignin(false)
  }

  useEffect(() => {
    if (logged && promoCode && promoCode.error && promoCode.appCode === 302) {
      applyPromoCode(promoCode.title)
    }
  }, [token])

  useEffect(() => {
    if (promoCode && !promoCode.error) {
      setInputPromoCodeValue(false)
      setLoadingCode(false)
    }
    if (promoCode && promoCode.appCode) setLoadingCode(false)
  }, [promoCode])

  useEffect(() => {
    if (promoCode && promoCode.error) {
      applyPromoCode('')
    }
  }, [])

  const loyaltyCoupons = ['LOYALTY3', 'LOYALTY4', 'LOYALTY5', 'LOYALTY6', 'LOYALTY7', 'LOYALTY8']
  const isLoyalty =
    (inputPromoCodeValue &&
      inputPromoCodeValue !== '' &&
      loyaltyCoupons.find(code => inputPromoCodeValue.toUpperCase() === code)) ||
    (promoCode && promoCode.title && loyaltyCoupons.find(code => promoCode.title.toUpperCase() === code))

  const isInflu =
    (inputPromoCodeValue && inputPromoCodeValue !== '' && inputPromoCodeValue.toUpperCase().includes('INFLU')) ||
    (promoCode && promoCode.title && promoCode.title.toUpperCase().includes('INFLU'))

  const isGift =
    (inputPromoCodeValue && inputPromoCodeValue !== '' && inputPromoCodeValue.toUpperCase().includes('GIFT')) ||
    (promoCode && promoCode.title && promoCode.title.toUpperCase().includes('GIFT'))

  const priceLimit = totalPriceWithSubscription >= (isLoyalty ? 650 : 150)

  const shouldApplyPromoCode = isGift || isInflu || priceLimit

  useEffect(() => {
    if (!isNaN(totalPriceWithSubscription) && !shouldApplyPromoCode && promoCode) {
      removePromoCode()
    }
  }, [totalPriceWithSubscription])

  const getTextErrorMessage = () => {
    return (
      <Typography variant="inherit" className="promo-code__error">
        {isLoyalty && !priceLimit
          ? t('cart.inputPromoCode.minimumOrderLimitLoyalty')
          : t('cart.inputPromoCode.minimumOrderLimit')}
      </Typography>
    )
  }

  const getErrorMessage = handleOpen => {
    let message = ''

    if (promoCode && promoCode.error) {
      const errorCode = promoCode.appCode

      switch (errorCode) {
        case 300:
          message = 'cart.inputPromoCode.errorMessage1'
          break

        case 303:
          message = 'cart.inputPromoCode.errorMessage2'
          break

        case 301:
          message = 'cart.inputPromoCode.errorMessage3'
          break

        case 304:
          message = 'cart.inputPromoCode.errorMessage3'
          break

        case 305:
          message = 'cart.inputPromoCode.errorMessage5'
          break

        case 306:
          message = 'cart.inputPromoCode.errorMessage1'
          break

        case 302:
          return (
            cart && (
              <Typography variant="inherit" className="promo-code__errorLogin">
                {t('cart.inputPromoCode.errorMessage4Part1')}
                <button type="button" className="promo-code__errorLoginButton" onClick={() => handleOpen('login')}>
                  {t('cart.inputPromoCode.login')}
                </button>
                {t('cart.inputPromoCode.errorMessage4Part2')}
              </Typography>
            )
          )

        default:
          break
      }
    }
    return (
      <Typography variant="inherit" className={promoCode && promoCode.error ? 'promo-code__error' : ''}>
        {t(message)}
      </Typography>
    )
  }

  const handleApplyClick = inputValue => {
    if (shouldApplyPromoCode) {
      setLoadingCode(true)
      if (promoCodeSubscription && promoCodeSubscription.title === inputValue) {
        if (handleSaveDisabled) handleSaveDisabled(true)
        setPromoCode(promoCodeSubscription)
      } else {
        applyPromoCode(inputValue)
        if (handleSaveDisabled) handleSaveDisabled(false)
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'applyPromo',
          promoCode: inputValue,
          userId: userData && userData.id
        },
        dataLayerName: 'YallaBaby'
      })
    } else {
      setShowAlertText(true)
    }
  }

  const getNoProductMessage = () => {
    let message = ''
    if (
      promoCode &&
      !promoCode.error &&
      (promoCode.productsId.length > 0 || promoCode.variantsId.length > 0) &&
      +discount === 0
    ) {
      message = t('cart.inputPromoCode.noProductsMessage')
    }
    return (
      <Typography variant="inherit" className={'promo-code__error'}>
        {t(message)}
      </Typography>
    )
  }

  return (
    <Fragment>
      <Grid
        container
        item
        justify="center"
        className={`promo-code ${currentLanguage === 'AR' ? 'promo-code__ar' : ''}`}>
        <Grid container item lg={8} xs={8} justify="flex-start" alignItems="center">
          <input
            id="inputPromoCodeValue"
            type="text"
            placeholder={t('cart.inputPromoCode.placeholder')}
            margin="normal"
            className="promo-code__input"
            value={inputPromoCodeValue || ''}
            onChange={evt => setInputPromoCodeValue(evt.target.value)}
          />
        </Grid>
        <Grid container item lg={4} xs={4} justify="flex-end" alignItems="center">
          <button
            id="applyPromo_button"
            type="button"
            disabled={!inputPromoCodeValue || inputPromoCodeValue.trim() === '' || (promoCode && !promoCode.error)}
            className={`promo-code__button ${
              (promoCode && !promoCode.error) || !inputPromoCodeValue || inputPromoCodeValue.trim() === ''
                ? 'disabled'
                : ''
            }`}
            onClick={() => handleApplyClick(inputPromoCodeValue.toUpperCase())}>
            {t('cart.inputPromoCode.applyButton')}
          </button>
        </Grid>
        {promoCode && !promoCode.error && (
          <Grid container item className="promo-code-chip" xs={12} lg={12}>
            <Chip
              className="custom-chip"
              label={promoCode.title}
              onDelete={() => {
                removePromoCode()
                if (handleSaveDisabled) handleSaveDisabled(false)
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container item justify="center" className="promo-code">
        <Grid container item justify="center">
          <Collapse in={loadingCode}>
            <img src={horizontalLoader} timeout={300} alt="Loading..." />
          </Collapse>
          {shouldApplyPromoCode ? getErrorMessage(handleOpenLoginSignin) : showAlertText && getTextErrorMessage()}
        </Grid>
      </Grid>
      <Grid container item justify="center" className="promo-code">
        <Grid container item justify="center">
          {getNoProductMessage()}
        </Grid>
      </Grid>
      <LoginSigninContainer
        openModal={openLoginSignin}
        handleClose={handleCloseLoginSignin}
        typeLoginSignin={formType}
        setTypeLoginSignin={setFormType}
        showFormFooter
        t={t}
        history={history}
        language={currentLanguage}
      />
    </Fragment>
  )
}

InputPromoCode.propTypes = propTypes
export default translate('common')(InputPromoCode)
