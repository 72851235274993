/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import ProgressBar from '../../atoms/ProgressBar'
import propTypes from './props'
import bgAmount from '../../../assets/images/bg-amount.svg'
import { getFormatedAmount } from '../../../utils/helpers'

const FreeDeliveryBar = ({ t, amountToFreeShipping, isPriceUnderLimit }) => {
  const freeShippingMinimumAmount = 150
  const getFreeDeliveryMsg = underLimit => {
    return (
      <>
        {underLimit ? (
          <>
            {t('cart.freeDeliveryText1')}
            <span className="free-delivery-bar__amount" style={{ backgroundImage: `url(${bgAmount})` }}>
              {getFormatedAmount(amountToFreeShipping)}
            </span>
            {t('cart.freeDeliveryText2')}
            <span className="free-delivery-bar__text--green">{t('cart.freeDeliveryText3')}</span>
          </>
        ) : (
          <span className="price-detail__free-shipping">{t('cart.freeShipping')}</span>
        )}
      </>
    )
  }

  return (
    <Grid container className="free-delivery-bar">
      <Typography className="free-delivery-bar__text">{getFreeDeliveryMsg(isPriceUnderLimit)}</Typography>
      <ProgressBar freeShippingMinimumAmount={freeShippingMinimumAmount} amountToFreeShipping={amountToFreeShipping} />
    </Grid>
  )
}
FreeDeliveryBar.propTypes = propTypes
export default FreeDeliveryBar
