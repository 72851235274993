/* eslint-disable react/button-has-type */
import React, { Fragment } from 'react'
import propTypes from './props'
import IconComponent from '../../atoms/IconComponent'
import Loader from '../../../assets/images/loader3.gif'

const FlatButton = ({
  title,
  icon,
  fill,
  bgColor,
  onClick,
  disabled,
  type,
  classes,
  idButton,
  payLoader,
  cartIcon,
  iconClass,
  customIcon
}) => (
  <Fragment>
    <button
      id={idButton || ''}
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled}
      className={bgColor ? `${classes} flat-button--${bgColor}` : `flat-button ${classes}`}>
      {<IconComponent fill={fill} icon={icon} />}
      <span>{payLoader ? <img width="51px" src={Loader} alt="Loading..." /> : title}</span>
      {cartIcon && <IconComponent className={iconClass} fill={fill} icon={cartIcon} />}
      {customIcon && customIcon}
    </button>
  </Fragment>
)

FlatButton.propTypes = propTypes
export default FlatButton
