/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Box } from '@material-ui/core'
import propTypes from './props'
import IconComponent from '../IconComponent'

const SearchButton = ({ setInputSearchVisible }) => {
  const handleClick = () => {
    setInputSearchVisible(prevState => !prevState)
  }

  return (
    <Box className="login-button">
      <a href="#" onClick={handleClick}>
        <IconComponent icon="searchJaka" />
      </a>
    </Box>
  )
}

SearchButton.propTypes = propTypes
export default SearchButton
