/* eslint-disable max-len */
import React from 'react'
// Tools
import propTypes from './props'

const Box = ({ fill = '#000', stroke = 'none', strokeWidth = 1, height = '1rem' }) => (
  <svg height={height} viewBox="0 0 22 16" cursor="pointer">
    <defs>
      <path
        id="box_svg__a"
        d="M19.145 6.627h-.018l-4.096-.055L11.584.7l7.469 2.264 2.223 3.7-2.13-.037zm-.278.534v4.713l-7.635 2.982V1.14l3.41 5.817c.037.074.13.13.222.13l4.003.073zm-15.808 0l4.095-.055a.257.257 0 0 0 .223-.13l3.317-5.669v13.549L3.06 11.874V7.16zm-.093-4.197l.056-.019L10.453.681l-3.465 5.89-4.189.074h-.018l-2.039.037 2.224-3.718zm16.494-.35a.288.288 0 0 0-.148-.11L11.102 0H10.935c-.018 0-.037 0-.055.018L2.707 2.485c-.038.019-.056.019-.075.037a.254.254 0 0 0-.055.074L.037 6.793a.267.267 0 0 0 .093.368c.038.037.093.037.149.037L2.52 7.16v4.897c0 .11.056.202.167.239l8.154 3.185h.019a.168.168 0 0 0 .093.018h.074c.018 0 .018 0 .037-.018l8.154-3.185a.254.254 0 0 0 .167-.24V7.162l2.335.037c.056 0 .111 0 .149-.037a.267.267 0 0 0 .092-.368L19.46 2.614z"
      />
    </defs>
    <g stroke={stroke} strokeWidth={strokeWidth}>
      <use fill={fill} fillRule="nonzero" xlinkHref="#box_svg__a" />
    </g>
  </svg>
)

Box.propTypes = propTypes

export default Box
