import React from 'react'

const PinkArrow = () => (
  <svg width="17px" height="15px" viewBox="0 0 17 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Flow-Transition-MOB" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g id="10--Filters-Copy" transform="translate(-20.000000, -76.000000)" stroke="#C38A9F" strokeWidth="3">
        <g
          id="Group"
          transform="translate(28.000000, 84.000000) rotate(-270.000000) translate(-28.000000, -84.000000) translate(22.000000, 77.000000)">
          <path
            d="M5.8,0 L5.8,12"
            id="Path-Copy"
            transform="translate(5.800000, 6.000000) rotate(-180.000000) translate(-5.800000, -6.000000) "
          />
          <polyline
            id="Path-2-Copy"
            strokeLinejoin="round"
            transform="translate(5.800000, 10.500000) rotate(-180.000000) translate(-5.800000, -10.500000) "
            points="0.3 13.5 5.99127414 7.5 11.3 13.5"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default PinkArrow
