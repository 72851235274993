import React from 'react'
import { Grid, FormControl, Select, MenuItem } from '@material-ui/core'
import UnfoldMore from '@material-ui/icons/UnfoldMore'
import propTypes from './props'

const OrderProductsBy = ({ orderBy, setOrderBy }) => {
  const onChangeSelect = e => {
    setOrderBy(e.target.value)
  }

  return (
    <Grid className="order-products">
      <span>Sort by</span>
      <FormControl className="order-products__form">
        <Select
          variant="standard"
          className="order-products__select"
          id="order-products-by-select"
          value={orderBy}
          onChange={e => onChangeSelect(e)}
          IconComponent={() => <UnfoldMore className="order-products__select__icon" />}>
          <MenuItem value={'bestSellers'}>BestSellers First</MenuItem>
          <MenuItem value={'lowPrice'}>Low to High</MenuItem>
          <MenuItem value={'highPrice'}>High to Low</MenuItem>
          <MenuItem value={'newest'}>Newest Arrivals</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )
}

OrderProductsBy.propTypes = propTypes
export default OrderProductsBy
