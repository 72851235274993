import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { translate } from 'react-i18next'
import { Grid, Snackbar, Hidden } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles'
import i18next from 'i18next'
import propTypes from './props'
import Header from '../../components/organisms/Header'
import Footer from '../../components/molecules/Footer'
import FlatButton from '../../components/molecules/FlatButton'
import Actions from '../../actions'

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#54CE8A'
      }
    }
  }
})

const ContactUs = ({ history, t, i18n, match }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const currentLanguage = useSelector(state => (state.Language.current === 'AR' ? 'Arabic' : 'English'))
  const [values, setValues] = React.useState({
    event: 'Contact us',
    userName: '',
    email: '',
    message: ''
  })

  const dispatch = useDispatch()

  const changeLanguage = language => dispatch(Actions.Language.changeLanguage(language))
  const submit = contactInfo => dispatch(Actions.Checkout.klaviyoTrack(contactInfo))

  useEffect(() => {
    if (match.params.ar) {
      i18next.changeLanguage('ar')
      changeLanguage('AR')
    } else {
      i18next.changeLanguage('en')
      changeLanguage('EN')
    }
  }, [])

  const handleChange = input => event => {
    const data = { ...values }
    data[input] = event.target.value.substring(0, 1000)
    setValues(data)
  }

  const handleSubmit = () => {
    submit(values)
    setOpen(true)
    setTimeout(() => history.push('/'), 3000)
  }

  return (
    <>
      <Header t={t} bg i18n={i18n} history={history} showSideCartIcon />

      <Grid container item justify="center" className="contact-us-container">
        <Grid container item xs={12} md={7} lg={4} className="contact-us">
          <Grid item container className="contact-us__title">
            <span>{t('default.contactUs')}</span>
          </Grid>

          <ValidatorForm className="contact-us__form" onSubmit={() => handleSubmit()}>
            <Grid container item xs={12} className="contact-us__field">
              <TextValidator
                id="userName"
                className={classes.root}
                variant="outlined"
                name="userName"
                label={`${t('contactUs.name')}`}
                type="text"
                value={values.userName}
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={handleChange('userName')}
              />
            </Grid>
            <Grid container item xs={12} className="contact-us__field">
              <TextValidator
                id="email"
                className={classes.root}
                variant="outlined"
                name="email"
                label={`${t('contactUs.email')}`}
                type="email"
                value={values.email}
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'email is not valid']}
                onChange={handleChange('email')}
              />
            </Grid>
            <Grid container item xs={12} className="contact-us__message-field">
              <TextValidator
                id="message"
                name="message"
                className={classes.root}
                variant="outlined"
                label={`${t('contactUs.message')}`}
                type="text"
                multiline
                rows={12}
                rowsMax={12}
                value={values.message}
                validators={['required']}
                errorMessages={['This field is required']}
                onChange={handleChange('message')}
              />
            </Grid>

            <Grid container justify="center">
              <Grid item xs={6} container justify="center">
                <FlatButton
                  idButton="checkout_shipping_nextButton"
                  classes="information-steps__next-button"
                  type="submit"
                  bgColor="green"
                  title={t('contactUs.sendMessage')}
                />
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          variant="success"
          open={open}
          autoHideDuration={3000}
          className="notify--success"
          onClose={() => setOpen(false)}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message={<span id="message-id">{t('contactUs.successMessage')}</span>}
        />
      </Grid>

      <Hidden smDown>
        <Footer t={t} currentLanguage={currentLanguage} history={history} />
      </Hidden>
      <div id="background" />
    </>
  )
}

ContactUs.propTypes = propTypes
export default translate('common')(ContactUs)
