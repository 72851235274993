/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { translate } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Actions from '../../../actions'
import propTypes from './props'
import {
  ImageCheck,
  haveOfferTag,
  formatMoney,
  gtmSingleProduct,
  getAddedAmount,
  gtmSelectItem,
  productInEditionQty
} from '../../../utils/helpers'
import OkIcon from '../../../assets/images/YB-ok-green-icon.svg'
import SpecialPriceLabel from '../../../assets/images/special-price-label.svg'
import QuantityModifier from '../../molecules/QuantityModifier'
import FlatButton from '../../molecules/FlatButton'
import ArrivesTodayLabel from '../../atoms/ArrivesTodayLabel'
import OutOfStockLabel from '../../atoms/OutOfStockLabel'

const DiaperCard = ({
  t,
  key,
  cart,
  diaper,
  currentLanguage,
  getDetailPageURI,
  closeModalUpgrade,
  diaperToReplace,
  upgrade
}) => {
  const { title, images, variants, options, presentationsBySize } = diaper
  const { inStock } = useSelector(state => state.Products.filters)
  const { userData } = useSelector(state => state.Users)

  const [showOk, setShowOk] = useState(false)

  const [selectedVariant, setSelectedVariant] = useState(
    (diaperToReplace && variants.find(variant => variant.option1 === diaperToReplace.product_size)) || variants[0]
  )
  const presentationOptions = !inStock
    ? presentationsBySize[selectedVariant.option1]
    : presentationsBySize[variants[0].option1]
  const [selectedAmount, setSelectedAmount] = useState(presentationOptions[0])
  const sizeRanges = diaper.options.find(opt => opt.name === 'Weight').values
  const { price, compare_at_price, appliedDiscount } = selectedVariant
  const [alertOutStock, setAlertOutStock] = useState(false)
  const [outOfStock, setOutOfStock] = useState(selectedVariant.inventory_quantity <= 0)

  const handleAlertOutStock = boolean => {
    setAlertOutStock(boolean)
  }
  useEffect(() => {
    const variant = variants.find(
      item => item.option2 === selectedAmount.quantity && item.option3 === selectedAmount.weight
    )
    setSelectedVariant(variant)
  }, [selectedAmount, selectedVariant])

  useEffect(() => {
    setOutOfStock(selectedVariant.inventory_quantity + productInEditionQty(selectedVariant, cart) <= 0)
  }, [diaper, selectedVariant])

  const selectVariant = size => {
    const variant = variants.find(item => item.option1 === size)
    setSelectedVariant(variant)
    setSelectedAmount(presentationsBySize[size][0])
  }

  const dispatch = useDispatch()

  const addDiaper = data => {
    dispatch(Actions.Checkout.addDiaper(data))
  }
  const removeDiaper = (index, variant_id, quantity, subscription) => {
    dispatch(
      Actions.Checkout.gtmRemoveProducts({ ...diaper, variant_id: selectedVariant.id, purchase_type: subscription })
    )
    dispatch(Actions.Checkout.removeDiaper(index, variant_id, quantity))
  }

  const selectedVariantImage =
    images && images.length > 0 && images.find(img => selectedVariant && img.id === selectedVariant.image_id)

  const offer = haveOfferTag(diaper)

  const handleQuantityChange = type => {
    const purchaseType = cart.find(
      item => item.variant_id === selectedVariant.id || +item.shopifyVariantId === selectedVariant.id
    ).purchase_type
    const bubblesAnimation = document.getElementById('cart-button')
    switch (type) {
      case 'add':
        addDiaper({
          ...diaper,
          variant_id: selectedVariant.id,
          purchase_type: purchaseType || 'ONE_TIME',
          price,
          product_size: selectedVariant.option1,
          sku: selectedVariant.sku
        })
        bubblesAnimation.classList.remove('animate')
        bubblesAnimation.classList.add('animate')
        setTimeout(() => {
          setShowOk(false)
          bubblesAnimation.classList.remove('animate')
        }, 500)
        setShowOk(true)
        break

      case 'remove':
        for (let index = cart.length - 1; index >= 0; index -= 1) {
          if (+cart[index].variant_id === selectedVariant.id || +cart[index].shopifyVariantId === selectedVariant.id) {
            removeDiaper(index, null, null, purchaseType || 'ONE_TIME')
            break
          }
        }
        break

      default:
        break
    }
  }

  const handleClickReplace = () => {
    removeDiaper(null, +diaperToReplace.shopifyVariantId)

    addDiaper({
      ...diaper,
      variant_id: selectedVariant.id,
      shopifyVariantId: selectedVariant.id,
      listPrice: +price,
      netPrice: +compare_at_price,
      purchase_type: 'SUBSCRIPTION',
      price,
      product_size: selectedVariant.option1,
      size: selectedVariant.option1,
      sku: selectedVariant.sku
    })

    closeModalUpgrade()
  }

  const getSelectedVariantQuantity = variant => {
    let q = 0
    if (diaperToReplace) {
      cart.forEach(up => {
        if (+diaperToReplace.shopifyVariantId === +up.variant_id || +diaperToReplace.variant_id === +up.variant_id) {
          q += 1
        }
      })
    } else {
      cart.forEach(up => {
        if (+up.variant_id === variant.id || +up.shopifyVariantId === variant.id) {
          q += 1
        }
      })
    }
    return q
  }

  const selectedVariantQuantity = getSelectedVariantQuantity(selectedVariant)

  const pushToPdp = () => {
    gtmSelectItem(diaper, selectedVariant)
    getDetailPageURI(diaper.id, title)
  }

  const renderSizeOptions = (size, index) => {
    return (
      <button
        type="button"
        key={`${options.product_id}-${size}`}
        className={`filter__item__variants__button${
          (selectedVariant && size === selectedVariant.option1) ||
          (getSelectedVariantQuantity(selectedVariant) > 0 && size === selectedVariant.option1)
            ? '__selected'
            : ''
        }`}
        onClick={() => {
          selectVariant(size)
        }}>
        <Grid container item direction="column">
          <span>
            {size !== 'NB' ? 'Size ' : ''}
            <strong>{`${size} `}</strong>
          </span>
          <span>{sizeRanges[index]}</span>
        </Grid>
      </button>
    )
  }

  const renderSeeMore = index => {
    const isSelected = selectedVariant && options[0].values.indexOf(selectedVariant.option1) >= 5
    return index === 5 ? (
      <button
        type="button"
        key={`${options.product_id}-see-more`}
        className={`filter__item__variants__button${isSelected ? '__selected' : ''}`}
        onClick={() => pushToPdp()}>
        <Grid container item direction="column">
          <span>
            {t('default.see')}
            <strong>{' + '}</strong>
          </span>
          <span>{t('default.more')}</span>
        </Grid>
      </button>
    ) : null
  }

  const getSizeButtons = () => {
    if (diaperToReplace) {
      return options[0].values.map(
        (size, index) => options[0].values[index] && upgrade.includes(size) && renderSizeOptions(size, index)
      )
    }
    return options[0].values.map((size, index) =>
      index < 6 && options[0].values[index] ? renderSizeOptions(size, index) : renderSeeMore(index)
    )
  }

  const getBundleButtons = () => {
    const moreThanThree = presentationOptions.length > 2
    const bundles = moreThanThree ? presentationOptions.slice(0, 2) : presentationOptions
    return (
      <>
        {bundles.map(item => (
          <button
            type="button"
            key={item.quantity}
            className={`filter__item__variants__variant-chip${
              item.quantity === selectedAmount.quantity ? '__selected' : ''
            }`}
            onClick={() => {
              setSelectedAmount(item)
            }}>
            <strong>{`${item.quantity} u.`}</strong>
          </button>
        ))}
        {moreThanThree && (
          <button
            type="button"
            key={`${options.product_id}-see-more`}
            className={`filter__item__variants__button`}
            onClick={() => pushToPdp()}>
            <Grid container item direction="column">
              <span>
                {t('default.see')}
                <strong>{' + '}</strong>
              </span>
              <span>{t('default.more')}</span>
            </Grid>
          </button>
        )}
      </>
    )
  }

  return (
    <Grid
      item
      container
      xs={2}
      sm={3}
      justify="center"
      className={`filter__item${diaperToReplace && diaper.id === diaperToReplace.id ? '-upgrade' : ''}`}
      key={key}
      onClick={() =>
        gtmSingleProduct(diaper, compare_at_price, selectedVariant.sku, userData, { list: 'diapers' }, 'productClick')
      }>
      {showOk && (
        <div className="filter__item__okey">
          <img src={OkIcon} alt="Ok" />
        </div>
      )}
      {offer && (
        <>
          <div className="filter__item__offer">
            <img src={SpecialPriceLabel} alt="offer" />
          </div>
          <Grid container item xs={6} direction="row" className="filter__item__special-price-text">
            <Typography variant="inherit">
              <span>{t('default.specialPrice')}</span>
            </Typography>
          </Grid>
        </>
      )}
      {diaperToReplace && diaper.id === diaperToReplace.id && (
        <Grid container item className="filter__item-upgrade__current-diaper">
          <Typography variant="inherit">{t('cart.upgradeSize.currentDiaper')}</Typography>
        </Grid>
      )}
      <Grid item container xs={12} lg={12} className="filter__item__title">
        {alertOutStock && <OutOfStockLabel inventoryQuantity={selectedVariant.inventory_quantity} t={t} />}
        <Grid item container>
          <ArrivesTodayLabel t={t} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="center"
          direction="row"
          alignItems="center"
          onClick={() => getDetailPageURI && pushToPdp()}
          className={`filter__item__image${getDetailPageURI ? '-product-detail' : ''}`}>
          <img src={selectedVariantImage ? selectedVariantImage.src : ImageCheck(diaper).src} alt={diaper.title} />
        </Grid>
        <Grid container item xs={12} className="filter__item__desc" direction="column">
          <Typography variant="inherit">
            {`${title} X`}
            <span style={{ color: selectedVariantQuantity > 0 && '#639175' }}>
              {` ${selectedAmount.quantity * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)} units`}
            </span>
          </Typography>
        </Grid>
        <Grid container item xs={12} className="filter__item__variants__amounts" justify="center">
          {selectedVariant && getBundleButtons()}
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={`filter__item__variants diaper-variants${diaperToReplace ? '-upgrade' : ''}`}
        direction="row">
        {options && getSizeButtons()}
      </Grid>
      <Grid container item xs={12} className="filter__item__info" justify="flex-start">
        <Grid
          container
          item
          className={`filter__item__info__amount-card ${currentLanguage === 'AR' ? 'inverted' : ''}`}>
          {compare_at_price > 0 && (
            <Typography className={`regular-price ${currentLanguage === 'AR' ? 'inverted' : ''}`} variant="inherit">
              {`${t('default.aed')} ${formatMoney(
                price * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
              )}`}
            </Typography>
          )}
          <Grid alignItems="center">
            <Typography variant="inherit" className="subscription-price">
              {`${t('default.aed')} ${formatMoney(getAddedAmount(compare_at_price, price, selectedVariantQuantity))}`}
            </Typography>
            {appliedDiscount > 0 && (
              <Typography variant="inherit" className="subscription-text">
                <strong>
                  {`${t('default.aed')} ${formatMoney(
                    appliedDiscount * (selectedVariantQuantity === 0 ? 1 : selectedVariantQuantity)
                  )}
                  ${t('default.off')} `}
                </strong>
              </Typography>
            )}
          </Grid>
        </Grid>

        {diaperToReplace ? (
          <Grid item container justify="center">
            <FlatButton
              idButton="replace-diaper"
              classes={`filter__item__info__add-button`}
              onClick={() => handleClickReplace()}
              title={t(`cart.upgradeSize.replace`)}
            />
          </Grid>
        ) : (
          <Grid item container justify="center">
            {selectedVariantQuantity === 0 ? (
              <FlatButton
                idButton="add-diaper"
                cartIcon={outOfStock ? '' : 'cart2'}
                iconClass="cart-icon-2"
                classes={`filter__item__info__add-button ${outOfStock ? 'out-of-stock' : ''}`}
                onClick={() => {
                  if (!outOfStock) {
                    addDiaper({
                      ...diaper,
                      variant_id: selectedVariant.id,
                      purchase_type: 'ONE_TIME',
                      price,
                      product_size: selectedVariant.option1,
                      sku: selectedVariant.sku
                    })
                    const bubblesAnimation = document.getElementById('cart-button')
                    bubblesAnimation.classList.remove('animate')
                    bubblesAnimation.classList.add('animate')
                    setTimeout(() => {
                      setShowOk(false)
                      bubblesAnimation.classList.remove('animate')
                    }, 500)
                    setShowOk(true)
                  }
                }}
                title={outOfStock ? t('default.outOfStock') : t('default.addToBundle')}
              />
            ) : (
              <QuantityModifier
                quantity={selectedVariantQuantity}
                setQuantity={handleQuantityChange}
                variantSelected={selectedVariant}
                handleAlertOutStock={handleAlertOutStock}
                alertOutStock={alertOutStock}
                stockControl
                cart={cart}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

DiaperCard.propTypes = propTypes

export default translate('common')(DiaperCard)
