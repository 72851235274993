import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import propTypes from './props'
import Loading from '../../atoms/Loader'

const RemoveCardModal = ({ t, handleClose, ableRemove, cardInfo, removeCard }) => {
  const [loading, setLoading] = useState(false)

  return loading ? (
    <Loading loading />
  ) : (
    <Grid container item className="remove-card-modal">
      <Grid container item className="remove-card-modal__check">
        {t('removeCardModal.check')}
      </Grid>
      <Grid container item>
        <Typography variant="inherit" className="remove-card-modal__description">
          <span>{ableRemove ? t('removeCardModal.question') : t('removeCardModal.denied')}</span>
          <strong>{cardInfo.toUpperCase()}</strong>
          <span>{ableRemove ? t('removeCardModal.questionSign') : t('removeCardModal.causeDenied')}</span>
        </Typography>
      </Grid>
      <Grid container item className="remove-card-modal__buttons">
        <button type="button" className="remove-card-modal__buttons__cancel" onClick={() => handleClose()}>
          {t(`${ableRemove ? 'default.cancel' : 'default.ok'}`)}
        </button>
        {ableRemove && (
          <button
            type="button"
            className="remove-card-modal__buttons__remove"
            onClick={() => {
              setLoading(true)
              removeCard()
            }}>
            {t('removeCardModal.removeButton')}
          </button>
        )}
      </Grid>
    </Grid>
  )
}

RemoveCardModal.propTypes = propTypes
export default RemoveCardModal
