/*  eslint-disable jsx-a11y/label-has-for */
/*  eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import { getDeliveryDays, getDayBlocked, isHoliday, isSafari } from '../../../utils/helpers'
import Actions from '../../../actions'
import propTypes from './props'
import FlatButton from '../../molecules/FlatButton'

const StatusAndShippingInformation = ({
  shippingDate,
  status,
  t,
  currentLanguage,
  id,
  emirate,
  openDatePicker,
  handleCloseDatePicker
}) => {
  const [selectedDate, setSelectedDate] = useState(moment(shippingDate))
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [safariDate, setSafariDate] = useState(moment(shippingDate))
  const dispatch = useDispatch()
  const updateSubscriptionShipping = subscriptionData => dispatch(Actions.Checkout.updateSubscription(subscriptionData))
  const localShippingDateUpdate = (newDate, subId) =>
    dispatch(Actions.Users.updateSubscriptionsShippingDate(newDate, subId))
  const setShippingDate = value => dispatch(Actions.Checkout.setShippingDate(value))
  const isEdition = useSelector(state => state.Checkout.isEdition)
  const editionDate = moment().add(6, 'days')

  const minDate = getDeliveryDays(emirate || 'Dubai')

  const canEdit = () => {
    return status === 'PENDING'
  }

  const handleSave = () => {
    if (isEdition) {
      setShippingDate(moment(selectedDate).format('YYYY-MM-DD'))
    }
    const data = {
      id,
      subscriptionShippingDate: selectedDate.format('YYYY-MM-DD')
    }
    updateSubscriptionShipping(data)
    localShippingDateUpdate(data.subscriptionShippingDate, id)
    if (isSafari()) {
      const safariData = {
        id,
        subscriptionShippingDate: safariDate.format('YYYY-MM-DD')
      }
      updateSubscriptionShipping(safariData)
      localShippingDateUpdate(safariData.subscriptionShippingDate, id)
    }
    if (!isEdition) handleCloseDatePicker()
  }

  useEffect(() => {
    if (isEdition) {
      setSelectedDate(editionDate)
      setSaveDisabled(false)
    }
  }, [])

  const handleRenderCalendarInfo = () => (
    <Grid container className="save-date-button-container" alignItems="center" justify="center" spacing={2}>
      <>
        <Grid item container>
          <FlatButton
            disabled={saveDisabled}
            title={t('default.saveNewShippingDate')}
            classes={`flat-button--green-jaka ${saveDisabled ? 'flat-button--green-jaka--disabled' : ''}`}
            cartIcon="floppyDisk"
            onClick={() => handleSave()}
          />
        </Grid>
        <Grid item container>
          <FlatButton title={'Cancel'} classes="flat-button--gray" onClick={() => handleCloseDatePicker()} />
        </Grid>
      </>
    </Grid>
  )

  return (
    <Grid
      container
      key={id}
      direction="row"
      wrap="nowrap"
      style={{ position: 'relative !important' }}
      className={`status-shipping-information${currentLanguage === 'AR' ? ' invertAr' : ''} ${
        isEdition && 'in-edition'
      }`}>
      {shippingDate && (
        <Grid container className="status-shipping-information-container" direction="row" alignItems="center">
          <SingleDatePicker
            hideKeyboardShortcutsPanel
            id={`calendarComponent${id}`}
            date={selectedDate}
            small
            onDateChange={date => {
              setSelectedDate(date)
              if (isSafari()) setSafariDate(date)
              setSaveDisabled(date._i === moment(date._d).format('YYYY-MM-DD'))
            }}
            keepOpenOnDateSelect
            disabled={!canEdit()}
            focused={openDatePicker}
            onFocusChange={({ focused }) => {
              if (!focused) setSelectedDate(moment(shippingDate))
              if (isEdition) setSelectedDate(editionDate)
            }}
            displayFormat="DD/MM/YYYY"
            isDayBlocked={date => getDayBlocked(emirate, date.day()) || isHoliday(date)}
            isOutsideRange={day =>
              (isEdition && day.isBefore(moment().add(5, 'days'))) ||
              day.isBefore(moment(minDate)) ||
              day.isAfter(moment(getDeliveryDays(emirate)).add(60, 'days'))
            }
            numberOfMonths={1}
            readOnly
            renderCalendarInfo={() => handleRenderCalendarInfo()}
          />
        </Grid>
      )}
    </Grid>
  )
}

StatusAndShippingInformation.propTypes = propTypes
export default StatusAndShippingInformation
