import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { LastLocationProvider } from 'react-router-last-location'
import i18next from 'i18next'
import { translate } from 'react-i18next'
import useTagManagerLocation from './Hooks/useTagManagerLocation'
import PrivateRoute from './containers/PrivateRoute'
import Actions from './actions'
import { FIRST_COLLECTION } from './utils/urls'

const MainWrapper = React.lazy(() => import('./MainWrapper'))
const NotFound404 = React.lazy(() => import('./components/templates/NotFound404'))
const Products = React.lazy(() => import('./containers/Products'))
const CartCheck = React.lazy(() => import('./containers/CartCheck'))
const Checkout = React.lazy(() => import('./containers/Checkout'))
const CheckDone = React.lazy(() => import('./containers/CheckDone'))
const ContactUs = React.lazy(() => import('./containers/ContactUs'))
const Account = React.lazy(() => import('./containers/Account'))
const pastOrders = React.lazy(() => import('./containers/pastOrders'))
const Boxes = React.lazy(() => import('./containers/Boxes'))
const ChangePasswordModal = React.lazy(() => import('./containers/ChangePasswordModal'))
const VerifyRecovery = React.lazy(() => import('./containers/VerifyRecovery'))
const Helpdesk = React.lazy(() => import('./containers/Helpdesk'))
const ProductDetailPage = React.lazy(() => import('./containers/ProductDetailPage'))
const LoginRedirection = React.lazy(() => import('./containers/LoginRedirection'))
const Home = React.lazy(() => import('./containers/Home'))
const UpdatingPage = React.lazy(() => import('./containers/UpdatingPage'))

const Router = () => {
  const currentLanguage = useSelector(state => state.Language.current)
  const dispatch = useDispatch()
  useTagManagerLocation()
  useEffect(() => {
    dispatch(Actions.Users.fetchUpdateUser())
    i18next.changeLanguage(currentLanguage.toLowerCase())
    window.scrollTo(0, 0)
  }, [])
  return (
    <MainWrapper>
      <main>
        <LastLocationProvider>
          <Switch>
            <Route
              exact
              path="/"
              component={
                process.env.REACT_APP_IMAGE_TAG === 'prod'
                  ? () => {
                      window.location.href = 'https://yallababy.com'
                      return null
                    }
                  : Home
              }
            />
            <Route
              exact
              path="/about-us"
              component={() => {
                window.location.href = `https://yallababy.${
                  process.env.REACT_APP_IMAGE_TAG === 'prod' ? 'com' : 'ae'
                }#footer`
                return null
              }}
            />
            <Route
              exact
              path="/faq"
              component={() => {
                window.location.href = `https://yallababy.${
                  process.env.REACT_APP_IMAGE_TAG === 'prod' ? 'com' : 'ae'
                }#faq`
                return null
              }}
            />
            <Route exact path="/updating" component={UpdatingPage} />
            <Route exact path="/contact-us/:ar?" component={ContactUs} />
            <Route exact path="/account/changepassword" component={ChangePasswordModal} />
            <Route path="/404" component={NotFound404} />
            <Route exact path="/products/:collection/:login?/:customFilter?" component={Products} />
            <Route exact path="/products" render={() => <Redirect to={`/products/${FIRST_COLLECTION}`} />} />
            <Route exact path="/cart" component={CartCheck} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/login-redirect" component={LoginRedirection} />
            <Route exact path="/verifyrecovery" component={VerifyRecovery} />
            <Route exact path="/helpdesk/:token" component={Helpdesk} />
            <Route exact path="/product/:productName" component={ProductDetailPage} />
            <PrivateRoute>
              <Route exact path="/account" component={Account} />
              <Route exact path="/past-orders" component={pastOrders} />
              <Route exact path="/boxes" component={Boxes} />
              <Route exact path="/account/editcustomer" component={Checkout} />
              <Route exact path="/checkout/shipping" component={Checkout} />
              <Route exact path="/checkout/editpayment" component={Checkout} />
              <Route exact path="/account/editshipping" component={Checkout} />
              <Route exact path="/account/editpayment" component={Checkout} />
              <Route exact path="/checkout/pay" component={Checkout} />
              <Route exact path="/checkout/(done|fail)" component={CheckDone} />
            </PrivateRoute>
          </Switch>
        </LastLocationProvider>
      </main>
    </MainWrapper>
  )
}

export default translate('common')(Router)
