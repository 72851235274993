/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-tabs */
import React from 'react'
import propTypes from './props'

const RoundCloseIcon = props => (
  <button type="button" {...props}>
    <svg width={props.width || '12px'} height={props.height || '12px'} viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M645.86039,844.680696 L658.681897,831.854696 C659.106686,831.429758 659.105938,830.741655 658.680227,830.317637 C658.254367,829.893471 657.564547,829.894217 657.13961,830.319304 L644.318103,843.145304 C643.893314,843.570242 643.894062,844.258345 644.319773,844.682363 C644.745633,845.106529 645.435453,845.105783 645.86039,844.680696 Z M658.682741,843.150363 L645.854158,830.318211 C645.430496,829.894432 644.742917,829.893857 644.318545,830.316927 C643.894321,830.73985 643.893745,831.426005 644.317259,831.849637 L657.145842,844.681789 C657.569504,845.105568 658.257083,845.106143 658.681455,844.683073 C659.105679,844.26015 659.106255,843.573995 658.682741,843.150363 Z" id="path-1" />
      </defs>
      <g id="Company-New" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Com--Interviews-1-Copy-2" transform="translate(-644.000000, -830.000000)">
          <rect id="Icon-box" fillRule="nonzero" x="644" y="830" width="15" height="15" />
          <mask id="mask-2" fill="white">
            <use href="#path-1" />
          </mask>
          <use id="Close" fill="#8D8C8C" fillRule="nonzero" href="#path-1" />
        </g>
      </g>
    </svg>
  </button>
)

RoundCloseIcon.propTypes = propTypes
export default RoundCloseIcon
