import React from 'react'
import { Grid, Typography, Link, Hidden, Container } from '@material-ui/core'
import propTypes from './props'

const Copyright = ({ t, EurekaLabs }) => {
  const termsAndConditions = (
    <Grid className="footer-copyright__links" container>
      <Link target="_blank" href="https://yallababy.com/legal-yalla-baby-box.html" rel="noreferrer">
        {t('footer.termsAndConditions')}
      </Link>
      <Link target="_blank" href="https://yallababy.com/legal-yalla-baby-box.html" rel="noreferrer">
        {t('footer.privacyPolicy')}
      </Link>
    </Grid>
  )

  return (
    <Grid className="footer-copyright">
      <Container className="footer-copyright__wrapper">
        <Grid container className="footer-copyright__company">
          <Grid item className="footer-copyright__company__item">
            <Typography>
              {t('default.aCompany')}
              <span>{t('default.byEureka')}</span>
            </Typography>
          </Grid>
          <Grid item className="footer-copyright__company__image footer-copyright__company__item">
            <Link target="_blank" href="https://eurekalabs.me/" rel="noreferrer noopenner">
              <img src={EurekaLabs} alt="Eureka Labs" />
            </Link>
          </Grid>
          <Hidden mdDown>
            <Grid item className="footer-copyright__company__item">
              {termsAndConditions}
            </Grid>
          </Hidden>
        </Grid>
      </Container>

      <Hidden lgUp>{termsAndConditions}</Hidden>
    </Grid>
  )
}

Copyright.propTypes = propTypes
export default Copyright
